// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { Box, CssBaseline, IconButton } from "@mui/material";
import TextField from "@mui/material/TextField";
import TrashIcon from "assets/icons/trash-red.svg";
import Header from "components/Dashboard/Header";
import { FormTypes } from "constants/enum.constants";
import { useFormData } from "hooks/surveyFormData";
import { Button, Tabs } from "pages/adminV2/Components";
import AdminHeader from "pages/adminV2/Components/AdminHeader";
import { useEffect, useRef, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import "./CreateSurvey.css";
import DeleteSurveyModel from "./models/DeleteSurvey";
import RichTextEditor from "./RichTextEditor";
import styles from "./styles";
interface tab {
    name: string;
    url: string;
}

const tabs: tab[] = [
    {
        name: "Form",
        url: "/v2/admin/forms/create",
    },
    {
        name: "Assign",
        url: "/v2/admin/forms/create/assign",
    },
];

function CreateSurveyLayout() {
    const [openDeleteForm, setOpenDeleteForm] = useState<boolean>(false);
    const [selectedRow, setSelectedRow] = useState<any>({});

    const searchParams = new URLSearchParams(window.location.search);
    const [title, setTitle] = useState("");
    const [subTitle, setSubTitle] = useState("");
    const [directions, setDirections] = useState("");
    const id = parseInt(searchParams.get("id"));
    const isCreate = searchParams.get("isCreate");
    const isEdit = searchParams.get("isEdit");
    const isView = !!searchParams.get("isView") && searchParams.get("isView") === "true";
    const childRef = useRef<any>();
    const [noOfUsers, setNoOfUsers] = useState([0, 1, 2, 3, 4, 5, 6].map((value) => ({ value: value, label: value })));
    // const [openDeleteForm, setOpenDeleteForm] = useState<boolean>(false);
    const [selectedNoOfUsers, setSelectedNoOfUsers] = useState(null);
    const [activeTab, setActiveTab] = useState<tab>({
        name: "",
        url: "",
    });
    const location = useLocation();
    const navigate = useNavigate();

    const onTabSelect = (tab: tab) => {
        setActiveTab(tab);
        navigate(`${tab.url}?title=${title}&id=${id}&isEdit=${isEdit}`);
    };

    useEffect(() => {
        refetchSurveyQuestionsData();
    }, [activeTab]);

    useEffect(() => {
        const tab: tab = tabs.find((tab) => location.pathname == tab.url) || {
            name: "",
            url: "",
        };
        setActiveTab(tab);
        refetchSurveyQuestionsData();
    }, []);

    const handleSaveClick = () => {
        if (childRef.current) {
            if (typeof childRef.current.save === "function") {
                childRef.current.save();
            }
        }
    };
    const handleDirectionChange = (e: any) => {
        setDirections(e.target.value);
        if (childRef.current) {
            if (typeof childRef.current.handleDirections === "function") {
                childRef.current.handleDirections(e.target.value);
            }
        }
    };

    const handleSubTitleChange = (e: any) => {
        setSubTitle(e.target.value);
        if (childRef.current) {
            if (typeof childRef.current.handleSubTitle === "function") {
                childRef.current.handleSubTitle(e.target.value);
            }
        }
    };

    // handle delete click from child Outlet components
    const handleDeleteClick = () => {
        setOpenDeleteForm(true);
    };

    const handleSectionClick = () => {
        if (childRef.current) {
            if (typeof childRef.current.addSection === "function") {
                childRef.current.addSection();
            }
        }
    };

    const handleHeadDeleteAction = () => {
        setSelectedRow([id]);
        setOpenDeleteForm(true);
    };

    const onSurveyQuestionsDataFetchSuccess = (data: any) => {
        setTitle(data?.formDetails?.container_name);
        setSubTitle(data?.formDetails?.sub_title);
        setDirections(data?.formDetails?.directions);
        if (childRef.current) {
            if (typeof childRef.current.handleOriginalTitle === "function") {
                childRef.current.handleOriginalTitle(data?.formDetails?.container_name);
            }
        }
    };

    const onSurveyQuestionsDataFetchError = (error: any) => {
        // TODO:- Perform any operation in case of errors while fetching Survey Data
        //
    };

    const {
        isFetched,
        isFetching: isSurveyQuestionsDataFetching,
        isLoading: isSurveyQuestionsDataLoading,
        isError: isSurveyQuestionsDataError,
        data: allSurveyQuestionsData,
        error,
        refetch: refetchSurveyQuestionsData,
    } = useFormData({
        surveyID: id,
        formType: FormTypes.presurvey,
        isEnabled: !isCreate,
        onSuccess: onSurveyQuestionsDataFetchSuccess,
        onError: onSurveyQuestionsDataFetchError,
    });

    const handleTitleChange = (event: any) => {
        setTitle(event.target.value);
        if (childRef.current) {
            if (typeof childRef.current.handleTitle === "function") {
                childRef.current.handleTitle(event.target.value);
            }
        }
    };
    return (
        <div className="createSurveyContainer">
            <Box sx={{ display: "flex", flexDirection: "column", margin: "2rem 3rem" }}>
                <CssBaseline />
                <Header title="Admin Dashboard" redirectTo="/v2/admin/forms" />
                <AdminHeader
                    title="Admin create form"
                    onIconClick={() => {
                        undefined;
                    }}
                />

                <Box sx={styles.container}>
                    {/* <IconButton sx={{ width: "42px", height: "42px" }}>
                    <img src={EyeIcon} alt="view" />
                </IconButton> */}

                    <input
                        className="titleInput"
                        value={title ?? ""}
                        onChange={handleTitleChange}
                        disabled={window.location.pathname.indexOf("assign") >= 0 ? true : isView}
                    />
                    <Button
                        type="primaryOutlined"
                        text="Add form section"
                        onClick={() => handleSectionClick()}
                        style={{
                            width: "300px",
                        }}
                    />

                    <Button type="primary" text="Save" onClick={() => handleSaveClick()} />

                    <IconButton sx={{ width: "42px", height: "42px" }} onClick={() => handleHeadDeleteAction()}>
                        <img src={TrashIcon} alt="Delete" />
                    </IconButton>
                </Box>

                {openDeleteForm && (
                    <DeleteSurveyModel
                        open={openDeleteForm}
                        setOpen={setOpenDeleteForm}
                        selectedRows={[id]}
                        typeOfRequest="Form"
                        refetchFunction={() => navigate("/v2/admin/forms")}
                    />
                )}

                {/* <Box sx={{ ...styles.container, alignItems: "center" }}>
                <FormControlLabel control={<Checkbox />} label="Allow users to complete form more than 1 time" />
                <Box sx={{ ...styles.selectStyles }}>
                    <ReactSelect
                        data={noOfUsers}
                        label={"Select 0-6 (Default is 1)"}
                        defaultValue={selectedNoOfUsers}
                        setValue={setSelectedNoOfUsers}
                        isDisabled={false}
                    />
                </Box>
            </Box> */}

                <div style={{ marginTop: "10px" }}>
                    <Tabs
                        tabs={tabs}
                        activeTab={activeTab}
                        setActiveTab={setActiveTab}
                        onTabSelect={onTabSelect}
                        enableDataCheck={true}
                    />{" "}
                </div>
                {window.location.pathname.indexOf("assign") < 0 && (
                    <Paper
                        sx={{ marginTop: "20px", borderRadius: "10px !important", padding: "20px" }}
                        elevation={0}
                        key={1}
                    >
                        <Box sx={{ p: 3 }}>
                            <Grid container spacing={1}>
                                <Grid item xs={12} gap={"2rem"} display={"flex"} flexDirection={"column"}>
                                    <TextField
                                        placeholder="Subtitle (optional)"
                                        value={subTitle}
                                        variant="outlined"
                                        onChange={handleSubTitleChange}
                                        fullWidth
                                        required
                                        sx={{ mb: 2 }}
                                        InputProps={{
                                            sx: {
                                                borderRadius: "9px",
                                                padding: "5px 1rem",
                                                // fontSize: "14px",
                                            },
                                        }}
                                        multiline
                                    />

                                    <RichTextEditor
                                        value={directions}
                                        handleValueChange={handleDirectionChange}
                                        placeholderText="Directions"
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                    </Paper>
                )}

                <Box component="main" sx={{ flexGrow: 1, p: 3, mt: 0 }}>
                    <Outlet context={{ childRef }} />
                </Box>
                {openDeleteForm && (
                    <DeleteSurveyModel
                        open={openDeleteForm}
                        setOpen={setOpenDeleteForm}
                        selectedRows={selectedRow}
                        typeOfRequest="Form"
                    />
                )}
            </Box>
        </div>
    );
}

export default CreateSurveyLayout;
