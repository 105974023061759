import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "components/VSBox";

const VSButton = ({ loading, children, sx, ...rest }: any) => (
    <Button sx={{ minHeight: "2.5rem", ...sx }} disabled={loading} {...rest}>
        {loading && (
            <Box mr={1}>
                <CircularProgress color="inherit" size={15} />
            </Box>
        )}
        {children}
    </Button>
);

export default VSButton
