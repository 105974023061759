import { useRef } from "react";
import { useNavigate } from "react-router-dom";
import LandingPageFooter from "../Footer";
import Navbar from "../Navbar";
import ResponsiveDrawer from "./leftNavigation";
import "./style.css";

export default function TermsAndConditions() {
    const contactRef = useRef<HTMLDivElement>(null);
    const navigate = useNavigate();

    const onScroll = (temp: any) => {
        console.log("he;");
    };
    return (
        <div>
            <Navbar
                onScroll={() => {
                    navigate("/");
                }}
                withBackground={true}
            />
            <ResponsiveDrawer />
            <LandingPageFooter contactRef={contactRef} onScroll={onScroll} hide={true} />
        </div>
    );
}
