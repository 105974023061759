import { RefObject } from "react";
import SaasPlatformImg from "../../../assets/images/GrowWithVS.svg";
import "./SaasPlatform.css";

interface Props {
    vsortsRef: RefObject<HTMLDivElement>;
}

const SaasPlatform: React.FC<Props> = ({ vsortsRef }) => {
    return (
        <div className="saasPlatform" ref={vsortsRef}>
            <div className="saasPlatformContainer">
                <div className="saasPlatformContainer-left">
                    <h1 style={{ fontSize: "30px" }}>
                        VSorts™ AI: A Unified Platform for AI-Powered for Qualitative Data Collection, Structuring, and
                        Analysis
                    </h1>
                    <p style={{ fontSize: "18px" }}>
                        VSorts™ AI is revolutionizing qualitative data management across research, the future of work,
                        and consulting in education and beyond. Our integrated platform seamlessly supports the entire
                        data lifecycle—facilitating efficient data collection, structuring, storage, and AI-driven
                        qualitative analysis and business intelligence. With proprietary AI CoPilots, developed as
                        &quot;data analysts&quot; or &quot;coaches&quot; trained in diverse social science and
                        organizational frameworks, VSorts™ AI delivers real-time insights that streamline workflows,
                        enhance response rates, provide instant feedback, and reduce project timelines by up to 75%.
                    </p>
                </div>
                <img src={SaasPlatformImg} alt="Saas platform" />
            </div>
        </div>
    );
};

export default SaasPlatform;
