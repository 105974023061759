export const formElementsType = [
    {
        label: "Text",
        value: "text",
    },
    {
        label: "Long Text",
        value: "textarea",
    },
    {
        label: "Number",
        value: "number",
    },
    {
        label: "Radio",
        value: "radio",
    },
    {
        label: "Checkbox",
        value: "checkbox",
    },
    {
        label: "Dropdown",
        value: "dropdown",
    },
    {
        label: "Date",
        value: "date",
    },
    {
        label: "Time",
        value: "time",
    },
    {
        label: "State",
        value: "state",
    },
    {
        label: "Header Info",
        value: "header_info",
    },
];
