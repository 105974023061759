import { FormTypes } from "constants/enum.constants";
import { StringConstants } from "constants/string.constants";
import { SwalConstants } from "constants/swal.constants";
import { useDeleteCoPilot } from "hooks/CoPilots";
import { bulkDeleteFormQuestions } from "hooks/surveyFormData";
import { DynamicModel } from "pages/adminV2/Components";
import { SwalAlert } from "utils/swal-utils";
import "./DeleteSurvey.css";

interface props {
    open: boolean;
    setOpen: (value: boolean) => void;
    selectedRows?: readonly number[];
    typeOfRequest: "Form" | "CoPilot";
    refetchFunction: () => void;
}

function DeleteSurveyModel({ open, setOpen, selectedRows, typeOfRequest, refetchFunction }: props) {
    // Function to handle Deleting of a Form Question

    const numberOfRows = selectedRows?.length ?? 0;

    const bulkDeleteQuestion = async (ids: any) => {
        const response = await bulkDeleteFormQuestions(ids, { form_type: FormTypes.presurvey }).catch((error) => {
            SwalAlert({
                icon: SwalConstants.warning,
                title: StringConstants.operationErrorTitle,
                description: StringConstants.operationErrorDescription,
            });
        });

        if(response?.data?.status === "SUCCESS") {
            setOpen(false);
            refetchFunction()
        }
    };

    const onDeleteSuccess = (data: any) => {
        refetchFunction();
        setOpen(false);
    };
    const onDeleteError = (error: any) => {
        undefined;
    };

    const { mutate: callingCopilot } = useDeleteCoPilot({
        data: {
            ids: selectedRows,
        },
        onSuccess: onDeleteSuccess,
        onError: onDeleteError,
    });

    const handleBulkDelete = async () => {
        if (typeOfRequest == "CoPilot") {
            await callingCopilot();
        } else {
            bulkDeleteQuestion({ ids: selectedRows });
        }
    };

    console.log("SELECTED ROWS", selectedRows);

    return (
        <DynamicModel
            open={open}
            setOpen={setOpen}
            title={
                typeOfRequest == "CoPilot"
                    ? `Are you sure you want to delete ${numberOfRows} CoPilot${numberOfRows > 1 ? "s" : ""}?`
                    : `Are you sure you want to delete ${numberOfRows} Form${numberOfRows > 1 ? "s" : ""}??`
            }
            type={typeOfRequest}
        >
            <div className="deleteModel">
                <p className="deleteModelContainer__desc">You cannot undo this action once you confirm</p>
                <div className="deleteModelContainer">
                    <button onClick={() => handleBulkDelete()}>
                        {typeOfRequest == "CoPilot"
                            ? `Delete CoPilot${numberOfRows > 1 ? "s" : ""}`
                            : `Delete Form${numberOfRows > 1 ? "s" : ""}`}
                    </button>
                </div>
            </div>
        </DynamicModel>
    );
}

export default DeleteSurveyModel;
