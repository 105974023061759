import VSBox from "components/VSBox";
import PropTypes from 'prop-types';
import VSTypography from "./VSTypography";
function TabPanel(props: any) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <VSBox sx={{ p: 3 }}>
            <VSTypography>{children}</VSTypography>
          </VSBox>
        )}
      </div>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

export default TabPanel;