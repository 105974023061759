import CoPilotPage from "pages/dashboard/CoPilots/coPilotPage";

export default function PreviewCoPilot({ selectedRow }: any) {
    const searchParams = new URLSearchParams(window.location.search);
    const id = searchParams.get("id");
    const isCreate = searchParams.get("isCreate");
    return (
        <div>
            <CoPilotPage preview={true} id={id} />
        </div>
    );
}
