// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { Button, Grid } from "@mui/material";
import { Box } from "@mui/system";
import { CurrentUserSelector } from "app/slices/UserSlice";
import CalendarIcon from "assets/icons/calendar.svg";
import CircularLoadingProgress from "components/CircularProgress";
import Header from "components/Dashboard/Header";
import Footer from "components/Footer";
import { FormTypes } from "constants/enum.constants";
import { useNextClusterStep } from "hooks/VsortsClusterFormData";
import styles from "pages/dashboard/styles";
import NetworkError from "pages/error/networkError";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import ParkingLot from "../parkingLot";
import UserSurveyForm from "../survey";
import UserVSortsActivities from "../user/UserVSortsActivities";
import VSetForm from "../vSets";

export default function VSortsClusterDetail() {
    const [clusterStepData, setClusterStepData] = useState([]);
    const [isData, setIsData] = useState(false);
    const [headerDetails, setHeaderDetails] = useState(null);
    const { clusterID } = useParams();
    const currentUser = useSelector(CurrentUserSelector);

    const navigate = useNavigate();

    const onNextClusterStepDataFetchSuccess = (data) => {
        // We set the data variable and make the setIsData variable to true signifying that the data has been set
        setClusterStepData(data);
        setIsData(true);
    };

    const onNextClusterStepDataFetchError = (error) => {
        // TODO:- Perform any operation in case of errors while fetching Survey Data
        //
    };

    const {
        isFetched,
        isFetching: isNextClusterStepDataFetching,
        // isLoading: isNextClusterStepDataLoading,
        isError: isNextClusterStepDataError,
        data,
        error,
        refetch: refetchNextClusterStepData,
    } = useNextClusterStep({
        clusterID,
        userID: currentUser.id,
        onSuccess: onNextClusterStepDataFetchSuccess,
        onError: onNextClusterStepDataFetchError,
    });

    /// We check if there is any data being fetched (In Foreground or Background) to display `CircularLoadingProgress`
    if (isNextClusterStepDataFetching) {
        return <CircularLoadingProgress />;
    }

    // We check if there is an error present while fetching all Questions of a Survey and display `NetworkError`
    if (isNextClusterStepDataError) {
        return <NetworkError handleOnRefresh={refetchNextClusterStepData} errorText={error} />;
    }

    if (isFetched && !isData) {
        setClusterStepData(data);
        setIsData(true);
    }

    const refreshNextClusterStep = (value) => {
        if (value) {
            refetchNextClusterStepData();
        }
    };

    const headerSection = (section) => {
        setHeaderDetails(section);
    };

    const ButtonStyles = {
        // borderColor: "#C0C0C0",
        // backgroundColor: "#C0C0C0",
        background: "#29ABE2",
        color: "white",
        textTransform: "none",
        fontFamily: "Montserrat",
        padding: "10px, 24px, 10px, 24px",
        fontWeight: "500",
        fontSize: "16px",
        lineHeight: "19px",
        borderRadius: "10px",
        // width: "150px",
        height: "42px",
    };

    const BoxStyles = {
        display: "flex",
        backgroundColor: "white",
        padding: "3rem",
        width: "100%",
        flexDirection: "column",
        alignItems: "center",
    };

    const currentDate = new Date();
    const startDate = new Date(clusterStepData.next_cluster_step.start_date?.split("T")[0]?.replace(/-/g, "/"));
    return (
        <>
            <Box sx={styles.dashboardLayoutContainer}>
                <Header />
                {/* header title and description */}
                {clusterStepData.next_cluster_step.start_date && currentDate < startDate ? (
                    <Box style={BoxStyles}>
                        <img
                            src={CalendarIcon}
                            alt="calendarIcon"
                            style={{ marginBottom: "20px" }}
                            onClick={() => navigate("/dashboard")}
                        />
                        <p>You have completed all the clusters steps available till date.</p>
                        <p>
                            The next activity will be available on{" "}
                            <b style={{ color: "#29ABE2" }}>{startDate.toDateString()}</b>.
                        </p>
                        <p>Please come back on or after the mentioned date to complete this cluster.</p>
                        <Button variant="contained" sx={ButtonStyles} onClick={() => navigate("/dashboard")}>
                            Go to dashboard
                        </Button>
                    </Box>
                ) : (
                    <>
                        {clusterStepData && clusterStepData.all_cluster_steps_completed && (
                            <Box style={BoxStyles}>
                                <p>You have completed all the cluster steps.</p>
                                <Button variant="contained" sx={ButtonStyles} onClick={() => navigate("/dashboard")}>
                                    Go to Dashboard
                                </Button>
                            </Box>
                        )}
                        {clusterStepData &&
                            clusterStepData.next_cluster_step &&
                            clusterStepData.next_cluster_step["form_type"] === FormTypes.vsorts && (
                                <Box sx={styles.titleContainer}>
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <UserVSortsActivities
                                                clusterActivities={clusterStepData.cluster_activities}
                                                clusterDetail={clusterStepData.cluster_detail}
                                                clusterStep={clusterStepData.next_cluster_step}
                                                headerDetails={headerDetails}
                                            />
                                        </Grid>
                                    </Grid>
                                </Box>
                            )}
                        <Box sx={styles.flexContainer}>
                            {clusterStepData &&
                                clusterStepData.next_cluster_step &&
                                (clusterStepData.next_cluster_step["form_type"] === FormTypes.presurvey ||
                                    clusterStepData.next_cluster_step["form_type"] === FormTypes.postsurvey ||
                                    clusterStepData.next_cluster_step["form_type"] === FormTypes.finalfeedback) && (
                                    <UserSurveyForm
                                        clusterStep={clusterStepData.next_cluster_step}
                                        refreshNextClusterStep={refreshNextClusterStep}
                                        clusterDetail={clusterStepData.cluster_detail}
                                    />
                                )}{" "}
                            {clusterStepData &&
                                clusterStepData.next_cluster_step &&
                                clusterStepData.next_cluster_step["form_type"] === FormTypes.unpacking && (
                                    <ParkingLot
                                        container_id={clusterStepData.next_cluster_step.container_id}
                                        cluster_id={clusterStepData.next_cluster_step.cluster_id}
                                        user_id={currentUser.id}
                                        cluster_step_id={clusterStepData.next_cluster_step.id}
                                    />
                                )}{" "}
                            {clusterStepData &&
                                clusterStepData.next_cluster_step &&
                                clusterStepData.next_cluster_step["form_type"] === FormTypes.vsorts && (
                                    <VSetForm
                                        clusterId={clusterStepData.next_cluster_step.cluster_id}
                                        clusterStep={clusterStepData.next_cluster_step}
                                        refreshNextClusterStep={refreshNextClusterStep}
                                        clusterDetail={clusterStepData.cluster_detail}
                                        headerSection={headerSection}
                                    />
                                )}{" "}
                        </Box>
                    </>
                )}

                <Footer />
            </Box>
        </>
    );
}
