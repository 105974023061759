import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";

// Axios Interceptor
import { request } from "utils/axios-utils";

// Constants
import { APIMethodConstants } from "constants/api.method.constants";
import { NetworkConstants } from "constants/network.constants";
import { QueryKeyConstants } from "constants/query.key.constants";
import { ClusterStepData } from "types/dashboard";

const fetchVSortClusterAutocompleteData = () => {
    return request({ url: NetworkConstants.getVSortsClusterAutocompleteData });
};

export const fetchVSortsClusterSteps = ({ clusterID }: any) => {
    return request({ url: NetworkConstants.getVSortsClusterData + clusterID });
};

export const fetchVSortsClusterPublicStatus = ({ clusterID }: any) => {
    return request({ url: NetworkConstants.getVSortsPublicStatus + clusterID });
};

const fetchClusterPublicStatus = (clusterID: any) => {
    return request({
        url: `${NetworkConstants.revertClusterIsPublic}/${clusterID}`,
    });
};

const fetchClusterDetails = (clusterID: any) => {
    return request({
        url: `${NetworkConstants.getClusterDetails}/${clusterID}`,
    });
};

const revertClusterIsPublic = ({ clusterID }: any) => {
    return request({
        url: `${NetworkConstants.revertClusterIsPublic}/${clusterID}`,
        method: APIMethodConstants.put,
    });
};

// Admin API
export const fetchVSortsClusterStepsByUser = ({ clusterID, userID }: { clusterID: number; userID: string }) => {
    return request({ url: NetworkConstants.getVSortsClusterDataByUser(clusterID, userID) });
};

// Admin API
export const fetchUserVSortsClusterStepsStatus = ({ clusterID }: { clusterID: number }) => {
    return request({ url: NetworkConstants.getVSortsUserClusterStep + clusterID });
};

const fetchVSortsClusterStepQuestions = ({ clusterID }: any) => {
    return request({ url: NetworkConstants.getVSortsClusterQuestionsData + clusterID });
};

const createEditClusterSteps = ({ apiMethodType, data }: any) => {
    return request({ url: NetworkConstants.addVSortsClusterData, method: apiMethodType, data: data });
};

const deleteClusterStep = ({ stepID }: any) => {
    return request({ url: NetworkConstants.deleteVSortsClusterStep + stepID, method: APIMethodConstants.delete });
};

export const fetchVSortsNextClusterStep = ({ clusterID, userID }: any) => {
    return request({
        url: `${NetworkConstants.getVSortsNextClusterStepData}?cluster_id=${clusterID}&user_id=${userID}`,
    });
};

// useVSortsClusterAutocompleteData -> To fetch VSorts Cluster Autocomplete Data (Pre-Surveys, Post-Surveys and Final Feedback Surveys)
export const useVSortsClusterAutocompleteData = ({ onSuccess, onError }: any) => {
    return useQuery(QueryKeyConstants.fetchVSortsClusterAutocompleteQueryKey, fetchVSortClusterAutocompleteData, {
        onSuccess,
        onError,
        select: (data) => {
            return data.data.data;
        },
    });
};

// useClusterFormData -> To fetch All Steps of a VSorts Cluster
export const useClusterFormData = ({ isAdmin, clusterID, userID, onSuccess, onError, isEnabled = true }: any) => {
    if (isAdmin) {
        return useQuery(
            [QueryKeyConstants.fetchAdminVSortsClusterSteps, clusterID, userID],
            () => fetchVSortsClusterStepsByUser({ clusterID, userID }),
            {
                enabled: isEnabled,
                staleTime: Infinity,
                onSuccess,
                onError,
                select: (data) => {
                    const allData = data.data.data;
                    const clusterSteps: any = allData.clusterSteps;
                    const nextClusterSteps: any = allData.nextClusterSteps;
                    const stepData: ClusterStepData[] = clusterSteps.map((stepData: any) => ({
                        id: stepData.id,
                        clusterId: stepData.cluster_id,
                        containerId: stepData.container_id,
                        containerName: stepData.container_name,
                        deleted: stepData.deleted,
                        formType: stepData.form_data ?? stepData.form_type,
                        stepNumber: stepData.step_number,
                    }));

                    const nextClusterStep: number = nextClusterSteps?.next_cluster_step?.step_number || 1;

                    return { stepData, nextClusterStep };
                },
            }
        );
    }

    return useQuery(
        [QueryKeyConstants.fetchVSortsClusterSteps, clusterID],
        () => fetchVSortsClusterSteps({ clusterID: clusterID }),
        {
            enabled: isEnabled,
            staleTime: Infinity,
            onSuccess,
            onError,
            select: (data) => {
                const allData = data.data.data;
                const stepData: ClusterStepData[] = allData.map((stepData: any) => ({
                    id: stepData.id,
                    clusterId: stepData.cluster_id,
                    containerId: stepData.container_id,
                    containerName: stepData.container_name,
                    deleted: stepData.deleted,
                    formType: stepData.form_data ?? stepData.form_type,
                    stepNumber: stepData.step_number,
                    start_date: stepData.start_date,
                    startDatePresent: stepData.start_date && stepData.start_date.length > 0 ? true : false,
                }));

                return { stepData, nextClusterStep: 1 };
            },
        }
    );
};

// useUserClusterStepData -> To fetch All User Step status of a VSorts Cluster
export const useUserClusterStepData = ({ isAdmin, clusterID, userID, onSuccess, onError, isEnabled = true }: any) => {
    if (isAdmin) {
        return useQuery(
            [QueryKeyConstants.fetchAdminVSortsClusterSteps, clusterID, userID],
            () => fetchUserVSortsClusterStepsStatus({ clusterID }),
            {
                enabled: isEnabled,
                staleTime: Infinity,
                onSuccess,
                onError,
                select: (data) => {
                    const allData = data.data.data;
                    const clusterSteps: any = allData.clusterSteps;
                    const nextClusterSteps: any = allData.nextClusterSteps;
                    const stepData: ClusterStepData[] = clusterSteps.map((stepData: any) => ({
                        id: stepData.id,
                        clusterId: stepData.cluster_id,
                        containerId: stepData.container_id,
                        containerName: stepData.container_name,
                        deleted: stepData.deleted,
                        formType: stepData.form_data ?? stepData.form_type,
                        stepNumber: stepData.step_number,
                    }));

                    const nextClusterStep: number = nextClusterSteps?.next_cluster_step?.step_number || 1;

                    return { stepData, nextClusterStep };
                    // return data.data.data;
                },
            }
        );
    }

    return useQuery(
        [QueryKeyConstants.fetchUserVSortsClusterSteps, clusterID],
        () => fetchUserVSortsClusterStepsStatus({ clusterID: clusterID }),
        {
            enabled: isEnabled,
            // staleTime: Infinity,
            onSuccess,
            onError,
            select: (data) => {
                // const allData = data.data.data;
                // const stepData: ClusterStepData[] = allData.map((stepData: any) => ({
                //     id: stepData.id,
                //     clusterId: stepData.cluster_id,
                //     containerId: stepData.container_id,
                //     containerName: stepData.container_name,
                //     deleted: stepData.deleted,
                //     formType: stepData.form_data ?? stepData.form_type,
                //     stepNumber: stepData.step_number,
                // }));

                // return { stepData, nextClusterStep: 1 };
                return data.data.data;
            },
        }
    );
};

// useClusterStepQuestionsFormData -> To fetch All Steps of a VSorts Cluster with questions for each step
export const useClusterStepQuestionsFormData = ({ clusterID, onSuccess, onError, isEnabled = true }: any) => {
    return useQuery(
        [QueryKeyConstants.fetchVSortsClusterSteps, clusterID],
        () => fetchVSortsClusterStepQuestions({ clusterID: clusterID }),
        {
            enabled: isEnabled,
            staleTime: 30000,
            onSuccess,
            onError,
            select: (data) => {
                return data.data.data;
            },
        }
    );
};

export async function V_SORTS_NEXT_CLUSTER_STEP(clusterId: any, userId: any) {
    const response = await fetch(
        `${NetworkConstants.getVSortsNextClusterStepData}?cluster_id=${clusterId}&user_id=${userId}`
    );
    const data = await response.json();
    if (!response.ok) {
        throw new Error(data.message || "Failed to fetch data");
    }
    return data.data?.data;
}

export const V_SORTS_GET_NEXT_CLUSTER_STEP_MUTATION = async ({ clusterId }: any) => {
    const response = await fetch(`${NetworkConstants.getVSortsClusterData}${clusterId}`);
    const data = await response.json();
    if (!response.ok) {
        throw new Error(data.message || "Failed to fetch data");
    }
    return data.data?.data;
};

export const useNextClusterStep = ({ clusterID, userID, onSuccess, onError }: any) => {
    return useQuery(
        [QueryKeyConstants.fetchSurveyQuestionsQueryKey, clusterID, userID],
        () => fetchVSortsNextClusterStep({ clusterID, userID }),
        {
            staleTime: Infinity,
            onSuccess,
            onError,
            select: (data) => {
                return data.data.data;
            },
        }
    );
};

export const fetchVSortsReactionData = async ({ clusterID, userID, containerId }: any) => {
    const response = await fetch(
        `${NetworkConstants.baseURL}${NetworkConstants.getVSortsReactionData}?cluster_id=${clusterID}&user_id=${userID}&container_id=${containerId}`
    );
    const data = await response.json();
    if (!response.ok) {
        throw new Error(data.message || "Failed to fetch data");
    }
    return data.data?.data;
};

export const useAllClusterSteps = ({ clusterID, onSuccess, onError }: any) => {
    return useQuery(
        [QueryKeyConstants.fetchVSortsClusterSteps, clusterID],
        () => fetchVSortsClusterSteps({ clusterID }),
        {
            staleTime: Infinity,
            onSuccess,
            onError,
            select: (data) => {
                return data.data.data;
            },
        }
    );
};

// useCreateClusterStepsFormData -> To add data a New VSorts Cluster
export const useCreateClusterStepsFormData = () => {
    const navigate = useNavigate();
    const queryClient = useQueryClient();

    return useMutation(
        ({ data, apiMethodType }: any) => createEditClusterSteps({ data: data, apiMethodType: apiMethodType }),
        {
            // We navigate back to the previous page if the Network Call is successful
            onSuccess: (_res, variables) => {
                const { cluster_id: clusterID } = variables.data;
                queryClient.invalidateQueries([QueryKeyConstants.fetchVSortsClusterSteps, clusterID]);
                queryClient.invalidateQueries(QueryKeyConstants.fetchVSortsClusterQueryKey);
                navigate(-1);
            },
        }
    );
};

// Same as useCreateClusterStepsFormData without navigation
export const useCreateClusterStepsForm = () => {
    const queryClient = useQueryClient();

    return useMutation(
        ({ data, apiMethodType }: any) => createEditClusterSteps({ data: data, apiMethodType: apiMethodType }),
        {
            onSuccess: (_res, variables) => {
                const { cluster_id: clusterID } = variables.data;
                queryClient.invalidateQueries([QueryKeyConstants.fetchVSortsClusterSteps, clusterID]);
                queryClient.invalidateQueries(QueryKeyConstants.fetchVSortsClusterQueryKey);
            },
        }
    );
};

// useDeleteVSortsClusterStepData -> To delete a step from a VSorts Cluster
export const useDeleteVSortsClusterStepData = ({ clusterID }: any) => {
    return useMutation((variables: any) => deleteClusterStep({ stepID: variables.stepID }));
};

export const useGetClusterIsPublicStatus = ({ clusterID, onSuccess, onError, isEnabled }: any) => {
    return useQuery([QueryKeyConstants.fetchCoPilotIsPublicStatus], () => fetchClusterPublicStatus(clusterID), {
        // staleTime: Infinity,
        enabled: isEnabled,
        onSuccess,
        onError,
        select: (data) => {
            return data.data.data;
        },
    });
};

export const useGetClusterDetails = ({ clusterID, onSuccess, onError, isEnabled }: any) => {
    return useQuery([QueryKeyConstants.fetchClusterDetailsKey], () => fetchClusterDetails(clusterID), {
        // staleTime: Infinity,
        enabled: isEnabled,
        onSuccess,
        onError,
        select: (data) => {
            return data.data.data;
        },
    });
};

export const useRevertClusterIsPublic = ({ clusterID, onSuccess, onError }: any) => {
    return useMutation(() => revertClusterIsPublic({ clusterID: clusterID }), {
        onSuccess: onSuccess,
        onError: onError,
    });
};
