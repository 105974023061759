const styles: any = {
    btnContinue: {
        // width: 135,
        height: 42,
        borderRadius: 10,
        border: "1px solid #29ABE2",
        background: "#29ABE2",
        color: "#fff",
        fontSize: 16,
        textTransform: "none",
        marginRight: 4,
        "&:hover": {
            background: "#29ABE2",
            color: "#fff",
            boxShadow: "none",
        },
        "&:disabled": {
            background: "#C0C0C0",
            color: "#fff",
            boxShadow: "none",
            border: "1px solid #C0C0C0",
        },
    },
    btnExit: {
        width: 135,
        height: 42,
        borderRadius: 10,
        border: "2px solid #94A8B0",
        background: "#fff",
        color: "#94A8B0",
        fontSize: 16,
        textTransform: "none",
        marginRight: "20px",
        "&:hover": {
            background: "#fff",
            color: "#94A8B0",
            boxShadow: "none",
            border: "2px solid #94A8B0",
        },
        "&:disabled": {
            background: "#fff",
            color: "#94A8B0",
            boxShadow: "none",
        },
    },
};

export default styles;
