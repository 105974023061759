// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { Grid } from "@material-ui/core";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Typography } from "@mui/material";
import DragIcon from "assets/icons//drag.svg";
import { Link } from "react-router-dom";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import "./sortableList.css";

const SortableItem = SortableElement(({ item, area, hoverItem, handleOpenCategoryDefinitionModal }) => (
    <Grid display="flex" justifyContent="center" alignItems="center" item xs={12} title={item.vignette_text}>
        {item?.vignette_text ? (
            <Grid
                container
                spacing={1}
                elevation={6}
                mt={area === "DISABLE" ? 0.5 : 0}
                style={{
                    boxShadow: "0 0 1px 4px rgba(169, 169, 169, 0.11)",
                    //boxShadow: "0 0 10px rgba(41, 171, 226, 0.5)",
                    backgroundColor: "#29ABE2",
                    opacity: item.id === -1 ? 0 : 1,
                    borderRadius: "8px",
                    minHeight: "80px",
                    width: "100%",
                    position: "relative",
                    marginBottom: "1.5rem",
                }}
            >
                {item?.vignette_text && item?.category_name && area === "DISABLE" && (
                    <Typography
                        variant="body2"
                        style={{
                            position: "absolute",
                            top: "-15px",
                            left: "10px",
                            color: "#70C6EB",
                            lineHeight: 0.8,
                            fontWeight: "bold",
                        }}
                    >
                        {item?.category_name}
                    </Typography>
                )}
                <Grid
                    item
                    style={{
                        backgroundColor: "#fff",
                        marginLeft: "10px",
                        width: "100%",
                        minHeight: "88px",
                        borderTopRightRadius: "5px",
                        borderBottomRightRadius: "5px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <Grid container>
                        <Grid
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            py={1}
                            mb={1}
                            item
                            xs={11}
                            sx={{ padding: "1rem 0rem 1rem 1rem" }}
                        >
                            {item.vignette_text}
                        </Grid>
                        <Grid item xs={1} display="flex" justifyContent="center" alignItems="center" mb={1} pr={1}>
                            <img style={{ width: 15, height: 30 }} src={DragIcon} alt="dots" />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        ) : area === "ENABLE" ? (
            <Grid
                container
                spacing={1}
                elevation={6}
                style={{
                    borderRadius: "8px",
                    height: "88px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: "#F9F9F9",
                }}
            />
        ) : (
            <Grid
                container
                spacing={1}
                style={{
                    border: "1px dashed #29ABE2",
                    borderRadius: "8px",
                    height: "88px",
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    margin: "0 0",
                    marginBottom: "1.5rem",
                    backgroundColor: "#F3F8FB",
                }}
                className="sortable-item"
                onMouseEnter={() => hoverItem(item)}
            >
                <Grid item>
                    <Grid container>
                        <Grid item xs={12} style={{ color: "#29ABE2" }}>
                            {item.category_name}
                            &nbsp;
                            <Link style={{ color: "#29ABE2" }} onClick={() => handleOpenCategoryDefinitionModal()}>
                                <InfoOutlinedIcon fontSize="inherit" />
                            </Link>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        )}
    </Grid>
));

const SortableList = SortableContainer(
    ({ area, items, isDragging, hoverItem, isDraggable = true, handleOpenCategoryDefinitionModal }) => (
        <Grid container spacing={3} py={area === "DISABLE" ? 1 : 0}>
            {items?.map((item, index) => (
                <SortableItem
                    disabled={!item?.vignette_text || !isDraggable}
                    area={area}
                    key={index}
                    index={index}
                    item={item}
                    collection={area}
                    isDragging={isDragging}
                    hoverItem={hoverItem}
                    handleOpenCategoryDefinitionModal={handleOpenCategoryDefinitionModal}
                />
            ))}
        </Grid>
    )
);

export default SortableList;
