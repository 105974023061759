const styles = {
    container: {
        padding: {
            xs: '30px 6% 0',
            md: '110px 6% 0',
        },
        background: '#0c0c0c',
    },
    mainFooter: {
        display: 'flex',
        flexDirection: {
            xs: 'column',
            md: 'row',
        },
        alignItems: 'center',
        borderBottom: '1px solid #8F8F8F',
        paddingBottom: 40,
    },
    mainFooterLeft: {
        flex: 6,
        paddingRight: {
            xs: 0,
            md: '20%',
        },
        marginBottom: {
            xs: 40,
            md: 0,
        },
        display: 'flex',
        flexDirection: 'column',
        alignItems: {
            xs: 'center',
            md: 'flex-start',
        },
    },
    flexContainer: {
        display: 'flex',
        flexDirection: {
            xs: 'column',
            md: 'row',
        },
        alignItems: 'center',
    },
    logo: {
        height: 70,
        marginRight: 20,
    },
    desc: {
        fontSize: '1rem',
        color: '#8f8f8f',
        marginTop: {
            xs: 10,
            md: 0,
        },
    },
    demoLink: {
        marginTop: 75,
        display: 'block',
    },
    requestMeetingText: {
        fontWeight: 700,
        fontSize: {
            xs: '2rem',
            xl: '3rem',
        },
        color: '#fff',
    },
    mainFooterRight: {
        flex: 4,
        display: 'flex',
        flexDirection: 'column',
        alignItems: {
            xs: 'center',
            md: 'flex-start',
        }
    },
    info: {
        display: 'flex',
        flexDirection: 'column',
    },
    infoTextBold: {
        fontWeight: 700,
        fontSize: '1rem',
        color: '#fff',
        marginTop: {
            xs: 10,
            md: 0,
        },
    },
    infoText: {
        fontSize: '1rem',
        color: '#fff',
    },
    followUs: {
        marginTop: 30,
    },
    socialIcons: {
        display: 'flex',
        alignItems: 'center',
        marginTop: 16,
    },
    menuSocial: {
        marginRight: 20,
    },
    socialIcon: {
        height: 16,
    },
    menuIcon: {
        width: 30,
        height: 30,
        marginRight: 8,
    },
    menuLink: {
        display: 'flex',
        alignItems: 'center',
        textDecoration: 'none',
        marginRight: 20,
    },
    menuInfo: {
        fontSize: '1.1rem',
        color: '#fff',
    },
    btnSignUp: {
        padding: '13px 37px',
        background: 'linear-gradient(270deg, #25A2D5 0%, #009043 100%)',
        border: 'none',
        borderRadius: 5,
        fontWeight: 700,
        fontSize: '1rem',
        color: '#fff',
        textDecoration: 'none',
        margin: '40px 0',
        display: 'block',
        width: 'fit-content',
    },
    footerMenu: {
        display: 'flex',
        flexDirection: {
            xs: 'column',
            md: 'row',
        },
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '30px 0'
    },
    menuItem: {
        display: 'flex',
        alignItems: 'center',
        fontWeight: 700,
        fontSize: '1rem',
        color: '#fff',
        textDecoration: 'none',
        textTransform: 'none',
        margin: '0 10px',
    },
    footerMenuInfoContainer: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: {
            xs: 20,
            md: 0,
        },
    },
}

export default styles