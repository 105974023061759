import { deleteVSortsCluster } from "hooks/VSortsClusterData";
import { DynamicModel } from "pages/adminV2/Components";
import "./deleteVset.css";

interface props {
    open: boolean;
    setOpen: (value: boolean) => void;
    selected?: any;
    refetchVsetData: () => void;
}

function DeleteVSetModal({ open, setOpen, selected, refetchVsetData }: props) {
    const handleDelete = async () => {
        await deleteVSortsCluster(selected.id, {}).then((_) => {
            refetchVsetData();
            setOpen(false);
        });
    };

    return (
        <DynamicModel
            open={open}
            setOpen={setOpen}
            title={`Are you sure you want to delete ${selected["VSet name"]} VSet?`}
        >
            <div className="deleteModal">
                <div className="deleteModalContainer">
                    <p className="deleteModalContainer__desc">You cannot undo this action once you confirm</p>
                    <button onClick={() => handleDelete()}>Delete VSet</button>
                </div>
            </div>
        </DynamicModel>
    );
}

export default DeleteVSetModal;
