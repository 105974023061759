const styles: any = {
    title: {
        fontWeight: "700",
        fontSize: "24px",
        color: "#111111",
        lineHeight: "34px",
        marginTop: "3vh",
    },
    subTitle: {
        fontWeight: "400",
        fontSize: "16px",
        color: "#111111",
        lineHeight: "26px",
        marginTop: "3vh",
        marginBottom: "3vh",
    },
    startBtn: {
        width: "455px",
        height: "42px",
        color: "#29ABE2",
        borderColor: "#29ABE2",
        fontSize: "16px",
        fontWeight: "500",
        borderRadius: "10px",
        lineHeight: "19.5px",
        padding: "10px, 24px, 10px, 24px",
        gap: "8px",
        borderWidth: "2px",
    },
};
export default styles;
