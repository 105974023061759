import Select from "react-select";
import "./Select.css";

const customStyles = (isFixedWidth: boolean) => ({
    menu: (provided: any) => ({
        ...provided,
        width: isFixedWidth ? "360px" : "100%",
    }),
    option: (provided: any) => ({
        ...provided,
        borderBottom: "1px dotted pink",
        textAlign: "center",
        fontFamily: "font-family: 'Roboto', sans-serif;",
        padding: 10,
    }),
    control: () => ({
        border: "1px solid #c0c0c0",
        background: "#fff",
        display: "flex",
        borderRadius: "7px",
        width: isFixedWidth ? "360px" : "100%",
        cursor: "pointer",
    }),
    singleValue: (provided: any, state: any) => {
        const opacity = state.isDisabled ? 0.5 : 1;
        const transition = "opacity 300ms";

        return { ...provided, opacity, transition };
    },
});

const ReactSelect = ({
    data,
    setValue,
    defaultValue,
    label,
    isMulti = false,
    isDisabled = false,
    isFixedWidth,
}: any) => {
    const handleSelect = (e: any) => {
        setValue(e);
    };

    return (
        <Select
            className="ReactSelect"
            options={data}
            placeholder={label ? <div>{label}</div> : "Select..."}
            isMulti={isMulti}
            menuPlacement="auto"
            menuPosition="absolute"
            isDisabled={isDisabled}
            value={defaultValue}
            styles={customStyles(isFixedWidth)}
            onChange={handleSelect}
        />
    );
};

export default ReactSelect;
