import "./DeleteVSets.css";

interface props {
    selectedRows: readonly number[];
    handleClickAction: () => void;
    type?: string;
}

const DeleteVSet = ({ type, selectedRows, handleClickAction }: props) => {
    return (
        <div className="deleteVSet">
            <div className="deleteVSetContainer">
                <p className="deleteVSetContainer__title">
                    Are you sure you want to delete {selectedRows?.length} VSet{selectedRows?.length > 1 && "s"}?
                </p>
                <p className="deleteVSetContainer__desc">You cannot undo this action once you confirm</p>
                <button onClick={() => handleClickAction()}>Delete VSet{selectedRows?.length > 1 && "s"}</button>
            </div>
        </div>
    );
};

export default DeleteVSet;
