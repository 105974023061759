import { Typography } from "@mui/material";
import coPilotStyle from "../../../../../../pages/dashboard/CoPilots/coPilotPageStyles";
import "../../../../Components/ViewFormQuestion/ViewFormQuestion.css";

export default function ViewIndividualResponse({ userResponse, index }: any) {
    return (
        <div className="collapsiblePanel__contentResponses">
            <div>
                <p>
                    <strong>Interaction {index + 1}: </strong>
                </p>

                <Typography style={coPilotStyle.outputTitles}>User&apos;s Input</Typography>
                <Typography style={coPilotStyle.vignetteDataDisplay}>{userResponse?.input_vignette}</Typography>
                <Typography sx={coPilotStyle.outputTitles} style={{ color: "#29ABE2", paddingTop: "20px" }}>
                    @VSorts AI
                </Typography>
                <Typography style={coPilotStyle.vignetteDataDisplay}>
                    {userResponse?.copilot_response?.explanation}
                </Typography>
                <Typography style={coPilotStyle.vignetteDataDisplay}>{userResponse?.v_class}</Typography>
                <br />
                <Typography style={{ color: "#29abe2", marginBottom: "10px", cursor: "pointer" }}>
                    View Scoring
                </Typography>
            </div>
            <hr />
        </div>
    );
}
