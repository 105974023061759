import AuthorDostIcon from "assets/icons/authordots.svg";
// import CeoAvatar from "assets/icons/ceoavatar.svg";
import LogoShortened from "assets/icons/Logo - Shortened.svg";

import "./Mission.css";

const Mission: React.FC = () => {
    return (
        <div>
            <div className="missionContainer">
                <div className="missionImageContainer">
                    <img src={LogoShortened} alt="testimonial" className="missionImage" />
                </div>
                <div className="missionContentContainer">
                    <div>
                        <h1 className="missionTitle">Our Vision.</h1>
                    </div>
                    <div>
                        Our vision is to be the leading provider of AI-assisted qualitative data solutions, empowering
                        organizational and personal users in Research, Future of Work, and Consulting in education and
                        beyond.
                    </div>
                    <div className="missionAuthor">
                        <img src={AuthorDostIcon} alt="testimonial" /> Dwayne Ray Cormier, Ph.D.{" "}
                        <span className="fontItalic">Founder and CEO</span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Mission;
