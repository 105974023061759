// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { Box, Typography } from "@mui/material"
import Logo from 'assets/images/logo-white.png'
import { Link } from "react-router-dom"
import styles from "./styles"

const ComingSoon = () => (
    <Box sx={styles.container}>
        <Link to="/" style={styles.logoLink}>
            <img src={Logo} alt="Logo" style={styles.logo} />
        </Link>
        <Typography sx={styles.title}>
            Coming Soon!
        </Typography>
    </Box>
)

export default ComingSoon