import uuid from "react-uuid";

// Material UI Components
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import SurveyElementWrapper from "./SurveyElementWrapper";

// Material Icons (Drag and Delete)
import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined";

// Form Element Type Constant

// Form Element Footer

// `RadioInput` is the function to show the user Radio Input while creating/editing a Survey Form
// 1. item -> Represents the Form Item Object (Consisting of all properties)
// 2. handleValue -> Represents the function to handle the Text Input value
// 3. deleteEl -> Represents the function to handle deleting the Form Element
// 4. handleRequired -> Represents the function to handle making a Form Element required/not required
// 5. handleElType -> Represents the function to handle the type of the Form Element
// 6. addOption -> Represents the function to add an option to the Radio Form Element
// 7. handleOptionValues -> Represents the function to handle the Text Value of the option/s of the Radio Form Element
// 8. deleteOption -> Represents the function to handle deleting of an option of the Radio Form Element
// 9. duplicateElement -> Represents the function to handle duplicating the Form Element
const RadioInput = (props: any) => {
    const { item, index, handleValue, addOption, handleOptionValues, deleteOption } = props;
    // Function to generate a new ID for the option and set the value to an empty string to pass it onto the `addOption` function
    const createNewOption = (id: any) => {
        const data = {
            id: uuid(),
            value: "",
        };
        addOption({ id, newOption: data, index });
    };

    return (
        <SurveyElementWrapper {...props}>
            <TextField
                defaultValue={item.question_text}
                variant="outlined"
                onBlur={(e) => handleValue({ id: item.id, e, index })}
                fullWidth
                required={!!item.is_required}
                placeholder="Question"
                sx={{ mb: 2 }}
                multiline
            />
            {item.answer_options &&
                item.answer_options.length > 0 &&
                item.answer_options.map((opt: any, key: number) => (
                    <Box key={key} sx={{ display: "flex" }}>
                        <TextField
                            variant="outlined"
                            fullWidth
                            placeholder={`Radio Option ${key + 1}`}
                            defaultValue={opt?.value}
                            key={opt?.id + key + 1}
                            sx={{ mb: 1 }}
                            onBlur={(e) =>
                                handleOptionValues({
                                    elId: item?.id,
                                    optionId: opt?.id,
                                    optionVal: e.target.value,
                                    index,
                                })
                            }
                        />
                        <Tooltip title="Delete Option" aria-label="delete-option">
                            <IconButton
                                aria-label="delete-option"
                                onClick={() => deleteOption({ elId: item.id, optionId: opt?.id, index })}
                                sx={{ ml: 2 }}
                            >
                                <DeleteOutlineOutlinedIcon color="secondary" />
                            </IconButton>
                        </Tooltip>
                    </Box>
                ))}
            <Button variant="text" onClick={() => createNewOption(item.id)}>
                Add Option
            </Button>
        </SurveyElementWrapper>
        // <Paper elevation={1}>
        //     <Box sx={{ textAlign: "center" }}>
        //         <DragIndicatorIcon sx={{ transform: "rotate(-90deg)", cursor: "all-scroll" }} />
        //     </Box>
        //     <Box sx={{ p: 3 }}>
        //         <Grid container spacing={1}>
        //             <Grid item xs={9}>
        //                 <TextField
        //                     defaultValue={item.question_text}
        //                     variant="outlined"
        //                     onBlur={(e) => handleValue(item.id, e)}
        //                     fullWidth
        //                     required={!!item.is_required}
        //                     placeholder="Question"
        //                     sx={{ mb: 2 }}
        //                 />
        //                 {item.answer_options &&
        //                     item.answer_options.length > 0 &&
        //                     item.answer_options.map((opt, key) => (
        //                         <Box key={key} sx={{ display: "flex" }}>
        //                             <TextField
        //                                 variant="outlined"
        //                                 fullWidth
        //                                 placeholder={`Radio Option ${key + 1}`}
        //                                 defaultValue={opt?.value}
        //                                 key={opt?.id + key + 1}
        //                                 sx={{ mb: 1 }}
        //                                 onBlur={(e) => handleOptionValues(item?.id, opt?.id, e.target.value)}
        //                             />
        //                             <Tooltip title="Delete Option" aria-label="delete-option">
        //                                 <IconButton
        //                                     aria-label="delete-option"
        //                                     onClick={() => deleteOption(item.id, opt?.id)}
        //                                     sx={{ ml: 2 }}
        //                                 >
        //                                     <DeleteOutlineOutlinedIcon color="secondary" />
        //                                 </IconButton>
        //                             </Tooltip>
        //                         </Box>
        //                     ))}
        //                 <Button variant="text" onClick={() => createNewOption(item.id)}>
        //                     Add Option
        //                 </Button>
        //             </Grid>
        //             <Grid item xs={3}>
        //                 <FormControl fullWidth>
        //                     <InputLabel id="el-type-label">Type</InputLabel>
        //                     <Select
        //                         labelId="el-type-label"
        //                         id="el-type"
        //                         label="Type"
        //                         value={item.answer_type}
        //                         onChange={(e) => handleElType(item.id, e.target.value)}
        //                     >
        //                         {formElementsType &&
        //                             formElementsType.map((el, key) => (
        //                                 <MenuItem key={key} value={el.value}>
        //                                     {el.label}
        //                                 </MenuItem>
        //                             ))}
        //                     </Select>
        //                 </FormControl>
        //             </Grid>
        //         </Grid>
        //     </Box>
        //     <FormElementFooter
        //         item={item}
        //         deleteEl={() => deleteEl(item.id)}
        //         duplicateElement={() => duplicateElement(item.id, item.answer_type)}
        //         handleRequired={() => handleRequired(item.id)}
        //     />
        // </Paper>
    );
};

export default RadioInput;
