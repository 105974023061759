import { Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

const Footer = () => {
    const navigate = useNavigate();
    return (
        <Grid
            container
            sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                padding: "1rem",
                gap: "10px",
                fontFamily: "Avenir",
                color: "#929292",
            }}
        >
            <Typography>VSorts™ AI ©2024 Culturally Responsive Solutions, LLC</Typography>
            <Typography
                onClick={() => {
                    navigate("/terms-conditions");
                }}
                style={{
                    cursor: "pointer",
                }}
            >
                Terms | Privacy
            </Typography>
        </Grid>
    );
};

export default Footer;
