import trustOrg4 from "../../../assets/images/Alabama-02.svg";
import trustOrg2 from "../../../assets/images/CSLA-01.svg";
import trustOrg1 from "../../../assets/images/Stem4Real-01.svg";
import trustOrg3 from "../../../assets/images/Voorhees-01.svg";
import "./Trust.css";

const trustedOrgData = [
    {
        image: trustOrg3,
        alt: "VOORHEES UNIVERSITY",
    },
    {
        image: trustOrg1,
        alt: "sTEM 4 REAL",
    },
    {
        image: trustOrg2,
        alt: "CAL STATE LA",
    },
    {
        image: trustOrg4,
        alt: "ALABAMA A&M UNIVERSITY",
    },
];

const Trust = () => {
    return (
        <div className="trust">
            <div className="trustContainer">
                <div className="trustContainer-top">
                    <h1>Trusted by Higher Education and Nonprofit Organizations</h1>
                    <p>
                        Gaining significant momentum in higher education, PreK-12, and the nonprofit sector, VSorts™ AI
                        is poised to redefine how the impact of bias and social risk is decreased in health care, law
                        enforcement, human resources, and market and social science research.{" "}
                    </p>
                </div>
                <div className="trustContainer-bottom">
                    <div className="trustContainer-bottomBlueContainer"></div>
                    {trustedOrgData.map((trustedOrg) => (
                        <img src={trustedOrg.image} alt={trustedOrg.alt} />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Trust;
