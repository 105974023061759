import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import 'dayjs/locale/en-gb';
import { DatePickerProps } from 'types/dashboard';

export default function DatePickerComponent({value, setValue, defaultValue} : DatePickerProps) {

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
      <DemoContainer components={['DatePicker']}>
        <DatePicker
          slotProps={{
            textField: {
              error: false,
              sx: {
                width: "95%"
              }
            },
          }}
          views={['year', 'month', 'day']}
          disableFuture
          value={value}
          onChange={(newValue: any) => setValue(newValue)}
        />
      </DemoContainer>
    </LocalizationProvider>
  );
}