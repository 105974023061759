import { Box, Button, Typography } from "@mui/material";
import { useCreateFormScore } from "hooks/surveyFormData";
import { useEffect, useState } from "react";
import { uid } from "react-uid";
import ScoringCardItem from "./ScoringCardItem";
import "./ViewFormQuestion.css";
import styles from "./styles";

export type Parameter = {
    id: number;
    title: string;
    enabled: boolean;
    type: "type1" | "type2" | "type3";
};

const ViewFormQuestion = ({ formQuestion, parametersData, userId, formType }: any) => {
    const [viewScores, setViewScores] = useState(false);
    const [selectedTier1Card, setSelectedTier1Card] = useState<number | null>(null);
    const [selectedTier2Card, setSelectedTier2Card] = useState<number | null>(null);
    const [selectedTier3Card, setSelectedTier3Card] = useState<number | null>(null);
    const [selectedAnswerId, setSelectedAnswerId] = useState(null);
    const [tier1Parameters, setTier1Parameters] = useState<Parameter[]>([]);
    const [tier2Parameters, setTier2Parameters] = useState<Parameter[]>([]);
    const [tier3Parameters, setTier3Parameters] = useState<Parameter[]>([]);
    const [submitTier1, setSubmitTier1] = useState<boolean>(false);
    const [submitTier2, setSubmitTier2] = useState<boolean>(false);
    const [submitTier3, setSubmitTier3] = useState<boolean>(false);
    const [enableTier1, setEnableTier1] = useState<boolean>(false);
    const [enableTier2, setEnableTier2] = useState<boolean>(false);
    const [enableTier3, setEnableTier3] = useState<boolean>(false);
    const [initialSetupComplete, setInitialSetupComplete] = useState(false);

    useEffect(() => {
        if (initialSetupComplete) {
            setSelectedTier2Card(null);
            setSelectedTier3Card(null);
        }
    }, [selectedTier1Card]);

    useEffect(() => {
        if (initialSetupComplete) {
            setSelectedTier3Card(null);
        }
    }, [selectedTier2Card]);

    useEffect(() => {
        const groupedParameters = parametersData?.reduce(
            (acc: { [key: string]: Parameter[] }, item: Parameter) => {
                acc[item.type] = [...(acc[item.type] || []), item];
                return acc;
            },
            { type1: [], type2: [], type3: [] }
        );

        setTier1Parameters(groupedParameters.type1);
        setTier2Parameters(groupedParameters.type2);
        setTier3Parameters(groupedParameters.type3);

        if (formQuestion.scores.length) {
            formQuestion.scores?.forEach((score: any) => {
                if (score.scoring_parameter_id >= 3 && score.scoring_parameter_id <= 5) {
                    setSelectedTier2Card(score.scoring_parameter_id);
                    setSubmitTier2(true);
                    setEnableTier3(true);
                }

                if (score.scoring_parameter_id >= 6 && score.scoring_parameter_id <= 11) {
                    setSelectedTier3Card(score.scoring_parameter_id);
                    setSubmitTier3(true);
                }

                if (score.scoring_parameter_id >= 1 && score.scoring_parameter_id <= 2) {
                    setSelectedTier1Card(score.scoring_parameter_id);
                    setSubmitTier1(true);
                    setEnableTier1(true);
                    setEnableTier2(true);
                }
            });
        }
        setInitialSetupComplete(true);
    }, [parametersData.length, initialSetupComplete === false]);

    const handleExpandableView = (answerId: any) => {
        setSelectedAnswerId(answerId);
        setViewScores((prev) => !prev);
    };

    const {
        mutate: createFormData,
        isLoading: isCreateFromDataLoading,
        isError: isCreateFormDataError,
        reset: resetCreateFormDataMutation,
    } = useCreateFormScore({
        data: {
            form_type: formType,
            answer_id: selectedAnswerId,
            user_id: userId,
        },
    });

    const submitTier = (type: string) => {
        switch (type) {
            case "Tier1":
                setSubmitTier1(true);
                createFormData(selectedTier1Card);
                setEnableTier2(true);
                break;
            case "Tier2":
                if (!submitTier1) {
                    alert("submit tier1 before submitting tier2");
                    return;
                }
                setSubmitTier2(true);
                createFormData(selectedTier2Card);
                setEnableTier3(true);
                break;
            case "Tier3":
                if (!submitTier1 && !submitTier2) {
                    alert("submit tier1 and tier2 before submitting tier3");
                    return;
                }
                setSubmitTier3(true);
                createFormData(selectedTier3Card);
                break;
        }
    };

    const handleSelectTier1Card = (id: number | null) => {
        setSelectedTier1Card(id);
        setEnableTier1(true);
    };

    const handleSelectTier2Card = (id: number | null) => {
        setSelectedTier2Card(id);
        // setEnableTier2(true);
    };

    const handleSelectTier3Card = (id: number | null) => {
        setSelectedTier3Card(id);
        // setEnableTier3(true);
    };

    return (
        <div className="collapsiblePanel__contentResponses">
            <div className="collapsiblePanel__contentResponses-ques">
                <p>
                    <strong>Question {formQuestion.question_number}: </strong>
                </p>
                <p>{formQuestion.question_text}</p>
            </div>
            <p>{formQuestion.answer}</p>
            <Typography onClick={() => handleExpandableView(formQuestion.answer_id)}>
                <p className="expandable-view">{viewScores ? "Close Scoring" : "View Scoring"}</p>
            </Typography>
            {viewScores ? (
                <Box>
                    <Box sx={styles.questionRowContainer}>
                        <Box sx={styles.questionCardContainer}>
                            {tier1Parameters.map((parameter) => {
                                let isEnabled = false;
                                if (submitTier1) {
                                    isEnabled = selectedTier1Card === parameter.id;
                                } else {
                                    isEnabled = parameter.id === 1 || parameter.id === 2;
                                }
                                return (
                                    <ScoringCardItem
                                        key={uid(parameter)}
                                        selectedCardId={selectedTier1Card}
                                        setSelectedTypeCard={handleSelectTier1Card}
                                        isEnabled={isEnabled}
                                        parameter={parameter}
                                    />
                                );
                            })}
                        </Box>
                        <Button
                            className={
                                submitTier1
                                    ? "submitButton submitted"
                                    : enableTier1
                                    ? "submitButton"
                                    : "disableSubmitButton"
                            }
                            onClick={() => submitTier("Tier1")}
                            disabled={submitTier1}
                        >
                            {submitTier1 ? "Submitted" : "Submit"}
                        </Button>
                    </Box>

                    <Box sx={styles.questionRowContainer}>
                        <Box sx={styles.questionCardContainer}>
                            {tier2Parameters.map((parameter) => {
                                let isEnabled = false;
                                if (submitTier2) {
                                    isEnabled = selectedTier2Card === parameter.id;
                                } else {
                                    if (selectedTier1Card === 1) {
                                        isEnabled = parameter.id === 3 || parameter.id === 4;
                                    } else if (selectedTier1Card === 2) {
                                        isEnabled = parameter.id === 5 || parameter.id === 4;
                                    }
                                }
                                return (
                                    <ScoringCardItem
                                        key={uid(parameter)}
                                        selectedCardId={selectedTier2Card}
                                        setSelectedTypeCard={handleSelectTier2Card}
                                        isEnabled={isEnabled}
                                        parameter={parameter}
                                    />
                                );
                            })}
                        </Box>
                        <Button
                            className={
                                submitTier2
                                    ? "submitButton submitted"
                                    : enableTier2
                                    ? "submitButton"
                                    : "disableSubmitButton"
                            }
                            onClick={() => submitTier("Tier2")}
                            disabled={submitTier2}
                        >
                            {submitTier2 ? "Submitted" : "Submit"}
                        </Button>
                    </Box>

                    <Box sx={styles.questionRowContainer}>
                        <Box sx={styles.questionCardContainer}>
                            {tier3Parameters.map((parameter) => {
                                let isEnabled = false;
                                if (submitTier3) {
                                    isEnabled = selectedTier3Card === parameter.id;
                                } else {
                                    if (selectedTier1Card === 1 && selectedTier2Card === 3) {
                                        isEnabled = parameter.id === 6 || parameter.id === 7;
                                    } else if (selectedTier2Card === 4) {
                                        isEnabled = parameter.id === 8 || parameter.id === 9;
                                    } else if (selectedTier1Card === 2 && selectedTier2Card === 5) {
                                        isEnabled = parameter.id === 10 || parameter.id === 11;
                                    }
                                }
                                return (
                                    <ScoringCardItem
                                        key={uid(parameter)}
                                        selectedCardId={selectedTier3Card}
                                        setSelectedTypeCard={handleSelectTier3Card}
                                        isEnabled={isEnabled}
                                        parameter={parameter}
                                    />
                                );
                            })}
                        </Box>
                        <Button
                            className={
                                submitTier3
                                    ? "submitButton submitted"
                                    : enableTier3
                                    ? "submitButton"
                                    : "disableSubmitButton"
                            }
                            onClick={() => submitTier("Tier3")}
                            disabled={submitTier3}
                        >
                            {submitTier3 ? "Submitted" : "Submit"}
                        </Button>
                    </Box>
                </Box>
            ) : null}
        </div>
    );
};

export default ViewFormQuestion;
