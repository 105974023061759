export const currentUserHasSuperAdminRole = (roles: any) => {
    return roles && roles.includes("superadmin");
};

export const currentUserHasAdminRole = (roles: any) => {
    return roles && (roles.includes("admin") || roles.includes("superadmin"));
};

export const currentUserHasUserRole = (roles: any) => {
    return roles && roles.includes("user");
};
