import { Box, FormControlLabel, Radio, RadioGroup, Typography } from "@mui/material";
import React from "react";
import styles from "./styles";

interface ClusterFilterProps {
    onFilterChange: (filter: string) => void;
    onSortingChange: (sorting: string) => void;
    handleFilterChanged: (filter: boolean) => void;
}

const ClusterFilter: React.FC<ClusterFilterProps> = ({ onFilterChange, onSortingChange, handleFilterChanged }) => {
    const [filter, setFilter] = React.useState("opentasks");
    const [sorting, setSorting] = React.useState("oldestcluster");
    const [filterFor, setFilterFor] = React.useState("culturalproficiency");

    const handleChangeFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
        onFilterChange((event.target as HTMLInputElement).value);
        setFilter((event.target as HTMLInputElement).value);
        handleFilterChanged(true);
    };

    const handleChangeFilterFor = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFilterFor((event.target as HTMLInputElement).value);
    };

    const handleChangeSorting = (event: React.ChangeEvent<HTMLInputElement>) => {
        onSortingChange((event.target as HTMLInputElement).value);
        setSorting((event.target as HTMLInputElement).value);
        handleFilterChanged(true);
    };

    console.log("FILTER", filter);

    return (
        <Box sx={styles.container}>
            <Box sx={styles.flexContainer}>
                <Box sx={styles.filterContainer}>
                    <Typography sx={styles.title}>Filter</Typography>
                    <RadioGroup
                        aria-labelledby="demo-filter-buttons-group-label"
                        name="filter-buttons-group"
                        value={filter}
                        onChange={handleChangeFilter}
                    >
                        <FormControlLabel
                            value="opentasks"
                            control={
                                <Radio
                                    disableRipple
                                    sx={{
                                        color: "#94A8B0",
                                        "&.Mui-checked": {
                                            color: "#94A8B0",
                                        },
                                    }}
                                />
                            }
                            label="Open&nbsp;tasks"
                        />
                        <FormControlLabel
                            value="completedtasks"
                            control={
                                <Radio
                                    disableRipple
                                    sx={{
                                        color: "#94A8B0",
                                        "&.Mui-checked": {
                                            color: "#94A8B0",
                                        },
                                    }}
                                />
                            }
                            label="Completed&nbsp;tasks"
                        />
                        <FormControlLabel
                            value="all"
                            control={
                                <Radio
                                    disableRipple
                                    sx={{
                                        color: "#94A8B0",
                                        "&.Mui-checked": {
                                            color: "#94A8B0",
                                        },
                                    }}
                                />
                            }
                            label="All"
                        />
                    </RadioGroup>
                </Box>
                <Box sx={styles.filterContainer}>
                    <Typography sx={styles.title}>Sorting</Typography>
                    <RadioGroup
                        aria-labelledby="demo-sorting-buttons-group-label"
                        name="sorting-buttons-group"
                        value={sorting}
                        onChange={handleChangeSorting}
                    >
                        {/* <FormControlLabel value="dueDate" control={<Radio color="default" />} label="Open tasks" /> */}
                        <FormControlLabel
                            value="newestcluster"
                            control={
                                <Radio
                                    disableRipple
                                    sx={{
                                        color: "#94A8B0",
                                        "&.Mui-checked": {
                                            color: "#94A8B0",
                                        },
                                    }}
                                />
                            }
                            label="New cluster"
                        />
                        <FormControlLabel
                            value="oldestcluster"
                            control={
                                <Radio
                                    disableRipple
                                    sx={{
                                        color: "#94A8B0",
                                        "&.Mui-checked": {
                                            color: "#94A8B0",
                                        },
                                    }}
                                />
                            }
                            label="Old cluster"
                        />
                    </RadioGroup>
                </Box>
                {/* <Box sx={styles.filterContainer}>
                    <Typography sx={styles.title}>Filter for</Typography>
                    <RadioGroup
                        aria-labelledby="demo-sorting-buttons-group-label"
                        name="sorting-buttons-group"
                        value={filterFor}
                        onChange={handleChangeFilterFor}
                    >
                        <FormControlLabel
                            value="culturalproficiency"
                            control={<Radio color="default" />}
                            label="Cultural Proficiency"
                        />
                        <FormControlLabel
                            value="culturalcompetence"
                            control={<Radio color="default" />}
                            label="Cultural Competence"
                        />
                        <FormControlLabel
                            value="culturalblindness"
                            control={<Radio color="default" />}
                            label="Cultural Blindness"
                        />
                    </RadioGroup>
                </Box> */}
            </Box>
        </Box>
    );
};

export default ClusterFilter;
