//Material UI Components
import Box from "@material-ui/core/Box";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import Select from "@material-ui/core/Select";
import Tooltip from "@material-ui/core/Tooltip";

// Material Icons (Drag and Delete)
import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined";
import { TextField } from "@mui/material";
import { ParameterTypeElements } from "constants/enum.constants";


// Static Components
// Cluster Form Builder Constants

// `ParameterStepItem` is the function to show the user Cluster Step Item (To pick either a VSorts Container, Pre-Survey, Post-Survey and Final Feedback) while creating/Editing the Cluster Form
// 1. item -> Represents the Form Item Object (Consisting of all properties)
// 2. handleAutocompleteInputValue -> Represents the function to handle the Text Input value
// 3. deleteEl -> Represents the function to handle deleting the Form Element
// 4. handleElType -> Represents the function to handle the type of the Form Element
// 5. autocompleteData -> Represents the list of VSorts Containers, Pre-Surveys, Post-Surveys and Final Feedback Surveys (Depending on `item.form_type`)
const ParameterStepItem = ({ item, deleteEl, handleElType, autocompleteData, handler, handleParamValueChange }: any) => {
    
    return (
        <Paper elevation={1} key={item.id}>
            <Box sx={{ p: 3 }}>
                <Grid container spacing={1}>
                    <Grid item xs={3}>
                        <FormControl fullWidth>
                            <InputLabel id="el-type-label">Type</InputLabel>
                            <Select
                                labelId="el-type-label"
                                id="el-type"
                                label="Type"
                                value={item.parameter_text}
                                onChange={(e) => handleElType(item.id, e.target.value)}
                            >
                                {ParameterTypeElements &&
                                    ParameterTypeElements.map((el, key) => (
                                        <MenuItem key={key} value={el.value}>
                                            {el.label}
                                        </MenuItem>
                                    ))}
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={9}>
                    <TextField
                            value={item.value}
                            variant="outlined"
                            onChange={(e) => handleParamValueChange(item.id, e.target.value)}
                            fullWidth
                            placeholder="Parameter Value"
                            label="Parameter Value"
                            sx={{ mb: 2 }}
                        />
                    </Grid>
                    <Tooltip title="Delete Element" aria-label="delete-element">
                        <IconButton
                            aria-label="delete-element"
                            onClick={() => deleteEl({ id: item.id })}
                            sx={{ ml: 2 }}
                        >
                            <DeleteOutlineOutlinedIcon color="secondary" />
                        </IconButton>
                    </Tooltip>
                </Grid>
            </Box>
        </Paper>
    );
};

export default ParameterStepItem;
