import CloseIcon from "@mui/icons-material/Close";
import { Box, Modal, Typography } from "@mui/material";

import AccordionDropdown from "components/AccordionDropdown";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 750,
    height: 543,
    bgcolor: "background.paper",
    boxShadow: 15,
    padding: "15px",
    borderRadius: "10px",
    display: "flex",
    flexDirection: "column",
};

const CategoryDefinitionModel = ({ open, handleClose, categories }: any) => {
    const title = categories[0]?.definition_title ?? "Cultural Competency Definitions";

    return (
        <div>
            <Modal
                keepMounted
                open={open}
                onClose={handleClose}
                aria-labelledby="keep-mounted-modal-title"
                aria-describedby="keep-mounted-modal-description"
            >
                <Box sx={style}>
                    {/* Close button */}
                    <Box style={{ display: "flex", justifyContent: "flex-end" }}>
                        <a onClick={() => handleClose()}>
                            <CloseIcon style={{ color: "#94A8B0" }} fontSize="small" />
                        </a>
                    </Box>

                    {/* Model Title */}
                    <Box style={{ display: "flex", justifyContent: "center" }}>
                        <Typography id="keep-mounted-modal-title" variant="h6" component="h2" fontWeight={500}>
                            {title}
                        </Typography>
                    </Box>

                    {/* Model Body */}
                    <Box sx={{ mt: 10, p: 10, overflowY: "scroll" }}>
                        {categories.map((category: any, index: number) => {
                            return (
                                <AccordionDropdown index header={category.category_name} key={index}>
                                    <Typography>{category.definition}</Typography>
                                    <br />
                                    <Typography color={"#929292"}>Example</Typography>
                                    <Typography sx={{ mt: 10 }}>{category.definition_example}</Typography>
                                </AccordionDropdown>
                            );
                        })}
                    </Box>
                </Box>
            </Modal>
        </div>
    );
};

export default CategoryDefinitionModel;
