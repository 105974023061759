const questionRowContainer = {
    display: "flex",
    marginTop: "-15px",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
};

const questionCardContainer = {
    flexDirection: "row",
    justifyContent: "left",
    display: "flex",
    gap: "1rem",
    alignItems: "center",
    width: "80%",
};

const styles = { questionRowContainer, questionCardContainer };

export default styles;
