import { useMemo } from "react";

// Material UI Components
import Box from "@material-ui/core/Box";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import Select from "@material-ui/core/Select";

// Material Drag Icon

// Form Element Type Constant
import { formElementsType } from "pages/admin/dashboard/components/FormBuilder/constants";

// Form Element Footer
import { Typography } from '@mui/material';
import FormElementFooter from "../ItemFooter";

// `SurveyElementWrapper` is the function to show the user Text Field Input while creating/editing a Survey Form
// 1. item -> Represents the Form Item Object (Consisting of all properties)
// 2. handleValue -> Represents the function to handle the Text Input value
// 3. deleteEl -> Represents the function to handle deleting the Form Element
// 4. handleRequired -> Represents the function to handle making a Form Element required/not required
// 5. handleElType -> Represents the function to handle the type of the Form Element
// 6. duplicateElement -> Represents the function to handle duplicating the Form Element
const styles = {
    dropdown: {
        outlineWidth: 0, 
        borderRadius: "10px",
         border: "none", 
         padding: "0",
         '& .MuiSelect-select': {
            padding: "6px",
            paddingLeft: "15px",
         },
    }
}
const SurveyElementWrapper = ({
    item,
    deleteEl,
    handleRequired,
    handleElType,
    duplicateElement,
    children,
    index,
    handler,
    arrayMoved
}: any) => {
    const { question_text, answer_type, is_required, answer_options, date, time } = item;
    return useMemo(
        () => (
            <Paper elevation={0} sx={{marginTop: "20px"}}>
                <Box sx={{ p: 3 }} display={"flex"}>
                    {handler}
                    <Grid container gap={"2rem"} display={"flex"} spacing={1}>
                        
                        <Grid item xs={3} display={"flex"} flexDirection={"column"} gap={"10px"}>
                            <Typography>Select Question Type</Typography>
                            <FormControl fullWidth>
                                {/* <InputLabel id="el-type-label">Type</InputLabel> */}
                                <Select
                                    sx={styles.dropdown}
                                    labelId="el-type-label"
                                    id="el-type"
                                    value={item.answer_type}
                                    onChange={(e) => handleElType({ id: item.id, type: e.target.value, index })}
                                >
                                    {formElementsType &&
                                        formElementsType.map((el, key) => (
                                            <MenuItem key={key} value={el.value}>
                                                <Typography>{el.label}</Typography>
                                            </MenuItem>
                                        ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={8} gap={"2rem"} display={"flex"} flexDirection={"column"}>
                            {children}
                        </Grid>
                    </Grid>
                </Box>
                <FormElementFooter
                    item={item}
                    deleteEl={deleteEl}
                    duplicateElement={duplicateElement}
                    handleRequired={handleRequired}
                    index={index}
                />
            </Paper>
        ),
        [question_text, answer_type, is_required, answer_options, date, time, arrayMoved]
    );
};

export default SurveyElementWrapper;
