import { COLOR } from "constants/color.constants";

const styles: any = {
    container: {
        padding: "30px 0 24px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%",
    },
    logo: {
        height: 40,
    },
    flexContainer: {
        display: "flex",
        alignItems: "center",
    },
    title: {
        fontSize: 16,
        color: "rgb(95, 124, 136)",
        cursor: "pointer",
    },
    plansPricingButton: {
        fontSize: 16,
        color: COLOR.VSBlue,
        cursor: "pointer",
        marginRight: "2.5vw",
    },
    avatarButton: {
        outlineWidth: 0,
        marginLeft: 30,
        border: "none",
        background: COLOR.background,
    },
    avatar: {
        width: 50,
        height: 50,
        borderRadius: "50%",
    },
};

export default styles;
