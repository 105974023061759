import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useState } from "react";
import "./FormResponsePanel.css";

import { Collapse } from "@mui/material";
import { useFetchScoreParameters } from "hooks/surveyFormData";
import ViewFormQuestion from "pages/adminV2/Components/ViewFormQuestion";
import { uid } from "react-uid";

export type Parameter = {
    id: number;
    title: string;
    enabled: boolean;
    type: "type1" | "type2" | "type3";
};

function FormResponsePanel({ header, content }: any) {
    const [isCollapse, setIsCollapse] = useState(false);

    const {
        isFetching: isParamtersFetching,
        isError: isParamtersError,
        data: scoringParameters,
        error,
        refetch: refetchScoringParameters,
    } = useFetchScoreParameters({
        isEnabled: !!content.length,
        onSuccess: (data: any) => {
            undefined;
        },
        onError: (data: any) => {
            undefined;
        },
    });

    return (
        <div className={`collapsiblePanel ${isCollapse ? "open" : ""}`}>
            <div className="collapsiblePanel__header">
                <div
                    className={`collapsiblePanel__headerleft ${isCollapse ? "active" : ""}`}
                    onClick={() => setIsCollapse(!isCollapse)}
                    role="button"
                >
                    <div className="collapsiblePanel__headerleft-Icon">
                        {isCollapse ? <KeyboardArrowDownIcon /> : <KeyboardArrowDownIcon />}
                    </div>
                    <p>{header}</p>
                </div>
            </div>
            <div className="collapsiblePanel__content">
                <Collapse in={isCollapse} timeout={1000}>
                    {content.length &&
                        content.map((ques: any) => (
                            <ViewFormQuestion
                                formType={"presurvey"}
                                key={uid(ques)}
                                formQuestion={ques}
                                parametersData={scoringParameters?.length ? scoringParameters : []}
                                userId={header}
                            />
                        ))}
                </Collapse>
            </div>
        </div>
    );
}

export default FormResponsePanel;
