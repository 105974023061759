import { SECTIONS } from "constants/landing-page.constants";
import { useRef } from "react";
import { Helmet } from "react-helmet";
import CTA from "./CTA";
import CompTable from "./CompTable";
import Enterprise from "./Enterprise";
import FAQs from "./FAQs";
import FeaturesSlides from "./FeatureSlides";
import Features from "./Features";
import LandingPageFooter from "./Footer";
import HeroSection from "./HeroSection";
import Mission from "./Mission";
import NSF from "./NSF";
import Navbar from "./Navbar";
import SaasPlatform from "./SaasPlatform";
import Testimonials from "./Testimonials";
import Trust from "./Trust";
import "./landingPage.css";

const LandingPage = () => {
    const vsortsRef = useRef<HTMLDivElement>(null);
    const featuresRef = useRef<HTMLDivElement>(null);
    const enterpriseRef = useRef<HTMLDivElement>(null);
    const contactRef = useRef<HTMLDivElement>(null);

    const onScroll = (refName: string) => {
        switch (refName) {
            case SECTIONS.VSORTS:
                vsortsRef.current?.scrollIntoView({ behavior: "smooth" });
                break;
            case SECTIONS.FEATURES:
                featuresRef.current?.scrollIntoView({ behavior: "smooth" });
                break;
            case SECTIONS.ENTERPRISE:
                enterpriseRef.current?.scrollIntoView({ behavior: "smooth" });
                break;
            case SECTIONS.CONTACT:
                contactRef.current?.scrollIntoView({ behavior: "smooth" });
                break;
            default:
                return;
        }
    };
    return (
        <div className="landingPage">
            <Helmet>
                <title>VSorts™ AI</title>
                <meta
                    name="description"
                    content="VSorts™ AI: A powerful SaaS platform for qualitative data analysis, integrating multimodal insights—text, audio, and video—with AI-driven CoPilots to support research, education, and consulting."
                />
            </Helmet>
            <Navbar onScroll={onScroll} withBackground={false} />
            <HeroSection />
            <SaasPlatform vsortsRef={vsortsRef} />
            <Features featuresRef={featuresRef} />
            <FeaturesSlides />
            <Enterprise enterpriseRef={enterpriseRef} />
            <CompTable />
            <NSF />
            <Trust />
            <Testimonials />
            <Mission />
            <FAQs />
            <CTA />
            <LandingPageFooter contactRef={contactRef} onScroll={onScroll} />
        </div>
    );
};

export default LandingPage;
