import { styled } from "@mui/material";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.white,
        color: "#929292",
        boxShadow: "0 0 8px 0px rgba(169, 169, 169, 1)",
        fontSize: 12,
        fontWeight: 400,
        borderRadius: 10
    },
}));

const LightToolTip = ({ title, placement, children }: any) => {
    return <LightTooltip title={title} placement={placement}>{children}</LightTooltip>;
};

export default LightToolTip;
