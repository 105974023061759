import { useAuth0 } from "@auth0/auth0-react";
import Button from "@mui/material/Button";
import CircularLoadingProgress from "components/CircularProgress";
import VSBox from "components/VSBox";

const SignUpButton = ({ hide }: any) => {
    const { loginWithRedirect } = useAuth0();

    return (
        <>
            <VSBox className={hide ? "visibility-hidden" : ""}>
                <Button
                    onClick={() =>
                        loginWithRedirect({
                            screen_hint: "signup",
                            ui_locales: "en",
                            mode: "signUp",
                        })
                    }
                    variant="outlined"
                    size="small"
                    id="auth0-sign-up-button"
                >
                    Sign Up
                </Button>
            </VSBox>
            {hide && <CircularLoadingProgress />}
        </>
    );
};

export default SignUpButton;
