import TestimonialAvatar1 from "assets/images/testimonial-1.png";
import TestimonialAvatar2 from "assets/images/testimonial-2.png";
import TestimonialAvatar3 from "assets/images/testimonial-3.png";
import TestimonialAvatar4 from "assets/images/testimonial-4.png";
import TrustedOrg1 from "assets/images/trusted-1.png";
import TrustedOrg2 from "assets/images/trusted-2.png";
import TrustedOrg3 from "assets/images/trusted-3.png";
import TrustedOrg4 from "assets/images/trusted-4.png";

export const SECTIONS = {
    VSORTS: "Vsorts",
    FEATURES: "Features",
    ENTERPRISE: "Request Demo",
    TESTIMONIALS: "Testimonials",
    CONTACT: "contact",
    FAQS: "FAQs",
};

export const TermsConditionsSections = {
    termsAndConditions: "Terms & Conditions",
    privacyPolicy: "Privacy Policy",
    cookies: "Cookies",
    aiDisclaimer: "Artificial Intelligence Disclaimer",
    eula: "Eula",
};

export const TAGLINES = [
    {
        id: 1,
        tagline: "Illuminating Bias with AI-Enhanced Qualitative Analysis Tools",
        subHeading: "Surface Bias to Foster Inclusion Across Diverse Sectors",
    },
    {
        id: 2,
        tagline: "Making Cultural Intelligence Simple and Accessible",
        subHeading: "Making Cultural Intelligence Simple and Accessible Through AI-Enhanced Tools",
    },
    {
        id: 3,
        tagline: "Reducing Social Friction Through AI-Enhanced Qualitative Analysis Tools",
        subHeading: "Surface Bias to Foster Inclusion Across Diverse Sectors",
    },
];

export const CULTURAL_CONTEXTUAL_POINTS = [
    "Out-of-the-box dashboards, activities, and dashboards",
    "User-friendly interface for seamless navigation",
    "Instantly deploy activities with a single click",
    "Instant data analysis and visualization",
];

export const TESTIMONIALS = [
    {
        id: 4,
        title: "Professor / Teacher Educator",
        prefix: "White Male",
        avatar: TestimonialAvatar1,
        quote: "“A feeling of enthusiasm to have this type of experience, along with my colleagues, to help us become culturally competent teachers.”",
    },
    {
        id: 2,
        title: "Pre-Service Teacher",
        prefix: "White Female",
        avatar: TestimonialAvatar2,
        quote: "“This activity was helpful to understanding new terms and recognizing what I need to work on.”",
    },
    {
        id: 3,
        title: "School Counselor",
        prefix: "White Female",
        avatar: TestimonialAvatar3,
        quote: "“The scenarios presented… were real and useful examples that allowed me to dive into my personal thoughts, beliefs, and skills and the teaching practices I use and want to use in the future.”",
    },
    {
        id: 4,
        title: "Pre-Service Teacher",
        prefix: "Hispanic or Latino",
        avatar: TestimonialAvatar4,
        quote: "“I think overall it taught me to make more efforts to learn, educate, and advocate for injustices I see in the classroom as well as my community.”",
    },
];

export const TRUSTED_ORGS = [
    {
        id: 1,
        logo: TrustedOrg1,
    },
    {
        id: 2,
        logo: TrustedOrg2,
    },
    {
        id: 3,
        logo: TrustedOrg3,
    },
    {
        id: 4,
        logo: TrustedOrg4,
    },
];

export const FAQS = [
    {
        id: 1,
        question: "What is VSorts™?",
        answer: "VSorts™ is a cloud-based application that uses a proprietary AI-enhanced qualitative analysis tool to help individuals and groups identify and address biases, promoting inclusive language and actions in personal and organizational settings. We blend thoughtful design with proprietary AI methodologies to offer users a deeper understanding of themselves and their communities. Those who use VSorts™ make more socially and culturally informed choices in various social situations. Our platform features vignettes, or short stories, rooted in social science to help users better comprehend culture, context, their own biases, and potential social risks. This cultivates a sense of belonging for individuals, irrespective of their location or identity.",
    },
    {
        id: 2,
        question: "What insights does VSorts™ offer?",
        answer: "VSorts™ offers nuanced social, cultural, and contextual insights across various sectors, including education, politics, travel, law enforcement, healthcare, workplaces, and social science research. Using AI agents trained through our proprietary methodology and user interfaces designed based on Human-Computer Interaction principles, VSorts™ enhances users' cultural and contextual awareness while identifying potential biases and social risks. This enables users to recognize bias and encourage inclusive language and actions. Our platform provides personalized insights and actionable strategies based on user input. Ideal for both personal growth and organizational development, VSorts™ elevates cultural awareness, equipping you to navigate diverse cultural contexts with skill and social appropriateness.",
    },
    {
        id: 3,
        question: "How does VSorts™ conceptualize and apply cultural and contextual awareness?",
        answer: "VSorts™ examines cultural and contextual awareness through the complex interplay between human interactions and the social and procedural norms specific to various settings, such as education, politics, and travel. Understanding these interactions and dynamics in their unique contexts is crucial for fostering both personal and organizational growth. Building on academic research, we combine human-centered design with AI agents trained in diverse social science paradigms. This blend equips users with the metacognitive tools needed to navigate cultural and contextual dynamics while reducing personal bias and social risks. Our ultimate aim is to offer insights specifically tailored to individual and organizational goals, encouraging informed choices and fostering authentic relationships across different cultural settings.",
    },
    {
        id: 4,
        question:
            "Who can benefit from using VSorts™, and what are some specific use cases across different industries?",
        answer:
            "VSorts™ offers tailored solutions for various sectors, from multinational corporations to research organizations. Our platform empowers users across diverse sectors to identify bias, reduce social risk, and foster inclusive language and actions.<br /><br />" +
            "<strong>How VSorts™ benefits various sectors:</strong><br /><br />" +
            "<ul>" +
            "<li><strong>Multinational Corporations:&nbsp;</strong>Elevate intercultural dialogue and champion inclusivity.</li>" +
            "<li><strong>Educational Institutions:&nbsp;</strong>Incorporate cultural awareness training in professional development, boosting teacher effectiveness.</li>" +
            "<li><strong>Government Agencies:&nbsp;</strong>Advance cultural sensitivity, enhancing diplomatic missions.</li>" +
            "<li><strong>Non-Profit Organizations:&nbsp;</strong>Integrate cultural awareness into board and stakeholder training, amplifying community engagement and social impact.</li>" +
            "<li><strong>Healthcare Providers:&nbsp;</strong>Equip medical staff with cultural awareness dispositions, ensuring holistic care for diverse patients, including those from marginalized backgrounds.</li>" +
            "<li><strong>Travel and Hospitality Industry:&nbsp;</strong>Tailored experiences resonating with international traveler needs.</li>" +
            "<li><strong>Technology Companies:&nbsp;</strong>Advocate for collaborative strategies and assess technological risks.</li>" +
            "<li><strong>Marketing and Advertising Agencies:&nbsp;</strong>Design and refine inclusive marketing initiatives driven by user input.</li>" +
            "<li><strong>Human Resource Departments:&nbsp;</strong>Implement professional training programs to identify and diminish discrimination risks.</li>" +
            "<li><strong>Research Organizations:&nbsp;</strong>Enhance understanding of human behavior and cultural nuances across diverse contexts and geographies.</li>" +
            "</ul>" +
            "By using VSorts™' advanced AI agents, users can effortlessly identify biases and encourage inclusive language and actions across diverse sectors. In today's interconnected world, VSorts™ stands as an essential resource for individuals and organizations striving to build more equitable communities on a global scale.",
    },
    {
        id: 5,
        question: "How does VSorts™ train its socially and culturally aware AI agents?",
        answer: "VSorts™ employs social science frameworks, like Cultural Proficiency©, and collaborates with respected social science experts to refine large language models collectively, thereby addressing and reducing both AI and human bias. This scientifically validated, proprietary approach ensures that the platform accurately represents a variety of cultural contexts across multiple sectors. Through our groundbreaking methodology, VSorts™ crafts human-centered user interfaces that empower users to confidently recognize biases, reduce social risk, and promote inclusive language and actions.",
    },
];

export const NSF_LINK =
    "https://seedfund.nsf.gov/awardees/history/details/?company=culturally-responsive-solutions-llc";
