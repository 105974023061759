import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";

// Axios Interceptor
import { request } from "utils/axios-utils";

// Constants
import { APIMethodConstants } from "constants/api.method.constants";
import { NetworkConstants } from "constants/network.constants";
import { QueryKeyConstants } from "constants/query.key.constants";

const fetchAllVSortsContainers = () => {
    return request({ url: NetworkConstants.getAllVSortsContainers });
};

const createNewVSortsConatainer = ({ data }: any) => {
    return request({ url: NetworkConstants.createVSortsContainer, method: APIMethodConstants.post, data: data });
};

const lockUnlockVSortsContainer = ({ data }: any) => {
    return request({ url: NetworkConstants.lockUnlockVSortsContainer, method: APIMethodConstants.post, data: data });
};

const fetchVSetResponsesInformation = ({ url }: any) => {
    return request({ url: NetworkConstants.fetchVSetResponses + url, method: APIMethodConstants.get });
};

const fetchVSetClusters = ({ url }: any) => {
    return request({ url: NetworkConstants.getVSortsClusters + url, method: APIMethodConstants.get });
};

const fetchVSetClusterInformation = ({ url }: any) => {
    return request({ url: NetworkConstants.getVSortsClusterInformation + url, method: APIMethodConstants.get });
};

const fetchVSetClusterAnswers = ({ url }: any) => {
    return request({ url: NetworkConstants.getVSortsClusterAnswers + url, method: APIMethodConstants.get });
};

const fetchVSetScoringParams = ({ url }: any) => {
    return request({ url: NetworkConstants.getVSortsScoringParams + url, method: APIMethodConstants.get });
};

const fetchAllVSetResponsesInformation = ({ url }: any) => {
    return request({ url: NetworkConstants.fetchAllVSetResponses + url, method: APIMethodConstants.get });
};

const fetchMinMaxUnpackingParameters = ({ url }: any) => {
    return request({ url: NetworkConstants.getVSetsMinMaxUnpackingParameters + url, method: APIMethodConstants.get });
};

const completeUnpackingStep = ({ data }: any) => {
    return request({ url: NetworkConstants.completeUnpackingStep, method: APIMethodConstants.post, data: data });
};

const fetchFlaggedVSetResponsesInformation = ({ url }: any) => {
    return request({ url: NetworkConstants.fetchFlaggedVSetResponses + url, method: APIMethodConstants.get });
};

const fetchVSortsNextClusterStep = ({
    clusterID,
    container_id,
    userID,
    form_type,
    cluster_step_id,
    vignette_id,
}: any) => {
    return request({
        url: `${NetworkConstants.getUnpackingQuestions}?cluster_id=${clusterID}&container_id=${container_id}&user_id=${userID}&form_type=${form_type}&cluster_step_id=${cluster_step_id}&vignette_id=${vignette_id}`,
    });
};

const fetchUnpackingResponses = ({ clusterID, container_id, userID, form_type, vignette_id }: any) => {
    return request({
        url: `${NetworkConstants.getUnpackingResponses}?cluster_id=${clusterID}&container_id=${container_id}&user_id=${userID}&form_type=${form_type}&vignette_id=${vignette_id}`,
    });
};

const fetchScoringFormInformation = ({ url }: any) => {
    return request({ url: NetworkConstants.getScoringInformation + url, method: APIMethodConstants.get });
};

const postScoringData = ({ bodyData }: any) => {
    return request({
        url: NetworkConstants.addScoringData,
        method: APIMethodConstants.post,
        data: bodyData,
    });
};

export const deleteVSortsContainer = (id: any, data: any) => {
    return request({
        url: `${NetworkConstants.deleteVSortsContainer}/${id}`,
        method: APIMethodConstants.post,
        data,
    });
};

// useVSortsContainerNamesData -> To fetch All VSorts Containers
export const useVSortsContainerNamesData = ({ onSuccess, onError }: any) => {
    return useQuery(QueryKeyConstants.fetchVSortsContainerQueryKey, () => fetchAllVSortsContainers(), {
        staleTime: 300000,
        onSuccess,
        onError,
        select: (data) => {
            return data.data.data;
        },
    });
};

// useCreateVSortsContainerData -> To create a New VSorts Container
export const useCreateVSortsContainerData = ({ data }: any) => {
    const navigate = useNavigate();

    const queryClient = useQueryClient();

    return useMutation(() => createNewVSortsConatainer({ data: data }), {
        // We add the data from the response to the existing data
        onSuccess: (data) => {
            const responseData = data.data.data;

            queryClient.setQueryData(QueryKeyConstants.fetchVSortsContainerQueryKey, (oldQueryData: any) => {
                return {
                    ...oldQueryData,
                    data: {
                        data: [...oldQueryData.data.data, responseData],
                    },
                };
            });

            // Navigating to create screen on successful creation of a VSorts Container
            navigate("create/" + responseData.id + "?name=" + responseData.container_name);
        },
    });
};

export const useCreateVsetContainer = () => {
    const queryClient = useQueryClient();

    return useMutation(
        ({ container_name }: any) => {
            return createNewVSortsConatainer({ data: { container_name: container_name } });
        },
        {
            onSuccess: (data) => {
                queryClient.invalidateQueries(QueryKeyConstants.fetchVSortsContainerQueryKey);
            },
        }
    );
};

// useVSortsContainerLockUnlockData -> To lock/unlock a VSorts Container
export const useVSortsContainerLockUnlockData = () => {
    const queryClient = useQueryClient();

    return useMutation((variables: any) => lockUnlockVSortsContainer({ data: variables.data }), {
        // We change the status of 'locked' for the current item after the request is successfully completed
        onSuccess: (_data, variables) => {
            queryClient.setQueryData(QueryKeyConstants.fetchVSortsContainerQueryKey, (oldQueryData: any) => {
                const modifiedData = oldQueryData.data.data.map((item: any, index: number) => {
                    if (item.id === variables.selectedVSortsContainerIndex) {
                        // Negating the value (Changing 0 -> 1 and 1 -> 0)
                        item.locked = 1 - item.locked;
                        return item;
                    }
                    return item;
                });
                return {
                    ...oldQueryData,
                    data: {
                        data: modifiedData,
                    },
                };
            });
        },
    });
};

// useVSetUserResponses -> To fetch VSet Responses of a particular user
export const useVSetUserResponses = ({
    container_id,
    cluster_id,
    user_id,
    onSuccess,
    onError,
    isEnabled = true,
}: any) => {
    return useQuery(
        [QueryKeyConstants.fetchVSetUserResponsesQueryKey, container_id, cluster_id, user_id],
        () =>
            fetchVSetResponsesInformation({ url: container_id + "&cluster_id=" + cluster_id + "&user_id=" + user_id }),
        {
            enabled: isEnabled,
            staleTime: Infinity,
            onSuccess,
            onError,
            select: (data) => {
                return data.data.data;
            },
        }
    );
};

// useAllVSetUserResponses -> To fetch All users VSet Responses
export const useAllVSetUserResponses = ({ container_id, onSuccess, onError, isEnabled = true }: any) => {
    return useQuery(
        [QueryKeyConstants.fetchAllVSetUserResponsesQueryKey, container_id],
        () => fetchAllVSetResponsesInformation({ url: container_id }),
        {
            enabled: isEnabled,
            staleTime: Infinity,
            onSuccess,
            onError,
            select: (data) => {
                return data.data.data;
            },
        }
    );
};

// useVSetUserResponses -> To fetch VSet Responses of a particular user
export const useGetVSetFlaggedResponses = ({
    container_id,
    cluster_id,
    user_id,
    onSuccess,
    onError,
    isEnabled = true,
}: any) => {
    return useQuery(
        [QueryKeyConstants.fetchFlaggedVSetUserResponsesQueryKey, container_id, cluster_id, user_id],
        () =>
            fetchFlaggedVSetResponsesInformation({
                url: container_id + "&cluster_id=" + cluster_id + "&user_id=" + user_id,
            }),
        {
            enabled: isEnabled,
            staleTime: Infinity,
            onSuccess,
            onError,
            select: (data) => {
                return data.data.data;
            },
        }
    );
};

// useVSetUserResponses -> To fetch VSet Responses of a particular user
export const useGetVSetMinMaxUnpackingCount = ({ container_id, onSuccess, onError, isEnabled = true }: any) => {
    return useQuery(
        [QueryKeyConstants.fetchVSetsMinMaxUnpackingParameters, container_id],
        () => fetchMinMaxUnpackingParameters({ url: container_id }),
        {
            enabled: isEnabled,
            staleTime: Infinity,
            onSuccess,
            onError,
            select: (data) => {
                return data.data.data;
            },
        }
    );
};

// useCreateVSortsClusterData -> To create a New VSorts Cluster
export const useCompleteUnpackingStep = ({ data }: any) => {
    const queryClient = useQueryClient();
    const navigate = useNavigate();

    return useMutation(() => completeUnpackingStep({ data: data }), {
        // We add the data from the response to the existing data
        onSuccess: (data) => {
            const responseData = data.data.data;
            queryClient.setQueryData(QueryKeyConstants.completeUnpackingStepQueryKey, (oldQueryData: any) => {
                return {
                    ...oldQueryData,
                    data: {
                        data: [...oldQueryData.data.data, responseData],
                    },
                };
            });
            // Navigating to create screen on successful creation of a VSorts Cluster
            // navigate("/user/vsort-cluster/" + responseData.id + "?name=" + responseData.cluster_name);
        },
    });
};

export const useGetUnpackingQuestions = ({
    clusterID,
    container_id,
    userID,
    form_type,
    cluster_step_id,
    vignette_id,
    onSuccess,
    onError,
}: any) => {
    return useQuery(
        [
            QueryKeyConstants.fetchGetUnpackingQuestionsQueryKey,
            clusterID,
            container_id,
            userID,
            form_type,
            cluster_step_id,
            vignette_id,
        ],
        () => fetchVSortsNextClusterStep({ clusterID, container_id, userID, form_type, cluster_step_id, vignette_id }),
        {
            staleTime: Infinity,
            onSuccess,
            onError,
            select: (data) => {
                return data.data.data;
            },
        }
    );
};

export const useGetUnpackingResponses = ({
    clusterID,
    container_id,
    userID,
    form_type,
    vignette_id,
    onSuccess,
    onError,
}: any) => {
    return useQuery(
        [QueryKeyConstants.fetchGetUnpackingResponsesQueryKey, clusterID, container_id, userID, form_type, vignette_id],
        () => fetchUnpackingResponses({ clusterID, container_id, userID, form_type, vignette_id }),
        {
            staleTime: Infinity,
            onSuccess,
            onError,
            select: (data) => {
                return data.data.data;
            },
        }
    );
};

export const useScoringForm = ({
    container_id,
    form_type,
    clusterID,
    vignette_id,
    user_id,
    onSuccess,
    onError,
    isEnabled = true,
}: any) => {
    const clusterIdQuery = clusterID ? `&cluster_id=${clusterID}` : "";
    const vignetteIdQuery = vignette_id ? `&vignette_id=${vignette_id}` : ``;
    return useQuery(
        [QueryKeyConstants.fetchGetScoringQueryKey, form_type, container_id, user_id],
        () =>
            fetchScoringFormInformation({
                url:
                    container_id + "&form_type=" + form_type + "&user_id=" + user_id + clusterIdQuery + vignetteIdQuery,
            }),
        {
            enabled: isEnabled,
            staleTime: Infinity,
            onSuccess,
            onError,
            select: (data) => {
                return data.data.data;
            },
        }
    );
};

// usePostVSetsCategoriesMutation -> To create or update a VSets category
export const usePostScoresMutation = () => {
    const queryClient = useQueryClient();
    return useMutation(
        ({ data, cluster_id, container_id, vignette_id, form_type, user_id }: any) => {
            const bodyData: any = {};
            bodyData["data"] = data;
            bodyData["cluster_id"] = cluster_id;
            bodyData["container_id"] = container_id;
            bodyData["vignette_id"] = vignette_id;
            bodyData["form_type"] = form_type;
            bodyData["user_id"] = user_id;
            return postScoringData({ bodyData: bodyData });
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries(QueryKeyConstants.fetchVSetsCategories);
            },
        }
    );
};

// useVSetClusters -> To fetch VSet clusters
export const useVSetClusters = ({ org_id, form_type, container_id, onSuccess, onError, isEnabled = true }: any) => {
    return useQuery(
        [QueryKeyConstants.fetchVSortsClusterQueryKey, org_id, form_type, container_id],
        () => fetchVSetClusters({ url: org_id + "&form_type=" + form_type + "&container_id=" + container_id }),
        {
            enabled: isEnabled,
            staleTime: Infinity,
            onSuccess,
            onError,
            select: (data) => {
                return data.data.data;
            },
        }
    );
};

export const useVSetClusterInformation = ({
    container_id,
    cluster_id,
    onSuccess,
    onError,
    isEnabled = true,
    value,
}: any) => {
    return useQuery(
        [QueryKeyConstants.fetchVSortsClusterQueryKey, container_id, cluster_id, value],
        () => fetchVSetClusterInformation({ url: container_id + "&cluster_id=" + cluster_id }),
        {
            enabled: isEnabled,
            staleTime: Infinity,
            onSuccess,
            onError,
            select: (data) => {
                return data.data.data;
            },
        }
    );
};

export const useVSetClusterAnswers = ({
    container_id,
    cluster_id,
    onSuccess,
    onError,
    isEnabled = true,
    clusterInformation,
}: any) => {
    return useQuery(
        [QueryKeyConstants.fetchVSortsClusterQueryKey, container_id, cluster_id, clusterInformation],
        () => fetchVSetClusterAnswers({ url: container_id + "&cluster_id=" + cluster_id }),
        {
            enabled: isEnabled,
            staleTime: Infinity,
            onSuccess,
            onError,
            select: (data) => {
                return data.data.data;
            },
        }
    );
};

export const useVSetClusterScoringParams = ({ onSuccess, onError, isEnabled = true, answer }: any) => {
    return useQuery([QueryKeyConstants.fetchVSortsClusterQueryKey, answer], () => fetchVSetScoringParams({ url: "" }), {
        enabled: isEnabled,
        staleTime: Infinity,
        onSuccess,
        onError,
        select: (data) => {
            return data.data;
        },
    });
};
