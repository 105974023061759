// Form Elements
import {
    CheckboxInput,
    DateInput,
    DropdownInput,
    HeaderInfoInput,
    NumberInput,
    RadioInput,
    SectionInput,
    StateInput,
    TextArea,
    TextFieldInput,
    TimeInput,
} from "pages/admin/dashboard/components/FormBuilder/elements";

// `EditElements` is the function to show the user the Form Elements in Editor Mode
// 1. item -> Represents the Form Item Object (Consisting of all properties)
// 2. handleValue -> Represents the function to handle the TextInput for the Form Question
// 3. deleteEl -> Represents the function to delete a Form Element
// 4. handleRequired -> Represents the function to handle closing the Dialog
// 5. handleElType -> Represents the function to handle the current type of Form Element
// 6. duplicateElement -> Represents the function to duplicate a Form Element
// 7. addOption -> Represents the function to add an option for a Form Question (With Options - Radio/Checkbox/Select)
// 8. handleOptionValues -> Represents the function to handle the text for each option
// 9. deleteOption -> Represents the function to delete an option for a Form Question
// 10. handleDate -> Represents the function to handle Date Input
// 11. handleTime -> Represents the function to handle Time Input
export const EditElements = ({
    item,
    index,
    handler,
    handleValue,
    handleSectionValue,
    deleteEl,
    deleteSec,
    handleRequired,
    handleElType,
    duplicateElement,
    addOption,
    handleOptionValues,
    deleteOption,
    handleDate,
    handleTime,
    totalSection,
    addElement,
    arrayMoved,
}: any) => {
    switch (item.answer_type) {
        case "section":
            return (
                <SectionInput
                    key={item.id}
                    item={item}
                    index={index}
                    handler={handler}
                    addElement={addElement}
                    handleSectionValue={handleSectionValue}
                    deleteSec={deleteSec}
                    totalSection={totalSection}
                    arrayMoved={arrayMoved}
                />
            );
        case "text":
            return (
                <TextFieldInput
                    key={item.id}
                    item={item}
                    index={index}
                    handler={handler}
                    handleValue={handleValue}
                    deleteEl={deleteEl}
                    handleRequired={handleRequired}
                    handleElType={handleElType}
                    duplicateElement={duplicateElement}
                    arrayMoved={arrayMoved}
                />
            );
        case "textarea":
            return (
                <TextArea
                    key={item.id}
                    item={item}
                    index={index}
                    handler={handler}
                    handleValue={handleValue}
                    deleteEl={deleteEl}
                    handleRequired={handleRequired}
                    handleElType={handleElType}
                    duplicateElement={duplicateElement}
                    arrayMoved={arrayMoved}
                />
            );
        case "header_info":
            return (
                <HeaderInfoInput
                    key={item.id}
                    item={item}
                    index={index}
                    handler={handler}
                    handleValue={handleValue}
                    deleteEl={deleteEl}
                    handleElType={handleElType}
                    duplicateElement={duplicateElement}
                    arrayMoved={arrayMoved}
                />
            );
        case "number":
            return (
                <NumberInput
                    key={item.id}
                    item={item}
                    index={index}
                    handler={handler}
                    handleValue={handleValue}
                    deleteEl={deleteEl}
                    handleRequired={handleRequired}
                    handleElType={handleElType}
                    duplicateElement={duplicateElement}
                    arrayMoved={arrayMoved}
                />
            );
        case "radio":
            return (
                <RadioInput
                    key={item.id}
                    item={item}
                    index={index}
                    handler={handler}
                    handleValue={handleValue}
                    deleteEl={deleteEl}
                    handleRequired={handleRequired}
                    handleElType={handleElType}
                    addOption={addOption}
                    handleOptionValues={handleOptionValues}
                    deleteOption={deleteOption}
                    duplicateElement={duplicateElement}
                    arrayMoved={arrayMoved}
                />
            );
        case "checkbox":
            return (
                <CheckboxInput
                    key={item.id}
                    item={item}
                    index={index}
                    handler={handler}
                    handleValue={handleValue}
                    deleteEl={deleteEl}
                    handleRequired={handleRequired}
                    handleElType={handleElType}
                    addOption={addOption}
                    handleOptionValues={handleOptionValues}
                    deleteOption={deleteOption}
                    duplicateElement={duplicateElement}
                    arrayMoved={arrayMoved}
                />
            );
        case "dropdown":
            return (
                <DropdownInput
                    key={item.id}
                    item={item}
                    index={index}
                    handler={handler}
                    handleValue={handleValue}
                    deleteEl={deleteEl}
                    handleRequired={handleRequired}
                    handleElType={handleElType}
                    addOption={addOption}
                    handleOptionValues={handleOptionValues}
                    deleteOption={deleteOption}
                    duplicateElement={duplicateElement}
                    arrayMoved={arrayMoved}
                />
            );
        case "date":
            return (
                <DateInput
                    key={item.id}
                    item={item}
                    index={index}
                    handler={handler}
                    handleValue={handleValue}
                    deleteEl={deleteEl}
                    handleRequired={handleRequired}
                    handleElType={handleElType}
                    handleDate={handleDate}
                    duplicateElement={duplicateElement}
                    arrayMoved={arrayMoved}
                />
            );
        case "time":
            return (
                <TimeInput
                    key={item.id}
                    item={item}
                    index={index}
                    handler={handler}
                    handleValue={handleValue}
                    deleteEl={deleteEl}
                    handleRequired={handleRequired}
                    handleElType={handleElType}
                    handleTime={handleTime}
                    duplicateElement={duplicateElement}
                    arrayMoved={arrayMoved}
                />
            );
        case "state":
            return (
                <StateInput
                    key={item.id}
                    item={item}
                    index={index}
                    handler={handler}
                    handleValue={handleValue}
                    deleteEl={deleteEl}
                    handleRequired={handleRequired}
                    handleElType={handleElType}
                    duplicateElement={duplicateElement}
                    arrayMoved={arrayMoved}
                />
            );
        default:
            return <></>;
    }
};
