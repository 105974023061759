// Material Imports
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import AddCircleOutlineOutlinedIcon from "@material-ui/icons/AddCircleOutlineOutlined";
import { FormTypeLabels } from "constants/enum.constants";
import SectionOrderDialog from "./SectionOrderDialog";

// `Header` is the function to show the user the Header of the currrent Form
// 1. title -> Represents the title of the Form
const Header = ({ title, onChange, disabled, addSection, sectionData, handleSectionDataChange, formType }: any) => {
    const label = `${FormTypeLabels[formType]} Title`;

    return (
        <Grid container justifyContent="space-between" spacing={0}>
            <Grid item></Grid>
            <Grid item md={8} xs={12}>
                <TextField
                    value={title}
                    label={label}
                    placeholder={label}
                    name="title"
                    fullWidth
                    onChange={onChange}
                    disabled={disabled}
                    multiline
                />
            </Grid>
            <Grid item md={2} xs={12}>
                <Tooltip title="Add Section" aria-label="add-section">
                    <IconButton aria-label="add-section" onClick={addSection} sx={{ position: "sticky", top: 30 }}>
                        <AddCircleOutlineOutlinedIcon color="secondary" />
                    </IconButton>
                </Tooltip>
                <SectionOrderDialog data={sectionData} onChange={(items: any) => handleSectionDataChange(items)} />
            </Grid>
        </Grid>
    );
};

export default Header;
