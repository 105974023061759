// Material UI Components
import Divider from "@material-ui/core/Divider";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import IconButton from "@material-ui/core/IconButton";
import Switch from "@material-ui/core/Switch";
import Tooltip from "@material-ui/core/Tooltip";
import CopyIcon from "assets/icons/copy.svg";
import TrashIcon from "assets/icons/trash-red.svg";

// Material Icons (Delete and Copy)

// `FormElementFooter` is the function to show the user the Footer for each Form Element
// 1. item -> Represents the Form Item Object (Consisting of all properties)
// 2. deleteEl -> Represents the function to handle deleting the Form Element
// 3. duplicateElement -> Represents the function to handle duplicating the Form Element
// 4. handleRequired -> Represents the function to handle making a Form Element required/not required
const FormElementFooter = ({ item, deleteEl, duplicateElement, handleRequired, index }: any) => {
    return (
        <>
            <Divider light />
            <FormGroup row sx={{ alignItems: "center" }}>
                {handleRequired && (
                    <FormControlLabel
                        control={
                            <Switch
                                checked={!!item.is_required}
                                onChange={() => handleRequired({ id: item.id, index: index })}
                                name="required-field"
                                color="primary"
                            />
                        }
                        label="Required"
                        sx={{ ml: 2 }}
                    />
                )}
                <Tooltip title="Delete Element" aria-label="delete-element">
                    <IconButton aria-label="delete-element" sx={{ width: "42px", height: "42px" }} onClick={() => deleteEl({ id: item.id, index })}>
                        <img src={TrashIcon} alt="Delete" />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Duplicate Element" aria-label="duplicate-element">
                    <IconButton aria-label="copy-element" sx={{ width: "42px", height: "42px" }} onClick={() => duplicateElement({ elId: item.id, elType: item.answer_type, index })}>
                        <img src={CopyIcon} alt="copyIcon" />
                    </IconButton>
                </Tooltip>
            </FormGroup>
        </>
    );
};

export default FormElementFooter;
