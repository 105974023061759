// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

// Constants
import { StringConstants } from "constants/string.constants";

// Custom Styles
import { ErrorPageInformation, ErrorPageInformationWrapper, ErrorPageWrapper } from "pages/error/Error.styles";

// `NetworkError` is the function to show the user the UI when the user encounters an error while a network request
// 1. handleOnRefresh -> Represents the function to handle refreshing the network call (The one in question when the Network Call failed)
// 2. errorText -> Represents the text to show the error message
export default function NetworkError({ handleOnRefresh, errorText }) {
    return (
        <ErrorPageWrapper>
            <ErrorPageInformationWrapper>
                <ErrorPageInformation>
                    <h2>{StringConstants.networkErrorTitle}</h2>
                    <p>{StringConstants.networkErrorDescription}</p>
                    <p>{errorText.message}</p>
                </ErrorPageInformation>
                <button className="action-button" onClick={handleOnRefresh}>
                    {StringConstants.refresh}
                </button>
            </ErrorPageInformationWrapper>
        </ErrorPageWrapper>
    );
}
