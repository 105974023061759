import styled from "styled-components";

export const Stepper = styled.ul`
    & {
        display: flex;
        padding: 1rem;
        min-width: 60rem;
        list-style: none;
        position: sticky;
        top: 4rem;
        background: #fff;
        z-index: 9;
        padding-top: 0.5rem;
    }

    &:before {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        content: "";
        width: calc(100% - 20px);
    }
`;

export const StepperItem = styled.li`
    & {
        flex: 100%;
        padding: 20px 20px 20px 40px;
        background: repeating-linear-gradient(-65deg, #fff, #fff 20px, #fcfcfc 20px, #fcfcfc 40px);
        margin: 0 0 0 -19px;
        -webkit-clip-path: polygon(20px 50%, 0% 0%, calc(100% - 20px) 0%, 100% 50%, calc(100% - 20px) 100%, 0% 100%);
    }
    &.presurvey {
        background: #e9cc74;
        font-weight: bold;
    }
    &.postsurvey {
        background: #673ab7;
    }
    &.vsorts {
        background: #009688;
    }
    &.video {
        background: #3f51b5;
    }
    &.finalfeedback {
        background: #1976d2;
    }
    &.unpacking {
        background: #e9edc9;
    }
    &:first-child {
        padding: 20px;
        -webkit-clip-path: polygon(0% 0%, calc(100% - 20px) 0%, 100% 50%, calc(100% - 20px) 100%, 0% 100%);
    }
`;

export const FloatingButtonWrapper = styled.div`
    height: 40px;
    position: fixed;
    bottom: 4%;
    right: 0.5%;
    opacity: 1;
    .floating-item {
        border: 1px solid indigo;
        padding: 1rem;
        display: inline-block;
    }
    .action-button {
        color: white;
        border-radius: 16px;
        padding: 1rem;
        display: inline-block;
        &.preview {
            background-color: #3f50b5;
        }
        &.save {
            background-color: #357a38;
        }
    }
`;
