import { InputAdornment, TextField } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { ReactComponent as CloseIcon } from "assets/icons/close.svg";
import { ReactComponent as SearchIcon } from "assets/icons/search.svg";
import React, { useEffect, useState } from "react";
import styles from "./styles";

export type ItemType = {
    id: number;
    name: string;
    display_name: string;
};

export type PropsType = {
    value: string;
    placeholder?: string;
    style?: React.CSSProperties;
    onSearch: (searchKey: string) => void;
    items?: ItemType[];
    setSelectedData?: (selectedData: ItemType | null) => void;
};

const Search = ({ value, placeholder = "", style = {}, onSearch, items = [], setSelectedData }: PropsType) => {
    const [searchKey, setSearchKey] = useState<string>(value);
    const [selectedValue, setSelectedValue] = useState<ItemType | null>(null);

    useEffect(() => {
        setSearchKey(value);
    }, [value]);

    const handleChange = (event: any, value: string | null) => {
        if (value) {
            const selectedOption = items.find((item) => item.display_name === value);
            if (selectedOption) {
                setSelectedValue(selectedOption);
                if (setSelectedData) {
                    setSelectedData(selectedOption);
                }
            }
        } else {
            setSelectedValue(null);
            if (setSelectedData) {
                setSelectedData(null);
            }
        }
    };

    const handleClearSearchKey = () => {
        setSearchKey("");
        setSelectedValue(null);
        if (setSelectedData) {
            setSelectedData(null);
        }
        onSearch("");
    };

    return (
        <Autocomplete
            freeSolo
            id="free-solo-2-demo"
            disableClearable
            sx={{width: "100%"}}
            options={items.map((option) => option.display_name)}
            inputValue={searchKey}
            onInputChange={(event, value) => {
                setSearchKey(value);
                onSearch(value);
            }}
            onChange={handleChange}
            renderInput={(params) => (
                <TextField
                    {...params}
                    size="small"
                    variant="outlined"
                    placeholder={placeholder}
                    sx={[style, styles.searchBox]}
                    value={searchKey}
                    InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                            <InputAdornment position="start" sx={{ ml: "15px", cursor: "pointer" }}>
                                <SearchIcon style={styles.searchIcon} />
                            </InputAdornment>
                        ),
                        endAdornment: (
                            <InputAdornment
                                position="end"
                                sx={{ mr: "15px", cursor: "pointer" }}
                                onClick={handleClearSearchKey}
                            >
                                <CloseIcon style={styles.closeIcon} />
                            </InputAdornment>
                        ),
                        style: { padding: "4px" },
                    }}
                />
            )}
        />
    );
};

export default Search;
