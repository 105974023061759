import { COLOR } from "constants/color.constants";

const styles: any = {
    layout: {
        padding: "0 80px",
        background: COLOR.background,
        height: "100%",
        display: "flex",
        flexDirection: "column",
    },
    headerContainer: {
        padding: "30px 0 24px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%",
    },
    logo: {
        height: 40,
    },
    container: {
        margin: "10rem",
    },
    messageContainer: {
        borderRadius: 10,
        padding: 50,
        background: "#FFFFFF",
        marginBottom: 30,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    title: {
        fontWeight: 500,
        fontSize: 18,
        marginTop: 20,
    },
    body: {
        fontWeight: 400,
        fontSize: 18,
        color: "#929292",
        marginTop: 10
    },
    button: {
        marginTop: 30
    }
};

export default styles;
