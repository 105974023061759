const styles = {
    tabs: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        height: 68,
        backgroundColor: 'transparent',
    },
    tab: {
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: 150,
        height: '100%',
        padding: 20,
        borderRadius: 10,
        backgroundColor: '#fff',
        fontSize: 18,
        fontWeight: 500,
        color: '#111',
    },
    activeTab: {
        justifyContent: 'flex-start',
        backgroundColor: '#29ABE2',
        fontWeight: 700,
        border: '2px solid $1D9DD3',
        color: '#fff',
        flex: 1,
    },
    tabWithMargin: {
        marginRight: 20,
    }
}

export default styles