// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { useAuth0 } from "@auth0/auth0-react";
import CircularLoadingProgress from "components/CircularProgress";
import { useGetAllOrganizations } from "hooks/OrganizationData";
import LoginButton from "pages/layouts/navigation/LoginButton";
import { useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";

const { REACT_APP_VSORTS_ORG_NAME } = process.env;

const LoginPage = () => {
    const { isAuthenticated, error } = useAuth0();
    const [foundOrgId, setFoundOrgId] = useState(false);
    const [orgId, setOrgId] = useState<any>("");

    const navigate = useNavigate();
    useEffect(() => {
        if (!isAuthenticated) {
            const loginBtn = document.getElementById("auth0-login-button");
            if (loginBtn) {
                loginBtn.click();
            }
        }
    }, [isAuthenticated]);

    const onDataFetchSuccess = async (data: any) => {
        const filteredData = await data?.organizations?.filter(
            (eachOrg: any) => window.location.origin.includes(eachOrg?.domain_name)
            // "https://calstatelautr.vsorts.ai/".includes(eachOrg?.domain_name)
        );
        if (filteredData.length > 0) {
            if (filteredData[0]["organization_name"] == REACT_APP_VSORTS_ORG_NAME) {
                setOrgId("");
                setFoundOrgId(true);
            } else {
                setOrgId(filteredData[0]["id"]);
                setFoundOrgId(true);
            }
        } else {
            setOrgId("");
            setFoundOrgId(true);
        }
    };
    const onDataFetchError = (err: any) => {
        undefined;
    };
    const {
        isLoading: isorgDataLoading,
        isError: isOrgDataError,
        data: orgData,
        orgError,
        refetch: refetchOrgList,
    } = useGetAllOrganizations({
        onSuccess: onDataFetchSuccess,
        onError: onDataFetchError,
    });

    if (isAuthenticated) {
        return <Navigate to="/dashboard" />;
    } else if (error) {
        console.log("Login page", error);

        // alert("Access Denied: You do not have permission to access this application.");
        // navigate(`/`);
    }

    if (isorgDataLoading) {
        return <CircularLoadingProgress />;
    }
    return <>{foundOrgId && <LoginButton orgId={orgId} hide={true} />}</>;
};

export default LoginPage;
