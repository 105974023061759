import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`
    .action-button {
        margin-right: 10px;
        font-family: titillium web, sans-serif;
        display: inline-block;
        text-transform: uppercase;
        color: #fff;
        text-decoration: none;
        border: none;
        background: #29ABE2;
        cursor: pointer;
        padding: 10px 40px;
        font-size: 14px;
        font-weight: 700;
        border-radius: 1px;
        margin-top: 15px;
        -webkit-transition: .2s all;
        transition: .2s all;
    }
    .table-wrap {
        margin: auto 0px !important;
    }
    
    .center-table {
        margin: 0px auto !important;
    }
    
    .table-header {
        background-color: black !important;
        color: white !important;
    }

    .table-title {
        /* background-color: rgb(124, 124, 124) !important; */
        color: #929292 !important;
    }
    
    .header {
        position: sticky !important;
        top: 0 !important;
    }

    input:required {
        border-color: #ccc !important;
        box-shadow: none !important;
        -webkit-box-shadow: none !important;
    }
`;
