import { DynamicModel } from "pages/adminV2/Components";
import "./DeleteSection.css";

interface props {
    open: boolean;
    setOpen: (value: boolean) => void;
    handleDelete:any;
}

function DeleteSectionModel({ open, setOpen, handleDelete }: props) {
    // Function to handle Deleting of a Form Question
    return (
        <DynamicModel
            open={open}
            setOpen={setOpen}
            title={handleDelete.title}
        >
            <div className="deleteModel">
                <p className="deleteModelContainer__desc">You cannot undo this action once you confirm</p>
                <div className="deleteModelContainer">
                    <button onClick={handleDelete.handleSubmit}>
                        Delete
                    </button>
                </div>
            </div>
        </DynamicModel>
    );
}

export default DeleteSectionModel;
