// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { useSelector } from "react-redux";

import { styled } from "@mui/material/styles";
import { CurrentUserSelector } from "app/slices/UserSlice";
import Box from "components/VSBox";
import Paper from "components/VSPaper";
import VSTypography from "components/VSTypography";
import { APIMethodConstants } from "constants/api.method.constants";
import { useSaveVSortMutation } from "hooks/surveyFormData";
import { useEffect, useState } from "react";
import { SortableContainer, SortableElement, arrayMove } from "react-sortable-hoc";

import { Grid } from "@mui/material";
import SortingItems from "../SortingItems";
import VSetsEvaluation from "../VSetsEvaluation";
import "./style.css";

const defaultBackgroundVars = {
    "--color": "#ffffff",
};

const changedBackgroundVars = {
    "--color": "#93cac7",
};

const OptionItem = styled((props) => <Paper elevation={0} square {...props} />)(({ theme }) => ({
    padding: theme.spacing(2),
    background: "var(--color)",
}));

const SortableItem = SortableElement(({ value, curIndex, recentIndex }: any) => {
    const vars = curIndex === recentIndex ? changedBackgroundVars : defaultBackgroundVars;
    return (
        <div className="v-set-container__options__item">
            <OptionItem style={vars}>
                <VSTypography size="lg">{value.vignette_text || "No Text Found"}</VSTypography>
            </OptionItem>
        </div>
    );
});

const SortableList = SortableContainer(({ children }: any) => {
    return <div>{children}</div>;
});

export const OptionSet = ({ domain, VSortContainerData, refreshNextClusterStep, clusterStep, clusterId }: any) => {
    const { container_id, id: clusterStepID, cluster_id, form_type, domain_id } = clusterStep;

    const currentUser = useSelector(CurrentUserSelector);
    const { mutate: saveVSort, isLoading: isSaving } = useSaveVSortMutation();

    const [items, setItems] = useState([]);
    const [categories, setCategories] = useState([]);
    const [recentIndex, setRecentIndex] = useState(-1);

    useEffect(() => {
        if (domain.vignettes) {
            setItems(domain.vignettes);
        }
    }, [domain]);
    useEffect(() => {
        if (VSortContainerData) {
            setCategories(VSortContainerData);
        }
    }, [VSortContainerData]);

    const onSortEnd = ({ oldIndex, newIndex }: any) => {
        setRecentIndex(newIndex);
        setItems((prev) => arrayMove(prev, oldIndex, newIndex));
    };

    const handleNext = () => {
        const sorted = items.map((item, index) => ({ vignette_id: item.id, rank_number: index + 1 }));
        saveVSort(
            {
                apiMethodType: APIMethodConstants.post,
                data: {
                    container_id,
                    user_id: currentUser.id,
                    cluster_step_id: clusterStepID,
                    cluster_id,
                    data: sorted,
                    form_type,
                    domain_id,
                },
            },
            {
                onSuccess: () => refreshNextClusterStep(true),
                onError: () => {
                    undefined;
                },
                onSettled: () => {
                    undefined;
                },
            }
        );
    };

    return (
        <Box className="v-set-container" sx={{ width: "100%", padding: ".5rem 3rem" }}>
            <Grid container>
                {!domain.is_answered ? (
                    <Grid item xs={12}>
                        <SortingItems
                            categories={categories}
                            data={items}
                            clusterStep={clusterStep}
                            refreshNextClusterStep={refreshNextClusterStep}
                        />
                    </Grid>
                ) : (
                    <Grid item sx={{ width: "100%" }}>
                        <VSetsEvaluation
                            containerId={domain.vignettes[0].container_id}
                            clusterStep={clusterStep}
                            clusterId={clusterId}
                            domainId={domain_id}
                        />
                    </Grid>
                )}
            </Grid>

            {/* <Box className="v-set-container__options">
                <SortableList onSortEnd={onSortEnd}>
                    {items.map((item, index) => (
                        <SortableItem
                            key={item.id}
                            index={index}
                            curIndex={index}
                            value={item}
                            recentIndex={recentIndex}
                        />
                    ))}
                </SortableList>
            </Box>
            <VSTypography
                size="lg"
                sx={(theme) => ({
                    display: "flex",
                    marginBottom: "1rem",
                    color: theme.palette.info.main,
                })}
                variant="paragraph"
            >
                *{domain.footer_text || StringConstants.vignetteDefaultFooterText}
            </VSTypography>
            <Grid item xs={12} sx={{ display: "flex", flexDirection: "row-reverse", marginRight: "50px" }}>
                <VSButton variant="contained" color="primary" size="large" onClick={handleNext} loading={isSaving}>
                    SUBMIT
                    <ArrowRightIcon />
                </VSButton>
            </Grid> */}
        </Box>
    );
};
