import { TablePagination } from "@mui/material";
import CircularLoadingProgress from "components/CircularProgress";
import { useGetAdminCoPilotResponses } from "hooks/CoPilots";
import NetworkError from "pages/error/networkError";
import { useEffect, useState } from "react";
import { uid } from "react-uid";
import CoPilotResponsePanel from "./CoPilotResponsePanel/CoPilotResponsePanel";

export default function ViewCoPilotResponses({ coPilotId }: any) {
    const [coPilotResponseData, setCoPilotResponseData] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    useEffect(() => {
        refetchData();
    }, []);

    const onSuccessFetch = (data: any) => {
        setCoPilotResponseData(data);
    };

    const onErrorFetch = (error: any) => {
        undefined;
    };
    const {
        isFetched,
        isFetching: isDataFetching,
        isLoading: isDataLoading,
        isError: isDataError,
        data: allData,
        error,
        refetch: refetchData,
    } = useGetAdminCoPilotResponses({
        coPilotID: coPilotId,
        isEnabled: true,
        onSuccess: onSuccessFetch,
        onError: onErrorFetch,
    });
    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    if (isDataFetching || isDataLoading) {
        return <CircularLoadingProgress />;
    }

    if (isDataError) {
        return <NetworkError handleOnRefresh={refetchData} errorText={error} />;
    }
    return (
        <div>
            {coPilotResponseData?.map((eachResponse: any) => {
                return (
                    <CoPilotResponsePanel
                        key={uid(eachResponse)}
                        header={eachResponse?.email ? eachResponse?.email : eachResponse?.user_id}
                        content={eachResponse?.responses}
                    />
                );
            })}
            {coPilotResponseData?.length && (
                <div>
                    <TablePagination
                        component="div"
                        count={coPilotResponseData?.length}
                        page={page}
                        onPageChange={handleChangePage}
                        rowsPerPage={rowsPerPage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </div>
            )}
        </div>
    );
}
