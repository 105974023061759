const styles = {
    container: {
        display: "flex",
        flexDirection: "row",
        gap: ".1rem",
        justifyContent: "space-between",
        alighItems: "center",
        backgroundColor: "#fff",
        padding: "20px",
        marginTop: "0.5rem",
        borderRadius: "10px",
    },
    buttonContainer: {
        flex: "1",
        display: "flex",
        gap: "10px",
    },
    searchContainer: {
        flex: "2",
        width: "100%",
    },
    fieldInputLabel: {
        fontSize: "14px",
        fontWeight: "400",
        lineHeight: "24px",
        textAlign: "left",
        color: "#929292",
    },
};

export default styles;
