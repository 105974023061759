// Material Imports

export const ViewParameterElements = ({ elements }: any) => {
    
    return (
        <>
            <div className="table-wrap mt-5">
                <div className="table-responsive">
                    <table className="table table-responsive align-middle text-center table-bordered border-dark">
                        <thead>
                            <tr>
                                <th className="table-header" key="parameter-name-header">
                                    Parameter Name
                                </th>
                                <th className="table-header" key="parameter-value-header">
                                    Parameter Value
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                            (elements || []).map((eachParameter: any) => {
                                    
                                    return(

                                    <tr>
                                        <td>
                                            {eachParameter.parameter_text}
                                        </td>
                                        <td>
                                            {eachParameter.value}
                                        </td>
                                    </tr>)
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
};
