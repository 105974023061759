// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { useEffect } from "react";
import Nestable from "react-nestable";

// Custom Styles
import {
    FloatingButtonWrapper
} from "pages/admin/dashboard/components/ClusterFormBuilder/ClusterFormBuilder.styles";

// Constants
// Material Imports
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import AddCircleOutlineOutlinedIcon from "@material-ui/icons/AddCircleOutlineOutlined";
import DragIndicatorIcon from "@material-ui/icons/DragIndicator";
import DeleteConfirmationDialog from "pages/shared/DeleteConfirmationDialog";

// Constants
import { APIMethodConstants } from "constants/api.method.constants";

// Form Builder Hooks

// Static Components
import CircularLoadingProgress from "components/CircularProgress";
import NetworkError from "pages/error/networkError";
// Parameter Form Builder Constants

// Parameter Form Components
import { ViewParameterElements } from "pages/admin/dashboard/VSortsContainer/VSetParameters/ViewParameterElements";
import { ParameterElements } from "./ParameterElements";
// Swal Alert - Dialog to display any errors
import { ParameterTypeElements } from "constants/enum.constants";
import { useCreateEditParamValues, useDeleteVSortParameterRelation, useVSortsAllParameters, useVSortsContainerParameterData } from "hooks/VSortsContainerTableData";
import { useParams } from "react-router-dom";
import { SwalAlert } from "utils/swal-utils";
// 1. isCreate :- Represents if the FormBuilder needs to be opened in the creator mode
// 2. isEdit :- Represents if the FormBuilder needs to be opened in Editor mode
// 3. isView :- Represents if the FormBuilder needs to be opened in Viewer mode
// 4. surveyName :- Represents the name of the current Survey - Pre-Survey, Post-Survey and Final-Feedback
// 5. surveyID :- Represents the ID of the current Survey
const CreateVSetParameters = ({ isCreate, isEdit, isView }: any) => {
    const { containerID } = useParams();

    const initVal = ParameterTypeElements[0]?.value;
    const [isData, setIsData] = React.useState(false);
    const [vsetParameterData, setVsetParameterData] = React.useState<any>([]);
    const [elementType, setElementType] = React.useState("offset");
    const [previewEnabled, setPreviewEnabled] = React.useState(false);
    const [dialogData, setDialogData] = React.useState<any>(false);
    const [allParameters, setAllParameters] = React.useState([])
    const [deleteID, setDeleteID] = React.useState()


    React.useEffect(() => {
        refetchVSetParameters();
    }, []);

    // Function to add new a VSet parameter
    const addElement = () => {
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let result = ' ';
        const charactersLength = characters.length;
        for (let i = 0; i < charactersLength; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        const parameter: any = allParameters.filter((each: any) => each.parameter_text == elementType)
        const parameter_id = parameter[0]?.id
        const newData = {
            id: result,
            container_id: Number(containerID),
            parameter_id: parameter_id,
            value: 0,
            deleted: 0,
            parameter_text: elementType
        };
        setVsetParameterData((prevState: any) => [...prevState, newData]);
        setElementType(initVal);
    };

    const deleteEl = ({ id }: any) => {
        setDialogData({
            title: "the Element",
            itemLabel: "the element",
            handleSubmit: () => deleteElement({ id }),
        });
    };

    // Function to delete a VSet parameter
    const deleteElement = ({ id }: any) => {
        if (/^[0-9]+$/. test(String(id))) {
            deleteStep(id);
        }
        const updated = vsetParameterData.filter((item: any) => item.id !== id);
        setVsetParameterData(updated);
        setDialogData(false);
    };

    // Function to Handle Autocomplete Input Values
    const handleParamValueChange = (id: any, event: any) => {
        const newArr: any = vsetParameterData.map((el: any) => {
            if (el.id === id) {
                return { ...el, value: event };
            } else {
                return el;
            }
        });
        setVsetParameterData(newArr);
    };

    // Function to Handle Form Element Type (VSorts, Pre Survey, Post Survey, Final Feedback and Video)
    const handleElType = (id: any, paramtext: any) => {
        const parameter: any = allParameters.filter((each: any) => each.parameter_text == paramtext)
        const parameter_id = parameter[0]?.id
        const newArr = vsetParameterData.map((el: any) => {
            if (el.id === id) {
                return { ...el, parameter_text: paramtext, parameter_id: parameter_id };
            } else {
                return el;
            }
        });
        setVsetParameterData(newArr);
    };

    // Function to toggle the preview state (true/false)
    const togglePreview = () => setPreviewEnabled(!previewEnabled);

    // Render Form Elements in Edit Mode
    const renderEditElements = ({ item, handler }: any) => {
        return ParameterElements({
            item,
            deleteEl,
            handleElType,
            handler,
            handleParamValueChange: handleParamValueChange
        });
    };


    const onVSetsParameterDataFetchSuccess = (data: any) => {
        setAllParameters(data)
        // TODO:- Perform any operation after the successful fetching of VSorts Container Data
        // 
    };

    const onVSetsParameterDataFetchError = (error: any) => {
        // TODO:- Perform any operation in case of errors while fetching VSorts Container Data
        // 
    };

  
    useEffect(() => {
        refetchVSetsAllParametersData()
    },[])
    
    // `useVSortsAllParameters` is the query to fetch VSorts All parameter kinds
    // 1. isFetching -> Represents data being fetched (In background)
    // 2. isLoading -> Represents data being fetched (In Foreground)
    // 3. isError -> Represents if the request errored out
    // 4. data -> Represents the data from the API Response
    // 5. error -> Represents the error message of the request erroring out
    // 6. refetch -> Represents the refetch function to initiate a network request
    const {
        isLoading: isVSetsAllParamtersDataLoading,
        isError: isVSetsAllParamtersDataError,
        data: VSetsAllParametersData,
        error: VSetsAllParametersDataError,
        refetch: refetchVSetsAllParametersData,
    } = useVSortsAllParameters({
        onSuccess: onVSetsParameterDataFetchSuccess,
        onError: onVSetsParameterDataFetchError,
    });

    const onVSetsContainerParameterDataFetchSuccess = (data: any) => {
        // We set the data variable and make the setIsData variable to true signifying that the data has been set
        setVsetParameterData((data || []).map((item: any, index: number) => ({ ...item })));
        setIsData(true);
    };

    const onVSetsContainerParameterDataFetchError = (error: any) => {
        // TODO:- Perform any operation in case of errors while fetching Survey Data
        // 
    };

    // `useVSortsContainerParameterData` is the query to fetch All parameters in a VSorts
    // 1. isFetched -> Represents that the data has finished fetching (In Background)
    // 2. isFetching -> Represents data being fetched (In background)
    // 3. isLoading -> Represents data being fetched (In Foreground)
    // 4. isError -> Represents if the request errored out
    // 5. data -> Represents the data from the API Response
    // 6. error -> Represents the error message of the request erroring out
    // 7. refetch -> Represents the refetch function to initiate a network request
    const {
        isFetched,
        isFetching: isVSetsContainerParameterDataFetching,
        isLoading: isVSetsContainerParameterDataLoading,
        isError: isVSetsContainerParameterDataError,
        data: VSetsContainerParametersData,
        error: VSetsContainerParametersDataError,
        refetch: refetchVSetParameters,
    } = useVSortsContainerParameterData({
        containerID: containerID,
        isEnabled: !isCreate,
        onSuccess: onVSetsContainerParameterDataFetchSuccess,
        onError: onVSetsContainerParameterDataFetchError,
    });

    // We check if the data is fetched and hasn't be set yet to set the data
    // This is to ensure that we set the data with background refetch but don't cause an infinite loop of react setState
    if (isFetched && !isData) {
        onVSetsContainerParameterDataFetchSuccess(VSetsContainerParametersData);
    }

    // `createVSetParameters` is the mutation to create VSorts Parameters
    // 1. mutate -> Represents the mutation function to make the network request
    // 2. isError -> Represents the error caused while creating VSorts Parameter
    // 3. reset -> Represents the function to clear the useCreateEditParamValues mutation state
    const {
        mutate: createVSetParameters,
        isError: isCreateVSetParameterError,
        reset: resetCreateVSetParamterMutation,
    }: any = useCreateEditParamValues();

    // Function to handle submit Form
    const handleSubmit = async () => {
        
        createVSetParameters({
            apiMethodType: APIMethodConstants.post,
            data: {
                data: vsetParameterData
            },
        });
    };

    // `deleteFormQuestion` is the mutation to delete a VSorts parameter
    // 1. mutate -> Represents the mutation function to make the network request
    // 2. isError -> Represents the error caused while delete a VSorts Parameter
    // 3. reset -> Represents the function to clear the useDeleteVSortParameterRelation mutation state
    const {
        mutate: deleteVSetParam,
        isError: isDeleteVSetParameterError,
        reset: resetDeleteVSetParameterMutation,
    }: any = useDeleteVSortParameterRelation({
        id: deleteID,
    });

    // Function to handle Deleting of a VSorts Parameter
    const deleteStep = async (id: any) => {
        setDeleteID(id)
        
        deleteVSetParam({
            id: id,
        });
    };

    // We check if there is any data being fetched (In Foreground or Background) to display `CircularLoadingProgress`
    if (isVSetsAllParamtersDataLoading || isVSetsContainerParameterDataLoading || isVSetsContainerParameterDataFetching) {
        return <CircularLoadingProgress />;
    }

    // We check if there is an error present and display `NetworkError`
    if (isVSetsAllParamtersDataError) {
        return (
            <NetworkError
                handleOnRefresh={refetchVSetsAllParametersData}
                errorText={VSetsAllParametersDataError}
            />
        );
    }

    // We check if there is an error present and display `NetworkError`
    if (isVSetsContainerParameterDataError) {
        return <NetworkError handleOnRefresh={refetchVSetParameters} errorText={VSetsContainerParametersDataError} />;
    }

    // We check if there's an error while performing mutations `useCreateFormData` and `useDeleteFormQuestionData`
    // The state of both the mutations is reset and we show the appropriate error to the user
    if (isDeleteVSetParameterError || isCreateVSetParameterError) {
        resetDeleteVSetParameterMutation();
        resetCreateVSetParamterMutation();

        // SwalAlert being called with {} to take the default value to given to the parameters
        SwalAlert({});
    }

    // `parameterFormQuestionsComponent` -> Represents the current view to be displayed (Edit/Create/Preview)
    let parameterFormQuestionsComponent;

    // Checking if the current mode is that of the Creator/Editor
    if (!previewEnabled && (isCreate || isEdit)) {
        parameterFormQuestionsComponent = (
            <>
                <Grid item md={8} xs={10}>
                    <Nestable
                        items={vsetParameterData}
                        renderItem={renderEditElements}
                        maxDepth={1}
                        handler={
                            <Box sx={{ textAlign: "center", cursor: "all-scroll" }}>
                                <DragIndicatorIcon sx={{ transform: "rotate(-90deg)" }} />
                            </Box>
                        }
                    />
                </Grid>
                <Grid item md={1}>
                    <Tooltip title="Add Element" aria-label="add-element">
                        <IconButton aria-label="add-element" onClick={addElement} sx={{ position: "sticky", top: 30 }}>
                            <AddCircleOutlineOutlinedIcon color="secondary" />
                        </IconButton>
                    </Tooltip>
                </Grid>
            </>
        );
    }

    // Checking if the current mode is that of Previewing the Form
    if (previewEnabled || isView) {
        // We check if we are currently in View Mode, if so, we use the `vsetParameterData` variable
        // If we are in preview mode, we use the `data` variable
        parameterFormQuestionsComponent = (
            <>
                <Grid item md={9} xs={11}>
                    {ViewParameterElements({ elements: vsetParameterData })}
                </Grid>
            </>
        );
    }

    return (
        <>
            <DeleteConfirmationDialog
                open={!!dialogData}
                handleClose={() => setDialogData(false)}
                handleSubmit={dialogData.handleSubmit}
                title={dialogData.title}
                itemLabel={dialogData.itemLabel}
            />
            <Grid container spacing={1} direction="row" justifyContent="center">
                {parameterFormQuestionsComponent}
            </Grid>
            {
                // Display the Buttons to Go Back or Preview and Save only if the current mode is that of a Creator or Editor
                (isCreate || isEdit) && (
                    <FloatingButtonWrapper>
                        {/* Go Back/Preview Button */}
                        <div className="action-button preview" onClick={togglePreview}>
                            {previewEnabled ? "Go Back" : "Preview"}
                        </div>

                        {/* Save Button */}
                        <div className="action-button save" onClick={handleSubmit}>
                            Save
                        </div>
                    </FloatingButtonWrapper>
                )
            }
        </>
    );
};

export default CreateVSetParameters;
