// Material Imports
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import VSButton from "components/VSButton";

export default function DeleteConfirmationDialog({ title, itemLabel, open, handleClose, handleSubmit, loading }: any) {
    return (
        <div>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle> Delete {title}</DialogTitle>
                <DialogContent>
                    <DialogContentText>Are you sure you want to delete {itemLabel}?</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <VSButton color="success" variant="contained" onClick={handleSubmit} loading={loading}>
                        Confirm
                    </VSButton>
                </DialogActions>
            </Dialog>
        </div>
    );
}
