import { useDeleteOrganizations } from "hooks/VSortsClusterData";
import { DynamicModel } from "pages/adminV2/Components";
import "./DeleteOrganization.css";

interface props {
    id: number;
    containerType: string;
    currentContainerType: string;
    open: boolean;
    setOpen: (value: boolean) => void;
    selected?: any;
    refetchFunction: () => void;
}

function DeleteOrganizationModal({
    id,
    containerType,
    currentContainerType,
    open,
    setOpen,
    selected,
    refetchFunction,
}: props) {
    const onDeleteSuccess = (data: any) => {
        refetchFunction();
    };
    const onDeleteError = (error: any) => {
        undefined;
    };

    const { mutate: callingDeleteOrganization } = useDeleteOrganizations({
        data: {
            containerType: containerType,
            containerID: id,
            orgIds: selected,
        },
        onSuccess: onDeleteSuccess,
        onError: onDeleteError,
    });

    const callDeleteOrganization = async () => callingDeleteOrganization();

    const handleDelete = async () => {
        setOpen(false);
        await callDeleteOrganization();
    };

    // const handleDelete = async () => {
    //     await deleteVSortsCluster(selected.id, {}).then((_) => {
    //         refertchClusterData();
    //         setOpen(false);
    //     });
    // };

    return (
        <DynamicModel
            open={open}
            setOpen={setOpen}
            title={`Are you sure you want to remove an organization access to this ${currentContainerType}?`}
        >
            <div className="deleteModal">
                <p className="deleteModalContainer__desc">You cannot undo this action once you confirm</p>
                <div className="deleteModalContainer">
                    <button onClick={() => handleDelete()}>Remove</button>
                </div>
            </div>
        </DynamicModel>
    );
}

export default DeleteOrganizationModal;
