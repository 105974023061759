import axios from "axios";

// Custom Constants
import { NetworkConstants } from "constants/network.constants";
import Cookies from "universal-cookie";

// Creating an axios client with the BaseURL
const client = axios.create({
    baseURL: NetworkConstants.baseURL,
});

// Accepting all options that Axios would accept
export const request = async ({ ...options }) => {
    const cookies = new Cookies();
    const idToken = cookies.get("idToken");
    client.defaults.headers.common.Authorization = `Bearer ${idToken}`;
    // Return of the response if we have an onSuccess Callback
    const onSuccess = (response: any) => response;
    // Throw the error that the request encountered
    const onError = (error: any) => {
        // TODO :- Catch all errors and relevant logging mechanisms here
        throw error;
    };

    try {
        const response = await client(options);
        return onSuccess(response);
    } catch (error) {
        return onError(error);
    }
};
