/* eslint-disable no-unused-expressions */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { Box, Card, CardContent, Grid } from "@material-ui/core";
import { purple } from "@mui/material/colors";
import LinearProgress, { linearProgressClasses } from "@mui/material/LinearProgress";
import { styled } from "@mui/material/styles";
import { useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import { arrayMove } from "react-sortable-hoc";

// import arrayMove from "array-move";
import RefreshIcon from "@mui/icons-material/Refresh";
import { Button, IconButton } from "@mui/material";
import { CurrentUserSelector } from "app/slices/UserSlice";
import LightToolTip from "components/LightTooltip";
import VSButton from "components/VSButton";
import { APIMethodConstants } from "constants/api.method.constants";
import { useSaveVSortMutation } from "hooks/surveyFormData";
import _ from "lodash";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import styles from "../styles";
import CategoryDefinitionModel from "./Components/CategoryDefinitionModel";
import CustomModal from "./Components/CustomModal";
import LinearProgressComponent from "./Components/LinearProgress";
import SortableList from "./sortableList";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        backgroundColor: theme.palette.mode === "light" ? "#009245" : "#308fe8",
    },
}));

export const ColorButton = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText(purple[500]),
    backgroundColor: "#29ABE2",
    margin: "0 20px 0 0",
    "&:hover": {
        backgroundColor: "rgba(41, 177, 226, 0.8)",
    },
    borderRadius: "8px",
    borderWidth: "2px",
}));

const DisableButton = styled(Button)(({ theme }) => ({
    color: "#94A8B0",
    backgroundColor: "#F5F8FB",
    margin: "0 20px 0 0",
    "&:hover": {
        backgroundColor: "#F5F8FB",
        color: "#94A8B0",
        borderRadius: "8px",
        border: "2px solid #94A8B0",
    },
    borderRadius: "8px",
    border: "2px solid #94A8B0",
}));

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    // border: "2px solid #000",
    boxShadow: 15,
    padding: "20px",
    borderRadius: "10px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
};

const SortingItems = ({ data, categories, clusterStep, refreshNextClusterStep }: any) => {
    const { container_id, id: clusterStepID, cluster_id, form_type, domain_id } = clusterStep;

    const currentUser = useSelector(CurrentUserSelector);
    const { mutate: saveVSort, isLoading: isSaving } = useSaveVSortMutation();

    const [enableItems, setEnableItems] = useState<any[]>([]);
    const [disableItems, setDisableItems] = useState<any[]>([
        { vignette_text: null },
        { vignette_text: null },
        { vignette_text: null },
        { vignette_text: null },
        { vignette_text: null },
        { vignette_text: null },
    ]);

    const [open, setOpen] = useState(false);

    const handleTooltipClose = () => {
        setOpen(false);
    };

    const handleTooltipOpen = () => {
        setOpen(true);
    };

    const handleOpenCategoryDefinitionModal = () => {
        setOpenCategoryDefinitionModal(!openCategoryDefinitionModal);
    };

    const [openModal, setOpenModal] = useState(false);
    const [openCategoryDefinitionModal, setOpenCategoryDefinitionModal] = useState(false);
    const [categoryDefinitions, setCategoryDefinitions] = useState([]);
    const [totalItems, setTotalItems] = useState(0);
    const [totalLength, setTotalLength] = useState(0); // items completed
    const [totalProgress, setTotalProgress] = useState(0);
    const [draggingItem, setDraggingItem] = useState<any>(null);
    const [draggingItemIndex, setDraggingItemIndex] = useState<any>(null);
    const [targetOrderIndex, setTargetOrderIndex] = useState(null);
    const [isDragging, setIsDragging] = useState(false);
    const [sourceArea, setSourceArea] = useState(null);
    const [targetArea, setTargetArea] = useState(null);
    const [selectedHoverItem, setSelectedHoverItem] = useState<any>(null);
    const navigate = useNavigate();

    const enableRef = useRef<any>();
    const disableRef = useRef<any>();

    const handleAreaMouseEnter = (area: any) => {
        if (isDragging === true) {
            setTargetArea(area);
        } else {
            setSourceArea(area);
            setTargetArea(area);
        }
    };

    useEffect(() => {
        if (data && categories) {
            const updatedCategories = categories.map((item: any) => ({ ...item, vignette_text: null }));
            setDisableItems(updatedCategories);
            // randomize the order in which vignettes are presented to the user.
            const shuffledArray = data
                .map((a) => ({ sort: Math.random(), value: a }))
                .sort((a, b) => a.sort - b.sort)
                .map((a) => a.value);
            setEnableItems([...shuffledArray]);
            setTotalItems(data.length);
            setCategoryDefinitions(updatedCategories);
        }
    }, [data, categories]);

    const handleDragStart = (e: any) => {
        setIsDragging(true);
        if (sourceArea === "ENABLE") {
            setDraggingItemIndex(e.index);
            setDraggingItem(enableItems[e.index]);
        } else if (sourceArea === "DISABLE") {
            if (disableItems[e.index]?.vignette_text !== null) {
                if (targetArea === "DISABLE") {
                    setDraggingItemIndex(e.index);
                }
                if (enableItems.length === 0 || enableItems[enableItems.length - 1].id !== -1) {
                    enableItems.push({
                        id: -1,
                        vignette_text: "placeholder",
                    });
                    setEnableItems(enableItems);
                }
                setDraggingItem(disableItems[e.index]);
            }
        }
    };

    const handleDragging = (e: any) => {
        if (draggingItem && isDragging && sourceArea === "DISABLE" && targetArea === "ENABLE") {
            if (draggingItem.vignette_text !== null) {
                setDraggingItem(null);
                const enableNodes = enableRef.current.container.children;
                const lastNodeIndex = enableNodes.length - 1;
                const lastNode = enableNodes[lastNodeIndex];
                // eslint-disable-next-line react/no-deprecated
                ReactDOM.render(
                    <Grid
                        container
                        spacing={1}
                        style={{
                            boxShadow: "0 0 5px rgba(0,0,0,0.2)",
                            backgroundColor: "#29ABE2",
                            borderRadius: "5px",
                            minHeight: "80px",
                            width: "100%",
                        }}
                    >
                        <Grid
                            item
                            style={{
                                backgroundColor: "#fff",
                                marginLeft: "10px",
                                width: "100%",
                                minHeight: "80px",
                                borderTopRightRadius: "5px",
                                borderBottomRightRadius: "5px",
                                display: "flex",
                                alignItems: "center",
                            }}
                        >
                            <Grid container>
                                <Grid item xs={12}>
                                    {draggingItem.vignette_text}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>,

                    disableRef.current.helper
                );
                enableRef.current.manager.active = {
                    collection: "ENABLE",
                    index: lastNodeIndex,
                };
                enableRef.current.handlePress(e);
                let pageXCount = 0.5;
                switch (enableNodes.length % 3) {
                    case 1:
                        pageXCount = 2.5;
                        break;
                    case 2:
                        pageXCount = 1.5;
                        break;
                    default:
                        break;
                }
                enableRef.current.initialOffset = {
                    x: e.pageX - lastNode.clientWidth * pageXCount,
                    y: e.pageY - (e.pageY - lastNode.offsetTop) + lastNode.clientHeight * 0.5,
                };
                enableRef.current.handleMove(e);
            }
        }
    };

    const handleDragOver = (e: any) => {
        if (isDragging && sourceArea === "DISABLE" && targetArea === "ENABLE" && e.collection === "ENABLE") {
            setTargetOrderIndex(e.newIndex);
        }
    };

    useEffect(() => {
        const totalLength = disableItems.filter((item) => item.vignette_text !== null).length;
        const totalProgress = (totalLength / totalItems) * 100;
        setTotalProgress(totalProgress);
        setTotalLength(totalLength);
    }, [disableItems]);

    const handleDragEnd = (e: any) => {
        if (targetArea === "ENABLE" && sourceArea === "DISABLE" && e.collection === "DISABLE") {
            return;
        }
        if (enableItems[enableItems.length - 1].id === -1) {
            enableItems.pop();
            setEnableItems(enableItems);
        }
        if (targetArea === "DISABLE") {
            if (sourceArea === "ENABLE") {
                const findFirstNullCat = disableItems.findIndex((item) => item.vignette_text === null);
                const hoverItemIndex = disableItems.findIndex((item) => item.id === selectedHoverItem.id);

                // Hover over already populated item from left to right column
                if (disableItems[hoverItemIndex] === undefined) {
                    const popToLeft = disableItems[e.newIndex];
                    disableItems[e.newIndex] = enableItems[draggingItemIndex];
                    disableItems[e.newIndex].category_name = popToLeft.category_name;
                    enableItems[draggingItemIndex] = popToLeft;

                    setDisableItems([...disableItems]);
                    setEnableItems([...enableItems]);

                    setDraggingItemIndex(null);
                    setTargetOrderIndex(null);
                    setIsDragging(false);
                    setSourceArea(targetArea);
                    setDraggingItem(null);

                    return;
                }

                if (disableItems[hoverItemIndex].vignette_text !== null) {
                    return;
                }
                const result = insert(disableItems, hoverItemIndex, {
                    ...enableItems[e.oldIndex],
                    category_name: disableItems[hoverItemIndex].category_name,
                });
                result.splice(hoverItemIndex + 1, 1);
                enableItems.push({ vignette_text: null });
                enableItems.splice(e.oldIndex, 1);
                setDisableItems([...result]);
                setEnableItems([...enableItems]);
            } else {
                // if (
                //     disableItems[e.oldIndex].vignette_text !== null &&
                //     disableItems[e.newIndex].vignette_text !== null
                // ) {
                // Hover over already populated item from right to right column
                if (e.oldIndex !== e.newIndex) {
                    // index are swapped out
                    let newArray = swapObjects(disableItems, e.oldIndex, e.newIndex);
                    newArray = resetObjectToDisabled(newArray, e.oldIndex);

                    const newEnableItems = shiftObjectToEnabled(disableItems, e.newIndex);

                    setEnableItems([...newEnableItems]);
                    setDisableItems(newArray);
                    // setDisableItems(arrayMove(disableItems, e.oldIndex, e.newIndex));
                }
                // }
            }
        } else if (targetArea === "ENABLE") {
            if (sourceArea === "ENABLE") {
                if (e.oldIndex !== e.newIndex) {
                    setEnableItems(arrayMove(enableItems, e.oldIndex, e.newIndex));
                }
            } else {
                const nullCalculator = disableItems.filter((item) => item.vignette_text === null).length;
                enableItems.splice(
                    _.isNull(targetOrderIndex) ? enableItems.length : targetOrderIndex,
                    0,
                    disableItems[draggingItemIndex]
                );
                const catInd = categories.findIndex(
                    (item: any) => item.category_name === disableItems[draggingItemIndex].category_name
                );
                const result = insert(disableItems, draggingItemIndex, { ...categories[catInd], vignette_text: null });
                result.splice(draggingItemIndex + 1, 1);
                // disableItems.splice(draggingItemIndex, 1);

                // disableItems.splice(draggingItemIndex, 0, { ...categories[catInd], vignette_text: null });
                // check the vignette_text is null on which index and remove it from the enableItems array
                const nullIndex = enableItems.findIndex((item) => item.vignette_text === null);
                if (nullIndex !== -1) {
                    enableItems.splice(nullIndex, 1);
                }
                setEnableItems([...enableItems]);
                setDisableItems([...result]);
            }
        }
        setDraggingItemIndex(null);
        setTargetOrderIndex(null);
        setIsDragging(false);
        setSourceArea(targetArea);
        setDraggingItem(null);
    };
    const swapObjects = (arr: any, i1: any, i2: any) => {
        const temp = { ...arr[i1] };
        const temp2 = { ...arr[i2] };
        const oldId = arr[i1].id;
        const newId = arr[i2].id;
        const redArr = arr.reduce((acc: any, item: any) => {
            if (oldId === item.id) {
                arr[i2].category_name = temp.category_name;
                return [...acc, arr[i2]];
            } else if (newId === item.id) {
                arr[i1].category_name = temp2.category_name;
                return [...acc, arr[i1]];
            }
            return [...acc, item];
        }, []);

        return redArr;
    };
    const swapElements = (arr: any, i1: any, i2: any) => {
        const arrCopy = [...arr];
        // Step 1
        const temp = arrCopy[i1];
        const temp2 = arrCopy[i2];

        // Step 2
        arrCopy[i1] = arrCopy[i2];

        // Step 3
        arrCopy[i2] = temp;

        return arrCopy;
    };

    // move object from right to left
    const shiftObjectToEnabled = (arr: array, i1: int) => {
        const resetItem = arr[i1];
        const firstEmptySlotIndex = enableItems.findIndex((item) => item.vignette_text === null);
        enableItems[firstEmptySlotIndex] = resetItem;
        return enableItems;
    };

    //reset object to disabled state
    const resetObjectToDisabled = (arr: array, i1: int) => {
        const updatedCategories = categories.map((item: any) => ({ ...item, vignette_text: null }));
        arr[i1] = updatedCategories[i1];
        return arr;
    };

    const insert = (arr: any, index: any, newItem: any) => [
        // part of the array before the specified index
        ...arr.slice(0, index),
        // inserted item
        newItem,
        // part of the array after the specified index
        ...arr.slice(index),
    ];
    const onSubmit = () => {
        const sorted = disableItems.map((item, index) => ({ vignette_id: item.id, rank_number: index + 1 }));
        saveVSort(
            {
                apiMethodType: APIMethodConstants.post,
                data: {
                    container_id,
                    user_id: currentUser.id,
                    cluster_step_id: clusterStepID,
                    cluster_id,
                    data: sorted,
                    form_type,
                    domain_id,
                },
            },
            {
                onSuccess: () => {
                    setOpenModal(false);
                    refreshNextClusterStep(true);
                    window.location.reload();
                },
                onError: () => {
                    undefined;
                },
                onSettled: () => {
                    undefined;
                },
            }
        );
    };

    const hoverItem = (item) => {
        setSelectedHoverItem(item);
    };

    const handleReset = () => {
        if (data && categories) {
            const updatedCategories = categories.map((item: any) => ({ ...item, vignette_text: null }));
            setDisableItems(updatedCategories);
            // randomize the order in which vignettes are presented to the user.
            const shuffledArray = data
                .map((a) => ({ sort: Math.random(), value: a }))
                .sort((a, b) => a.sort - b.sort)
                .map((a) => a.value);
            setEnableItems([...shuffledArray]);
        }
    };

    const handleExit = () => {
        navigate("/dashboard");
    };

    return (
        <>
            <Box>
                <Grid container spacing={2} sx={{ width: "100%" }}>
                    {/* Left draggable items */}
                    <Grid item xs={6}>
                        <Box sx={{ padding: "1rem 1rem 0rem 0rem" }}>
                            <Card
                                elevation={0}
                                style={{ backgroundColor: "#fff", minHeight: "535px", width: "100%", padding: "5px" }}
                                onMouseEnter={() => handleAreaMouseEnter("ENABLE")}
                            >
                                <CardContent>
                                    <SortableList
                                        axis="xy"
                                        area="ENABLE"
                                        items={enableItems}
                                        draggingItem={draggingItem}
                                        isDragging={isDragging}
                                        sourceArea={sourceArea}
                                        targetArea={targetArea}
                                        ref={enableRef}
                                        selfRef={enableRef}
                                        otherRef={disableRef}
                                        onSortStart={handleDragStart}
                                        onSortOver={handleDragOver}
                                        onSortEnd={handleDragEnd}
                                    />
                                </CardContent>
                            </Card>
                        </Box>
                    </Grid>

                    {/* Right droppable items */}
                    <Grid item xs={6} sx={{ boxShadow: "none" }}>
                        <Card
                            style={{ width: "100%", minHeight: "535px", boxShadow: "none" }}
                            onMouseEnter={() => handleAreaMouseEnter("DISABLE")}
                        >
                            <CardContent>
                                <SortableList
                                    axis="xy"
                                    area="DISABLE"
                                    items={disableItems}
                                    draggingItem={draggingItem}
                                    isDragging={isDragging}
                                    sourceArea={sourceArea}
                                    targetArea={targetArea}
                                    ref={disableRef}
                                    selfRef={disableRef}
                                    otherRef={enableRef}
                                    onSortStart={handleDragStart}
                                    onSortMove={handleDragging}
                                    onSortEnd={handleDragEnd}
                                    hoverItem={hoverItem}
                                    handleOpenCategoryDefinitionModal={handleOpenCategoryDefinitionModal}
                                />
                                {/* Old Submit Button */}
                                {/* <Grid container mt={3}>
                            <Grid display="flex" justifyContent="flex-end" alignItems="flex-end" item xs={12}>
                                {totalLength === totalItems ? (
                                    <ColorButton
                                        loading
                                        onClick={() => {
                                            setOpenModal(true);
                                        }}
                                        variant="contained"
                                    >
                                        Submit
                                    </ColorButton>
                                ) : (
                                    <ClickAwayListener onClickAway={handleTooltipClose}>
                                        <div>
                                            <Tooltip
                                                PopperProps={{
                                                    disablePortal: true,
                                                }}
                                                onClose={handleTooltipClose}
                                                open={open}
                                                disableFocusListener
                                                disableHoverListener
                                                disableTouchListener
                                                placement="bottom-end"
                                                title="You cannot submit your response until all vignettes are sorted."
                                            >
                                                <DisableButton variant="outlined" onClick={handleTooltipOpen}>
                                                    Submit
                                                </DisableButton>
                                            </Tooltip>
                                        </div>
                                    </ClickAwayListener>
                                )}
                            </Grid>
                        </Grid> */}
                            </CardContent>
                        </Card>
                    </Grid>

                    <CustomModal
                        openModal={openModal}
                        onModalClose={() => setOpenModal(false)}
                        onSubmit={onSubmit}
                        onCancel={() => setOpenModal(false)}
                        loading={isSaving}
                    />

                    <CategoryDefinitionModel
                        open={openCategoryDefinitionModal}
                        handleClose={handleOpenCategoryDefinitionModal}
                        categories={categoryDefinitions}
                    />
                </Grid>
            </Box>

            <Grid
                container
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    paddingLeft: "10px",
                    paddingRight: "10px",
                }}
            >
                <Grid item xs={6} sx={{ display: "flex", justifyContent: "center" }}>
                    <LinearProgressComponent
                        totalItems={totalItems}
                        totalLength={totalLength}
                        totalProgress={(totalLength / totalItems) * 100}
                    />
                </Grid>
                <Grid>
                    <IconButton sx={{ mr: 20 }} onClick={handleReset}>
                        <RefreshIcon sx={{ transform: "rotate(270deg)", color: "#94A8B0", fontSize: "2rem" }} />
                    </IconButton>
                    <VSButton variant="contained" sx={{ ...styles.btnExit, boxShadow: "none" }} onClick={handleExit}>
                        Exit
                    </VSButton>
                    &nbsp;&nbsp;&nbsp;
                    {totalLength === totalItems ? (
                        <ColorButton
                            onClick={() => {
                                setOpenModal(true);
                            }}
                            variant="contained"
                            sx={{ ...styles.btnContinue, boxShadow: "none" }}
                        >
                            Next
                        </ColorButton>
                    ) : (
                        <LightToolTip
                            title={"You can not submit your response until all vignettes are completed"}
                            placement="top"
                        >
                            <span>
                                <ColorButton
                                    variant="contained"
                                    disabled={true}
                                    sx={{ ...styles.btnContinue, boxShadow: "none" }}
                                >
                                    Next
                                </ColorButton>
                            </span>
                        </LightToolTip>
                    )}
                </Grid>
            </Grid>
        </>
    );
};

export default SortingItems;
