import { createSlice } from "@reduxjs/toolkit";

const createVsetSlice = createSlice({
    name: "createVset",
    initialState: {
        currentCreateVset: null,
    },
    reducers: {
        setCurrentCreateVset: (state, action) => {
            state.currentCreateVset = action.payload;
        },
    },
});


export const { setCurrentCreateVset } = createVsetSlice.actions;
export const CurrentCreateVsetSelector = (state: any) => state.createVset.createVsetSlice;

export default createVsetSlice.reducer;