import { Box, Typography } from "@mui/material";
import { useFetchScoreParameters } from "hooks/surveyFormData";
import ViewFormQuestion from "pages/adminV2/Components/ViewFormQuestion";
import { useState } from "react";
import { uid } from "react-uid";
import styles from "../../../styles";
import "./../VSetUserCard.css";
import VSetCardItem from "./VSetCardItem";

interface VignetteResponse {
    vignette_id: number;
    vignette_text: string;
    assigned_category_id: number;
    assigned_category_name: string;
    user_category_id: number;
    user_category_name: string;
    container_id: number;
    container_name: string;
    domain_id: number;
    domain_name: string;
    domain_order: number;
    user_rank: number;
    assigned_rank: number;
    user_id: string;
    answers: ClusterAnswers[];
    unpacked: number;
    flagged: number;
    is_scoring_complete: number;
}

interface UserResponse {
    user_id: string;
    responses: VignetteResponse[];
}

interface ClusterAnswers {
    id: number;
    answer_id: number;
    answer_type: string;
    user_id: string;
    answer: string;
    question_text: string;
    question_id: number;
    question_number: number;
    vignette_id: number;
}

type ClusterInformationResponses = UserResponse[] | null;

// interface ClusterAnswerResponse {
//     user_id: string;
//     answer: string;
//     question_text: string;
//     question_number: number;
// }

// interface ClusterAnswers {
//     user_id: string;
//     responses: ClusterAnswerResponse[];
// }

interface OutletContext {
    childRef: React.RefObject<any>;
    // clusterAnswers: ClusterAnswers[] | null;
}

type vSetCardProps = {
    title: string;
    type: string | undefined;
    clusterInformationResponses: ClusterInformationResponses | null;
    userId: string;
};

const VSetCard: React.FC<vSetCardProps> = ({ title, type, clusterInformationResponses, userId }: vSetCardProps) => {
    // const { clusterAnswers } = useOutletContext<OutletContext>();
    const [clusterAnswers, setClusterAnswers] = useState<ClusterAnswers[] | []>([]);
    const [showAnswers, setShowAnswers] = useState<boolean>(false);
    const [activeIndex, setActiveIndex] = useState<number | null>(null);

    const [isCollapse, setIsCollapse] = useState(false);

    const {
        isFetching: isParamtersFetching,
        isError: isParamtersError,
        data: scoringParameters,
        error,
        refetch: refetchScoringParameters,
    } = useFetchScoreParameters({
        isEnabled: !!clusterAnswers.length,
        onSuccess: (data: any) => {
            console.log();
        },
        onError: (data: any) => {
            console.log(data);
        },
    });

    return (
        <Box>
            {type === "all" || type === "All" ? (
                <Typography>
                    <p className="card-container-top-line">{title}</p>
                </Typography>
            ) : null}
            <Box sx={styles.cardContainer} display="flex" alignItems="center" marginTop="-15px">
                {clusterInformationResponses
                    ?.find((userResponse) => userResponse.user_id === userId)
                    ?.responses.map(
                        (response, index) =>
                            title === response.domain_name && (
                                <VSetCardItem
                                    key={response.vignette_id}
                                    title={response.user_category_name}
                                    sideLine={true}
                                    content={response.vignette_text}
                                    information={response}
                                    isActive={activeIndex === index}
                                    onClick={() => {
                                        setActiveIndex(index);
                                        setClusterAnswers(response?.answers);
                                        setShowAnswers(true);
                                    }}
                                />
                            )
                    )}
            </Box>

            {showAnswers
                ? clusterAnswers &&
                  Array.isArray(clusterAnswers) &&
                  clusterAnswers.map((ques: any) => (
                      <ViewFormQuestion
                          key={uid(ques)}
                          formQuestion={ques}
                          parametersData={scoringParameters?.length ? scoringParameters : []}
                          userId={userId}
                          formType={"vsorts"}
                      />
                  ))
                : null}
        </Box>
    );
};

export default VSetCard;
