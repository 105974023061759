//import SearchIcon from '@mui/icons-material/Search'
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Box, Button, IconButton, Typography } from "@mui/material";
import ChartIcon from "assets/icons/chart.svg";
import TimeIcon from "assets/icons/time.svg";
import CircularLoadingProgress from "components/CircularProgress";
import {
    CLUSTER_STATUS_COMPLETED,
    CLUSTER_STATUS_NOT_STARTED,
    CLUSTER_STATUS_PROGRESS,
    CLUSTER_STATUS_RESULTS_PENDING,
    CLUSTER_STATUS_SUBMITTED,
} from "constants/dashboard.constants";
import React, { Dispatch, SetStateAction, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Cluster } from "types/dashboard";
import { User } from "types/user";
import styles from "./styles";

type PropsType = {
    currentUser: User | null;
    isLoading: boolean;
    clusters: Cluster[];
    onSelectCluster: (cluster: Cluster) => void;
    setOpenFilter: Dispatch<SetStateAction<boolean>>;
};

const Clusters: React.FC<PropsType> = ({ currentUser, isLoading, clusters, onSelectCluster, setOpenFilter }) => {
    const [tab, setTab] = useState<string>("tasks");
    const navigate = useNavigate();

    const tabClusters: Cluster[] = useMemo(() => {
        return clusters;
        let filteredClusters: Cluster[] = [];
        if (tab === "tasks") {
            filteredClusters = clusters.filter((cluster) => cluster.status !== CLUSTER_STATUS_COMPLETED);
        } else if (tab === "completed") {
            filteredClusters = clusters.filter((cluster) => cluster.status === CLUSTER_STATUS_COMPLETED);
        } else {
            filteredClusters = clusters;
        }

        return filteredClusters;
    }, [tab, clusters]);

    const handleTabSelect = (tabName: string) => {
        setTab(tabName);
    };

    const navigateToClusterDetailPage = (clusterID: number) => {
        navigate(`/user/vsort-cluster/${clusterID}`);
    };

    if (isLoading) {
        return <CircularLoadingProgress />;
    }

    const availableDateRender = (date: string) => {
        const availableDate = new Date(date.split("T")[0]?.replace(/-/g, "/"));

        const availableDateFormat =
            (availableDate.getMonth() > 8 ? availableDate.getMonth() + 1 : "0" + (availableDate.getMonth() + 1)) +
            "/" +
            (availableDate.getDate() > 9 ? availableDate.getDate() : "0" + availableDate.getDate()) +
            "/" +
            availableDate.getFullYear();
        return availableDateFormat;
    };
    return (
        <Box sx={styles.container}>
            {/* <Box sx={{ ...styles.flexContainer }}>
                <Box sx={{ ...styles.flexContainer }}>
                    <Typography sx={styles.title}>VSorts™ Clusters</Typography>
                    <Button sx={styles.btnSearch}>
                        <img src={SearchIcon} alt="search" />
                    </Button>
                </Box>

                <Box sx={{ ...styles.flexContainer }}>
                    <Box>
                        <IconButton sx={styles.btnTab}>
                            <RefreshIcon sx={{ color: "#29ABE2", rotate: "270deg" }} />
                        </IconButton>
                        <Button
                            disableRipple
                            variant="contained"
                            sx={styles.btnFilter}
                            onClick={() => setOpenFilter((openFilter) => !openFilter)}
                        >
                            <Typography fontSize={"0.88rem"}>
                                Filter by{" "}
                                <ArrowForwardIosIcon fontSize="small" sx={{ color: "#94A8B0", fontSize: "1.1rem" }} />
                            </Typography>
                        </Button>
                    </Box>
                </Box>
            </Box> */}

            <Box sx={{ ...styles.clusters }}>
                {console.log("tabclusters", tabClusters)}
                {tabClusters.map((cluster, index) => {
                    const currentDate = new Date();
                    const startDate = new Date(cluster.available_date?.split("T")[0]?.replace(/-/g, "/"));

                    return (
                        <Box
                            sx={styles.cluster}
                            onClick={() => onSelectCluster(cluster)}
                            key={index}
                            id={`cluster-${index}`}
                        >
                            <Box sx={styles.clusterInfo}>
                                <Typography sx={styles.clusterTitle}>{cluster.title}</Typography>
                                <Box sx={styles.flexContainer} id={`cluster-status-${index}`}>
                                    {cluster.status === CLUSTER_STATUS_COMPLETED && (
                                        <Box sx={styles.flexContainer}>
                                            <img src={ChartIcon} alt="Chart" style={styles.clusterIcon} />
                                            <Typography sx={styles.resultsText}>&nbsp;View results</Typography>
                                        </Box>
                                    )}
                                    {cluster.status === CLUSTER_STATUS_NOT_STARTED && (
                                        <Box sx={styles.flexContainerWrap}>
                                            <Typography sx={styles.pendingText}>Not&nbsp;started&nbsp;&nbsp; </Typography>
                                            {cluster.available_date && currentDate < startDate ? (
                                                <Typography sx={styles.availableText}>
                                                    | Available {availableDateRender(cluster.available_date)}
                                                </Typography>
                                            ) : (
                                                <Typography sx={styles.availableText}>
                                                    | Available now
                                                </Typography>
                                            )}
                                        </Box>
                                    )}
                                    {cluster.status === CLUSTER_STATUS_PROGRESS && (
                                        <Box sx={styles.flexContainerWrap}>
                                            <Typography
                                                sx={{
                                                    ...styles.progressText,
                                                    color: "#F84444",
                                                }}
                                            >
                                                Incomplete&nbsp;&nbsp;
                                            </Typography>
                                            {/* Percentage */}
                                            <Box sx={styles.progressPercentageCintainer}>
                                                <img src={TimeIcon} alt="Time" style={styles.clusterIcon} />
                                                <Typography sx={styles.progressText}>
                                                    {`${cluster.completedStatus}%`}&nbsp;&nbsp; 
                                                </Typography>
                                            </Box>
                                            {cluster.available_date && currentDate < startDate ? (
                                                <Typography sx={styles.availableText}>
                                                    | Available {availableDateRender(cluster.available_date)}
                                                </Typography>
                                            ) : (
                                                <Typography sx={styles.availableText}>
                                                    | Available now
                                                </Typography>
                                            )}
                                        </Box>
                                    )}
                                    {cluster.status === CLUSTER_STATUS_RESULTS_PENDING && (
                                        <Typography sx={styles.pendingText}>Results pending</Typography>
                                    )}
                                    {cluster.status === CLUSTER_STATUS_SUBMITTED && (
                                        <Typography sx={styles.pendingText}>Results pending</Typography>
                                    )}
                                </Box>
                            </Box>
                            {/* Cluster Buttons */}
                            <Box id={`cluster-action-${index}`}>
                                {cluster.status === CLUSTER_STATUS_NOT_STARTED && (
                                    // Start Button

                                    <Box sx={styles.flexContainer}>
                                        <Button
                                            sx={styles.btnStart}
                                            style={{
                                                borderColor:
                                                    cluster.available_date && currentDate < startDate
                                                        ? "#C0C0C0"
                                                        : "#29ABE2",
                                            }}
                                            onClick={() => navigateToClusterDetailPage(cluster.id)}
                                            disabled={cluster.available_date && currentDate < startDate ? true : false}
                                        >
                                            Start
                                        </Button>
                                        <IconButton sx={styles.btnMore}>
                                            <MoreVertIcon />
                                        </IconButton>
                                    </Box>
                                )}
                                {cluster.status === CLUSTER_STATUS_PROGRESS && (
                                    // Continue Button
                                    <Box sx={styles.flexContainer}>
                                        <Button
                                            sx={styles.btnContinue}
                                            style={{
                                                borderColor:
                                                    cluster.available_date && currentDate < startDate
                                                        ? "#C0C0C0"
                                                        : "#29ABE2",
                                                background:
                                                    cluster.available_date && currentDate < startDate
                                                        ? "#C0C0C0"
                                                        : "#29ABE2",
                                                color: "#fff",
                                            }}
                                            disabled={cluster.available_date && currentDate < startDate ? true : false}
                                            onClick={() => navigateToClusterDetailPage(cluster.id)}
                                        >
                                            Continue
                                        </Button>
                                        <IconButton sx={styles.btnMore}>
                                            <MoreVertIcon />
                                        </IconButton>
                                    </Box>
                                )}
                                {cluster.status === CLUSTER_STATUS_RESULTS_PENDING && (
                                    // Submitted Button, result pending
                                    <Box sx={styles.flexContainer}>
                                        <Button disabled sx={styles.btnSubmitted}>
                                            Submitted
                                        </Button>
                                        <IconButton sx={styles.btnMore}>
                                            <MoreVertIcon />
                                        </IconButton>
                                    </Box>
                                )}
                                {cluster.status === CLUSTER_STATUS_SUBMITTED && (
                                    // Submitted Button, result pending
                                    <Box sx={styles.flexContainer}>
                                        <Button disabled sx={styles.btnSubmitted}>
                                            Submitted
                                        </Button>
                                        <IconButton sx={styles.btnMore}>
                                            <MoreVertIcon />
                                        </IconButton>
                                    </Box>
                                )}
                                {cluster.status === CLUSTER_STATUS_COMPLETED && (
                                    // Completed Button
                                    <Box sx={styles.flexContainer}>
                                        <Button disabled sx={styles.btnCompleted}>
                                            Completed
                                        </Button>
                                        <IconButton sx={styles.btnMore}>
                                            <MoreVertIcon />
                                        </IconButton>
                                    </Box>
                                )}
                            </Box>
                        </Box>
                    );
                })}
            </Box>
        </Box>
    );
};

export default Clusters;
