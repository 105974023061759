// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { useState } from "react";
import Nestable from "react-nestable";
import uuid from "react-uuid";

// Custom Styles
import { FloatingButtonWrapper } from "pages/admin/dashboard/components/FormBuilder/FormBuilder.styles";

// Constants
import { APIMethodConstants } from "constants/api.method.constants";

// Material Imports
import Grid from "@material-ui/core/Grid";
import DragIndicatorIcon from "@material-ui/icons/DragIndicator";
import DeleteConfirmationDialog from "pages/shared/DeleteConfirmationDialog";

// Static Components
import CircularLoadingProgress from "components/CircularProgress";
import VSBox from "components/VSBox";
import VSTab from "components/VSTab";
import VSTabPanel from "components/VSTabPanel";
import VSTabs from "components/VSTabs";
import NetworkError from "pages/error/networkError";

// Form Constants
import { formElementsType } from "pages/admin/dashboard/components/FormBuilder/constants";

// Form Components
import { EditElements } from "pages/admin/dashboard/components/FormBuilder/EditElements";
import Header from "pages/admin/dashboard/components/FormBuilder/Header";
import { ViewElements } from "pages/admin/dashboard/components/FormBuilder/ViewElements";

// Form Builder Hooks
import {
    deleteFormQuestion,
    useCreateEditFormData,
    useDeleteSection,
    useFormData,
    useFormResponsesData,
} from "hooks/surveyFormData";

// Swal Alert - Dialog to display any errors
import { StringConstants } from "constants/string.constants";
import { SwalConstants } from "constants/swal.constants";
import { useEffect } from "react";
import { SwalAlert } from "utils/swal-utils";
import ViewResponses from "../SurveyResponses/ViewResponses";

function a11yProps(index: any) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

// `FormBuilder` is the function to display the user the Drag and Drop Form Builder to add data to a new Survey
// 1. formType :- preSurveyQuestions, postSurveyQuestions and finalFeedbackQuestions
// 2. isCreate :- Represents if the FormBuilder needs to be opened in the creator mode
// 3. isEdit :- Represents if the FormBuilder needs to be opened in Editor mode
// 4. isView :- Represents if the FormBuilder needs to be opened in Viewer mode
// 5. surveyName :- Represents the name of the current Survey - Pre-Survey, Post-Survey and Final-Feedback
// 6. surveyID :- Represents the ID of the current Survey
const FormBuilder = ({ formType, isCreate, isEdit, isView, surveyName, surveyID }: any) => {
    const initVal = formElementsType[0]?.value;

    // const [title, setTitle] = React.useState("Untitled Form");

    const [isData, setIsData] = useState(false);
    const [data, setData] = useState<any[]>([]);
    const [isResponsesData, setIsResponsesData] = useState(false);
    const [responsesColData, setResponsesColData] = useState<any[]>([]);
    const [responsesRowData, setResponsesRowData] = useState([]);
    const [elementType, setElementType] = useState("text");
    const [previewEnabled, setPreviewEnabled] = useState(false);
    const [containerName, setContainerName] = useState(surveyName || "");

    const [dropdownOption, setDropdownOption] = useState("");
    const [usaDropdownOption, setUsaDropdownOption] = useState("");
    const [dialogData, setDialogData] = useState<any>(false);
    const [arrayMoved, setArrayMoved] = useState(false);
    const [value, setValue] = useState(0);

    useEffect(() => {
        refetchSurveyQuestionsData();
    }, []);

    // Function to add new a new Form element in selected section
    const addElement = ({ index }: any) => {
        const currentIndex = data[index].length;
        const newData = {
            id: `removeOnClientSideOnly${uuid()}`,
            question_number: currentIndex + 1,
            question_text: "",
            answer_type: elementType,
            visible: true,
            is_required: false,
            answer_options: [],
        };
        const updatedData = data.map((section, secIndex) => {
            if (secIndex === index) {
                section.questions.push(newData);
            }
            return section;
        });
        setData(updatedData);
        setElementType(initVal);
    };

    // Function to add a new section
    const addSection = () => {
        const newData = {
            id: `removeOnClientSideOnly${uuid()}`,
            questions: [],
            answer_type: "section",
            section_title: "New Untitled Section",
        };
        setData((prev) => [...prev, newData]);
        setElementType(initVal);
    };

    // Function to delete a Form element
    const deleteElement = ({ id, index }: any) => {
        if (isEdit && id && typeof id === "number") {
            deleteQuestion(id);
        }
        const updatedData = data.map((section) => {
            const el = section.questions[index] || {};

            if (el.id === id) {
                section.questions.splice(index, 1);
            }
            return section;
        });
        setData(updatedData);
        setDialogData(false);
    };

    const deleteEl = ({ id, index }: any) => {
        setDialogData({
            title: "the Element",
            itemLabel: "the element",
            handleSubmit: () => deleteElement({ id, index }),
        });
    };

    const deleteSec = ({ index, section_title, section_id }: any) => {
        setDialogData({
            title: `Section: ${section_title} `,
            itemLabel: "the section",
            handleSubmit: () => deleteSection({ index, section_id }),
        });
    };

    // Function to delete a section
    const deleteSection = ({ index, section_id }: any) => {
        if (section_id && !String(section_id).includes("removeOnClientSideOnly")) {
            postDeleteSection({
                apiMethodType: APIMethodConstants.post,
                data: { section_id, form_type: formType },
            });
        }
        const updatedData = data.filter((_, dataIndex) => dataIndex !== index);
        setData(updatedData);
        setDialogData(false);
    };

    // Function to duplicate a Form element
    const duplicateElement = ({ elId, elType, index }: any) => {
        const updatedData = data.map((section) => {
            const el = section.questions[index] || {};
            if (el.id === elId) {
                const newEl: any = {};
                Object.assign(newEl, el);
                newEl["id"] = `removeOnClientSideOnly${uuid()}`;
                section.questions.splice(index + 1, 0, newEl);
            }
            return section;
        });
        setData(updatedData);
    };

    // Function to Handle Form element Input Values
    const handleValue = ({ id, e, index }: any) => {
        const newArr = data.map((section) => {
            if (section.questions[index]?.id === id) {
                section.questions[index].question_text = e.target.value;
            }
            return section;
        });
        setData(newArr);
        return;
    };

    // Function to Handle section element Input Values
    const handleSectionValue = ({ e, index, isDescription }: any) => {
        const newArr = data.map((section, sectionIndex) => {
            if (sectionIndex === index) {
                if (isDescription) {
                    section.description = e.target.value;
                } else {
                    section.section_title = e.target.value;
                }
            }
            return section;
        });

        setData(newArr);
        return;
    };

    // Function to Handle Form element being Required/Not required
    const handleRequired = ({ id, index }: any) => {
        const newArr = data.map((section) => {
            const el = section.questions[index] || {};
            if (el.id === id) {
                el.is_required = !el.is_required;
            }
            return section;
        });
        setData(newArr);
    };

    // Function to Handle Form Element Type (Text, TextArea, Number, Radio, Checkbox, Dropdown, Date, Time, State and Section)
    const handleElType = ({ id, type, index }: any) => {
        const newArr = data.map((section) => {
            if (section.questions[index]?.id === id) {
                section.questions[index].answer_type = type;
            }
            return section;
        });
        setData(newArr);
    };

    // Function to Handle Form Element Options
    const addOption = ({ id, newOption, index }: any) => {
        const newArr = data.map((section) => {
            const el = section.questions[index] || {};
            if (el.id === id) {
                const objVal = el.answer_options || [];
                el.answer_options = [...objVal, newOption];
            }
            return section;
        });
        setData(newArr);
    };

    // Function to Handle Form Element Date
    const handleDate = ({ id, dateVal, index }: any) => {
        const newArr = data.map((section) => {
            const el = section.questions[index] || {};
            if (el.id === id) {
                el.date = dateVal;
            }
            return section;
        });
        setData(newArr);
    };

    // Function to Handle Form Element Time
    const handleTime = ({ id, dateVal, index }: any) => {
        const newArr = data.map((section) => {
            const el = section.questions[index] || {};
            if (el.id === id) {
                el.time = dateVal;
            }
            return section;
        });
        setData(newArr);
    };

    // Function to Change Form Element Option Values
    const handleOptionValues = ({ elId, optionId, optionVal, index }: any) => {
        const newArr = data.map((section) => {
            const el = section.questions[index] || {};
            if (el.id === elId) {
                el?.answer_options &&
                    el?.answer_options.forEach((opt: any) => {
                        if (opt.id === optionId) {
                            opt.value = optionVal;
                        }
                    });
            }
            return section;
        });
        setData(newArr);
    };

    // Function to Delete Form Element Option
    const deleteOption = ({ elId, optionId, index }: any) => {
        const newArr = data.map((section) => {
            const el = section.questions[index] || {};
            if (el.id === elId) {
                const newOptions = el?.answer_options && el?.answer_options.filter((opt: any) => opt.id !== optionId);
                el.answer_options = newOptions;
            }
            return section;
        });
        setData(newArr);
    };

    // Function to toggle the preview state (true/false)
    const togglePreview = () => setPreviewEnabled(!previewEnabled);

    const handleDropdownChange = (event: any) => {
        //
        setDropdownOption(event.target.value);
    };

    const handleUSADropdownChange = (event: any) => {
        //
        setUsaDropdownOption(event.target.value);
    };

    // update order change in data
    const handleOrderChange = ({ items }: any) => {
        setData(items);
        setArrayMoved((prev) => !prev);
    };

    // Render Form Elements in Edit Mode
    const renderEditElements = (props: any) => {
        const { item, index, handler } = props;
        return EditElements({
            item,
            index,
            handleValue,
            handleSectionValue,
            deleteEl,
            deleteSec,
            handleRequired,
            handleElType,
            duplicateElement,
            addOption,
            handleOptionValues,
            deleteOption,
            handleDate,
            handleTime,
            addElement,
            handler,
            totalSection: data.length,
            arrayMoved,
        });
    };

    // Render Form Elements in View Mode
    const renderViewElements = (props: any) => {
        const { item, index } = props;
        return ViewElements({
            index,
            item,
            dropdownOption,
            handleDropdownChange,
            handleDate,
            handleTime,
            usaDropdownOption,
            handleUSADropdownChange,
            totalSection: data.length,
        });
    };

    const onSurveyQuestionsDataFetchSuccess = (data: any) => {
        // We set the data variable and make the setIsData variable to true signifying that the data has been set
        const updatedData = data?.questions?.map((section: any) => ({
            ...section,
            answer_type: "section",
        }));
        setData(updatedData);
        setIsData(true);
    };

    const onSurveyResponsesDataFetchSuccess = (data: any) => {
        // We set the data variable and make the setIsData variable to true signifying that the data has been set
        const columnDefs = [];
        columnDefs.push({ field: "email", filter: true });
        data?.questions?.allQuestions?.map((eachSection: any) => {
            eachSection.questions?.map((eachQuestion: any) => {
                const temp: any = {};
                temp["field"] = eachQuestion["question_text"];
                columnDefs.push(temp);
            });
        });
        setResponsesColData(columnDefs);
        setResponsesRowData(data.allAnswers);
        setIsResponsesData(true);
    };

    const onSurveyQuestionsDataFetchError = (error: any) => {
        // TODO:- Perform any operation in case of errors while fetching Survey Data
        //
    };

    const onSurveyResponsesDataFetchError = (error: any) => {
        // TODO:- Perform any operation in case of errors while fetching Survey Data
        //
    };

    // `useFormData` is the query to fetch All Questions in a Survey - Pre-Survey, Post-Survey and Final-Feedback
    // 1. isFetched -> Represents that the data has finished fetching (In Background)
    // 2. isFetching -> Represents data being fetched (In background)
    // 3. isLoading -> Represents data being fetched (In Foreground)
    // 4. isError -> Represents if the request errored out
    // 5. data -> Represents the data from the API Response
    // 6. error -> Represents the error message of the request erroring out
    // 7. refetch -> Represents the refetch function to initiate a network request
    const {
        isFetched,
        isFetching: isSurveyQuestionsDataFetching,
        isLoading: isSurveyQuestionsDataLoading,
        isError: isSurveyQuestionsDataError,
        data: allSurveyQuestionsData,
        error,
        refetch: refetchSurveyQuestionsData,
    } = useFormData({
        surveyID: surveyID,
        formType: formType,
        isEnabled: !isCreate,
        onSuccess: onSurveyQuestionsDataFetchSuccess,
        onError: onSurveyQuestionsDataFetchError,
    });

    const {
        isResponsesFetched,
        isFetching: isSurveyResponsesDataFetching,
        isLoading: isSurveyResponsesDataLoading,
        isError: isSurveyResponsesDataError,
        data: allSurveyResponsesData,
        responsesError,
        refetch: refetchSurveyResponsesData,
    }: any = useFormResponsesData({
        surveyID: surveyID,
        formType: formType,
        isEnabled: !isCreate,
        onSuccess: onSurveyResponsesDataFetchSuccess,
        onError: onSurveyResponsesDataFetchError,
    });

    // We check if the data is fetched and hasn't be set yet to set the data
    // This is to ensure that we set the data with background refetch but don't cause an infinite loop of react setState
    if (isFetched && !isData) {
        onSurveyQuestionsDataFetchSuccess(allSurveyQuestionsData);
    }

    // We check if the data is fetched and hasn't be set yet to set the data
    // This is to ensure that we set the data with background refetch but don't cause an infinite loop of react setState
    if (isResponsesFetched && !isResponsesData) {
        onSurveyResponsesDataFetchSuccess(allSurveyResponsesData);
    }

    // `useCreateEditFormData` is the mutation to create/edit a from for a Survey (Pre-Survey, Post-Survey and Final Feedback)
    // 1. mutate -> Represents the mutation function to make the network request
    // 2. isError -> Represents the error caused while creating a form
    // 3. reset -> Represents the function to clear the useCreateEditFormData mutation state
    const {
        mutate: createFormData,
        isLoading: isCreateFromDataLoading,
        isError: isCreateFormDataError,
        reset: resetCreateFormDataMutation,
    } = useCreateEditFormData({
        apiMethodType: APIMethodConstants.post,
        data: {
            container_id: surveyID,
            container_name: containerName,
            form_type: formType,
            data: data,
        },
    });

    const { mutate: postDeleteSection } = useDeleteSection();

    // Function to handle submit Form
    const handleSubmit = async () => {
        if (!isCreateFromDataLoading) {
            createFormData();
        }
    };

    // Function to handle Deleting of a Form Question
    const deleteQuestion = async (questionID: any) => {
        await deleteFormQuestion(questionID, { form_type: formType }).catch((error) => {
            SwalAlert({
                icon: SwalConstants.warning,
                title: StringConstants.operationErrorTitle,
                description: StringConstants.operationErrorDescription,
            });
        });
    };

    /// We check if there is any data being fetched (In Foreground or Background) to display `CircularLoadingProgress`
    if (isSurveyQuestionsDataLoading || isSurveyQuestionsDataFetching) {
        return <CircularLoadingProgress />;
    }

    /// We check if there is any data being fetched (In Foreground or Background) to display `CircularLoadingProgress`
    if (isSurveyResponsesDataLoading || isSurveyResponsesDataFetching) {
        return <CircularLoadingProgress />;
    }

    // We check if there is an error present while fetching all Questions of a Survey and display `NetworkError`
    if (isSurveyQuestionsDataError) {
        return <NetworkError handleOnRefresh={refetchSurveyQuestionsData} errorText={error} />;
    }

    // We check if there is an error present while fetching all Questions of a Survey and display `NetworkError`
    if (isSurveyResponsesDataError) {
        return <NetworkError handleOnRefresh={refetchSurveyResponsesData} errorText={responsesError} />;
    }

    // We check if there's an error while performing mutations `useCreateFormData`
    // The state of both the mutations is reset and we show the appropriate error to the user
    if (isCreateFormDataError) {
        resetCreateFormDataMutation();
        SwalAlert({});
    }

    // `formQuestionsComponent` -> Represents the current view to be displayed (Edit/Create/Preview)
    let formQuestionsComponent;

    // Checking if the current mode is that of the Creator/Editor
    if (!previewEnabled && (isCreate || isEdit)) {
        formQuestionsComponent = (
            <Grid item md={10}>
                <Nestable
                    className="survey-nestable-list"
                    items={data}
                    renderItem={renderEditElements}
                    onChange={handleOrderChange}
                    maxDepth={2}
                    confirmChange={({ destinationParent }) => {
                        if (!destinationParent) {
                            return false;
                        }
                        return true;
                    }}
                    childrenProp="questions"
                    handler={
                        <VSBox sx={{ textAlign: "center", cursor: "all-scroll" }}>
                            <DragIndicatorIcon sx={{ transform: "rotate(-90deg)" }} />
                        </VSBox>
                    }
                />
            </Grid>
        );
    }

    const handleChange = (event: any, newValue: any) => {
        setValue(newValue);
        if (newValue == 1) {
            refetchSurveyResponsesData();
        }
    };
    // Checking if the current mode is that of Previewing the Form
    if (previewEnabled || isView) {
        formQuestionsComponent = (
            <>
                <VSBox sx={{ width: "100%" }}>
                    <VSBox sx={{ borderBottom: 1, borderColor: "divider" }}>
                        <VSTabs value={value} onChange={handleChange} aria-label="basic tabs example">
                            <VSTab label="Questions" {...a11yProps(0)} />
                            <VSTab label="Responses" {...a11yProps(1)} />
                        </VSTabs>
                    </VSBox>
                    <VSTabPanel value={value} index={0}>
                        <Grid item md={12}>
                            <Nestable
                                className="survey-nestable-list"
                                items={data}
                                renderItem={renderViewElements}
                                maxDepth={2}
                                confirmChange={() => false}
                                childrenProp="questions"
                                handler={<div />}
                            />
                        </Grid>
                    </VSTabPanel>
                    <VSTabPanel value={value} index={1}>
                        <ViewResponses rows={responsesRowData} cols={responsesColData} />
                    </VSTabPanel>
                </VSBox>
            </>
        );
    }

    return (
        <div className="survey-form-wrapper">
            <DeleteConfirmationDialog
                open={!!dialogData}
                handleClose={() => setDialogData(false)}
                handleSubmit={dialogData.handleSubmit}
                title={dialogData.title}
                itemLabel={dialogData.itemLabel}
            />
            {!previewEnabled && !isView && (
                <VSBox sx={{ p: 1 }} className="sticky-survey-header">
                    <Header
                        title={containerName}
                        onChange={({ target }: any) => setContainerName(target.value)}
                        disabled={isView || previewEnabled}
                        addSection={addSection}
                        sectionData={data}
                        handleSectionDataChange={(newData: any) => setData(newData)}
                        formType={formType}
                    />
                </VSBox>
            )}
            <Grid container spacing={1} direction="row" justifyContent="center">
                {formQuestionsComponent}
            </Grid>
            {
                // Display the Buttons to Go Back or Preview and Save only if the current mode is that of a Creator or Editor
                (isCreate || isEdit) && (
                    <FloatingButtonWrapper>
                        {/* Go Back/Preview Button */}
                        <div className="action-button preview" onClick={togglePreview}>
                            {previewEnabled ? "Go Back" : "Preview"}
                        </div>
                        {/* Save Button */}
                        {!previewEnabled && (
                            <div className="action-button save" onClick={handleSubmit}>
                                Save
                            </div>
                        )}
                    </FloatingButtonWrapper>
                )
            }
        </div>
    );
};
export default FormBuilder;
