import Button from "@mui/material/Button";

// import VSColorButton from "components/VSColorButton";

/* eslint-disable no-unused-expressions */
import { Box } from "@mui/material";
import { purple } from "@mui/material/colors";
import { styled } from "@mui/material/styles";
import VSDisableButton from "components/VSDisableButton";

// import arrayMove from "array-move";
const VSColorButton = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText(purple[500]),
    textTransform: "capitalize",
    fontFamily: "Montserrat",
    fontWeight: "500",
    fontSize: "14px",
    padding: "8px 36px 8px 36px",
    backgroundColor: "#29ABE2",
    margin: "0 20px 0 0",
    "&:hover": {
        backgroundColor: "rgba(41, 177, 226, 0.8)",
        // color: "#94A8B0",
        // borderRadius: "8px",
        // border: "2px solid #94A8B0",
    },
    borderRadius: "10px",
    border: "2px",

    // color: theme.palette.getContrastText(purple[500]),
    // backgroundColor: "#29ABE2",
    // margin: "0 20px 0 0",
    // "&:hover": {
    //     backgroundColor: "rgba(41, 177, 226, 0.8)",
    // },
    // borderRadius: "8px",
    // borderWidth: "2px",
}));

const UnpackingStepperProgress = ({
    totalSteps,
    order,
    handleNext,
    handleBack,
    nextText,
    loading,
    totalQuestions,
    currentQuestion,
}: any) => {
    const handleNextClick = () => {
        console.log("inside handle next click");
        console.log(handleNext);
        if (handleNext) {
            handleNext(currentQuestion.question_number + 1);
        }
    };

    const handleBackClick = () => {
        if (handleBack) {
            handleBack(currentQuestion.question_number - 1);
        }
    };

    const isFinalQuestion = () => {
        return totalQuestions == currentQuestion?.question_number;
    };

    return (
        <Box sx={{ width: "100%", display: "flex", justifyContent: "end" }}>
            <VSDisableButton variant="outlined" onClick={handleBackClick} sx={{ maxHeight: "43px" }}>
                Back
            </VSDisableButton>

            {isFinalQuestion() ? (
                <VSColorButton onClick={handleNextClick} variant="contained" sx={{ maxHeight: "43px" }}>
                    Submit
                </VSColorButton>
            ) : (
                <VSDisableButton onClick={handleNextClick} variant="outlined" sx={{ maxHeight: "43px" }}>
                    Next
                </VSDisableButton>
            )}
        </Box>
    );
};

export default UnpackingStepperProgress;
