import { DynamicModel } from "pages/adminV2/Components";
import "./DeleteCluster.css";

interface props {
    open: boolean;
    setOpen: (value: boolean) => void;
    handleDelete: () => void;
    itemLabel?: string;
    title?: string;
}

function DelecteClusterActivityModal({ open, setOpen, handleDelete, itemLabel, title }: props) {
    const handleDeleteClick = async () => {
        handleDelete();
    };

    const defaultItemLabel = "cluster activity";
    const displayItemLabel = title || defaultItemLabel;
    const modalTitle = `Are you sure you want to delete this ${displayItemLabel}?`;

    return (
        <DynamicModel open={open} setOpen={setOpen} title={modalTitle}>
            <div className="deleteModal">
                <div className="deleteModalContainer">
                    <p className="deleteModalContainer__desc">You cannot undo this action once you confirm</p>
                    <button onClick={() => handleDeleteClick()}>Delete {displayItemLabel}</button>
                </div>
            </div>
        </DynamicModel>
    );
}

export default DelecteClusterActivityModal;
