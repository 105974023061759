import { Box, CssBaseline, Typography } from "@mui/material";
import Header from "components/Dashboard/Header";
import { Button } from "pages/adminV2/Components";
import AdminHeader from "pages/adminV2/Components/AdminHeader";
import { useRef, useState } from "react";
import { Outlet } from "react-router-dom";
import styles from "../../Survey/CreateSurvey/styles";

function PreviewCoPilotLayout() {
    const childRef = useRef<any>();
    const [noOfUsers, setNoOfUsers] = useState([0, 1, 2, 3, 4, 5, 6].map((value) => ({ value: value, label: value })));
    const [selectedNoOfUsers, setSelectedNoOfUsers] = useState(null);

    const handleExitPreview = () => {
        window.history.back();
    };

    const handleSectionClick = () => {
        if (childRef.current) {
            if (typeof childRef.current.addSection === "function") {
                childRef.current.addSection();
            }
        }
    };

    const searchParams = new URLSearchParams(window.location.search);
    const title = searchParams.get("title");
    const id = searchParams.get("id");

    return (
        <Box sx={{ display: "flex", flexDirection: "column", margin: "0rem 3rem" }}>
            <CssBaseline />
            <Header title="Admin Dashboard" redirectTo="/v2/admin/copilots" />
            <AdminHeader
                title="Preview a CoPilot"
                onIconClick={() => {
                    undefined;
                }}
            />

            <Box sx={styles.container}>
                <Typography sx={styles.titleText}>{title}</Typography>
                <Button type="primaryOutlined" text="Exit Preview" onClick={() => handleExitPreview()} />
            </Box>

            <Box component="main" sx={{ flexGrow: 1, p: 3, mt: 0 }}>
                <Outlet context={{ childRef }} />
            </Box>
        </Box>
    );
}

export default PreviewCoPilotLayout;
