import { Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import styles from "./styles";

export default function CoPilotsCarousel({ coPilotsList }: { coPilotsList: any }) {
    const navigate = useNavigate();

    return (
        <div>
            <Typography sx={styles.title}>{coPilotsList?.title}</Typography>
            <Typography sx={styles.subTitle}>{coPilotsList?.sub_title}</Typography>

            <Button
                variant="outlined"
                style={styles.startBtn}
                onClick={() => navigate(`/aicopilot/${coPilotsList?.id}`)}
            >
                Get started
            </Button>
        </div>
    );
}
