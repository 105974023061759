import { useParams } from "react-router-dom";

/// VSorts Container Table Data Hooks
import { useVSortsContainerParameterData } from "hooks/VSortsContainerTableData";

// Static Components
import CircularLoadingProgress from "components/CircularProgress";
import NetworkError from "pages/error/networkError";
import { ViewParameterElements } from "./ViewParameterElements";

// `ViewVSetParameterData` is the function to display the user VSorts Container data
export default function ViewVSetParameterData() {
    // VSorts Container ID
    const { containerID } = useParams();

    const onVSortsContainerParametersDataSuccess = (data: any) => {
        
        // TODO:- Perform any operation after the successful fetching of Survey Data
        // 
    };

    const onVSortsContainerParametersDataError = (error: any) => {
        // TODO:- Perform any operation in case of errors while fetching Survey Data
        // 
    };

    // `useVSortsContainerCategoryData` is the query to fetch All VSorts Container Categories
    // 1. isFetching -> Represents data being fetched (In background)
    // 2. isLoading -> Represents data being fetched (In Foreground)
    // 3. isError -> Represents if the request errored out
    // 4. data -> Represents the data from the API Response
    // 5. error -> Represents the error message of the request erroring out
    // 6. refetch -> Represents the refetch function to initiate a network request

    const {
        isFetching: isVSortsContainerParametersDataFetching,
        isLoading: isVSortsContainerParametersDataLoading,
        isError: isVSortsContainerParametersDataError,
        data: VSortsContainerParametersData,
        error: VSortsContainerParametersDataError,
        refetch: refetchVSortsContainerParametersData,
    } = useVSortsContainerParameterData({
        onSuccess: onVSortsContainerParametersDataSuccess,
        onError: onVSortsContainerParametersDataError,
        containerID: containerID,
    })

    /// We check if there is any data being fetched (In Foreground or Background) to display `CircularLoadingProgress`
    if (
        isVSortsContainerParametersDataLoading ||
        isVSortsContainerParametersDataFetching 
    ) {
        return <CircularLoadingProgress />;
    }

    // We check if there is an error in fetching VSorts Categories and display `NetworkError`
    if (isVSortsContainerParametersDataError) {
        return (
            <NetworkError
                handleOnRefresh={refetchVSortsContainerParametersData}
                errorText={VSortsContainerParametersDataError}
            />
        );
    }


    return (
        <ViewParameterElements elements={VSortsContainerParametersData}/>
    );
}
