import { FormTypes } from "constants/enum.constants";
import { useFormData } from "hooks/surveyFormData";
import { forwardRef, useState } from "react";
import { useOutletContext } from "react-router-dom";
import FormBuilder from "../FormBuilder";

const CreateSurvey = forwardRef(function CreateSurvey() {
    const { childRef }: any = useOutletContext();

    const [data, setData] = useState<any[]>([]);

    const searchParams = new URLSearchParams(window.location.search);
    const id = searchParams.get("id");
    const isCreate = searchParams.get("isCreate");

    const onSurveyQuestionsDataFetchSuccess = (data: any) => {
        // We set the data variable and make the setIsData variable to true signifying that the data has been set
        const updatedData = data?.questions?.map((section: any) => ({
            ...section,
            answer_type: "section",
        }));
        setData(updatedData);
        //setIsData(true);
    };

    const onSurveyQuestionsDataFetchError = (error: any) => {
        // TODO:- Perform any operation in case of errors while fetching Survey Data
        //
    };

    // 1. isFetched -> Represents that the data has finished fetching (In Background)
    // 2. isFetching -> Represents data being fetched (In background)
    // 3. isLoading -> Represents data being fetched (In Foreground)
    // 4. isError -> Represents if the request errored out
    // 5. data -> Represents the data from the API Response
    // 6. error -> Represents the error message of the request erroring out
    // 7. refetch -> Represents the refetch function to initiate a network request
    const {
        isFetched,
        isFetching: isSurveyQuestionsDataFetching,
        isLoading: isSurveyQuestionsDataLoading,
        isError: isSurveyQuestionsDataError,
        data: allSurveyQuestionsData,
        error,
        refetch: refetchSurveyQuestionsData,
    } = useFormData({
        surveyID: id,
        formType: FormTypes.presurvey,
        isEnabled: !isCreate,
        onSuccess: onSurveyQuestionsDataFetchSuccess,
        onError: onSurveyQuestionsDataFetchError,
    });

    const handleTitleChange1 = () => {
        console.log("inside handle title change");
    };

    return (
        <div>
            <FormBuilder
                childRef={childRef}
                formType={FormTypes.presurvey}
                isCreate={true}
                isEdit={false}
                isView={false}
                surveyID={id}
            />
        </div>
    );
});

export default CreateSurvey;
