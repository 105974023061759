import { useState } from "react";
import {
    BtnBold,
    BtnBulletList,
    BtnItalic,
    BtnNumberedList,
    BtnRedo,
    BtnStrikeThrough,
    BtnStyles,
    BtnUnderline,
    BtnUndo,
    Editor,
    EditorProvider,
    Separator,
    Toolbar,
} from "react-simple-wysiwyg";

export default function RichTextEditor({ value, handleValueChange, placeholderText }: any) {
    const [showToolbar, setShowToolbar] = useState(false);

    const onBlurFunction = (event: any) => {
        if (event?.relatedTarget?.id != "richTextEditorSelect") {
            setShowToolbar(false);
        }
    };
    return (
        <EditorProvider>
            <Editor
                value={value}
                onChange={handleValueChange}
                placeholder={placeholderText}
                onFocus={() => setShowToolbar(true)}
                onBlur={onBlurFunction}
            >
                {showToolbar && (
                    <Toolbar>
                        <BtnUndo />
                        <BtnRedo />
                        <Separator />
                        <BtnBold />
                        <BtnItalic />
                        <BtnUnderline />
                        <BtnStrikeThrough />
                        <Separator />
                        <BtnNumberedList />
                        <BtnBulletList />
                        <Separator />
                        {/* <BtnLink /> */}
                        {/* <BtnClearFormatting /> */}
                        {/* <HtmlButton /> */}
                        {/* <Separator /> */}
                        <BtnStyles id="richTextEditorSelect" />
                    </Toolbar>
                )}
            </Editor>
        </EditorProvider>
    );
}
