const { REACT_APP_API_END_POINT } = process.env;
const isLocalhost = Boolean(
    window.location.hostname === "localhost" ||
        // [::1] is the IPv6 localhost address.
        window.location.hostname === "[::1]" ||
        // 127.0.0.0/8 are considered localhost for IPv4.
        window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
);
let baseURL = "";
if (window.location.origin.includes("vsortsai.com")) {
    baseURL = isLocalhost
        ? `http://${window.location.hostname}:3013/v0/`
        : `${window.location.protocol}//api.${window.location.hostname}/v0/`;
} else {
    baseURL = isLocalhost ? `http://${window.location.hostname}:3013/v0/` : `${window.location.origin}/v0/`;
}

// NetworkConstants -> Represents all the API Endpoints for the application
export const NetworkConstants = {
    // We check if `REACT_APP_API_END_POINT` is undefined, if so, we set it to the local environment
    baseURL: baseURL,

    // ------------------------------------------------------------------
    // VSorts User Endpoints - START

    // *GET user profile
    getProfile: "user/profile",
    getPermissions: "roles",
    getAssignRoles: "roles/assignRoleList",

    // VSorts User Endpoints - END
    // ------------------------------------------------------------------

    // ------------------------------------------------------------------
    // VSorts Cluster Endpoints - START

    // *GET Requests
    getAllVSortsClusters: "vSortCluster/allClusters",
    getAllAssignedVSortsClusters: "vSortCluster/allAssignedClusters",
    getUserAssignedVSortsClusters: "vSortCluster/userAssignedClusters",
    getAdminAssignedVSortsClusters: "vSortCluster/adminAssignedClusters",

    getOrganizations: "organization/orgList",
    assignOrganization: "organization/assignOrg",
    deleteOrganizations: "organization",

    getAssignList: "assignEntity/assignList",
    // *POST Requests
    createVSortsClusters: "vSortCluster",
    deleteVSortsCluster: "vSortCluster/delete",
    deleteMultipleClusters: "vSortCluster/deleteMultipleClusters",
    deployVSortCluster: "vSortCluster/deploy?cluster_id=",
    deleteMultipleVSortsClusters: "vignette/deleteMultipleContainers",

    // Survey Cluster - END
    // ------------------------------------------------------------------

    // Demographics Endpoints - START
    getAllDemographicsOptions: "demographics",
    demographicsData: "demographics/user",

    // Demographics Endpoints - END

    // ------------------------------------------------------------------
    // Survey Endpoints - START

    // Survey Items
    // *GET Requests
    getAllSurveys: "formContainers/getNames?form_type=",

    // *POST Requests
    copySurveyContainer: "formContainers/clone",
    createSurvey: "formContainers/createName",
    lockUnlockSurveyContainer: "formContainers/unlockContainer",
    deleteSurveyContainer: "formContainers/deleteContainer",
    deleteSurveySection: "formQuestions/deleteSection",

    // Survey Forms
    // *GET Requests
    fetchSurveyQuestions: "formQuestions?container_id=",
    fetchUserSurveyQuestions: "formQuestions/user?container_id=",
    fetchSurveyPrevNext: "formQuestions/prevNext?container_id=",

    // Survey Responses
    // *GET Requests
    fetchSurveyResponses: "formQuestions/responses?container_id=",
    fetchUserFormResponses: "/answers/survey?container_id=",

    // *GET Requests
    fetchVSetQuestions: "vignette?container_id=",

    fetchVSetReaction: "reacting?container_id=",

    // *GET Requests
    fetchVSetResponses: "vignette/responses?container_id=",
    fetchAllVSetResponses: "vignette/allUsersVSetResponses?container_id=",

    // *GET Requests
    fetchFlaggedVSetResponses: "vignette/flagged?container_id=",

    // *POST/PUT Requests
    createEditSurveyQuestions: "formQuestions",
    createFormScore: "scores/add",

    // *DELETE Request
    deleteSurveyQuestion: "formQuestions/delete",
    bulkeleteSurveyQuestion: "formQuestions/deleteMultiple",

    // *POST request
    saveSurveyFormQuestionAnswers: "formQuestions/saveAnswers",

    // *POST request
    saveUnpackingFormQuestionAnswers: "formQuestions/saveUnpackingAnswers",

    // *POST request
    saveVSetOrder: "vignette/saveAnswers",

    // Survey Endpoints - END
    // ------------------------------------------------------------------

    // ------------------------------------------------------------------
    // VSorts Container Endpoints - START

    // *GET Requests
    getAllVSortsContainers: "vignette/containerName",
    getVSortsContainerData: "vignette?container_id=",

    // *POST Requests
    copyVSortsContainer: "vignette/clone",
    createVSortsContainer: "vignette/containerName",
    lockUnlockVSortsContainer: "vignette/unlockContainer",
    deleteVSortsContainer: "vignette/deleteContainer",
    addVSortsContainerData: "vignette/vignettesEdit",

    // VSorts Container Endpoints - END
    // ------------------------------------------------------------------

    // ------------------------------------------------------------------
    // VSorts Cluster Endpoints - START

    // *GET Requests
    getVSortsClusterAutocompleteData: "vignette/containerDetail",
    getVSortsClusterData: "clusterSteps/getSteps?cluster_id=",
    getVSortsPublicStatus: "clusterSteps/getClusterPublicStatus?cluster_id=",
    getVSortsUserClusterStep: "clusterSteps/getUserClusterSteps?cluster_id=",
    getVSortsNextClusterStepData: "clusterSteps/getNextClusterStep",
    getVSortsClusterQuestionsData: "clusterSteps/getStepsQuestions?cluster_id=",
    getVSortsClusters: "clusters?org_id=",
    getVSortsClusterAnswers: "answers/survey?container_id=",
    getVSortsScoringParams: "scoring/parameters",
    revertClusterIsPublic: "clusters/isPublic",
    getClusterDetails: "clusters/getClusterDetails",

    getVSortsClusterInformation: "answers/vset?container_id=",
    // *Admin GET Request
    getVSortsClusterDataByUser: (clusterId: number, userId: string) =>
        `vSortCluster/cluster/${clusterId}/user/${userId}`,

    // *POST Requests
    addVSortsClusterData: "clusterSteps",

    // *DELETE Request
    deleteVSortsClusterStep: "clusterSteps/",
    deleteVignettes: "vignette/deleteVignettes/",

    // VSorts Cluster Endpoints - END
    // ------------------------------------------------------------------

    userEndpoints: {
        me: "user/me",
    },

    // VSets Categories
    getVSetsCategories: "categories",
    getVSetsContainerCategories: "categories/container/",
    createVSetsCategories: "categories/create",
    updateVSetsCategories: "categories/update",
    deleteVSetsCategories: "categories/delete",
    bulkDeleteVSetsCategories: "categories/deleteMultiple",

    // VSets Activity Types
    getVSetsActivityTypes: "activityTypes",

    // VSet Parameter Data
    getVSetsContainerParameters: "vsetParameters/",
    addVSetsContainerParameters: "vsetParameters/",
    getVSetsMinMaxUnpackingParameters: "vsetParameters/minMaxUnpacking?container_id=",

    // ------------------------------------------------------------------
    // Unpacking Questions Endpoints - START

    // Get Request
    getUnpackingQuestions: "unpacking/getUnpackingStep",
    getUnpackingResponses: "unpacking/getUnpackingResponses",
    completeUnpackingStep: "unpacking",

    // Get Request
    getScoringInformation: "scoring?container_id=",
    addScoringData: "scoring",

    // User list data
    getAllUsers: "/user/list",

    // email
    sendEmail: "/email",

    // to get all the vignettes that have been scored
    getAllScoredVignettes: "/viewScores?cluster_id=",

    // get score of an individual vignette
    getEachVignetteScore: "/viewScores/eachVignette?cluster_id=",

    // get the vset reaction data
    getVSortsReactionData: "reacting",
    vSetsReactionNextStep: "/vignette/updateUserClusterStep",
    getVSetDetails: "/vignette/title?container_id=",
    getOrganizationList: "/organization",
    putOrganizationList: "/organization/assign",
    fetchAllCoPilots: "/aicopilot",
    createCoPilotTitle: "/aicopilot/title",
    fetchAdminCoPilotList: "/aicopilot/adminCoPilotList",
    fetchPreviousEntries: "/aicopilot/previousEntries",
    fetchCoPilotQueryCount: "/aicopilot/queryCount",
    fetchCoPilotEngines: "/aicopilot/listOfEngines",
    saveCoPilot: "/aicopilot/updateCoPilot",
    duplicateCoPilot: "/aicopilot/duplicate",
    revertIsPublic: "/aicopilot/isPublic",
    deleteCoPilot: "/aicopilot/delete",
    deployCoPilot: "/aicopilot/publish",
    fetchAdminCoPilotResponses: "/aicopilot/adminViewResponses",
    getSubscriptionDetails: "/stripe",
    useGetAllOrganizations: "/organization/unauthenticated-routed/getOrganizations",
    importUsers: "/user/importUsers",
    updateUserRole: "/roles/assignRole",
};
