import { Navigate, useParams } from "react-router-dom";

import { Paper } from "@mui/material";
import { useVSetQuestionQuery } from "hooks/surveyFormData";

import { CurrentUserSelector } from "app/slices/UserSlice";
import CircularLoadingProgress from "components/CircularProgress";
import { useVSortsContainerCategoryData } from "hooks/VSortsContainerTableData";
import NetworkError from "pages/error/networkError";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { OptionSet } from "./OptionSet";

const VSetForm = ({ clusterStep, refreshNextClusterStep, clusterDetail, headerSection, clusterId }: any) => {
    const currentUser = useSelector(CurrentUserSelector);
    const { containerID } = useParams();
    const {
        container_id: surveyID,
        domain_id: domainID,
        id: clusterStepID,
        completed_steps: completedDomainName,
        container_name: containerName,
        form_type: formType,
    } = clusterStep;

    const {
        isFetching: isVSetDataFetching,
        isError: isVSetDataError,
        data: currentDomain,
        error,
        refetch: refetchSurveyQuestionsData,
    } = useVSetQuestionQuery({
        clusterId,
        surveyID: surveyID,
        domainID: domainID,
        completedDomainName,
        clusterStepID,
        isEnabled: !!surveyID,
        userId: currentUser.id,
    });

    useEffect(() => {
        if (currentDomain) {
            headerSection(currentDomain);
        }
    }, [currentDomain]);
    const onVSortsContainerCategoriesDataSuccess = (data: any) => {
        // TODO:- Perform any operation after the successful fetching of Survey Data
        //
    };

    const onVSortsContainerCategoriesDataError = (error: any) => {
        // TODO:- Perform any operation in case of errors while fetching Survey Data
        //
    };
    const {
        isFetching: isVSortsContainerCategoriesDataFetching,
        isLoading: isVSortsContainerCategoriesDataLoading,
        isError: isVSortsContainerCategoriesDataError,
        data: VSortsContainerCategoriesData,
        error: ContainerError,
        refetch: refetchVSortsContainerCategoriesData,
    } = useVSortsContainerCategoryData({
        onSuccess: onVSortsContainerCategoriesDataSuccess,
        onError: onVSortsContainerCategoriesDataError,
        containerID: surveyID,
    });

    if (isVSetDataFetching || isVSortsContainerCategoriesDataFetching) {
        return <CircularLoadingProgress />;
    }

    // We check if there is an error present while fetching all Questions of a Survey and display `NetworkError`
    if (isVSetDataError) {
        return <NetworkError handleOnRefresh={refetchSurveyQuestionsData} errorText={error} />;
    }
    if (isVSortsContainerCategoriesDataError) {
        return <NetworkError handleOnRefresh={refetchVSortsContainerCategoriesData} errorText={ContainerError} />;
    }

    if (!currentDomain || !VSortsContainerCategoriesData) {
        return <Navigate to="/user/dashboard" replace />;
    }

    return (
        <Paper
            elevation={0}
            sx={{
                borderRadius: "20px",
                p: 3,
                display: "flex",
                justifyContent: "center",
                width: "80%",
                maxWidth: "1000px",
                margin: "auto",
            }}
        >
            <OptionSet
                domain={currentDomain}
                VSortContainerData={VSortsContainerCategoriesData}
                clusterStep={clusterStep}
                clusterId={clusterDetail.id}
                refreshNextClusterStep={refreshNextClusterStep}
            />

            {/* <Grid container> */}
            {/* <Grid item xs={12}>
                    <ContainerHeader title={`${containerName} (${FormTypeLabels[formType]})`} />
                </Grid> */}
            {/* <Grid item xs={12} sx={{ display: "flex", flexDirection: "column" }}>
                    <Card sx={{ width: "100%" }}> */}
            {/* <CardContent> */}
            {/* <VSTypography
                                size="xlg"
                                sx={{ textDecoration: "underline" }}
                                color="#01579b"
                                gutterBottom
                                variant="body2"
                                component="div"
                            >
                                {currentDomain.domain_name}
                            </VSTypography>
                            <VSTypography size="lg" color="#2e7d32" variant="paragraph">
                                *{currentDomain.header_text || StringConstants.vignetteDefaultHeaderText}
                            </VSTypography> */}
            {/* <OptionSet
                    domain={currentDomain}
                    clusterStep={clusterStep}
                    clusterId={clusterDetail.id}
                    refreshNextClusterStep={refreshNextClusterStep}
                /> */}
            {/* </CardContent> */}
            {/* </Card>
                </Grid> */}
            {/* </Grid> */}
        </Paper>
    );
};

export default VSetForm;
