const styles: any = {
    container: {
        padding: "2.5rem 2rem",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    profileContainer: {
        position: "relative",
    },
    profileImage: {
        borderRadius: "99px",
        objectFit: "contain",
        width: "8rem",
        height: "8rem",
    },
    editImageContainer: {
        position: "absolute",
        bottom: 0,
        right: 0,
        backgroundColor: "#fff",
        borderRadius: "99px",
        boxShadow: "1px 1px 8px 0px rgba(0, 0, 0, 0.25)",
        padding: "10px",
    },
    editImageIcon: {
        height: "1.5rem",
    },
    userName: {
        fontSize: "1.2rem",
        fontWeight: "bold",
    },
    logo: {
        height: 40,
    },
    flexContainer: {
        display: "flex",
        alignItems: "center",
        gap: "10px",
        marginTop: "1rem",
    },
    actionItem: {
        marginTop: "1rem",
        color: "#929292",
        cursor: "pointer",
    },
    subTitle: {
        marginTop: "1rem",
        color: "#929292",
    },
    title: {
        fontSize: 16,
        color: "rgb(95, 124, 136)",
    },
    switchAccount: {
        border: "1px solid #929292",
        borderRadius: "24px",
        display: "flex",
        alignItems: "center",
        gap: "10px",
        marginTop: "2rem",
        backgroundColor: "#fff",
        padding: "6px 20px",
    },
    icon: {
        objectFit: "contain",
        height: "1.5rem",
    },
    verifiedBadge: {
        objectFit: "contain",
        height: "1rem",
    },
};

export default styles;
