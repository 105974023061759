import { DynamicModel } from "pages/adminV2/Components";
import "./coPolitModal.css";

interface props {
    open: boolean;
    setOpen: (value: boolean) => void;
}

const CoPilotModal = ({ open, setOpen }: props) => {
    return (
        <DynamicModel title="What is the purpose of this activity?" open={open} setOpen={setOpen}>
            <div className="content">
                Our AI, supported by experts in the loop, will categorize them along the Cultural Proficiency Continuum,
                which includes six levels: Cultural Destructiveness, Cultural Incapacity, Cultural Blindness, Cultural
                Pre-competence, Cultural Competence, and Cultural Proficiency. Your confidential contributions will
                enhance our AI&apos;s cultural and contextual awareness. You are welcome to submit multiple
                interactions. Thank you.
            </div>
        </DynamicModel>
    );
};

export default CoPilotModal;
