import React, { RefObject } from "react";
import AnalyticsImage from "../../../assets/images/VSorts Graphic 2.svg";
import EducationResearchImage from "../../../assets/images/VSorts Graphic 3.svg";
import EducatorDevelopmentImage from "../../../assets/images/VSorts Graphic 4.svg";
import OrganizationalDevelopmentImage from "../../../assets/images/VSorts Graphic 5.svg";
import "./Enterprise.css";

const enterpriseData = [
    {
        title: ["AI-Powered Platform for Streamlined Qualitative Data Collection, Structuring, and Analysis"],
        content:
            "VSorts™ AI is transforming education with a comprehensive SaaS platform for seamless qualitative data management. By integrating data collection, structuring, storage, and analysis, VSorts™ AI empowers educational stakeholders to quickly share insights, make informed decisions, and provide context-specific feedback. As the trusted source for AI-enabled qualitative data tools in education, VSorts™ AI sets a new standard in efficiency and precision, featuring Cultural Proficiency© AI CoPilots to advance research and professional learning.",
        image: OrganizationalDevelopmentImage,
    },
    {
        title: ["Educational Research"],
        content:
            "VSorts™ AI streamlines educational research with an all-in-one platform for qualitative data management. Equipped with AI CoPilots trained in social science frameworks, VSorts™ AI supports both inductive and deductive analysis, empowering researchers to derive contextually rich insights. Integrated CRM tools make it easy to securely manage projects and participants, ensuring compliance with IRB, FERPA, and HIPAA requirements. This blend of theory, practical knowledge, and robust security accelerates research, advancing educational policy and practice.",
        image: AnalyticsImage,
    },
    {
        title: ["Educational Consulting"],
        content:
            "VSorts™ AI’s SaaS platform empowers educational consultants by doubling their service capacity and increasing client load by 100%. With tools like the Cluster Deployment System, Forms/Surveys/Polls, VSets, and AI CoPilots—acting as data analysts or coaches—consultants can efficiently manage a larger client base while quickly delivering analyzed data and actionable recommendations. This AI-driven approach enables consultants to provide instant insights for school improvement initiatives. By saving time and enhancing capacity, VSorts™ AI allows consultants to deliver timely, impactful feedback that supports agile, adaptive, and responsive teaching across the PreK-20 continuum and beyond.",
        image: EducatorDevelopmentImage,
    },
    {
        title: ["Future of Work"],
        content:
            "In school and workplace settings, VSorts™ AI elevates organizational development and evaluation. The platform enables institutions to refine processes, policies, and team dynamics by applying Society for Human Resource Management (SHRM) best practices to bridge sociocultural gaps. Leaders gain context-specific insights aligned with their organization’s mission and vision, fostering a conscious and effective work environment. With built-in CRM tools and AI CoPilots trained in human resource and organizational development frameworks, VSorts™ AI simplifies stakeholder management—whether by department or individual—allowing leaders to seamlessly onboard employees, facilitate training, and efficiently conduct assessments.",
        image: EducationResearchImage,
    },
];
interface Props {
    enterpriseRef: RefObject<HTMLDivElement>;
}

const Enterprise: React.FC<Props> = ({ enterpriseRef }) => {
    return (
        <div className="enterprise" id="enterprise" ref={enterpriseRef}>
            <div className="enterpriseContainer">
                {enterpriseData.map((enterprise, index) => (
                    <div className={`enterpriseContainer__card ${index % 2 == 1 ? "reverse" : ""}`}>
                        <div className="enterpriseContainer__cardContent">
                            <h1 style={{ fontSize: "30px" }}>{enterprise.title}</h1>
                            <p style={{ fontSize: "18px" }}>{enterprise.content}</p>
                        </div>
                        <div className="enterpriseContainer__cardImage">
                            <img src={enterprise.image} alt="" />
                        </div>
                    </div>
                ))}
                <div className="enterpriseContainer__sector">
                    {/* <h1 style={{ fontSize: "24px" }}>
                        Don’t see your business sector? Let’s explore how VSorts™ AI can tailor our insights to meet
                        your unique needs and transform your organization.
                    </h1> */}
                    <button
                        className="navbarActionButton navbarActionButtons-getStarted"
                        onClick={() =>
                            window.open("https://calendly.com/vsorts-info/vsorts-ai-customer-success", "_blank")
                        }
                    >
                        Book a demo
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Enterprise;
