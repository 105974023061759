import { Grid, Paper } from "@mui/material";
import { Box } from "@mui/system";
import SortingItems from "./SortingItems";

export default function VSetSorting() {
    return (
        <>
            <div style={{ width: "100%" }}>
                <Box
                    sx={{
                        display: "flex",
                        flexWrap: "wrap",
                        bgcolor: "background.paper",
                        justifyContent: "space-between",
                    }}
                >
                    <Paper elevation={2}>
                        <Grid container>
                            <Grid item xs={12} sx={{ display: "flex", flexDirection: "column" }}>
                                <Grid container>
                                    <Grid
                                        style={{
                                            backgroundColor: "#ffffff",
                                            padding: "15px",
                                            borderRadius: "5px",
                                        }}
                                        item
                                        xs={12}
                                    >
                                        <SortingItems />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Paper>
                </Box>
            </div>
        </>
    );
}
