// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import CloseIcon from "@mui/icons-material/Close";
import { Button, CircularProgress, Grid, Modal, Typography } from "@mui/material";
import { purple } from "@mui/material/colors";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/system";
import styles from "../../styles";

const CustomModal = ({ openModal, onModalClose, onSubmit, onCancel, loading }: any) => {
    const ColorButton = styled(Button)(({ theme }) => ({
        color: theme.palette.getContrastText(purple[500]),
        backgroundColor: "#29ABE2",
        margin: "0 20px 0 0",
        "&:hover": {
            backgroundColor: "rgba(41, 177, 226, 0.8)",
        },
    }));
    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 400,
        bgcolor: "background.paper",
        // border: "2px solid #000",
        boxShadow: 15,
        padding: "20px",
        paddingBottom: "60px",
        borderRadius: "10px",
        display: "flex",
        flexDirection: "column",
        //alignItems: "center",
        //justifyContent: "center",
    };
    return (
        <Modal
            open={openModal}
            onClose={onModalClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <Box style={{ display: "flex", justifyContent: "flex-end" }}>
                    <a onClick={onCancel}>
                        <CloseIcon style={{ color: "#94A8B0" }} fontSize="small" />
                    </a>
                </Box>
                <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <Typography id="modal-modal-title" variant="h6" component="h2" sx={{mt: 5}}>
                        Are you sure?
                    </Typography>
                    <Typography textAlign="center" id="modal-modal-description" sx={{ my: 10, mx: 4, color: "#929292", width: "90%" }}>
                        You will not be able to make any changes after submission.
                    </Typography>
                    <Grid container mt={10} display="flex" justifyContent="center" alignItems="center">
                        <Button size="medium" onClick={onSubmit} variant="contained" sx={{...styles.btnContinue, boxShadow: "none", width: 110}}>
                            {loading ? (
                                <Box>
                                    <CircularProgress color="inherit" size={15} />{" "}
                                </Box>
                            ) : (
                                "Yes"
                            )}
                        </Button>
                        &nbsp;&nbsp;
                        &nbsp;
                        <Button size="medium" mxl={2} onClick={onCancel} variant="outlined" sx={{ ...styles.btnExit, width: 110}}>
                            No
                        </Button>
                    </Grid>
                </Box>
            </Box>
        </Modal>
    );
};

export default CustomModal;
