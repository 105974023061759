import { Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import styles from "../../../../styles";
import "./../../VSetUserCard.css";

type VSetCardItemProps = {
    sideLine: boolean;
    content: string;
    title: string;
    isActive: boolean;
    information?: VignetteResponse;
    onClick?: () => void;
};

interface VignetteResponse {
    vignette_id: number;
    vignette_text: string;
    assigned_category_id: number;
    assigned_category_name: string;
    user_category_id: number;
    user_category_name: string;
    container_id: number;
    container_name: string;
    domain_id: number;
    domain_name: string;
    domain_order: number;
    user_rank: number;
    assigned_rank: number;
    user_id: string;
    answers: ClusterAnswers[];
    unpacked: number;
    flagged: number;
    is_scoring_complete: number;
}

interface ClusterAnswers {
    id: number;
    answer_id: number;
    answer_type: string;
    user_id: string;
    answer: string;
    question_text: string;
    question_id: number;
    question_number: number;
    vignette_id: number;
}

const VSetCardItem = ({ information, sideLine, content, title, isActive, onClick }: VSetCardItemProps) => {
    const [color, setColor] = useState<string>("#009245");

    useEffect(() => {
        const handleVignetteState = () => {
            if (information?.is_scoring_complete === 1) {
                setColor("#E6E8F1");
            } else if (information?.unpacked === 0 && information?.flagged === 1) {
                setColor("#F84444");
            } else if (information?.unpacked === 1 && information?.flagged === 1) {
                setColor("#29ABE2");
            } else {
                setColor("#009245");
            }
        };

        handleVignetteState();
    }, []);

    return (
        <Box sx={styles.itemLayout(sideLine)}>
            {sideLine ? <Typography className="card-top-line">{title}</Typography> : null}
            <Box sx={{ ...styles.item(sideLine, isActive), display: "flex", alignItems: "center" }} onClick={onClick}>
                {sideLine ? <div className="card-style" style={{ backgroundColor: color }}></div> : null}
                <div>
                    <Typography sx={styles.typographyStyle(sideLine)}>{content}</Typography>
                </div>
            </Box>
        </Box>
    );
};

export default VSetCardItem;
