import { useAuth0 } from "@auth0/auth0-react";
import { Box, Typography } from "@mui/material";
import { useTour } from "@reactour/tour";
import { setTabNavigation } from "app/slices/TabNavigationSlice";
import { setCurrentUser } from "app/slices/UserSlice";
import ShieldIcon from "assets/icons/Shield.svg";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { User } from "types/user";
import Cookies from "universal-cookie";
import styles from "./styles";

type PropsType = {
    user: User | null;
};

const UserProfilePopover = ({ user, handleClose }: any) => {
    const { logout } = useAuth0();
    const { setIsOpen, setCurrentStep } = useTour();
    const cookies = new Cookies();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const logoutWithRedirect = () => {
        cookies.remove("idToken");
        dispatch(setCurrentUser(null));
        dispatch(setTabNavigation(true));
        logout({ returnTo: window.location.origin });
    };

    const verifySubscription = () => {
        switch (user?.subscriptionInfo?.stripeDetails.name) {
            case "Personal":
                return {
                    upgradePlan: "Personal Plus",
                    upgradeOption: true,
                };
            case "Personal Plus":
                return {
                    upgradePlan: "Professional",
                    upgradeOption: true,
                };
            default:
                return {
                    upgradeOption: false,
                };
        }
    };

    const redirectToStripe = () => {
        navigate(`/stripe`);
    };

    return (
        <Box sx={styles.container}>
            <Box sx={styles.profileContainer}>
                <img style={styles.profileImage} src={user?.img} alt="profileImg" />
                {/* <Box sx={styles.editImageContainer}>
                    <img src={editPhotoIcon} alt="Logo" style={styles.editImageIcon} />
                </Box> */}
            </Box>
            <Box sx={styles.flexContainer}>
                <Typography sx={styles.userName}>{user?.name}</Typography>
                <img src={ShieldIcon} alt="Switch Icon" style={styles.verifiedBadge} />
            </Box>
            <Typography sx={styles.subTitle}>{user?.email}</Typography>
            {user?.organizationName === process.env.REACT_APP_VSORTS_ORG_NAME && verifySubscription().upgradeOption ? (
                <button style={styles.switchAccount}>
                    {/* <img style={styles.icon} src={SwitchAccountLogo} alt="Switch Icon" /> */}
                    <Typography sx={{ color: "#929292" }} onClick={redirectToStripe}>
                        Upgrade to {verifySubscription().upgradePlan}
                    </Typography>
                </button>
            ) : (
                ""
            )}

            <Typography sx={styles.actionItem}>Edit Your Information</Typography>
            <Typography sx={styles.actionItem}>Dashboard</Typography>
            <Typography
                sx={styles.actionItem}
                onClick={() => {
                    navigate("/dashboard");
                    handleClose();
                    setCurrentStep(0);
                    setIsOpen(true);
                }}
            >
                VSorts Guide
            </Typography>
            <Typography sx={styles.actionItem} onClick={() => logoutWithRedirect()}>
                Logout
            </Typography>
            <Typography sx={styles.subTitle}>Terms | Privacy</Typography>
        </Box>
    );
};

export default UserProfilePopover;
