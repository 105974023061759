import ArrowRightIcon from "assets/icons/arrowrigtwhite.svg";
import DotsIcon from "assets/icons/dotsgreen.svg";
import { useNavigate } from "react-router-dom";
import "./CTA.css";

const CTA: React.FC = () => {
    const navigate = useNavigate();
    return (
        <div className="ctaContainer">
            <div className="ctaBox ctaBox1">
                <img src={DotsIcon} alt="Icon" className="ctaBox1Icon" />
                <div className="ctaBox1Text">
                    VSorts™ AI <span className="textGreen">Changing the world one vignette at a time™.</span>
                </div>
            </div>
            <button className="ctaBox ctaBox2" onClick={() => navigate("/login")} style={{ border: "0px" }}>
                <h1 className="ctaBox2Text">Sign up for free!</h1>
                <img src={ArrowRightIcon} alt="Arrow" />
            </button>
        </div>
    );
};

export default CTA;
