const styles = {
    container: {
        display: "flex",
        flexDirection: "row",
        gap: "20px",
        justifyContent: "space-between",
        alighItems: "center",
        backgroundColor: "#fff",
        padding: "20px",
        borderRadius: "10px",
    },
    buttonContainer: {
        display: "flex",
        gap: "20px",
    },
    searchContainer: {
        // flex: "2",
        width: "100%",
        display: "flex",
        // justifyContent: "center",
        alignItems: "center"
    },
};

export default styles;
