import { Box, Typography } from "@mui/material";
import { Parameter } from "../../../Survey/ViewForm/ViewFormResponse/FormResponsePanel/FormResponsePanel";
import "./VSetUserCard.css";
import styles from "./styles";

type ScoringCardItemProps = {
    parameter: Parameter;
    isEnabled: boolean;
    selectedCardId: number | null;
    setSelectedTypeCard: (id: number) => void;
};

const ScoringCardItem = ({
    parameter,
    isEnabled = true,
    selectedCardId,
    setSelectedTypeCard,
}: ScoringCardItemProps) => {
    const isSelected = selectedCardId === parameter.id ? true : false;
    let hoverColor = "";
    let bgColor = "";
    if (parameter.id === 1 || parameter.id === 3 || parameter.id === 6 || parameter.id === 7) {
        hoverColor = "#F84444";
        bgColor = "#F8F9FD";
    } else if (parameter.id === 4 || parameter.id === 8 || parameter.id === 9) {
        hoverColor = "#29ABE2";
        bgColor = "#F3F8FB";
    } else if (parameter.id === 2 || parameter.id === 5 || parameter.id === 10 || parameter.id === 11) {
        hoverColor = "#009245";
        bgColor = "#EAFFF4";
    }
    return (
        <Box
            sx={styles.itemLayout()}
            onClick={
                isEnabled
                    ? () => setSelectedTypeCard(parameter.id)
                    : () => {
                          undefined;
                      }
            }
        >
            <Box
                sx={{
                    ...styles.item(isEnabled, isSelected, hoverColor, bgColor),
                    display: "flex",
                    alignItems: "center",
                }}
            >
                <Typography sx={styles.typographyStyle()}>{parameter.title}</Typography>
            </Box>
        </Box>
    );
};

export default ScoringCardItem;
