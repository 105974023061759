const styles: any = {
    container: {
        background: "#F3F8FB",
        borderRadius: 10,
        minHeight: "max-content",
        transition: "all 1s ease-in-out",
        border: "1px solid #29ABE2",
    },
    flexContainer: {
        display: "flex",
        padding: "30px 10px 30px 20px",
        flexDirection: "column",
        height: 450,
        overflowY: "auto",
        gap: "1.5rem",
    },
    filterContainer: {
        display: "flex",
        flexDirection: "column",
        gap: "0.5rem",
    },
    title: {
        fontWeight: 400,
        fontSize: 16,
        color: "#929292",
    },
};

export default styles;
