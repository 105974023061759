const container = {
    display: "flex",
    flexDirection: "row",
    gap: "1rem",
    justifyContent: "space-between",
    alighItems: "center",
    backgroundColor: "#fff",
    padding: "15px",
    borderRadius: "10px",
    marginBottom: "1.2rem",
};

const cardContainer = {
    ...container,
    flexWrap: "wrap",
    alignItems: "stretch",
};

const itemLayout = (sideLine: boolean) => ({
    flex: "0 1 30%",
    marginBottom: "30px",
    maxWidth: sideLine ? undefined : "22%",
    marginTop: sideLine ? undefined : "15px",
});

const item = (sideLine: boolean, active: boolean) => ({
    borderRadius: "20px",
    flex: 1,
    backgroundColor: "#fff",
    border: `2px solid ${active ? "#29ABE2" : sideLine ? "#F3F8FB" : "#E3E8E8"}`,
    display: "grid",
    gridTemplateColumns: "1fr 30fr",
    alignItems: "center",
    justifyContent: sideLine ? "flex-start" : "center",
    height: `${sideLine ? "100%" : "100px"}`,
    cursor: "pointer",
});

const typographyStyle = (sideLine: boolean) => ({
    flexGrow: 1,
    display: "flex",
    alignItems: "center",
    padding: "15px",
});

const styles = { container, cardContainer, item, typographyStyle, itemLayout };

export default styles;
