import LazyProtectedAdminComponentWrapper from "components/LazyProtectedAdminComponentWrapper";
import * as React from 'react';

import VSBox from "components/VSBox";
import VSTab from "components/VSTab";
import VSTabPanel from "components/VSTabPanel";
import VSTabs from "components/VSTabs";
import SurveyForm from "../../Survey/Form";
import CreateVSetParameters from "../VSetParameters/CreateVSetParameters";
import ViewVSetParameterData from "../VSetParameters/ViewVSetParameters";

import { FormTypes } from "constants/enum.constants";

const VSetVignetteTabView = React.lazy(() => import("pages/admin/dashboard/VSortsContainer/ViewVSorts"));

const CreateEditVSortsContainer = React.lazy(() => import("pages/admin/dashboard/VSortsContainer/CreateEditVSorts"));

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function VSetTabView({ isCreate = false, isView = false, isEdit = false }: any) {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: any, newValue: any) => {
    setValue(newValue);
  };

  return (
    <VSBox sx={{ width: '100%' }}>
      <VSBox sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <VSTabs value={value} onChange={handleChange}>
          <VSTab label="VSet Vignettes" {...a11yProps(0)} />
          <VSTab label="VSet Parameters" {...a11yProps(1)} />
          <VSTab label="VSet Unpacking" {...a11yProps(2)} />
          <VSTab label="VSet Scoring" {...a11yProps(3)} />
        </VSTabs>
      </VSBox>
      <VSTabPanel value={value} index={0}>
        <LazyProtectedAdminComponentWrapper Component={() => {
          return (isView ?
            <VSetVignetteTabView />
            :
            <CreateEditVSortsContainer isCreate={isCreate} />)
        }} />
      </VSTabPanel>
      <VSTabPanel value={value} index={1}>
        <LazyProtectedAdminComponentWrapper Component={() => { return (isView ? <ViewVSetParameterData /> : <CreateVSetParameters isCreate={true} />) }} />
      </VSTabPanel>
      <VSTabPanel value={value} index={2}>
        <LazyProtectedAdminComponentWrapper Component={() => { return (isView ? <SurveyForm surveyQuestionsType={FormTypes.unpacking} isView={true} isUnpacking={true} /> : isEdit ? <SurveyForm surveyQuestionsType={FormTypes.unpacking} isEdit={true} isUnpacking={true} /> : <SurveyForm surveyQuestionsType={FormTypes.unpacking} isCreate={true} isUnpacking={true} />) }} />
      </VSTabPanel>
      <VSTabPanel value={value} index={3}>
        <LazyProtectedAdminComponentWrapper Component={() => { return (isView ? <SurveyForm surveyQuestionsType={FormTypes.scoring} isView={true} isScoring={true} /> : isEdit ? <SurveyForm surveyQuestionsType={FormTypes.scoring} isEdit={true} isScoring={true} /> : <SurveyForm surveyQuestionsType={FormTypes.scoring} isCreate={true} isScoring={true} />) }} />
      </VSTabPanel>
    </VSBox>
  );
}