import { createSlice } from "@reduxjs/toolkit";

const unpackingSlice = createSlice({
    name: "unpacking",
    initialState: {
        heatMapView: false,
        openDialog: false,
        unpackedVignetteCount: 0,
        minimumUnpackingCount: 0,
        maximumUnpackingCount: 100000,
    },
    reducers: {
        setHeatMapView: (state, action) => {
            state.heatMapView = action.payload;
        },
        setOpenDialog: (state, action) => {
            state.openDialog = action.payload;
        },
        setUnpackedVignetteCount: (state, action) => {
            state.unpackedVignetteCount = action.payload;
        },
        setMinimumUnpackingCount: (state, action) => {
            state.minimumUnpackingCount = action.payload;
        },
        setMaximumUnpackingCount: (state, action) => {
            state.maximumUnpackingCount = action.payload;
        },
    },
});

export const {
    setHeatMapView,
    setOpenDialog,
    setUnpackedVignetteCount,
    setMinimumUnpackingCount,
    setMaximumUnpackingCount,
} = unpackingSlice.actions;
export const HeatMapViewSelector = (state: any) => state.unpacking.heatMapView;
export const OpenDialogSelector = (state: any) => state.unpacking.openDialog;
export const UnpackedVignetteCountSelector = (state: any) => state.unpacking.unpackedVignetteCount;
export const MinimumUnpackingCountSelector = (state: any) => state.unpacking.minimumUnpackingCount;
export const MaximumUnpackingCountSelector = (state: any) => state.unpacking.maximumUnpackingCount;

export default unpackingSlice.reducer;
