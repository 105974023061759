import TablePagination from "@mui/material/TablePagination";
import CircularLoadingProgress from "components/CircularProgress";
import { useEffect, useState } from "react";
import { useLocation, useOutletContext, useParams } from "react-router-dom";
import VSetTab from "../VSetTab";
import "./VSet.css";
import VSetUserCard from "./VSetUserCard/index";

interface Domain {
    domain_name: string;
    header_text: string;
}

interface VignetteResponse {
    vignette_id: number;
    vignette_text: string;
    assigned_category_id: number;
    assigned_category_name: string;
    user_category_id: number;
    user_category_name: string;
    container_id: number;
    container_name: string;
    domain_id: number;
    domain_name: string;
    domain_order: number;
    user_rank: number;
    assigned_rank: number;
    user_id: string;
    answers: ClusterAnswers[];
    unpacked: number;
    flagged: number;
    is_scoring_complete: number;
}

interface ClusterAnswers {
    id: number;
    answer_id: number;
    answer_type: string;
    user_id: string;
    answer: string;
    question_text: string;
    question_id: number;
    question_number: number;
    vignette_id: number;
}

interface UserResponse {
    user_id: string;
    email?: string;
    responses: VignetteResponse[];
}

interface ClusterInformation {
    domains: Domain[];
    responses: UserResponse[] | null;
}

interface OutletContext {
    childRef: React.RefObject<any>;
    clusterInformation: ClusterInformation | null;
    cluster_id: number;
}

interface Tabs {
    label: string;
    count: number;
}

const ViewVset = () => {
    const { childRef, clusterInformation, cluster_id } = useOutletContext<OutletContext>();
    const [loading, setLoading] = useState(true);
    const { type } = useParams();
    const [tabs, setTabs] = useState<Tabs[]>([]);
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const container_id = params.get("container_id");

    // Pagination state
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    useEffect(() => {
        if (clusterInformation?.domains) {
            const domains = clusterInformation?.domains.map((domain) => ({
                label: domain.domain_name,
                count: 0,
            }));
            setTabs([{ label: "All", count: userCards.length }, ...domains]);
            setLoading(false);
        }
    }, [type, clusterInformation]);

    useEffect(() => {
        if (cluster_id === 0) {
            setLoading(false);
        }
    }, [cluster_id]);

    // Uncomment and use your data fetching logic
    // const { data, error, isLoading, isError } = useAllVSetUserResponses({
    //     container_id,
    //     onSuccess: (data: any) => {
    //         setUserResponses(data);
    //         setLoading(false); // Set loading to false when data is successfully fetched
    //     },
    //     onError: (error: any) => {
    //         console.error("Error fetching data:", error);
    //         setLoading(false); // Set loading to false even on error
    //     },
    //     isEnabled: true,
    // });

    const userCards = clusterInformation?.responses
        ? clusterInformation.responses.map((response, i) => (
              <VSetUserCard
                  key={i}
                  userId={response.email ? response.email : response.user_id}
                  type={type}
                  clusterInformationResponses={clusterInformation.responses}
                  items={tabs}
              />
          ))
        : [];

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const paginatedUserCards = userCards.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

    useEffect(() => {
        if (clusterInformation?.domains && clusterInformation.responses) {
            const domainsWithCounts = clusterInformation.domains.map((domain) => {
                const count =
                    clusterInformation.responses?.filter((response) =>
                        response.responses.some((userResponse) => userResponse.domain_name === domain.domain_name)
                    ).length || 0;

                return {
                    label: domain.domain_name,
                    count: count,
                };
            });

            const totalCount = clusterInformation.responses.length;

            setTabs([{ label: "All", count: totalCount }, ...domainsWithCounts]);
        }
    }, [clusterInformation, type]);

    if (loading) {
        return <CircularLoadingProgress />;
    }

    return (
        <div className="createVSet">
            {cluster_id !== 0 && <VSetTab items={tabs} containerId={container_id} type={type} />}

            {clusterInformation?.responses && paginatedUserCards}
            <TablePagination
                component="div"
                count={userCards.length}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </div>
    );
};

export default ViewVset;
