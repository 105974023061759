import { Grid } from "@material-ui/core";
import { Box, Typography } from "@mui/material";
import LinearProgress, { linearProgressClasses } from "@mui/material/LinearProgress";
import { styled } from "@mui/material/styles";

interface LinearProgressProps {
  totalProgress: number;
  totalLength: number;
  totalItems: number;
}

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: theme.palette.mode === "light" ? "#009245" : "#308fe8",
  },
}));


const LinearProgressComponent = ({totalProgress = 100, totalLength = 5, totalItems = 5}: LinearProgressProps) => {
  return (
        <Grid container spacing={4} alignItems="center" px={1} sx={{ width: '100%' }}>
            <Box flex={1}>
                <BorderLinearProgress  variant="determinate" value={totalProgress} />
            </Box>
                <Typography
                    variant="body2"
                    component="span"
                    sx={{ color: totalLength === totalItems ? "#009245 !important" : "#929292", lineHeight: 0.8, display: "inline", marginLeft: "2rem" }}
                >
                    {totalLength}/{totalItems} completed
                </Typography>
        </Grid>
  )
}

export default LinearProgressComponent