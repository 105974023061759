
const styles: any = {
    flexContainer: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },
    flex: {
        display: "flex",
        gap: "10px",
        width: "100%",
        flex: 6,
        transition: "all 1s ease-in-out",
    },
    titleContainer: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginBottom: 20,
    },
    title: {
        fontWeight: 500,
        fontSize: 24,
        color: "#000",
    },
    btnAction: {
        border: "none",
        marginLeft: 16,
        padding: 0,
        minWidth: "unset",
    },
    actionIcon: {
        width: 18,
        height: 18,
    }
};

export default styles;
