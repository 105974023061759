import { useMutation, useQuery } from "react-query";

// Axios Interceptor
import { request } from "utils/axios-utils";

// Constants
import { APIMethodConstants } from "constants/api.method.constants";
import { NetworkConstants } from "constants/network.constants";
import { QueryKeyConstants } from "constants/query.key.constants";

const fetchOrganizationList = () => {
    return request({
        url: `${NetworkConstants.getOrganizationList}`,
    });
};

const fetchAllOrganizationList = () => {
    return request({
        url: `${NetworkConstants.useGetAllOrganizations}`,
    });
};

const assignOrganization = ({ data }: any) => {
    return request({ url: NetworkConstants.putOrganizationList, method: APIMethodConstants.put, data: data });
};

export const useGetOrganizationList = ({ onSuccess, onError }: any) => {
    return useQuery([QueryKeyConstants.fetchOrganizationList], () => fetchOrganizationList(), {
        staleTime: Infinity,
        onSuccess,
        onError,
        select: (data) => {
            return data.data.data;
        },
    });
};

export const useGetAllOrganizations = ({ onSuccess, onError }: any) => {
    return useQuery([QueryKeyConstants.fetchAllOrganizations], () => fetchAllOrganizationList(), {
        staleTime: Infinity,
        onSuccess,
        onError,
        select: (data) => {
            return data.data;
        },
    });
};

// useCreateVSortsContainerData -> To create a New VSorts Container
export const useAssignOrganization = () => {
    return useMutation(({ data }: any) => assignOrganization({ data: data }), {
        // We add the data from the response to the existing data
        onSuccess: (data) => {
            const responseData = data.data.data;
            window.location.reload();
            return responseData;
            // Navigating to create screen on successful creation of a VSorts Container
        },
    });
};
