const styles = {
    container: {
        display: "flex",
        flexDirection: "row",
        gap: "0.6rem",
        padding: "0.6rem 0",
    },
    badge: {
        color: "#29ABE2",
        border: "1px solid #29ABE2",
        fontSize: "14px"
    },
    clusterButton: (isAdd: boolean) => ({
        color: isAdd ? "#009245" : "#C3C3C3",
        border: `1px solid ${isAdd ? "#009245" : "#C3C3C3"}`,
        fontSize: "14px"
    }),
};

export default styles;
