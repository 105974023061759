import { AdminCluster, UserStatus } from "types/dashboard";

export const USER_NAME = "Dwayne Ray Cormier";
export const USER_ROLE = "User";
export const USER_EMAIL = "love@vsorts.com";
export const USER_STATUS: UserStatus = {
    flaggedVignettes: 45,
    unpackedVignettes: 26,
    culturalProfiency: 5.1,
};
export const USER_ORGANIZATION = "VSorts™";
export const USER_GROUP = "Beta";

export const ADMIN_CLUSTERS: AdminCluster[] = [
    {
        user_id: 1,
        cluster_id: 94,
        score: 10,
    },
    {
        user_id: 2,
        cluster_id: 94,
        score: 12,
    },
    {
        user_id: 3,
        cluster_id: 94,
        score: 15,
    },
    {
        user_id: 2,
        cluster_id: 95,
        score: 20,
    },
    {
        user_id: 4,
        cluster_id: 95,
        score: 18,
    },
    {
        user_id: 6,
        cluster_id: 95,
        score: 12,
    },
    {
        user_id: 8,
        cluster_id: 95,
        score: 9,
    },
    {
        user_id: 5,
        cluster_id: 96,
        score: 25,
    },
    {
        user_id: 6,
        cluster_id: 96,
        score: 18,
    },
    {
        user_id: 7,
        cluster_id: 96,
        score: 20,
    },
    {
        user_id: 8,
        cluster_id: 97,
        score: 16,
    },
    {
        user_id: 9,
        cluster_id: 97,
        score: 24,
    },
    {
        user_id: 1,
        cluster_id: 98,
        score: 1,
    },
    {
        user_id: 2,
        cluster_id: 98,
        score: 5,
    },
    {
        user_id: 3,
        cluster_id: 98,
        score: 40,
    },
    {
        user_id: 4,
        cluster_id: 98,
        score: 35,
    },
    {
        user_id: 5,
        cluster_id: 98,
        score: 42,
    },
    {
        user_id: 6,
        cluster_id: 98,
        score: 12,
    },
];
