import UnpackingSurveyQuestions from "./UnpackingSurveyQuestions";

export default function UnpackingSurveyForm({
    clusterStep,
    refreshNextClusterStep,
    clusterDetail,
    vignette_id,
    vignette_text,
    vignette_rank_category,
    setSurveyPrevNextData,
}: any) {
    const { form_type, container_id, container_name, section_id, section_order } = clusterStep;
    return (
        <UnpackingSurveyQuestions
            refreshNextClusterStep={refreshNextClusterStep}
            formType={form_type}
            sectionId={section_id}
            sectionOrder={section_order}
            surveyName={container_name}
            surveyID={container_id}
            clusterId={clusterDetail.id}
            clusterStepId={clusterDetail.cluster_step_id}
            vignette_id={vignette_id}
            vignette_text={vignette_text}
            vignette_rank_category={vignette_rank_category}
            setSurveyPrevNextData={setSurveyPrevNextData}
        />
    );
}
