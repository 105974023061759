

import CircularLoadingProgress from "components/CircularProgress";
import NetworkError from "pages/error/networkError";

import { HeatMapViewSelector } from "app/slices/UnpackingSlice";
import { useVSetUserResponses } from "hooks/VSortsContainerData";
import { useVSortsContainerCategoryData } from "hooks/VSortsContainerTableData";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ViewVSetResponses from "./ResponsesGrid";



export default function VSetResponses({ isCreate = false, isView = false, isEdit = false, container_id, cluster_id, user_id = '', cluster_step_id }: any) {
    const [isResponsesData, setIsResponsesData] = useState(false);
    const [responsesColData, setResponsesColData] = useState<any>([]);
    const [responsesRowData, setResponsesRowData] = useState<any[]>([]);
    const heatMapView = useSelector(HeatMapViewSelector);
    useEffect(() => {
        if(heatMapView){
            refetchVSetsResponsesData();
            refetchVSortsContainerCategoriesData();
        }
    },[])

    const categoryValueGetter = (params: any) => {
        return params.data[params.column.colId]['user_rank_value'];
      };

    const onVSetsUserResponsesDataSuccess = (data: any) => {
        // TODO:- Perform any operation after the successful fetching of Survey Data
        // 
        const columnDefs = []
        columnDefs.push({field: "Domain", filter:true});
        data?.map((eachCategory: any, index: number) => {
            const temp: any = {}
            temp['field'] = eachCategory['category_name']
            temp['cellStyle'] = (params: any) => {
                const styleObject: any = {}
                if (Math.abs(params.value - index) == 0) {
                    //mark cells as #b0e6b5
                    styleObject['backgroundColor'] = '#b0e6b5';
                }
                else if (Math.abs(params.value - index) == 1) {
                    styleObject['backgroundColor'] = '#c2ffba';
                }
                else if (Math.abs(params.value - index) == 2) {
                    styleObject['backgroundColor'] = '#fdffcb';
                } 
                else if (Math.abs(params.value - index) == 3) {
                    styleObject['backgroundColor'] = '#ffdcb9';
                } 
                else if (Math.abs(params.value - index) == 4) {
                    styleObject['backgroundColor'] = '#ffc0b1';
                } 
                else if (Math.abs(params.value - index) == 5) {
                    styleObject['backgroundColor'] = '#ff9892';
                } 
                if(params.data[params.column.colId]['unpacked'] == 1){
                    styleObject['textDecoration'] = 'underline';
                    styleObject['color'] = 'red';
                }
                return styleObject;
            }
            temp["valueGetter"] =  categoryValueGetter
            columnDefs.push(temp)
        })
        setResponsesColData(columnDefs)
    };

    const onVSetsUserResponsesDataError = (error: any) => {
        // TODO:- Perform any operation in case of errors while fetching Survey Data
        // 
    };

    const onVSetResponsesDataFetchSuccess = (data: any) => {
        const rowDefs: any[] = []
        data?.map((eachDomain: any) => {
            const temp: any = {}
            temp['Domain'] = eachDomain['domain_name']
            eachDomain.vignettes?.map((eachVignette: any, index: number) => {
                temp[responsesColData[index + 1]['field']] = eachVignette
            })
            rowDefs.push(temp)
        })
        setResponsesRowData(rowDefs)
        // We set the data variable and make the setIsData variable to true signifying that the data has been set
    };
    const onVSetResponsesDataFetchError = (error: any) => {
        // TODO:- Perform any operation in case of errors while fetching Survey Data
        // 
    };
    
    const {
        isResponsesFetched,
        // isFetching: isVSetsResponsesDataFetching,
        isLoading: isVsetsResponsesDataLoading,
        isError: isVSetsResponsesDataError,
        data: allVSetsResponsesData,
        vsetResponesError,
        refetch: refetchVSetsResponsesData,
    }: any = useVSetUserResponses({
        container_id: container_id, 
        cluster_id: cluster_id, 
        user_id: user_id,
        isEnabled: !isCreate,
        onSuccess: onVSetResponsesDataFetchSuccess,
        onError: onVSetResponsesDataFetchError,
    });

    const {
        isFetching: isVSortsContainerCategoriesDataFetching,
        isLoading: isVSortsContainerCategoriesDataLoading,
        isError: isVSortsContainerCategoriesDataError,
        data: VSortsContainerCategoriesData,
        error: VSortsContainerCategoriesDataError,
        refetch: refetchVSortsContainerCategoriesData,
    } = useVSortsContainerCategoryData({
        onSuccess: onVSetsUserResponsesDataSuccess,
        onError: onVSetsUserResponsesDataError,
        containerID: container_id,
    });

    if (
        isVSortsContainerCategoriesDataLoading ||
        isVSortsContainerCategoriesDataFetching
    ) {
        return <CircularLoadingProgress />;
    }
    
    if (isResponsesFetched && !isResponsesData) {
        onVSetResponsesDataFetchSuccess(allVSetsResponsesData);
    }
    
    if (isVsetsResponsesDataLoading ) {
        return <CircularLoadingProgress />;
    }

    // We check if there is an error present while fetching all Questions of a Survey and display `NetworkError`
    if (isVSetsResponsesDataError) {
        return <NetworkError handleOnRefresh={refetchVSetsResponsesData} errorText={vsetResponesError} />;
    }

    // We check if there is an error in fetching VSorts Categories and display `NetworkError`
    if (isVSortsContainerCategoriesDataError) {
        return (
            <NetworkError
                handleOnRefresh={refetchVSortsContainerCategoriesData}
                errorText={VSortsContainerCategoriesDataError}
            />
        );
    }
  return (
    <ViewVSetResponses rows={responsesRowData} cols={responsesColData} cluster_step_id={cluster_step_id}/>
  );
}