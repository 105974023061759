// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import MenuIcon from '@mui/icons-material/Menu';
import { Box, Button, Drawer, Toolbar, Typography } from "@mui/material";
import DiscussIcon from 'assets/icons/discuss-white.svg';
import EmailGradientIcon from 'assets/icons/email-gradient.svg';
import EmailIcon from 'assets/icons/email.svg';
import FacebookIcon from 'assets/icons/fb-white.svg';
import LocationIcon from 'assets/icons/location.svg';
import PhoneGradientIcon from 'assets/icons/phone-gradient.svg';
import PhoneIcon from 'assets/icons/phone.svg';
import QuestionIcon from 'assets/icons/question-circle.svg';
import TwitterIcon from 'assets/icons/twitter-white.svg';
import YoutubeIcon from 'assets/icons/youtube-white.svg';
import Logo from 'assets/images/logo-white.png';
import { ADDRESS, EMAIL, PHONE_NUMBER } from "constants/contact.constants";
import { SECTIONS } from "constants/landing-page.constants";
import { DISCUSS_URL, FACEBOOK_URL, TWITTER_URL, YOUTUBE_URL } from "constants/social.constants";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import styles from './styles';

const NavHeader = ({ onScroll }) => {
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false)

    const toggleMobileMenu = () => {
        setIsMobileMenuOpen(!isMobileMenuOpen)
    }

    const onMobileMenuClick = menu => {
        toggleMobileMenu()
        onScroll(menu)
    }

    return (
        <React.Fragment>
            <Box sx={styles.header}>
                <Box sx={styles.info}>
                    <Box sx={styles.menuItems}>
                        <Button sx={styles.menuItem} onClick={() => onScroll(SECTIONS.FAQS)}>
                            <img src={QuestionIcon} alt="?" style={styles.menuIcon} />
                            <Typography sx={styles.menuInfo}>{`FAQ's`}</Typography>
                        </Button>
                        <Button sx={styles.menuItem}>
                            <img src={LocationIcon} alt="" style={styles.menuIcon} />
                            <Typography sx={styles.menuInfo}>{ADDRESS}</Typography>
                        </Button>
                    </Box>
                    <Box sx={styles.menuItems}>
                        <a href={`tel:${PHONE_NUMBER.number}`} style={styles.menuItem}>
                            <img src={PhoneIcon} alt="" style={styles.menuIcon} />
                            <Typography sx={styles.menuInfo}>{PHONE_NUMBER.format}</Typography>
                        </a>
                        <a href={`mailto: ${EMAIL}`} style={styles.menuItem}>
                            <img src={EmailIcon} alt="@" style={styles.menuIcon} />
                            <Typography sx={styles.menuInfo}>{EMAIL}</Typography>
                        </a>
                        <Box sx={styles.socialIcons}>
                            <a href={FACEBOOK_URL} style={styles.menuSocial} target="_blank" rel="noreferrer">
                                <img src={FacebookIcon} alt="Facebook" style={styles.socialIcon} />
                            </a>
                            <a href={TWITTER_URL} style={styles.menuSocial} target="_blank" rel="noreferrer">
                                <img src={TwitterIcon} alt="Twitter" style={styles.socialIcon} />
                            </a>
                            <a href={DISCUSS_URL} style={styles.menuSocial} target="_blank" rel="noreferrer">
                                <img src={DiscussIcon} alt="Discuss" style={styles.socialIcon} />
                            </a>
                            <a href={YOUTUBE_URL} style={styles.menuSocial} target="_blank" rel="noreferrer">
                                <img src={YoutubeIcon} alt="Youtube" style={styles.socialIcon} />
                            </a>
                        </Box>
                    </Box>
                </Box>
                <Toolbar sx={styles.mainHeader}>
                    <Link to="/">
                        <img src={Logo} alt="" style={styles.logo} />
                    </Link>
                    <Box sx={styles.menuItems}>
                        <Button sx={styles.menuItem} onClick={() => onScroll(SECTIONS.VSORTS)}>
                            VSorts™
                        </Button>
                        <Button sx={styles.menuItem} onClick={() => onScroll(SECTIONS.FEATURES)}>
                            Features
                        </Button>
                        <Button sx={styles.menuItem} onClick={() => onScroll(SECTIONS.ENTERPRISE)}>
                            Enterprise
                        </Button>
                        <Button sx={styles.menuItem} onClick={() => onScroll(SECTIONS.TESTIMONIALS)}>
                            Testimonials
                        </Button>
                        <Button sx={styles.menuItem} onClick={() => onScroll(SECTIONS.CONTACT)}>
                            Contact
                        </Button>
                    </Box>
                    <Box sx={styles.menuItems}>
                        <Link to="/login" style={styles.btnSignIn}>
                            Sign In
                        </Link>
                        <Link to="/coming-soon" style={styles.btnSignUp}>
                            Create Account
                        </Link>
                    </Box>
                </Toolbar>
                <Box sx={styles.mobileMenu}>
                    <Link to="/">
                        <img src={Logo} alt="" style={styles.logoMobile} />
                    </Link>
                    <Button onClick={toggleMobileMenu}>
                        <MenuIcon color="warning" />
                    </Button>
                </Box>
                <Drawer
                    anchor='left'
                    open={isMobileMenuOpen}
                    onClose={toggleMobileMenu}
                >
                    <Box sx={styles.menuContent}>
                        <Box sx={styles.mobileMain}>
                            <Button sx={styles.menuItem} onClick={() => onMobileMenuClick(SECTIONS.VSORTS)}>
                                VSorts™
                            </Button>
                            <Button sx={styles.menuItem} onClick={() => onMobileMenuClick(SECTIONS.FEATURES)}>
                                Features
                            </Button>
                            <Button sx={styles.menuItem} onClick={() => onMobileMenuClick(SECTIONS.ENTERPRISE)}>
                                Enterprise
                            </Button>
                            <Button sx={styles.menuItem} onClick={() => onMobileMenuClick(SECTIONS.TESTIMONIALS)}>
                                Testimonials
                            </Button>
                            <Button sx={styles.menuItem} onClick={() => onMobileMenuClick(SECTIONS.CONTACT)}>
                                Contact
                            </Button>
                            <Button sx={styles.menuItem} onClick={() => onMobileMenuClick(SECTIONS.FAQS)}>
                                {`FAQ's`}
                            </Button>
                        </Box>
                        <Box sx={styles.mobileMenuAction}>
                            <Link to="/login" style={styles.btnActionMobile}>
                                Sign In
                            </Link>
                            <Link to="/coming-soon" style={styles.btnActionMobile}>
                                Create Account
                            </Link>
                            <Box sx={styles.mobileMneuActionContact}>
                                <a href={`tel:${PHONE_NUMBER.number}`} style={styles.menuItem}>
                                    <img src={PhoneGradientIcon} alt="" style={styles.menuIcon} />
                                    <Typography sx={styles.menuInfo}>{PHONE_NUMBER.format}</Typography>
                                </a>
                                <a href={`mailto: ${EMAIL}`} style={styles.menuItem}>
                                    <img src={EmailGradientIcon} alt="@" style={styles.menuIcon} />
                                    <Typography sx={styles.menuInfo}>{EMAIL}</Typography>
                                </a>
                            </Box>
                        </Box>
                    </Box>
                </Drawer>
            </Box>
        </React.Fragment>
    );
}

export default NavHeader;
