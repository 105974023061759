import { Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import "./VSet.css";
import styles from "./styles";

interface Item {
    label: string;
    count: number;
}

interface VSetTabProps {
    items: Item[];
    containerId: string | null;
    type: string | undefined;
}

const VSetTab: React.FC<VSetTabProps> = ({ items, containerId, type }) => {
    const navigate = useNavigate();

    const handleItemClick = (item: string) => {
        navigate(`/v2/admin/vsets/view/${item}?container_id=${containerId}`);
    };

    return (
        <Box sx={styles.container} display="flex" alignItems="center">
            {items.map((item: Item, index: number) => (
                <Typography
                    key={index}
                    sx={{ color: item.label === type ? styles.active : styles.nonActive }}
                    onClick={() => handleItemClick(item.label)}
                    className="tab"
                >
                    {item.label} ({item.count})
                </Typography>
            ))}
        </Box>
    );
};

export default VSetTab;
