import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import { TermsConditionsSections } from "constants/landing-page.constants";
import * as React from "react";
import { useRef } from "react";
import "./style.css";

const drawerWidth = 240;

interface Props {
    /**
     * Injected by the documentation to work in an iframe.
     * Remove this when copying and pasting into your project.
     */
    window?: () => Window;
}

export default function ResponsiveDrawer(props: Props) {
    const { window } = props;
    const [activeTab, setActiveTab] = React.useState(0);
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const [isClosing, setIsClosing] = React.useState(false);
    const termsAndConditionsRef = useRef<HTMLDivElement>(null);
    const privacyPolicyRef = useRef<HTMLDivElement>(null);
    const cookiesRef = useRef<HTMLDivElement>(null);
    const aiDisclaimerRef = useRef<HTMLDivElement>(null);
    const eulaRef = useRef<HTMLDivElement>(null);

    const handleDrawerClose = () => {
        setIsClosing(true);
        setMobileOpen(false);
    };

    const handleDrawerTransitionEnd = () => {
        setIsClosing(false);
    };

    const handleDrawerToggle = () => {
        if (!isClosing) {
            setMobileOpen(!mobileOpen);
        }
    };

    const onScroll = (refName: string) => {
        switch (refName) {
            case TermsConditionsSections.termsAndConditions:
                setActiveTab(0);
                termsAndConditionsRef.current?.scrollIntoView({ behavior: "smooth" });
                break;
            case TermsConditionsSections.privacyPolicy:
                setActiveTab(1);

                privacyPolicyRef.current?.scrollIntoView({ behavior: "smooth" });
                break;
            case TermsConditionsSections.cookies:
                setActiveTab(2);

                cookiesRef.current?.scrollIntoView({ behavior: "smooth" });
                break;
            case TermsConditionsSections.aiDisclaimer:
                setActiveTab(3);

                aiDisclaimerRef.current?.scrollIntoView({ behavior: "smooth" });
                break;
            case TermsConditionsSections.eula:
                setActiveTab(4);

                eulaRef.current?.scrollIntoView({ behavior: "smooth" });
                break;
            default:
                return;
        }
    };
    const content = [
        {
            id: "1",
            heading: "| Terms & Conditions",
            text: (
                <div>
                    <Typography style={{ fontSize: "18px", fontWeight: "500", lineHeight: "28px" }}>
                        1. General
                    </Typography>
                    <p style={{ marginTop: "10px" }}>
                        These rules control visits to this platform (the &quot;Platform&quot;). If you use the Platform,
                        you agree with the rules and confirm
                        <p style={{ marginTop: "10px" }}>- You are 18 years or older;</p>
                        <p>- You have not been banned or blocked from this Platform before; and</p>
                        <p>- Your use follows all laws.</p>
                        These rules are preconditions for your use of the platform. If you do not meet these conditions
                        or disagree, you are not permitted to use the Platform. Vsorts can change these rules anytime.
                        Users are advised to often check for updates. If you continue to use the Platform after changes,
                        that shows you agree to be subject to the updated conditions. To use Vsorts’s products or
                        services, you necessarily need to agree to different rules.
                    </p>
                    <Typography style={{ fontSize: "18px", fontWeight: "500", lineHeight: "28px" }}>
                        2. Ownership of Materials
                    </Typography>
                    <p style={{ marginTop: "10px" }}>
                        Everything on the Platform is protected by copyright laws. You may not copy, share, change, or
                        export anything from here unless Vsorts says yes in writing. You get a temporary right to
                        download or use the Platform only for personal reasons or for your business if it could. Unless
                        stated otherwise, the End User License Agreement applies to all Vsorts software. No other rights
                        are given to you for anything under Vsorts’s patents copyrights or trademarks.
                    </p>
                    <Typography style={{ fontSize: "18px", fontWeight: "500", lineHeight: "28px" }}>
                        3. User Accounts and Profiles
                    </Typography>
                    <p style={{ marginTop: "10px" }}>
                        <p>
                            Some parts of the Platform require users to create an account or profile. Additional rules
                            may be added and will apply.
                        </p>
                        <p>
                            Where original and updated conflict, the updated rules will apply. Each account and password
                            should be linked to one person unless we agree otherwise. Information you input must be
                            truthful and accurate. Keep account info safe and tell us right away if someone else uses
                            your account without permission. Vsorts has no responsibility for any misuse of your
                            account.
                        </p>
                        <p>
                            We can limit stop or end your access to the Platform anytime without warning. Information
                            you share could be kept or deleted based on legal necessity.
                        </p>
                    </p>
                    <Typography style={{ fontSize: "18px", fontWeight: "500", lineHeight: "28px" }}>
                        4. Submissions
                    </Typography>
                    <p style={{ marginTop: "10px" }}>
                        <p>
                            You may add comments or share information on the Platform. The format of these
                            &quot;Contributions&quot; may be but is not limited to feedback, articles, and code. Unless
                            we agree otherwise in writing, anything you share is non-confidential. Vsorts is not
                            responsible for user Contributions.
                        </p>
                        <p>
                            Vsorts can use or share user Contributions in any way without paying you or giving credit.
                            We might also remove Contributions if needed. By sharing, you promise your Contributions do
                            not misappropriate someone else’s ideas and you will take responsibility for any
                            consequences resulting from your platform contributions.
                        </p>
                    </p>

                    <Typography style={{ fontSize: "18px", fontWeight: "500", lineHeight: "28px" }}>
                        5. Trademarks
                    </Typography>
                    <p style={{ marginTop: "10px" }}>
                        All logos, proprietary company names, and all other trademarks on the Platform belong to Vsorts
                        or others. Users have no permission to use them without written owner permission. This includes
                        but is not limited to any Vsorts trademarks, logos, or service marks.
                    </p>

                    <Typography style={{ fontSize: "18px", fontWeight: "500", lineHeight: "28px" }}>
                        6. Links to Other Material
                    </Typography>
                    <p style={{ marginTop: "10px" }}>
                        The Platform may link to other platforms. Vsorts does not control these platforms and is not
                        responsible for their contents. These links are here to help but cannot be construed to mean
                        that Vsorts supports their contents.
                    </p>

                    <Typography style={{ fontSize: "18px", fontWeight: "500", lineHeight: "28px" }}>
                        7. Disallowances
                    </Typography>
                    <p style={{ marginTop: "10px" }}>Users are not allowed to</p>
                    <ul>
                        <li>Change or make new versions of what’s on the Platform. </li>
                        <li>Copy share or show confidential Platform information from the others.</li>
                        <li>Sell, rent, or otherwise commercially dispose of Platform usage.</li>
                        <li>Access the Platform without permission or alter platform security.</li>{" "}
                        <li>
                            Use the Platform to do engage in behavior injurious to Vsorts’s reputation or violate
                            intellectual property rules.
                        </li>
                    </ul>

                    <Typography style={{ fontSize: "18px", fontWeight: "500", lineHeight: "28px" }}>
                        8. Legal Compliance
                    </Typography>
                    <p style={{ marginTop: "10px" }}>
                        Your use of the platform must be in compliance with all the rules from your country and local
                        area.
                    </p>

                    <Typography style={{ fontSize: "18px", fontWeight: "500", lineHeight: "28px" }}>
                        9. Disclaimer
                    </Typography>
                    <p style={{ marginTop: "10px" }}>
                        <p>
                            Vsorts takes care to supply users with good information but cannot promise accuracy.
                            Everything here is shared &quot;AS IS&quot; with no warranties for any specific purpose.
                            Vsorts will not be responsible for any problems that come from use of the Platform.
                        </p>
                        <p>
                            If you break any listed platform rule, your right to use the Platform stops right away.
                            Vsorts may terminate your access anytime for any reason without prior notice.
                        </p>
                    </p>

                    <Typography style={{ fontSize: "18px", fontWeight: "500", lineHeight: "28px" }}>
                        10. Choice of Laws
                    </Typography>
                    <p style={{ marginTop: "10px" }}>
                        These rules follow Pennsylvania laws. If a legal dispute arises under these rules you agree to
                        resolve it in the courts with jurisdiction of Delaware County, Pennsylvania.
                    </p>

                    <Typography style={{ fontSize: "18px", fontWeight: "500", lineHeight: "28px" }}>
                        11. About Your Privacy
                    </Typography>
                    <p style={{ marginTop: "10px" }}>
                        Go look at Vsorts’s Privacy Policy to understand how your information is collected used and
                        shared. The Privacy Policy is part of these rules.
                    </p>

                    <Typography style={{ fontSize: "18px", fontWeight: "500", lineHeight: "28px" }}>
                        12. Taking Down Copyrighted Content
                    </Typography>
                    <p style={{ marginTop: "10px" }}>
                        If you think something on this Platform infringes upon your intellectual property, please send
                        us a written notice that says
                    </p>
                    <ul>
                        <li>What material you think is infringing. </li>
                        <li>The name of the work you think is infringed upon.</li>
                        <li>Your contact information</li>
                        <li>
                            A statement saying it’s being used without permission and that you’re sure under penalty of
                            perjury.
                        </li>
                        <li>Your signature either electronic or written.</li>
                    </ul>
                    <p>
                        Send correspondence to this address:<br></br> Vsorts Legal Department <br></br> 24 Veterans
                        Square
                        <br></br>Media PA 19063help@vsorts.com
                    </p>
                    <Typography style={{ fontSize: "18px", fontWeight: "500", lineHeight: "28px" }}>
                        13. Other Terms
                    </Typography>
                    <p style={{ marginTop: "10px" }}>
                        These rules along with the Privacy Policy cover all interactions between you and Vsorts about
                        your use of the Platform. If Vsorts does not act on a breach of the rules it does not mean the
                        rule goes does not apply. If one rule is found to not apply, the other rules remain in effect.
                    </p>
                </div>
            ),
        },
        {
            id: "2",
            heading: "| Privacy Policy",
            text: (
                <div>
                    <p>
                        Culturally Responsive Solutions, LLC d/b/a VSorts™ (&quot;Vsorts&quot;) cares about keeping your
                        personal data safe. This privacy policy explains what information we collect, why we collect it,
                        and how we protect it. &quot;Personal data&quot; means anything that could help identify who you
                        are. We use your data in ways that follow laws and rules.
                    </p>
                    <Typography style={{ fontSize: "18px", fontWeight: "500", lineHeight: "28px" }}>
                        About Us
                    </Typography>
                    <Typography style={{ fontSize: "16px", fontWeight: "500", lineHeight: "28px", marginTop: "10px" }}>
                        Who We Are
                    </Typography>
                    <p style={{ marginTop: "10px" }}>
                        This policy is for Vsorts Inc. which is a company in the United States in Pennsylvania. Vsorts
                        works all over the world with publishers and advertisers.
                    </p>

                    <Typography style={{ fontSize: "16px", fontWeight: "500", lineHeight: "28px" }}>
                        What We Do
                    </Typography>
                    <p style={{ marginTop: "10px" }}>
                        Vsorts’s principal goal in processing your private information is to develop content designed to
                        further your business interests. We work and share information with the Hands On Lab at Old
                        Dominion University for the purpose of delivering you the optimal user experience.
                    </p>

                    <Typography style={{ fontSize: "18px", fontWeight: "500", lineHeight: "28px" }}>
                        Compliance
                    </Typography>
                    <p style={{ marginTop: "10px" }}>
                        We develop our policies in a manner informed by the to standards from groups like the Digital
                        Advertising Alliance (DAA) and the European Interactive Digital Advertising Alliance (EDAA).
                    </p>

                    <Typography style={{ fontSize: "18px", fontWeight: "500", lineHeight: "28px" }}>
                        Types of Users and Data Collection
                    </Typography>
                    <Typography style={{ fontSize: "16px", fontWeight: "500", lineHeight: "28px", marginTop: "10px" }}>
                        Users
                    </Typography>
                    <p style={{ marginTop: "10px" }}>
                        If you visit Vsorts or any a website or app using Vsorts’s technology, you are a
                        &quot;User.&quot; You might see personalized suggestions on what to read or watch. We collect
                        data through such methods as cookies and other tracking tools.
                    </p>

                    <Typography style={{ fontSize: "16px", fontWeight: "500", lineHeight: "28px" }}>
                        Data We Collect and Why
                    </Typography>
                    <p style={{ marginTop: "10px" }}>
                        • Cookie information in a token called IdToken. This token merely holds identification
                        information related to the user’s computer to enable the user to stay logged in.
                    </p>
                    <p>
                        • VSorts has at the heart of its company mission the use of vignettes to advance cultural
                        awareness among members of organizations and the public at large. To that end, information about
                        users is necessary information to the goals of our organization. Users’ Name, Email address,
                        Education Level, Income, Sexual Orientation, Political Orientation, Age Race, Gender, and
                        Location are collected voluntarily from users.
                    </p>

                    <Typography style={{ fontSize: "16px", fontWeight: "500", lineHeight: "28px" }}>
                        Data Protection and Moving Data
                    </Typography>
                    <p style={{ marginTop: "10px" }}>
                        Vsorts uses very strict standards and takes care to keep your personal info safe. Access to
                        personal data is limited to employees whose role requires such access to carry out. When data
                        leaves the European Economic Area (EEA), we follow international rules for such transfers.
                    </p>

                    <Typography style={{ fontSize: "16px", fontWeight: "500", lineHeight: "28px" }}>
                        Kids and Sensitive Info
                    </Typography>
                    <p style={{ marginTop: "10px" }}>
                        Our services are not for children under the age 16. We do not intentionally collect information
                        from them. If it happens by accident, it is expeditiously deleted.
                    </p>

                    <Typography style={{ fontSize: "16px", fontWeight: "500", lineHeight: "28px" }}>Access</Typography>
                    <p style={{ marginTop: "10px" }}>
                        We employ individuals in a position known as “Super Administrator”. These individuals are
                        charged with site maintenance and development. They are not employed by the commercial/sales arm
                        of the business. Consistent with the goal of maintaining privacy and security, these individuals
                        have complete access to all information collected by VSorts, private and otherwise for purposes
                        of maintenance and development. Any personal data used for any commercial purpose will be
                        de-identified.
                    </p>

                    <Typography style={{ fontSize: "16px", fontWeight: "500", lineHeight: "28px" }}>
                        Privacy Rights for EU and California Users
                    </Typography>
                    <p style={{ marginTop: "10px" }}>
                        People in the European Union or California have extra rights under GDPR and CCPA. You have the
                        right to receive your collected data, delete it, or stop its sale. If you are in California,
                        contact us for specific details on how Vsorts uses and shares your data.
                    </p>

                    <Typography style={{ fontSize: "16px", fontWeight: "500", lineHeight: "28px" }}>
                        Policy Changes
                    </Typography>
                    <p style={{ marginTop: "10px" }}>
                        We reserve the right to change this Privacy Policy as needed. Any updates will appear on our
                        website. Please return at regular intervals to see the latest version.
                    </p>

                    <Typography style={{ fontSize: "16px", fontWeight: "500", lineHeight: "28px" }}>Contact</Typography>
                    <p style={{ marginTop: "10px" }}>
                        Questions? Email us at privacy@vsorts.com or call 610-808-7067. You can also write to us at
                        VSorts™ AI, 24 Veterans Square Media PA 19063.
                    </p>
                    <p>
                        For additional support or for knowledge of your privacy rights, contact the regulator in your
                        area such as the Information Commissioner’s Office in the UK.
                    </p>
                </div>
            ),
        },
        {
            id: "3",
            heading: "| Cookies",
            text: (
                <div>
                    <Typography style={{ fontSize: "18px", fontWeight: "500", lineHeight: "28px" }}>
                        1. Introduction
                    </Typography>
                    <p style={{ marginTop: "10px" }}>
                        VSorts uses cookies to improve user experience. For simplicity, we use &quot;cookies&quot;
                        broadly to refer not only to Cookies but also other similar tools such as web beacone. This
                        policy explains how we use cookies. All users of our platform are subject to this policy.
                    </p>

                    <Typography style={{ fontSize: "18px", fontWeight: "500", lineHeight: "28px" }}>
                        2. What Are Cookies?
                    </Typography>
                    <p style={{ marginTop: "10px" }}>
                        A cookie is a file placed on devices when they visit website pages. It gets stored on your
                        device through your browser. The information kept in the file returns to our servers or others
                        you have connections with when you visit again.
                    </p>

                    <Typography style={{ fontSize: "18px", fontWeight: "500", lineHeight: "28px" }}>
                        3. Consent
                    </Typography>
                    <p style={{ marginTop: "10px" }}>
                        By using the VSorts Platform, you consent to our use of the idToken cookie, described below.
                    </p>
                    <p>
                        When you first visit our website we use a pop-up explaining our use of cookies are. Some cookies
                        are necessary to operate our platform. Otherwise, you can use the pop-up to refuse non-necessary
                        cookies access to your device. If you want no cookies at all you may attempt blocking them in
                        your browser. Doing so this might impair platform performance.
                    </p>
                    <p>
                        **Current Cookie Policy Consent Popup Language: We use cookies to facilitate your convenience
                        while using our platform. By selecting “Accept All” you consent to use our cookies.
                    </p>
                    <p>
                        **Future Cookie Policy Consent Popup Language: We use cookies to facilitate your convenience
                        while using our platform, deliver personalized content to you such as advertisements, and
                        analyze our site traffic.
                    </p>
                    <p>By selecting “Accept All” you consent to use our cookies.</p>

                    <Typography style={{ fontSize: "18px", fontWeight: "500", lineHeight: "28px" }}>
                        4. Cookies We Use
                    </Typography>
                    <p style={{ marginTop: "10px" }}>
                        VSorts uses cookies to store a single piece of information—an idToken, which is the user&apos;s
                        login token provided by the Auth0 authentication/authorization system. This ensures that users
                        can access the website without needing to log in repeatedly.
                    </p>
                    <p>
                        Necessary Cookies: These cookies are needed for simple site such as navigating the site and
                        maintaining security. Without them, the website will not function properly. The idToken cookie
                        is a necessary cookie.
                    </p>

                    <Typography style={{ fontSize: "18px", fontWeight: "500", lineHeight: "28px" }}>
                        5. Your Rights Regarding Personal Information
                    </Typography>
                    <p style={{ marginTop: "10px" }}>
                        You have certain rights over your personal information including:
                    </p>
                    <ul>
                        <li>You may ask to see the information we know about you.</li>
                        <li>You may ask us to stop using your personal information.</li>
                        <li>You may ask to get a full list of your data which we have.</li>
                        <li>
                            You may ask us to correct wrong information, delete old information, or limit what we do
                            with your data.
                        </li>
                    </ul>
                    <p>
                        To exercise these rights, check our Privacy Policy.Or contact us using our contact information,
                        listed below.
                    </p>

                    <Typography style={{ fontSize: "18px", fontWeight: "500", lineHeight: "28px" }}>
                        6. Enabling, Disabling, and Deleting Cookies
                    </Typography>
                    <p style={{ marginTop: "10px" }}>
                        You may control cookies in your browser settings, either automatically or by hand. You can also
                        set your browser to alert you when a cookie is used. See your browser’s help section for tips on
                        handling cookies.
                    </p>

                    <Typography style={{ fontSize: "18px", fontWeight: "500", lineHeight: "28px" }}>
                        7. Opt Out of Data Sharing
                    </Typography>
                    <p style={{ marginTop: "10px" }}>
                        You may opt out of data sharing at your will. To requests the cessation of your data sharing,
                        email us at privacy@Vsorts.com.
                    </p>

                    <Typography style={{ fontSize: "18px", fontWeight: "500", lineHeight: "28px" }}>
                        8. Contact Details
                    </Typography>
                    <p style={{ marginTop: "10px" }}>
                        Any questions about privacy or cookies, or how we use your data? Contact us by
                    </p>
                    <ul>
                        <li>Email: privacy@vsorts.com</li>
                        <li>Phone: 610-808-7067</li>
                        <li>Mailing Address: VSorts™</li>
                    </ul>
                    <p>
                        24 Veterans Square <br></br> Media PA 19063
                    </p>

                    <Typography style={{ fontSize: "18px", fontWeight: "500", lineHeight: "28px" }}>
                        9. Personal Information Our cookies collect
                    </Typography>
                    <p style={{ marginTop: "10px" }}>None</p>

                    <Typography style={{ fontSize: "18px", fontWeight: "500", lineHeight: "28px" }}>
                        10. Sharing of Information Stored in cookies
                    </Typography>
                    <p style={{ marginTop: "10px" }}>
                        By assenting to Vsorts’s use of cookies on your device, you authorize VSorts to share collected
                        personal information. VSorts may share user personal information to such types of third parties
                        as:
                    </p>
                    <ul>
                        <li>Auth0</li>
                        <li>Stripe</li>
                        <li>Camio</li>
                    </ul>
                </div>
            ),
        },
        {
            id: "4",
            heading: "| Artificial Intelligence Disclaimer",
            text: (
                <div>
                    <p style={{ marginTop: "10px" }}>
                        Artificial Intelligence (AI) Generated Content Disclaimer for Vsorts
                    </p>
                    <p>
                        Vsorts embraces the responsible use of AI. There are many ways to use AI to produce quality
                        content and also to automate a lot of work necessary for the goals of our users.
                    </p>
                    <p>
                        This content and other content on our site that originates from AI or other automated
                        technologies is solely for informational purposes. This content shouldn’t be used while making
                        decisions in specific contexts or as legal advice without verifying its accuracy or
                        completeness. Vsorts could use AI in many features and functions on our website. AI, however,
                        isn’t error-free. For instance, AI could make mistakes due processing incorrect inputs.
                    </p>
                    <p>
                        We try to maximize the quality but we are not able to guarantee the accuracy of the AI created
                        content. Therefore, Vsorts disclaims all liability for the accuracy, completeness, or
                        availability of the content generated by AI. Vsorts encourages users to review all content with
                        the greatest degree of caution.
                    </p>
                    <p>
                        Any AI-generated content is a recitation of prior human expertise and inputs, and user error
                        (and other unknown factors) will reduce the fidelity of any AI generated answer.
                    </p>
                    <p>
                        By using our site, you acknowledge that neither Vsorts nor its affiliates or its licensors are
                        liable for any subsequent actions you take in reliance upon AI-generated information from our
                        platform.
                    </p>
                </div>
            ),
        },
        {
            id: "5",
            heading: "| End User License Agreement (EULA)",
            text: (
                <div>
                    <p style={{ marginTop: "10px" }}>
                        Please read this. This agreement (&quot;EULA&quot;) rules how you use Vsorts. It includes the
                        vignettes, updates, and services from Vsorts or third parties (all called &quot;the
                        platform&quot;). (also called &quot;VSORTS&quot;).
                    </p>
                    <p>
                        By using or installing the platform, you accept this EULA and the Vsorts Privacy Policy (located
                        here on our site). If you do not accept these rules, do not access or use the platform.
                    </p>
                    <p>If you have a Vsorts account, this EULA and Terms of Use also apply.</p>
                    <p>Vsorts may change any part of this EULA whenever it wants, as explained in section 8.</p>

                    <Typography style={{ fontSize: "18px", fontWeight: "500", lineHeight: "28px" }}>
                        1. Permission to Use
                    </Typography>
                    <p style={{ marginTop: "10px" }}>
                        1.1 As long as this EULA stays in effect, Vsorts gives you a personal, non-exclusive,
                        transferable license. This allows you to install and use the platform (including updates). You
                        are not allowed to use the platform for business unless you obtain a special license from
                        Vsorts. The platform is given for use, not sold to you. Some parts of the platform may use
                        services from others, which might come with extra rules or charges. Be sure to look over and
                        accept those rules before using such parts.
                    </p>
                    <p>
                        1.2 You cannot: (i) sell, rent, or otherwise commercially dispose of Vsorts intellectual
                        property; (ii) try to break down, reverse, or rework the platform without direct permission;
                        (iii) hack the platform; (iv) change or remove any copyright or trademark marks; (v) operate the
                        platform to where rules do not allow it.
                    </p>
                    <p>
                        1.3 You agree to follow all laws and rules when using the platform. Vsorts can give instructions
                        on proper platform usage. These instructions are subject to change at Vsorts’s will.
                    </p>

                    <Typography style={{ fontSize: "18px", fontWeight: "500", lineHeight: "28px" }}>
                        2. Rights
                    </Typography>
                    <p style={{ marginTop: "10px" }}>
                        All ideas and copyrights related to the platform belong to Vsorts or those who gave Vsorts the
                        right to use them. This platform has national and global protection. No ownership interests in
                        any intellectual property passes to users of the platform by use of the platform.
                    </p>

                    <Typography style={{ fontSize: "18px", fontWeight: "500", lineHeight: "28px" }}>
                        3. Using and Accessing
                    </Typography>
                    <p style={{ marginTop: "10px" }}>
                        3.1 Extra fees could happen when using the Platform on a mobile device, like fees from your
                        phone company. Vsorts is not responsible for bad performance caused by network problems or
                        problems with your mobile device.
                    </p>

                    <Typography style={{ fontSize: "18px", fontWeight: "500", lineHeight: "28px" }}>
                        4. Monitoring
                    </Typography>
                    <p style={{ marginTop: "10px" }}>
                        If you use this platform, you agree that Vsorts may monitor the platform usage to check for
                        unauthorized use. If Vsorts finds any unauthorized or illegal usage, this Agreement and your
                        usage rights could end.
                    </p>

                    <Typography style={{ fontSize: "18px", fontWeight: "500", lineHeight: "28px" }}>
                        5. No Promises and Limits on Responsibility
                    </Typography>
                    <p style={{ marginTop: "10px" }}>
                        THE PLATFORMS ARE PROVIDED &quot;AS IS&quot; WITHOUT ANY PROMISES, GUARANTEES, OR WARRANTIES.
                        VSORTS GIVES NO GUARANTEES OR TAKES BLAME FOR ANY DAMAGES FROM DEFECTS IN THE PLATFORM.
                    </p>

                    <Typography style={{ fontSize: "18px", fontWeight: "500", lineHeight: "28px" }}>
                        6. Your Responsibility
                    </Typography>
                    <p style={{ marginTop: "10px" }}>
                        You agree to take responsibility and cover any costs Vsorts has from you breaking this EULA
                        including but not limited to : attorney fees, consequential damages, incidental damages, and
                        other costs not contemplated in this EULA.
                    </p>

                    <Typography style={{ fontSize: "18px", fontWeight: "500", lineHeight: "28px" }}>
                        7. License Termination
                    </Typography>
                    <p style={{ marginTop: "10px" }}>
                        This EULA lasts only so long as either the user or Vsorts Permits. You can end it by removing
                        the cessation of use of our platform. Vsorts can end the EULA anytime for any reason. When the
                        EULA ends, you may no longer utilize any Vsorts platform for any reason.
                    </p>

                    <Typography style={{ fontSize: "18px", fontWeight: "500", lineHeight: "28px" }}>
                        8. EULA Updates
                    </Typography>
                    <p style={{ marginTop: "10px" }}>
                        Vsorts may change this EULA whenever it decides to. These changes count right away when they’re
                        posted. If you keep using the Platform after the change, you accept the new rules.
                    </p>

                    <Typography style={{ fontSize: "18px", fontWeight: "500", lineHeight: "28px" }}>
                        9. Choice of Laws Clause
                    </Typography>
                    <p style={{ marginTop: "10px" }}>
                        This EULA follows the laws of Pennsylvania. Any conflicts will go to arbitration or Pennsylvania
                        courts. If you need clarifications about this EULA, contact Vsorts at help@vsorts.com.
                    </p>
                </div>
            ),
        },
    ];
    const drawer = (
        <div>
            <List>
                {["Terms & Conditions", "Privacy Policy", "Cookies", "Artificial Intelligence Disclaimer", "Eula"].map(
                    (text, index) => (
                        <ListItem
                            key={text}
                            disablePadding
                            onClick={() => {
                                onScroll(text);
                            }}
                        >
                            <ListItemButton>
                                <ListItemText
                                    primary={text}
                                    style={{
                                        color: index == activeTab ? "#29abe2" : "black",
                                        fontWeight: "500",
                                    }}
                                />
                            </ListItemButton>
                        </ListItem>
                    )
                )}
            </List>
        </div>
    );

    // Remove this const when copying and pasting into your project.
    const container = window !== undefined ? () => window().document.body : undefined;

    return (
        <Box sx={{ display: "flex" }}>
            <Box component="nav" sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 }, height: "85vh" }}>
                <Drawer
                    variant="permanent"
                    sx={{
                        "& .MuiDrawer-paper": {
                            // marginTop: "80px",
                            zIndex: "0",
                            border: "none",
                            width: drawerWidth,
                            position: "relative",
                        },
                    }}
                    style={{ paddingTop: "100px" }}
                    open
                >
                    {drawer}
                </Drawer>
            </Box>
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    p: 3,
                    marginTop: "90px",
                    backgroundColor: "#fff",
                    // height: "100vh",
                    // overflow: "auto",
                    width: { sm: `calc(100% - ${drawerWidth}px)` },
                }}
            >
                {content?.map((eachContent: any, index) => {
                    return (
                        <div
                            ref={
                                index == 0
                                    ? termsAndConditionsRef
                                    : index == 1
                                    ? privacyPolicyRef
                                    : index == 2
                                    ? cookiesRef
                                    : index == 3
                                    ? aiDisclaimerRef
                                    : eulaRef
                            }
                        >
                            <Typography
                                style={{
                                    color: "#29abe2",
                                    fontSize: "24px",
                                    fontStyle: "normal",
                                    marginTop: "30px",
                                    marginBottom: "30px",
                                    fontWeight: "1000",
                                    lineHeight: "34px",
                                }}
                            >
                                {eachContent?.heading}
                            </Typography>
                            <div>{eachContent?.text}</div>
                        </div>
                    );
                })}
            </Box>
        </Box>
    );
}
