import {
    MaximumUnpackingCountSelector,
    OpenDialogSelector,
    UnpackedVignetteCountSelector,
    setMaximumUnpackingCount,
    setMinimumUnpackingCount,
    setOpenDialog,
    setUnpackedVignetteCount,
} from "app/slices/UnpackingSlice";
import CircularLoadingProgress from "components/CircularProgress";
import { VSetParameterTypes } from "constants/enum.constants";
import { useGetVSetFlaggedResponses, useGetVSetMinMaxUnpackingCount } from "hooks/VSortsContainerData";
import NetworkError from "pages/error/networkError";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Unpacking from "../unpackingQuestions/index";
import ParkingLotVignettes from "./parkingLotVignettes";

export default function ParkingLot({ container_id, cluster_id, user_id, cluster_step_id }: any) {
    const dispatch = useDispatch();

    const [availableVignettes, setAvailableVignettes] = useState(0);
    const [vignetteSelected, setVignetteSelected] = useState<any>({});

    const unpackedVignetteCount = useSelector(UnpackedVignetteCountSelector);
    const openUnpacking = useSelector(OpenDialogSelector);
    const maximumUnpackingCount = useSelector(MaximumUnpackingCountSelector);

    const onVSetResponsesDataFetchSuccess = (data: any) => {
        let count = 0;
        let total = 0;
        (data || []).map((eachDomain: { [x: string]: any[] }) => {
            total = total + eachDomain["vignettes"].length;
            eachDomain["vignettes"].map((eachVignette) => {
                if (eachVignette?.unpacked == 1) {
                    count = count + 1;
                }
            });
        });
        dispatch(setUnpackedVignetteCount(count));
        setAvailableVignettes(total);
    };
    const onVSetResponsesDataFetchError = () => {
        dispatch(setUnpackedVignetteCount(0));
    };

    const {
        isFetching: isVSetsResponsesDataFetching,
        isLoading: isVsetsResponsesDataLoading,
        isError: isVSetsResponsesDataError,
        data: allVSetsResponsesData,
        error: vsetResponesError,
        refetch: refetchVSetsResponsesData,
    } = useGetVSetFlaggedResponses({
        container_id: container_id,
        cluster_id: cluster_id,
        user_id: user_id,
        isEnabled: true,
        onSuccess: onVSetResponsesDataFetchSuccess,
        onError: onVSetResponsesDataFetchError,
    });

    const onVSetParameterDataFetchSuccess = (data: any) => {
        (data || []).map((eachParam: { parameter_text: any; value: any }) => {
            if (eachParam.parameter_text == VSetParameterTypes.minUnpacking) {
                dispatch(setMinimumUnpackingCount(eachParam.value));
            } else if (eachParam.parameter_text == VSetParameterTypes.maxUnpacking) {
                dispatch(setMaximumUnpackingCount(eachParam.value));
            }
        });
    };

    const onVSetParameterDataFetchError = () => {
        dispatch(setUnpackedVignetteCount(0));
    };

    const {
        isFetching: isVSetsParameterDataFetching,
        isLoading: isVSetsParameterDataLoading,
        isError: isVSetsParameterDataError,
        data: vsetParameterData,
        error: vsetParameterError,
        refetch: refetchVSetsParameterData,
    } = useGetVSetMinMaxUnpackingCount({
        container_id: container_id,
        isEnabled: true,
        onSuccess: onVSetParameterDataFetchSuccess,
        onError: onVSetParameterDataFetchError,
    });

    if (isVsetsResponsesDataLoading || isVSetsResponsesDataFetching) {
        return <CircularLoadingProgress />;
    }

    if (isVSetsParameterDataLoading || isVSetsParameterDataFetching) {
        return <CircularLoadingProgress />;
    }

    // We check if there is an error present while fetching all Questions of a Survey and display `NetworkError`
    if (isVSetsResponsesDataError) {
        return <NetworkError handleOnRefresh={refetchVSetsResponsesData} errorText={vsetResponesError} />;
    }

    if (isVSetsParameterDataError) {
        return <NetworkError handleOnRefresh={refetchVSetsParameterData} errorText={vsetParameterError} />;
    }
    const openUnpackingDialog = (vignette: any) => {
        if (vignette?.unpacked == 0 && unpackedVignetteCount < maximumUnpackingCount) {
            setVignetteSelected(vignette);
            dispatch(setOpenDialog(true));
        }
    };
    return (
        <>
            {openUnpacking ? (
                <Unpacking
                    selectedValue={openUnpacking}
                    vignette={vignetteSelected}
                    open={openUnpacking}
                    container_id={container_id}
                    cluster_id={cluster_id}
                    user_id={user_id}
                    cluster_step_id={cluster_step_id}
                    onClose={openUnpackingDialog}
                    // refetchFlaggedData={refetchFlaggedData}
                />
            ) : (
                <ParkingLotVignettes
                    data={allVSetsResponsesData}
                    container_id={container_id}
                    cluster_id={cluster_id}
                    user_id={user_id}
                    cluster_step_id={cluster_step_id}
                    refetchVSetsResponsesData={refetchVSetsResponsesData}
                    availableVignettes={availableVignettes}
                    unpackedVignetteCount={unpackedVignetteCount}
                    setVignetteSelected={setVignetteSelected}
                    openUnpackingDialog={openUnpackingDialog}
                />
            )}
        </>
    );
}
