import { MenuItem, Select, Typography } from "@mui/material";
import { CSSProperties } from "react";
import uuid from "react-uuid";
import styles from "./styles";

export type PropsType = {
    options: OptionType[];
    onChange: (value: any) => void;
    selectedValue?: any;
    placeholder?: string;
    style?: CSSProperties;
    readOnly?: boolean;
};

export type OptionType = {
    value: any;
    text: string;
};

const AdminSelect = ({ options, onChange, selectedValue, placeholder, style = {}, readOnly = false }: PropsType) => {
    const handleOnChange = (value: string) => {
        onChange(value);
    };

    return (
        <>
            <Select
                value={selectedValue}
                onChange={(e) => handleOnChange(e.target.value)}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
                fullWidth
                sx={[styles.dropdown, style]}
                placeholder={placeholder}
                readOnly={readOnly}
            >
                {placeholder && (
                    <MenuItem key={uuid()} value={""} disabled selected>
                        <Typography sx={{ color: "grey", fontStyle: "italic"}}>{placeholder}</Typography>
                    </MenuItem>
                )}
                {options.map((option) => {
                    return (
                        <MenuItem key={uuid()} value={option.value}>
                            {option.text}
                        </MenuItem>
                    );
                })}
            </Select>
        </>
    );
};

export default AdminSelect;
