const styles = {
    container: {
        display: "flex",
        flexDirection: "row",
        gap: "1rem",
        justifyContent: "space-between",
        alighItems: "center",
        backgroundColor: "#fff",
        padding: "15px",
        borderRadius: "10px",
        //marginBottom: '0.8rem',
    },
    summaryContainer: {
        display: "flex",
        flexDirection: "row",
        gap: "0.6rem",
        padding: "0.6rem 0",
    },
    badge: {
        color: "#009245",
        border: "1px solid #009245",
    },
};

export default styles;
