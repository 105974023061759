const styles = {
    container: {
        display: "flex",
        flexDirection: "row",
        gap: "1rem",
        justifyContent: "space-between",
        alignItems: "center",
        backgroundColor: "#fff",
        padding: "15px 30px",
        borderRadius: "10px",
        marginBottom: "1.2rem",
    },
    blueContainer: {
        backgroundColor: "#29ABE2",
        color: "white",
        padding: "15px 30px",
        borderRadius: "10px",
        marginBottom: "1.2rem",
    },
    selectStyles: {
        flexGrow: 1,
    },
    titleText: {
        fontSize: "1em",
        fontWeight: "bold",
    },
};

export default styles;
