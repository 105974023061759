import Typography from "@mui/material/Typography";

// type FontSize = 'sm' | 'md' | 'lg' | 'xlg'
const FONT_SIZE: any = {
    sm: 12,
    md: 14,
    lg: 20,
    xlg: 26,
};

const VSTypography = ({ size = "md", sx, children, ...rest }: any) => {
    return (
        <Typography sx={{ fontSize: FONT_SIZE[size], ...sx }} {...rest}>
            {children}
        </Typography>
    );
};

export default VSTypography;
