const container = {
  display: "flex",
  flexDirection: "row",
  gap: "1rem",
  justifyContent: "space-between",
  alighItems: "center",
  backgroundColor: "#fff",
  padding: "15px",
  borderRadius: "10px",
  marginBottom: "1.2rem",
};

const cardContainer = {
  ...container,
  flexWrap: "wrap",
  alignItems: "stretch",
};

const itemLayout = () => ({
  flex: "0 1 30%",
  cursor: "pointer",
  marginBottom: "30px",
  maxWidth: '22%',
  marginTop: "15px",
});

const item = (isEnabled: boolean, isSelected: boolean, hoverColor: string, bgcolor: string) => ({
  borderRadius: "20px",
  flex: 1,
  background: isSelected ? `var(--VS-Background, ${bgcolor})` : isEnabled ? "#fff" : "",
  color: isSelected ? hoverColor : isEnabled ? "#94A8B0" : "#E4E4E4",
  border: isSelected ? `1px solid ${hoverColor}` : isEnabled ? "1px solid #E3E8E8" : "1px solid #E4E4E4",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  textAlign: "center",
  height: `100px`,
  ':hover': isEnabled ? {
    color: hoverColor,
    border: `2px solid ${hoverColor}`,
  } : {
    cursor: "not-allowed"
  }
});

const typographyStyle = () => ({
  flexGrow: 1,
  display: "flex",
  alignItems: "center",
  padding: "15px",
});

const styles = { container, cardContainer, item, typographyStyle, itemLayout };

export default styles;
