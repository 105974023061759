// Form Elements
import ParameterStepItem from "pages/admin/dashboard/VSortsContainer/VSetParameters/ParameterSteps";

// `EditElements` is the function to show the user the Form Elements in Editor Mode
// 1. item -> Represents the Form Item Object (Consisting of all properties)
// 2. handleAutocompleteInputValue -> Represents the function to handle Auto-Complete Data (VSorts Container, Pre-Survey, Post-Survey and Final Feedback)
// 3. handleValue -> Represents the function to handle the TextInput (Video) for the Form Question
// 4. deleteEl -> Represents the function to delete a Form Element
// 5. handleElType -> Represents the function to handle the current type of Form Element
// 6. formElementTypeToAutocompleteDataMapping -> Represents the function to map the auto-complete data to (item.form_type)
export const ParameterElements = ({
    item,
    handleAutocompleteInputValue,
    deleteEl,
    handleElType,
    handler,
    handleParamValueChange
}: any) => {
    switch (item.form_type) {
        default:
            return (
                <ParameterStepItem
                    handler={handler}
                    item={item}
                    deleteEl={deleteEl}
                    handleElType={handleElType}
                    key={item.id}
                    handleParamValueChange={handleParamValueChange}
                />
            );
    }
};
