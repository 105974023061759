// QueryKeyConstants -> Represents all the keys for different queries (GET Requests) made across the application
export const QueryKeyConstants: any = {
    // VSorts Cluster key
    fetchVSortsClusterQueryKey: "fetch-all-vsorts-clusters",
    fetchClusterDetailsKey: "fetch-cluster-details",

    // Survey Query Key (Pre-Survey, Post-Survey and Final Feedback)
    // Survey Items
    fetchSurveyQueryKey: "fetch-all-surveys",

    // Survey Questions
    fetchSurveyQuestionsQueryKey: "fetch-survey-questions",
    fetchUserSurveyQuestionsQueryKey: "fetch-survey-questions",
    fetchSurveyQuestionsPrevNextQueryKey: "fetch-survey-prev-next-sections",

    // Survey Responses
    fetchSurveyResponsesQueryKey: "fetch-survey-responses",
    fetchuserFormResponsesData: "fetch-user-survey-responses",

    // VSet Questions
    fetchVSetQuestionsQueryKey: "fetch-VSet-questions",

    fetchFormScoreKey: "fetch-form-score-key",
    fetchScoreParameters: "fetch-score-parameters",

    // VSet Responses
    fetchVSetUserResponsesQueryKey: "fetch-VSet-User-Responses",
    fetchAllVSetUserResponsesQueryKey: "fetch-All-VSet-User-Responses",

    // VSet Flagged Responses
    fetchFlaggedVSetUserResponsesQueryKey: "fetch-Flagged-VSet-User-Responses",

    // Unpacking Questions
    fetchGetUnpackingQuestionsQueryKey: "fetch-Unpacking-Questions",
    fetchGetUnpackingResponsesQueryKey: "fetch-Unpacking-Responses",
    completeUnpackingStepQueryKey: "complete-Unpacking-Step",

    // Scoring Section
    fetchGetScoringQueryKey: "fetch-Scoring-Section",

    // VSorts Container
    fetchVSortsContainerQueryKey: "fetch-all-vsorts-containers",
    fetchVSortsContainerDataQueryKey: "fetch-vsorts-container",
    fetchVSetDetails: "fetch-vset-details",

    // // VSorts Container Category
    // fetchVSortsContainerCategoriesQueryKey: "fetch-all-vsorts-containers-categories",

    // VSorts Cluster
    fetchVSortsClusterAutocompleteQueryKey: "fetch-vsorts-cluster-autocomplete",
    fetchVSortsClusterSteps: "fetch-vsorts-cluster-steps",

    // VSorts Cluster Admin
    fetchAdminVSortsClusterSteps: "fetch-admin-vsorts-cluster-steps",

    fetchUserVSortsClusterSteps: "fetch-user-vsorts-cluster-steps",

    // VSets Categories
    fetchVSetsCategories: "fetch-VSets-Categories",
    fetchVSetsContainerCategories: "fetch-VSets-Container-Categories",

    // VSet Activity Types
    fetchVsetActivityTypes: "fetch-vset-activities",

    // VSets Container Parameter Data
    fetchVSetsContainerParameters: "fetch-VSets-Container-Parameters",
    deleteVSetsContainerParameters: "delete-VSets-Container-Parameters",
    fetchVSetsAllParameters: "fetch-VSets-All-Parameters",
    fetchVSetsMinMaxUnpackingParameters: "fetch-VSets-MinMaxUnpacking-Parameters",

    // User List data
    fetchAllUsersQuery: "fetch-all-users",

    // email sending feature
    sendEmail: "send-email",

    // to fetch all the vignettes that have been scored in a cluster
    allScoredVignettes: "all-scored-vignettes",

    // view each vignette score
    viewEachVignetteScore: "view-each-vignette-score",

    // get evaluated vignette items
    fetchVSetReactionInformation: "fetch-vset-reaction-information",

    fetchVSetReactionNextStep: "fetch-vset-reaction-nextstep",

    getProfile: "user/profile",
    getPermissions: "user-permissions",
    getAssignRoles: "assign-roles",

    fetchOrganizationList: "fetch-organization-list",
    fetchAllDemographics: "fetch-all-demographics-options",
    fetchUserDemographics: "fetch-user-demographics",
    fetchAllCoPilots: "fetch-all-copilots",
    fetchSpecificCoPilots: "fetch-specific-copilots",
    callAICoPilot: "call-ai-copilots",
    fetchPreviousEntries: "fetch-previous-entries",
    fetchCoPIlotQueryCount: "fetch-copilot-query-count",
    fetchAdminCoPilotList: "fetch-admin-copilot-list",
    createCoPilotTitle: "create-copilot-title",
    fetchCoPilotEngines: "fetch-copilot-engines",
    fetchCoPilotIsPublicStatus: "fetch-copilot-ispublic-status",
    deployCoPilot: "deploy-copilot",
    fetchAdminCoPilotResponses: "fetch-admin-copilot-responses",
    fetchSubscriptionDetails: "fetch-subscription-details",
    fetchOrganizations: "fetch-organizations",
    fetchAssignList: "fetch-assign-list",
    fetchAllOrganizations: "fetch-all-organizations",
};
