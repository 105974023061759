import { useState } from "react";

import { Grid } from "@mui/material";
import VSButton from "components/VSButton";
import LinearProgressComponent from "pages/user-portal/vSets/Components/LinearProgress";
import { useNavigate } from "react-router-dom";
import styles from "./styles";

const StepperProgressBar = ({ totalSteps, order, handleNext, handleBack, nextText, loading }: any) => {
    const [activeStep, setActiveStep] = useState(order);
    const navigate = useNavigate();

    const handleNextClick = () => {
        if (handleNext) {
            handleNext(activeStep + 1);
        } else {
            setActiveStep((prevActiveStep: number) => prevActiveStep + 1);
        }
    };

    const handleBackClick = () => {
        if (handleBack) {
            handleBack(activeStep - 1);
        } else {
            setActiveStep((prevActiveStep: number) => prevActiveStep - 1);
        }
    };

    const handleExit = () => {
        navigate("/dashboard");
    };

    return (
        <Grid
            container
            sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                paddingLeft: "40px",
                paddingRight: "40px",
            }}
        >
            <Grid item xs={7} sx={{ display: "flex", justifyContent: "center" }}>
                <LinearProgressComponent
                    totalItems={totalSteps}
                    totalLength={order}
                    totalProgress={(order / totalSteps) * 100}
                />
            </Grid>
            <Grid>
                <VSButton
                    variant="contained"
                    sx={{ ...styles.btnExit, boxShadow: "none" }}
                    // onClick={handleBackClick}
                    onClick={handleExit}
                    disabled={activeStep === 0 || loading}
                >
                    Exit
                </VSButton>
                &nbsp;&nbsp;&nbsp;
                <VSButton
                    variant="contained"
                    sx={{ ...styles.btnContinue, boxShadow: "none" }}
                    onClick={handleNextClick}
                    disabled={loading}
                    loading={loading}
                >
                    Next
                </VSButton>
            </Grid>
        </Grid>
    );
};

export default StepperProgressBar;
