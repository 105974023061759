import { useMutation, useQuery, useQueryClient } from "react-query";

// Axios Interceptor
import { request } from "utils/axios-utils";

// Constants
import { APIMethodConstants } from "constants/api.method.constants";
import { NetworkConstants } from "constants/network.constants";
import { QueryKeyConstants } from "constants/query.key.constants";

export const fetchAllVSetsCategories = () =>
    request({ url: NetworkConstants.getVSetsCategories, method: APIMethodConstants.get });

// useGetVSetsCategoriesQuery -> To fetch VSets categories
export const useGetVSetsCategoriesQuery = () => {
    return useQuery(QueryKeyConstants.fetchVSetsCategories, fetchAllVSetsCategories, {
        staleTime: Infinity,
        select: (data) => {
            return data.data.data;
        },
    });
};

const postVSetsCategories = ({ url, data }: any) => {
    return request({ url, method: APIMethodConstants.post, data });
};

// usePostVSetsCategoriesMutation -> To create or update a VSets category
export const usePostVSetsCategoriesMutation = () => {
    const queryClient = useQueryClient();

    return useMutation(
        ({ categoryId, categoryName, activityTypeId }: any) => {
            let url;
            if (categoryId) {
                url = NetworkConstants.updateVSetsCategories + `/${categoryId}`;
            } else {
                url = NetworkConstants.createVSetsCategories;
            }
            return postVSetsCategories({ url, data: { category_name: categoryName, activity_type_id: activityTypeId } });
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries(QueryKeyConstants.fetchVSetsCategories);
            },
        }
    );
};

const deleteVSetsCategories = ({ categoryId }: any) => {
    return request({
        url: NetworkConstants.deleteVSetsCategories + `/${categoryId}`,
        method: APIMethodConstants.post,
    });
};

// useDeleteVSetsCategoriesMutation -> To delete a VSets categories or update a VSets category
export const useDeleteVSetsCategoriesMutation = () => {
    const queryClient = useQueryClient();

    return useMutation(({ categoryId }: any) => deleteVSetsCategories({ categoryId }), {
        onSuccess: () => {
            queryClient.invalidateQueries(QueryKeyConstants.fetchVSetsCategories);
            queryClient.invalidateQueries(QueryKeyConstants.fetchVSetsContainerCategories);
        },
    });
};

const bulkDeleteVSetsCategories = ({ categoryIds }: any) => {
    return request({ 
        url: NetworkConstants.bulkDeleteVSetsCategories, 
        method: APIMethodConstants.post, 
        data: { category_ids: categoryIds },
    });
};

// useBulkDeleteVSetsCategoriesMutation -> To bulk delete a VSets categories or update a VSets category
export const useBulkDeleteVSetsCategoriesMutation = () => {
    const queryClient = useQueryClient();

    return useMutation(({ categoryIds }: any) => bulkDeleteVSetsCategories({ categoryIds }), {
        onSuccess: () => {
            queryClient.invalidateQueries(QueryKeyConstants.fetchVSetsCategories);
            queryClient.invalidateQueries(QueryKeyConstants.fetchVSetsContainerCategories);
        },
    });
};
