// Material UI Components
import TextField from "@material-ui/core/TextField";
import AdapterDateFns from "@material-ui/lab/AdapterDateFns";
import LocalizationProvider from "@material-ui/lab/LocalizationProvider";
import TimePicker from "@material-ui/lab/TimePicker";
import SurveyElementWrapper from "./SurveyElementWrapper";

// Material Drag Icon

// Form Element Type Constant

// Form Element Footer

// `DateInput` is the function to show the user Date Input while creating/editing a Survey Form
// 1. item -> Represents the Form Item Object (Consisting of all properties)
// 2. handleValue -> Represents the function to handle the Text Input value
// 3. deleteEl -> Represents the function to handle deleting the Form Element
// 4. handleRequired -> Represents the function to handle making a Form Element required/not required
// 5. handleElType -> Represents the function to handle the type of the Form Element
// 6. duplicateElement -> Represents the function to handle duplicating the Form Element
const TimeInput = (props: any) => {
    const { item, handleValue, handleTime, index } = props;
    return (
        <SurveyElementWrapper {...props}>
            <TextField
                defaultValue={item.question_text}
                variant="outlined"
                onBlur={(e) => handleValue({ id: item.id, e, index })}
                fullWidth
                required={!!item.is_required}
                placeholder="Time Label"
                sx={{ mb: 2 }}
                multiline
            />
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <TimePicker
                    label="Pick Time"
                    value={item?.time}
                    onChange={(newTime) => handleTime({ id: item.id, dateVal: newTime, index })}
                    renderInput={(params) => <TextField fullWidth {...params} />}
                />
            </LocalizationProvider>
        </SurveyElementWrapper>
    );
};

export default TimeInput;
