import { Box, CssBaseline, IconButton } from "@mui/material";
import TrashIcon from "assets/icons/trash-red.svg";
import Header from "components/Dashboard/Header";
import { Button, Tabs } from "pages/adminV2/Components";
import AdminHeader from "pages/adminV2/Components/AdminHeader";
import React, { useEffect, useRef, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

import { useGetVSetDetails } from "hooks/VSortsContainerTableData";
import DeleteVSetModal from "../modals/DeleteVSet";
import "./CreateVSets.css";
import styles from "./styles";

interface tab {
    name: string;
    url: string;
}

const tabs: tab[] = [
    {
        name: "VSet",
        url: "/v2/admin/vsets/create",
    },
    {
        name: "Parameters",
        url: "/v2/admin/vsets/create/parameters",
    },
    {
        name: "Unpacking",
        url: "/v2/admin/vsets/create/unpacking",
    },
    {
        name: "Assign",
        url: "/v2/admin/vsets/create/assign",
    },
];

const CreateVsetLayout: React.FC = () => {
    const childRef = useRef<any>();
    const [activeTab, setActiveTab] = useState<tab>({
        name: "",
        url: "",
    });

    //const vsetContainer = useSelector(CurrentCreateVsetSelector);
    const location = useLocation();
    const navigate = useNavigate();

    //const { title } = useParams();
    const searchParams = new URLSearchParams(window.location.search);
    const [title, setTitle] = useState("");
    const id = searchParams.get("id");
    const isView = searchParams.get("isView") === "true";
    const [openDeleteVSet, setOpenDeleteVSet] = useState(false);

    const onTabSelect = (tab: tab) => {
        setActiveTab(tab);
        !isView
            ? navigate(`${tab.url}?title=${title}&id=${id}`)
            : navigate(`${tab.url}?title=${title}&id=${id}&isView=${isView}`);
    };

    const handleSaveClick = () => {
        if (childRef.current) {
            if (typeof childRef.current.save === "function") {
                childRef.current.save();
            }
        }
    };

    useEffect(() => {
        refetchVSetDetails();
    }, [activeTab]);

    useEffect(() => {
        const tab: tab = tabs.find((tab) => location.pathname == tab.url) || {
            name: "",
            url: "",
        };

        refetchVSetDetails();
        setActiveTab(tab);
    }, [location.pathname]);

    const handleDeleteClick = () => {
        setOpenDeleteVSet(true);
    };

    const onVSetDetailsFetchSuccess = (data: any) => {
        console.log(data);
        setTitle(data["container_name"]);
        if (childRef.current) {
            if (typeof childRef.current.handleOriginalTitle === "function") {
                childRef.current.handleOriginalTitle(data["container_name"]);
            }
        }
        if (childRef.current) {
            if (typeof childRef.current.handleInitialSubTitleAndDirections === "function") {
                childRef.current.handleInitialSubTitleAndDirections(data["sub_title"], data["directions"]);
            }
        }
    };

    const onVSetDetailsFetchError = (error: any) => {
        console.log(error);
    };
    const {
        isFetching: isVSetDetailsFetching,
        isLoading: isVSetDetailsLoading,
        isError: isVSetDetailsError,
        data: VSetDetailsData,
        error: VSetDetailsError,
        refetch: refetchVSetDetails,
    } = useGetVSetDetails({
        onSuccess: onVSetDetailsFetchSuccess,
        onError: onVSetDetailsFetchError,
        containerID: id,
    });

    const handleTitleChange = (event: any) => {
        setTitle(event.target.value);
        if (childRef.current) {
            if (typeof childRef.current.handleTitle === "function") {
                childRef.current.handleTitle(event.target.value);
            }
        }
    };
    return (
        <Box sx={{ display: "flex", flexDirection: "column", margin: "0rem 3rem" }}>
            <CssBaseline />
            <Header title="Admin Dashbaord" redirectTo="/v2/admin/vsets" />
            <AdminHeader
                title="Admin create vset"
                onIconClick={() => {
                    undefined;
                }}
            />

            <Box sx={styles.container}>
                {/* <IconButton sx={{ width: "42px", height: "42px" }}>
                    <img src={EyeIcon} alt="view" />
                </IconButton> */}

                <input
                    className="titleInput"
                    value={title ?? ""}
                    onChange={handleTitleChange}
                    disabled={
                        window.location.pathname.indexOf("parameters") >= 0 ||
                        window.location.pathname.indexOf("unpacking") >= 0 ||
                        window.location.pathname.indexOf("assign") >= 0
                            ? true
                            : isView
                    }
                />

                {!isView && (
                    <>
                        <Button type="primary" text="Save" onClick={() => handleSaveClick()} />

                        <IconButton sx={{ width: "42px", height: "42px" }} onClick={() => handleDeleteClick()}>
                            <img src={TrashIcon} alt="Delete" />
                        </IconButton>
                    </>
                )}
            </Box>

            {openDeleteVSet && (
                <DeleteVSetModal
                    open={openDeleteVSet}
                    setOpen={setOpenDeleteVSet}
                    selected={{ id: id, "VSet name": title }}
                    refetchVsetData={() => navigate("/v2/admin/vsets")}
                />
            )}

            <Tabs
                tabs={tabs}
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                onTabSelect={onTabSelect}
                enableDataCheck={true}
            />
            <Box component="main" sx={{ flexGrow: 1, p: 3, mt: 20, pb: 50 }}>
                <Outlet context={{ childRef, activeTab }} />
            </Box>
        </Box>
    );
};

export default CreateVsetLayout;
