// StringConstants -> Represents all the Strings used across the applications
export const StringConstants = {
    goToHomePage: "GO TO HOMEPAGE",
    refresh: "REFRESH",

    /// Empty States - START

    // VSorts Container
    vSortsContainerDataNotFilledTitle: "Table has missing entries",
    vSortsContainerDataNotFilledDescription: "Please fill all the information in the table to proceed further",

    /// Empty States - END

    /// Error States - START

    // Locked item
    itemLockedTitle: "This item is locked",
    itemLockedDescription: "Please unlock the item first to proceed further",

    // Page Not Found
    pageNotFoundErrorCode: 404,
    pageNotFoundErrorTitle: "Oops! This Page Could Not Be Found",
    pageNotFoundDescription:
        "Sorry but the page you are looking for does not exist, have been removed. name changed or is temporarily unavailable",

    // Network Error
    networkErrorTitle: "Error connecting to the server",
    networkErrorDescription:
        "There was an error trying to connect to the network. Please check your network connection and try again",

    // Operation Error
    operationErrorTitle: "Couldn't complete operation",
    operationErrorDescription:
        "We couldn't complete your operation. Please try again or report this issue and we'll get this resolved",

    // Default Vignette Text
    vignetteDefaultHeaderText: "Arrange the vignettes from top to bottom",
    vignetteDefaultFooterText: "The singular pronouns: 'their' or 'they' are used as gender-neutral pronouns",
    /// Error States - END

    // Email notification
    emailSuccessTitle: "Success",
    emailSuccess: "The email has been sent successfully.",

    emailErrorTitle: "Error",
    emailError: "There was a problem sending email.",

    // unpacking successful
    unpackingSuccessfulTitle: "Success",
    unpackingSuccessful: "Unpacking successfully completed.",

    importUserSuccessfulTitle: "Success",
    importUserSuccessful: "User import successfully completed.",

    importUserErrorTitle: "Error",
    importUserError: "User import failed.",
};
