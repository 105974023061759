import { Box, Typography } from "@mui/material";
import { CurrentUserSelector } from "app/slices/UserSlice";
import { RolesMapping } from "constants/enum.constants";
import { useSelector } from "react-redux";
import styles from "./styles";

type PropsType = {
    title?: string;
    onIconClick: () => void;
};

function AdminHeader({ title = "", onIconClick }: PropsType) {
    const currentUser = useSelector(CurrentUserSelector);
    return (
        <Box sx={styles.titleContainer}>
            {title?.length > 0 ? (
                <Typography sx={styles.title}>{title}</Typography>
            ) : (
                <Typography sx={styles.title}>{RolesMapping[currentUser?.role]}</Typography>
            )}

            <Box sx={styles.flexContainer}>
                {/* <IconButton onClick={() => onIconClick()}>
                    <img src={GridIcon} alt="#" style={styles.actionIcon} />
                </IconButton> */}
            </Box>
        </Box>
    );
}

export default AdminHeader;
