import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import { Typography } from "@mui/material";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import MuiAccordionSummary, { AccordionSummaryProps } from "@mui/material/AccordionSummary";
import { styled } from "@mui/material/styles";
import "./style.css";

const Accordion = styled((props: AccordionProps) => <MuiAccordion disableGutters elevation={0} {...props} />)(
    ({ theme }) => ({
        border: "1px solid #F3F8FB",
        marginBottom: "10px",
        borderRadius: "10px",
        "&::before": {
            display: "none",
        },
        "& .MuiAccordion-root:first-of-type": {
            borderRadius: "10px",
        },
    })
);

const AccordionSummary = styled((props: AccordionSummaryProps) => (
    <MuiAccordionSummary expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />} {...props} />
))(({ theme }) => ({
    backgroundColor: "rgba(0, 0, 0, 0)",
    flexDirection: "row",
    borderRadius: "10px",
    margin: "4px 12px 4px 12px",
    color: "#29ABE2",
    fontWeight: 400,
    "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
        transform: "rotate(90deg)",
    },
    "& .MuiAccordionSummary-content": {
        marginLeft: theme.spacing(1),
    },
    "& .MuiTypography-root": {
        //letterSpacing: "1px",
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: "12px 12px 12px 16px",
    textAlign: "left",
}));

const AccordionDropdown = ({ index, header, children }: any) => {
    return (
        <Accordion key={index}>
            <AccordionSummary>
                <Typography>{header}</Typography>
            </AccordionSummary>
            <AccordionDetails>{children}</AccordionDetails>
        </Accordion>
    );
};

export default AccordionDropdown;
