import MicIcon from "@mui/icons-material/Mic";
import {
    Box,
    Button,
    LinearProgress,
    linearProgressClasses,
    Typography
} from "@mui/material";
import VSParkingTask from "components/VSParkingTask/VSParkingTask";
import { useState } from "react";
import { useSpeechRecognition } from "react-speech-recognition";
import styled from "styled-components";

const BorderLinearProgress: any = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    padding: "5px",
    [`&.${linearProgressClasses.colorPrimary}`]: {
        // backgroundColor:
        //   theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        backgroundColor: "#009245",
    },
}));
const VSUnpackingContainer = () => {
    const {
        transcript,
        listening,
        resetTranscript,
        browserSupportsSpeechRecognition,
    } = useSpeechRecognition();

    const [ques, setQues] = useState(1);

    return (
        <>
            {ques == 1 ? (
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                        background: "#fff",
                        borderRadius: "21px",
                        padding: "10px 20px",
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            width: "100%",
                            alignItems: "center",
                            gap: "1.5rem",
                        }}
                    >
                        <Typography
                            sx={{
                                fontWeight: 400,
                                fontSize: "14px",
                                lineHeight: "19px",
                                display: "flex",
                                alignItems: "center",
                                color: "#929292",
                            }}
                        >
                            1/6 questions
                        </Typography>
                        <BorderLinearProgress
                            variant="determinate"
                            value={16}
                            sx={{ width: "80%" }}
                        />
                    </Box>
                    <Typography
                        sx={{
                            fontSize: "14px",
                            lineHeight: "24px",
                            display: "flex",
                            alignItems: "center",
                            color: "#000000",
                            marginTop: "3rem",
                            marginBottom: "1rem",
                        }}
                    >
                        Explain why the vignette below is considered{" "}
                        <strong style={{ fontWeight: 600 }}>
                            {" "}
                            Cultural Destructiveness.{" "}
                        </strong>
                    </Typography>
                    <VSParkingTask width={"100%"} />
                    <Box sx={{ display: "flex", justifyContent: "end" }}>
                        <Typography
                            sx={{
                                fontWeight: 400,
                                fontSize: "14px",
                                lineHeight: "19px",
                                display: "flex",
                                alignItems: "center",
                                color: "#929292",
                            }}
                        >
                            0/250 characters
                        </Typography>
                    </Box>
                    <textarea
                        style={{
                            background: "#F5F8FB",
                            borderRadius: "32px",
                            border: "none",
                            height: "15rem",
                            padding: "25px",
                        }}
                        placeholder="Type your response here or tap on mic to use voice..."
                        value={transcript}
                    ></textarea>
                    <Box
                        sx={{
                            width: "42px",
                            height: "42px",
                            background: "#94A8B0",
                            borderRadius: "50%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            position: "absolute",
                            left: "90%",
                            bottom: "20%",
                        }}
                        onClick={resetTranscript}
                    >
                        <MicIcon sx={{ color: "#ffff" }} />
                    </Box>
                    <Box
                        sx={{
                            marginTop: "1.5rem",
                            display: "flex",
                            justifyContent: "end",
                            gap: "8px",
                        }}
                    >
                        <Button
                            sx={{
                                padding: "12px 28px",
                                width: "125px",
                                height: "46px",
                                border: "2px solid #94A8B0",
                                color: "#94A8B0",
                            }}
                        >
                            Save
                        </Button>
                        <Button
                            sx={{
                                padding: "12px 28px",
                                width: "125px",
                                height: "46px",
                                border: "2px solid #94A8B0",
                                color: "#94A8B0",
                            }}
                        >
                            Next
                        </Button>
                    </Box>
                </Box>
            ) : (
                <></>
            )}
        </>
    );
};

export default VSUnpackingContainer;