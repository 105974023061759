const coPilotStyle: any = {
    title: {
        fontWeight: "700",
        fontSize: "24px",
        color: "#111111",
        lineHeight: "34px",
    },
    directions: {
        fontWeight: "400",
        fontSize: "18px",
        color: "#111111",
        lineHeight: "28px",
        marginTop: "3vh",
        marginBottom: "3vh",
        textAlign: "center",
    },
    promptMessage: {
        fontWeight: "400",
        fontSize: "14px",
        color: "#111111",
        lineHeight: "24px",
    },
    textSection: {
        marginTop: "3vh",
        width: "100%",
        minHeight: "552px",
        borderRadius: "10px",
        background: "#F8F9FD",
    },
    outputTitles: {
        fontWeight: "700",
        fontSize: "14px",
        color: "#111111",
        lineHeight: "24px",
    },
    vignetteDataDisplay: {
        paddingTop: "10px",
        fontWeight: "400",
        fontSize: "14px",
        color: "#111111",
        lineHeight: "24px",
    },
    submitButton: {
        background: "#C0C0C0",
        color: "white",
        width: "30px",
        height: "30px",
        marginRight: "3px",
    },
    previousEntryTitle: {
        fontSize: "16px",
        fontWeight: "400",
        lineHeight: "24px",
        textAlign: "left",
        color: "#929292",
    },
    eachQueryDiv: {
        paddingTop: "10px",
        paddingLeft: "1vw",
        display: "flex",
        justifyContent: "space-between",
        transition: "20s",
        cursor: "pointer",
    },
    eachQueryTitle: {
        fontSize: "14px",
        fontWeight: "400",
        lineHeight: "24px",
        textAlign: "left",
        color: "#111111",
    },
};
export default coPilotStyle;
