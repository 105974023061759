import { Box } from "@mui/material";
import Header from "components/Dashboard/Header";
import AdminHeader from "pages/adminV2/Components/AdminHeader";
import { Outlet } from "react-router-dom";

const ViewFormLayout = () => {
    return (
        <Box sx={{ display: "flex", flexDirection: "column", padding: "0rem 3rem", background: "#F8F9FD" }}>
            <Header title="Admin Dashboard" redirectTo="/v2/admin/forms"/>
            <AdminHeader
                title="Form"
                onIconClick={() => {
                    undefined;
                }}
            />
            <Box component="main" sx={{ flexGrow: 1, p: 3, mt: 0 }}>
                <Outlet />
            </Box>
        </Box>
    );
};

export default ViewFormLayout;
