import { ClusterUser, User, UserRole } from "types/user";

export const getUserName = (user: User | ClusterUser): string => {
    if (user.first_name || user.last_name) {
        return user.first_name + " " + user.last_name;
    }

    if (user.name) {
        return user.name;
    }

    return user.email;
};

export const getUserRole = (roles: string[]): UserRole => {
    if (roles?.includes(UserRole?.ADMIN?.toLowerCase())) {
        return UserRole.ADMIN;
    }

    return UserRole.NORMAL;
};
