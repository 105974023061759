import CloseIcon from "@mui/icons-material/Close";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";

import VSButton from "components/VSButton";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
        padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
        padding: theme.spacing(1),
    },
}));

const BootstrapDialogTitle = (props: any) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

export default function CustomizedDialogs({
    open,
    handleClose,
    title,
    children,
    handleConfirm,
    confirmationText = "Save",
    maxWidth = "sm",
    loading,
    dialogActions = true,
    ...rest
}: any) {
    return (
        <div>
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
                maxWidth={maxWidth}
                {...rest}
            >
                <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                    {title}
                </BootstrapDialogTitle>
                <DialogContent sx={{ minWidth: "25rem" }} dividers>
                    {children}
                </DialogContent>
                {dialogActions && 
                <DialogActions sx={{ mt: 1 }}>
                    <VSButton sx={{ mr: 3 }} onClick={handleClose}>
                        Cancel
                    </VSButton>
                    <VSButton autoFocus onClick={handleConfirm} variant="contained" color="success" loading={loading}>
                        {confirmationText}
                    </VSButton>
                </DialogActions>
                }
            </BootstrapDialog>
        </div>
    );
}
