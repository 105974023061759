import { Grid, Typography } from "@mui/material";
import CircularLoadingProgress from "components/CircularProgress";
import VSCard from "components/VSCard/VSCard";
import { FormTypes } from "constants/enum.constants";
import { useGetUnpackingQuestions } from "hooks/VSortsContainerData";
import NetworkError from "pages/error/networkError";
import { useEffect, useState } from "react";
import UnpackingSurveyForm from "./UnpackingSurveyForm";
import styles from "./styles";
export default function Unpacking(props: any) {
    const [clusterStepData, setClusterStepData] = useState<any>([{}]);
    const [isData, setIsData] = useState(false);
    const [surveyPrevNextData, setSurveyPrevNextData] = useState<any>();
    const { onClose, selectedValue, open, vignette, cluster_step_id, refetchFlaggedData } = props;

    const vignette_id = vignette.id;
    const vignette_text = vignette.vignette_text;
    const vignette_rank_category = vignette.user_rank_category;

    useEffect(() => {
        refetchUnpackingQuestionData();
    }, []);

    const onQuestionFetchSuccess = (data: any) => {
        // We set the data variable and make the setIsData variable to true signifying that the data has been set
        setClusterStepData(data);
        setIsData(true);
    };

    const onQuestionFetchError = () => {
        // TODO:- Perform any operation in case of errors while fetching Survey Data
        //
    };

    const {
        isFetched,
        isFetching: isQuestionDataFetching,
        // isLoading: isNextClusterStepDataLoading,
        isError: isQuestionDataError,
        data,
        error,
        refetch: refetchUnpackingQuestionData,
    } = useGetUnpackingQuestions({
        clusterID: props.cluster_id,
        container_id: props.container_id,
        userID: props.user_id,
        form_type: "unpacking",
        cluster_step_id: cluster_step_id,
        vignette_id: vignette_id,
        onSuccess: onQuestionFetchSuccess,
        onError: onQuestionFetchError,
    });

    /// We check if there is any data being fetched (In Foreground or Background) to display `CircularLoadingProgress`
    if (isQuestionDataFetching) {
        return <CircularLoadingProgress />;
    }

    // We check if there is an error present while fetching all Questions of a Survey and display `NetworkError`
    if (isQuestionDataError) {
        return <NetworkError handleOnRefresh={refetchUnpackingQuestionData} errorText={error} />;
    }

    if (isFetched && !isData) {
        setClusterStepData(data);
        setIsData(true);
    }

    const refreshNextClusterStep = (value: any) => {
        if (value) {
            refetchFlaggedData();
        }
    };

    const handleClose = () => {
        onClose(selectedValue);
    };

    const setPrevNextDataFunction = (data: any) => {
        setSurveyPrevNextData(data);
    };
    return (
        <Grid sx={styles.outerCardContainer}>
            {/* Title */}
            <Grid style={styles.headerContainer}>
                <Grid display="flex" direction="column" justifyContent="center" alignItems="center" container>
                    {clusterStepData &&
                        clusterStepData.next_cluster_step &&
                        clusterStepData.next_cluster_step.container_name && (
                            <Grid
                                display="flex"
                                direction="column"
                                justifyContent="center"
                                alignItems="center"
                                container
                            >
                                <Typography
                                    style={{ fontSize: "24px", lineHeight: "34px" }}
                                    gutterBottom
                                    fontWeight={700}
                                >
                                    {clusterStepData.next_cluster_step.container_name} | Unpacking
                                </Typography>
                                <Typography
                                    style={{ fontSize: "16px", lineHeight: "26px" }}
                                    gutterBottom
                                    fontWeight={600}
                                >
                                    {clusterStepData.next_cluster_step.sub_title}
                                </Typography>
                                <Typography
                                    style={{ fontSize: "16px", lineHeight: "26px" }}
                                    gutterBottom
                                    dangerouslySetInnerHTML={{
                                        __html: ` <b>Directions: </b> ${clusterStepData.next_cluster_step.directions}`,
                                    }}
                                ></Typography>
                            </Grid>
                        )}
                </Grid>
            </Grid>

            <VSCard sx={styles.outerCard}>
                {clusterStepData &&
                    clusterStepData.next_cluster_step &&
                    clusterStepData.next_cluster_step["form_type"] === FormTypes.unpacking && (
                        <UnpackingSurveyForm
                            clusterStep={clusterStepData.next_cluster_step}
                            refreshNextClusterStep={refreshNextClusterStep}
                            clusterDetail={clusterStepData.cluster_detail}
                            vignette_id={vignette_id}
                            vignette_text={vignette_text}
                            vignette_rank_category={vignette_rank_category}
                            setSurveyPrevNextData={setPrevNextDataFunction}
                        />
                    )}
            </VSCard>
        </Grid>
    );
}
