import uuid from "react-uuid";

// Material UI Components
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import SurveyElementWrapper from "./SurveyElementWrapper";

// Material Icons (Drag and Delete)
import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined";

// Form Element Type Constant

// Form Element Footer

// `DropdownInput` is the function to show the user Drop-down Input while creating/editing a Survey Form
// 1. item -> Represents the Form Item Object (Consisting of all properties)
// 2. handleValue -> Represents the function to handle the Text Input value
// 3. deleteEl -> Represents the function to handle deleting the Form Element
// 4. handleRequired -> Represents the function to handle making a Form Element required/not required
// 5. handleElType -> Represents the function to handle the type of the Form Element
// 6. addOption -> Represents the function to add an option to the Drop-down Form Element
// 7. handleOptionValues -> Represents the function to handle the Text Value of the option/s of the Drop-down Form Element
// 8. deleteOption -> Represents the function to handle deleting of an option of the Drop-down Form Element
// 9. duplicateElement -> Represents the function to handle duplicating the Form Element
const DropdownInput = (props: any) => {
    const { item, addOption, handleValue, handleOptionValues, deleteOption, index } = props;

    // Function to generate a new ID for the option and set the value to an empty string to pass it onto the `addOption` function
    const createNewOption = (id: any) => {
        const data = {
            id: uuid(),
            value: "",
        };
        addOption({ id, newOption: data, index });
    };

    return (
        <SurveyElementWrapper {...props}>
            <TextField
                defaultValue={item.question_text}
                variant="outlined"
                onBlur={(e) => handleValue({ id: item.id, e, index })}
                fullWidth
                required={!!item.is_required}
                placeholder="Question"
                sx={{ mb: 2 }}
                multiline
            />
            {item.answer_options &&
                item.answer_options.length > 0 &&
                item.answer_options.map((opt: any, key: number) => (
                    <Box key={key} sx={{ display: "flex" }}>
                        <TextField
                            variant="outlined"
                            fullWidth
                            placeholder={`Dropdown Option ${key + 1}`}
                            defaultValue={opt?.value}
                            key={opt?.id + key + 1}
                            sx={{ mb: 1 }}
                            onBlur={(e) =>
                                handleOptionValues({
                                    elId: item?.id,
                                    optionId: opt?.id,
                                    optionVal: e.target.value,
                                    index,
                                })
                            }
                        />
                        <Tooltip title="Delete Option" aria-label="delete-option">
                            <IconButton
                                aria-label="delete-option"
                                onClick={() => deleteOption({ elId: item.id, optionId: opt?.id, index })}
                                sx={{ ml: 2 }}
                            >
                                <DeleteOutlineOutlinedIcon color="secondary" />
                            </IconButton>
                        </Tooltip>
                    </Box>
                ))}
            <Button variant="text" onClick={() => createNewOption(item.id)}>
                Add Option
            </Button>
        </SurveyElementWrapper>
    );
};

export default DropdownInput;
