import { IconButton } from "@mui/material";
import ArrowLeft from "assets/icons/arrowleft.svg";
import ArrowRight from "assets/icons/arrowright.svg";
import QuoteIcon from "assets/icons/quote.svg";
import { useRef } from "react";
import Slider from "react-slick";
import "./Testimonials.css";

const Testimonials = () => {
    const sliderRef = useRef<Slider>(null);
    const testimonials = [
        {
            image: null,
            description: `A feeling of enthusiasm to have this type of experience, along with my colleagues, to help us become culturally competent teachers.`,
            signature: "White Male - Professor / Teacher Educator",
        },
        {
            image: null,
            description: `This activity was helpful to understanding new terms and recognizing what I need to work on.`,
            signature: "White Female - Pre-Service Teacher",
        },
        {
            image: null,
            description: `The scenarios presented… were real and useful examples that allowed me to dive into my personal thoughts, beliefs, and skills and the teaching practices I use and want to use in the future.`,
            signature: "Hispanic Female - School Counselor",
        },
        {
            image: null,
            description: `A feeling of enthusiasm to have this type of experience, along with my colleagues, to help us become culturally competent teachers.`,
            signature: "White Male - Professor / Teacher Educator",
        },
    ];

    const handleClickNext = () => {
        if (sliderRef.current) {
            sliderRef.current.slickNext();
        }
    };

    const handleClickPrevious = () => {
        if (sliderRef.current) {
            sliderRef.current.slickPrev();
        }
    };

    const settings = {
        infinite: testimonials.length > 0,
        speed: 1000,
        slidesToShow: 3,
        slidesToScroll: 1,
        centerPadding: "20%",
        useTransform: false,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: testimonials.length > 0,
                },
            },
            {
                breakpoint: 734,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: testimonials.length > 0,
                },
            },
        ],
    };

    return (
        <div className="testimonialWrapper">
            <Slider ref={sliderRef} {...settings} className="testimonialContainer">
                {testimonials.map((testimonial) => {
                    return (
                        <div className="testimonialBox">
                            <div>
                                <div className="testimonialImageContainer">
                                    <IconButton disableRipple onClick={() => handleClickPrevious()} className="testimonialNavMobBtn">
                                        <img src={ArrowLeft} alt="Left" />
                                    </IconButton>

                                    {testimonial.image ? (
                                        <img src={testimonial.image} alt="testimonial" className="testimonialImage" />
                                    ) : (
                                        <img src={QuoteIcon} alt="testimonial" className="testimonialImageIcon" />
                                    )}

                                    <IconButton disableRipple onClick={() => handleClickNext()} className="testimonialNavMobBtn">
                                        <img src={ArrowRight} alt="Right" />
                                    </IconButton>
                                </div>
                                <div>{testimonial.description}</div>
                            </div>

                            <div className="testimonialSignature">{testimonial.signature}</div>
                        </div>
                    );
                })}
            </Slider>
            <div className="testimonialSliderButtons">
                <IconButton disableRipple onClick={() => handleClickPrevious()}>
                    <img src={ArrowLeft} alt="Left" />
                </IconButton>
                <IconButton disableRipple onClick={() => handleClickNext()}>
                    <img src={ArrowRight} alt="Right" />
                </IconButton>
            </div>
        </div>
    );
};

export default Testimonials;
