import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import RefreshIcon from "@mui/icons-material/Refresh";
import { Box, Button, IconButton, InputAdornment, Tab, Tabs, TextField, Typography } from "@mui/material";
import { ReactComponent as CloseIcon } from "assets/icons/close.svg";
import { ReactComponent as SearchIcon } from "assets/icons/search.svg";
import { useState } from "react";
import styles from "./styles";
const SearchBar = () => {
    const [searchKey, setSearchKey] = useState("");
    const handleSearchChange = (event: any) => {
        setSearchKey(event.target.value);
    };
    const handleClearSearchKey = () => {
        setSearchKey("");
    };
    return (
        <TextField
            size="small"
            variant="outlined"
            placeholder={""}
            sx={[styles, styles.searchBox]}
            value={searchKey}
            onChange={handleSearchChange}
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start" sx={{ ml: "15px", cursor: "pointer" }}>
                        <SearchIcon styles={styles.searchIcon} />
                    </InputAdornment>
                ),
                endAdornment: (
                    <InputAdornment
                        position="end"
                        onClick={handleClearSearchKey}
                        sx={{ mr: "15px", cursor: "pointer" }}
                    >
                        <CloseIcon styles={styles.closeIcon} />
                    </InputAdornment>
                ),
                style: { padding: "4px" },
            }}
        />
    );
};
export default function TopNavCard({ selectedTab, setSelectedTab, setOpenFilter, isFilterChanged }: any) {
    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setSelectedTab(newValue);
    };
    return (
        <Box sx={styles.container}>
            <Tabs
                value={selectedTab}
                onChange={handleTabChange}
                aria-label="cluster and copilot tab"
                TabIndicatorProps={{
                    style: {
                        backgroundColor: "#29ABE2",
                        height: "2px",
                    },
                }}
            >
                <Tab
                    label="Clusters"
                    id="0"
                    sx={{
                        minWidth: 0,
                        padding: 0,
                        textTransform: "none",
                        fontSize: "inherit",
                        marginRight: "30px",
                        "&.Mui-selected": {
                            color: "#29ABE2",
                            fontWeight: "bold",
                        },
                    }}
                />
                <Tab
                    label="AI CoPilots"
                    id="1"
                    sx={{
                        minWidth: 0,
                        padding: 0,
                        textTransform: "none",
                        fontSize: "inherit",
                        "&.Mui-selected": {
                            color: "#29ABE2",
                            fontWeight: "bold",
                        },
                    }}
                />
            </Tabs>

            <Box sx={{ ...styles.flexContainer }}>
                <SearchBar />
                <Box>
                    {isFilterChanged && (
                        <IconButton sx={styles.btnTab}>
                            <RefreshIcon sx={{ color: "#29ABE2", rotate: "270deg" }} />
                        </IconButton>
                    )}

                    <Button
                        disableRipple
                        variant="contained"
                        sx={styles.btnFilter}
                        onClick={() => setOpenFilter((openFilter: any) => !openFilter)}
                    >
                        <Typography fontSize={"0.88rem"}>
                            Filters{" "}
                            <ArrowForwardIosIcon fontSize="small" sx={{ color: "#94A8B0", fontSize: "1.1rem" }} />
                        </Typography>
                    </Button>
                </Box>
            </Box>
        </Box>
    );
}
