import Dialog from '@mui/material/Dialog';
import CircularLoadingProgress from 'components/CircularProgress';
import { FormTypes } from 'constants/enum.constants';
import { useGetUnpackingQuestions } from 'hooks/VSortsContainerData';
import NetworkError from 'pages/error/networkError';
import UserSurveyForm from 'pages/user-portal/survey';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';


export default function UnpackingDialog(props: any) {
    const [clusterStepData, setClusterStepData] = useState<any>([]);
    const [isData, setIsData] = useState(false);
  const { onClose, selectedValue, open, vignette_id, vignette_text, vignette_rank_category, cluster_step_id, refetchFlaggedData} = props;

    useEffect(() => {
        refetchNextClusterStepData();
    },[])

    const onNextClusterStepDataFetchSuccess = (data: any) => {
        // We set the data variable and make the setIsData variable to true signifying that the data has been set
        setClusterStepData(data);
        setIsData(true);
    };

    const onNextClusterStepDataFetchError = (error: any) => {
        // TODO:- Perform any operation in case of errors while fetching Survey Data
        // 
    };

    const {
        isFetched,
        isFetching: isNextClusterStepDataFetching,
        // isLoading: isNextClusterStepDataLoading,
        isError: isNextClusterStepDataError,
        data,
        error,
        refetch: refetchNextClusterStepData,
    } = useGetUnpackingQuestions({
        clusterID: props.cluster_id,
        container_id: props.container_id,
        userID: props.user_id,
        form_type: 'unpacking',
        cluster_step_id: cluster_step_id,
        vignette_id:vignette_id,
        onSuccess: onNextClusterStepDataFetchSuccess,
        onError: onNextClusterStepDataFetchError,
    });


     /// We check if there is any data being fetched (In Foreground or Background) to display `CircularLoadingProgress`
     if (isNextClusterStepDataFetching) {
        return <CircularLoadingProgress />;
    }

    // We check if there is an error present while fetching all Questions of a Survey and display `NetworkError`
    if (isNextClusterStepDataError) {
        return <NetworkError handleOnRefresh={refetchNextClusterStepData} errorText={error} />;
    }

    if (isFetched && !isData) {
        setClusterStepData(data);
        setIsData(true);
    }

    const refreshNextClusterStep = (value: any) => {
        if (value) {
            refetchFlaggedData();
        }
    };

    

  const handleClose = () => {
    onClose(selectedValue);
  };


  return (
    <Dialog onClose={handleClose} open={open}>
      {clusterStepData &&
                        clusterStepData.next_cluster_step &&
                        (   clusterStepData.next_cluster_step["form_type"] === FormTypes.unpacking) && (
                            <UserSurveyForm
                                clusterStep={clusterStepData.next_cluster_step}
                                refreshNextClusterStep={refreshNextClusterStep}
                                clusterDetail={clusterStepData.cluster_detail}
                                vignette_id={vignette_id}
                                vignette_text={vignette_text}
                                vignette_rank_category={vignette_rank_category}
                            />
                        )}{" "}
    </Dialog>
  );
}

UnpackingDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
};