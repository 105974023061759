import VSUnpackingContainer from "components/VSUnpackingContainer/VSUnpackingContainer";
import VSUnpackingHeader from "components/VSUnpackingHeader/VSUnpackingHeader";

const Unpacking = () => {
    return (
        <>
            <VSUnpackingHeader></VSUnpackingHeader>
            <VSUnpackingContainer></VSUnpackingContainer>
        </>
    );
};

export default Unpacking;
