// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { Box } from "@mui/material";
import { setTabNavigation } from "app/slices/TabNavigationSlice";
import { APIMethodConstants } from "constants/api.method.constants";
import {
    useClusterFormData,
    useCreateClusterStepsForm,
    useDeleteVSortsClusterStepData,
    useVSortsClusterAutocompleteData,
} from "hooks/VsortsClusterFormData";
import _ from "lodash";
import ClusterStepItem from "pages/adminV2/Components/ClusterBuilder/Elements/ClusterStepItem/ClusterStepItem";
import ClusterSummary from "pages/adminV2/Components/ClusterBuilder/Elements/ClusterSummary";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import Nestable from "react-nestable";
import { useDispatch } from "react-redux";
import { useNavigate, useOutletContext } from "react-router-dom";
import { ClusterStep } from "types/clusters";
import DelecteClusterModal from "../../modals/DeleteClusters";
import DelecteClusterActivityModal from "../../modals/DeleteClusters/DelecteClusterActivity";

const Cluster = forwardRef(function Cluster() {
    const searchParams = new URLSearchParams(window.location.search);
    const id = parseInt(searchParams.get("id"));
    // const title = searchParams.get("title");
    const [title, setTitle] = useState("");
    const [originalTitle, setOriginalTitle] = useState("");

    const isCreate = searchParams.get("isCreate");
    const isView = !!searchParams.get("isView") && searchParams.get("isView") === "true";
    const [optionsOpen, setOptionsOpen] = useState(false);
    const [clusterStepItems, setClusterStepItems] = useState<any[]>([]);
    const [openDeleteCluster, setOpenDeleteCluster] = useState<boolean>(false);
    const [openDelteClusterActivity, setOpenDelteClusterActivity] = useState<boolean>(false);
    const [selectedStep, setSelectedStep] = useState<ClusterStep | null>(null);

    const [originalData, setOriginalData] = useState([]);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { childRef }: any = useOutletContext();

    const onClusterStepsFetchSuccess = (response: { stepData: any }) => {
        const steps = response.stepData ?? response;

        // id from response is set ad stepId and index is set as id
        setClusterStepItems((steps || []).map((item: any, index: number) => ({ ...item, stepId: item.id, id: index })));
        setOriginalData((steps || []).map((item: any, index: number) => ({ ...item, stepId: item.id, id: index })));
    };

    const onClusterStepsFetchError = () => {
        undefined;
    };

    useEffect(() => {
        refetchVSortsClusterSteps();
    }, []);

    useEffect(() => {
        if (isView) {
            dispatch(setTabNavigation(true));
            return;
        }

        if (_.isEqual(clusterStepItems, originalData) && _.isEqual(originalTitle, title)) {
            dispatch(setTabNavigation(true));
            return;
        } else {
            dispatch(setTabNavigation(false));
            return;
        }
    }, [clusterStepItems, title]);

    useImperativeHandle(childRef, () => {
        return {
            saveCluster() {
                handleSaveCluster();
            },
            deleteCluster() {
                handleDeleteCluster();
            },
            handleOriginalTitle(value: any) {
                setTitle(value);
                setOriginalTitle(value);
            },
            handleTitle(value: any) {
                setTitle(value);
            },
        };
    });

    // `createVSortsClusterStepsData` is the mutation to create VSorts Cluster Steps for a VSorts Cluster
    const {
        mutate: createVSortsClusterStepsData,
        isError: isCreateVSortsClusterError,
        reset: resetCreateVSortsClusterMutation,
        isLoading: isLoadingCreateClusterStep,
        isSuccess: isSuccessCreateVSortsClusterStep,
    } = useCreateClusterStepsForm();

    const handleSaveCluster = async () => {
        const updatedStepData = clusterStepItems.map((item: ClusterStep, index) => ({
            cluster_id: item.clusterId,
            container_id: item.containerId,
            container_name: item.containerName,
            form_type: item.formType,
            step_id: item.stepID,
            step_number: index + 1,
            start_date: item.start_date,
            id: item.stepId,
        }));

        await createVSortsClusterStepsData({
            apiMethodType: APIMethodConstants.post,
            data: {
                cluster_id: id,
                data: updatedStepData,
                cluster_name: title,
            },
        });
    };

    const handleDeleteCluster = async () => {
        setOpenDeleteCluster(true);
    };

    useEffect(() => {
        if (isSuccessCreateVSortsClusterStep === true) {
            dispatch(setTabNavigation(true));
            navigate("/v2/admin/clusters");
        }
    }, [isSuccessCreateVSortsClusterStep]);

    // `useClusterFormData` is the query to fetch All Steps in a VSorts Cluster
    const {
        isFetched,
        isFetching: isClusterStepsFetching,
        isLoading: isClusterStepsLoading,
        isError: isVSortsClusterStepsError,
        data: clusterStepsData,
        error: clusterStepsError,
        refetch: refetchVSortsClusterSteps,
    } = useClusterFormData({
        //isAdmin: currentUserHasAdminRole(roles),
        clusterID: id,
        isEnabled: !isCreate,
        onSuccess: onClusterStepsFetchSuccess,
        onError: onClusterStepsFetchError,
    });

    // `useVSortsClusterAutocompleteData` is the query to fetch VSorts Cluster Autocomplete Data
    const {
        isLoading: isClusterAutocompleteLoading,
        isError: isClusterAutocompleteError,
        data: clusterAutocompleteData,
        error: clusterAutocompleteError,
        refetch: refetchAllVSortsContainerData,
    } = useVSortsClusterAutocompleteData({
        onSuccess: () => {
            undefined;
        },
        onError: () => {
            undefined;
        },
    });

    const formElementTypeToAutocompleteDataMapping = (formType: string) => {
        switch (formType) {
            case "vsorts":
                return clusterAutocompleteData.containers;
            case "unpacking": //Unpacking is related to each VSet so we will return the same containers
                return clusterAutocompleteData.containers;
            case "presurvey":
            case "survey":
                return clusterAutocompleteData.preSurveyContainers;
            // case "postsurvey":
            //     return clusterAutocompleteData.postSurveyContainers;
            // case "finalfeedback":
            //     return clusterAutocompleteData.finalFeedbackSurveyContainers;
            // case "video":
            //     return clusterAutocompleteData.preSurveyContainers;
            default:
                return [];
                break;
        }
    };

    // `deleteFormQuestion` is the mutation to delete a VSorts Cluster Step
    const {
        mutate: deleteVSortsClusterStep,
        isError: isDeleteVSortsClusterStepError,
        reset: resetDeleteVSortsClusterMutation,
    } = useDeleteVSortsClusterStepData({
        clusterID: id,
    });

    // Function to handle Deleting of a VSorts Cluster Step
    const deleteStep = async (stepId: any) => {
        deleteVSortsClusterStep({
            stepID: stepId,
        });
    };

    const handleActivityDeleteClick = (stepItem: ClusterStep) => {
        setSelectedStep(stepItem);
        setOpenDelteClusterActivity(true);
    };

    const handleActivityDelete = () => {
        deleteStep(selectedStep.stepId);
        const updatedItems = clusterStepItems.filter((item: ClusterStep) => item.id !== selectedStep.id);
        setClusterStepItems(updatedItems);
        setOpenDelteClusterActivity(false);
    };

    const handleAddClusterActivity = () => {
        const currentIndex = clusterStepItems.length;
        const newData = {
            clusterId: id,
            stepNumber: currentIndex + 1,
            id: currentIndex,
            containerId: "",
            formType: "",
        };
        //setClusterStepItems((prevState: any) => [...prevState, newData]);
        setClusterStepItems([...clusterStepItems, newData]);
    };

    const handleItemsDrag = ({ items, dragItem, targetPath }: any) => {
        const updatedItems = items.map((item: any, index: number) => ({ ...item, stepNumber: index + 1 }));
        setClusterStepItems([...items]);
    };

    const handleActivityTypeChange = (stepItem: ClusterStep) => {
        const newArr = clusterStepItems.map((el) => {
            if (el.id === stepItem.id) {
                return { ...stepItem };
            } else {
                return el;
            }
        });
        setClusterStepItems(newArr);
    };

    const handleActivityChange = (stepItem: ClusterStep) => {
        const newArr = clusterStepItems.map((el) => {
            if (el.id === stepItem.id) {
                return { ...stepItem };
            } else {
                return el;
            }
        });

        setClusterStepItems(newArr);
    };

    const handleStartDateStatusChange = (stepItem: ClusterStep, status: boolean) => {
        const newArr = clusterStepItems.map((el) => {
            if (el.id === stepItem.id) {
                return { ...stepItem, startDatePresent: status };
            } else {
                return el;
            }
        });

        setClusterStepItems(newArr);
    };

    const handleRenderItems = (renderItem) => {
        //
        const itemData: ClusterStep = renderItem.item;
        return (
            <ClusterStepItem
                clusterStep={itemData}
                formTypeContainers={formElementTypeToAutocompleteDataMapping}
                handleActivityChange={handleActivityChange}
                handleActivityTypeChange={handleActivityTypeChange}
                handleActivityDelete={handleActivityDeleteClick}
                isView={isView}
                handleStartDateStatusChange={handleStartDateStatusChange}
            />
        );
    };

    // render component
    let ClusterStepComponent = (
        <Nestable
            items={clusterStepItems}
            renderItem={handleRenderItems}
            maxDepth={1}
            onChange={handleItemsDrag}
            disableDrag={isView}
        />
    );

    if (isLoadingCreateClusterStep) {
        ClusterStepComponent = <h2>Loading...</h2>;
    }

    return (
        <Box sx={{ display: "flex", flexDirection: "column" }}>
            <ClusterSummary
                clusterStepItems={clusterStepItems}
                handleAddClusterActivity={handleAddClusterActivity}
                isView={isView}
            />

            <Box component="main" sx={{ flexGrow: 1, p: 3, mt: 0 }}>
                {ClusterStepComponent}
            </Box>

            {/* Delete the whole cluster */}
            {openDeleteCluster && (
                <DelecteClusterModal
                    open={openDeleteCluster}
                    setOpen={setOpenDeleteCluster}
                    selected={{ id: id, "Cluster name": title }}
                    refertchClusterData={() => navigate("/v2/admin/clusters")}
                />
            )}

            {openDelteClusterActivity && (
                <DelecteClusterActivityModal
                    open={openDelteClusterActivity}
                    setOpen={setOpenDelteClusterActivity}
                    handleDelete={handleActivityDelete}
                />
            )}
        </Box>
    );
});

export default Cluster;
