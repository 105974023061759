import {
    Box,
    Checkbox,
    CssBaseline,
    FormControl,
    FormControlLabel,
    FormGroup,
    IconButton,
    Typography,
} from "@mui/material";
import EyeIcon from "assets/icons/eye.svg";
import TrashIcon from "assets/icons/trash-red.svg";
import Header from "components/Dashboard/Header";
import { useGetIsPublicStatus, useGetSpecificCoPilot, useRevertIsPublic } from "hooks/CoPilots";
import _ from "lodash";
import { Button, Tabs } from "pages/adminV2/Components";
import AdminHeader from "pages/adminV2/Components/AdminHeader";
import DeleteSurveyModel from "pages/adminV2/Survey/models/DeleteSurvey";
import { useEffect, useRef, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import styles from "../../Survey/CreateSurvey/styles";
import AddOptions from "../AddOptions";

interface tab {
    name: string;
    url: string;
}

const tabs: tab[] = [
    {
        name: "Copilot",
        url: "/v2/admin/copilots/create",
    },
    {
        name: "Assign",
        url: "/v2/admin/copilots/create/assign",
    },
];

function CreateCoPilotLayout() {
    const searchParams = new URLSearchParams(window.location.search);
    const id = searchParams.get("id") || "";
    const isEdit = searchParams.get("isEdit");
    const [title, setTitle] = useState("");

    const childRef = useRef<any>();
    const navigate = useNavigate();
    const [noOfUsers, setNoOfUsers] = useState([0, 1, 2, 3, 4, 5, 6].map((value) => ({ value: value, label: value })));
    const [selectedNoOfUsers, setSelectedNoOfUsers] = useState(null);
    const [optionsOpen, setOptionsOpen] = useState(false);
    const [selectedOptions, setSelectedOptions] = useState<any>([]);
    const [coPilotOptions, setCoPilotOptions] = useState<any>([]);
    const originalcoPilotOptions = useRef<any>([]);
    const [activeTab, setActiveTab] = useState<tab>({
        name: "",
        url: "",
    });
    const [checkChanged, setCheckChanged] = useState(false);
    const [openDeleteCoPilot, setOpenDeleteCoPilot] = useState<boolean>(false);

    const onTabSelect = (tab: tab) => {
        setActiveTab(tab);
        navigate(`${tab.url}?title=${title}&id=${id}&isEdit=${isEdit}`);
    };

    useEffect(() => {
        const tab: tab = tabs.find((tab) => location.pathname == tab.url) || {
            name: "",
            url: "",
        };
        setActiveTab(tab);
    }, []);

    useEffect(() => {
        refetchSpecificCoPilotData();
    }, [activeTab]);

    useEffect(() => {
        if (optionsOpen) {
            refetchData();
        }
    }, [optionsOpen]);

    const handleSaveClick = () => {
        if (childRef.current) {
            if (typeof childRef.current.save === "function") {
                childRef.current.save();
            }
        }
    };

    const handleOptionsClick = () => {
        setOptionsOpen(true);
    };

    const onSuccessFetch = (data: any) => {
        setCoPilotOptions([
            {
                text: "Publish this CoPilot to the VSorts AI platform and allow the public to view and use it",
                checked: !!data?.is_public,
            },
        ]);

        originalcoPilotOptions.current = [
            {
                text: "Publish this CoPilot to the VSorts AI platform and allow the public to view and use it",
                checked: !!data?.is_public,
            },
        ];
    };

    const onErrorFetch = (error: any) => {
        undefined;
    };
    const {
        isFetched,
        isFetching: isDataFetching,
        isLoading: isDataLoading,
        isError: isDataError,
        data: allData,
        error,
        refetch: refetchData,
    } = useGetIsPublicStatus({
        coPilotID: id,
        isEnabled: optionsOpen,
        onSuccess: onSuccessFetch,
        onError: onErrorFetch,
    });

    const onSaveSuccess = (data: any) => {
        setCheckChanged(false);
    };
    const onSaveError = (error: any) => {
        setOptionsOpen(false);
    };

    const {
        mutate: callingCopilot,
        isError: isPostError,
        reset: resetCallAICoPilot,
        isLoading: isPostLoading,
        error: postError,
    } = useRevertIsPublic({
        coPilotID: id,
        onSuccess: onSaveSuccess,
        onError: onSaveError,
    });

    const handleSaveOptions = async () => {
        if (checkChanged) {
            await callingCopilot();
        }
        setOptionsOpen(false);
    };

    const handleSectionClick = () => {
        if (childRef.current) {
            if (typeof childRef.current.addSection === "function") {
                childRef.current.addSection();
            }
        }
    };

    const previewCoPilot = () => {
        navigate(`/v2/admin/copilots/preview?title=${title}&id=${id}`);
    };

    const handleOptionSelection = (event: any) => {
        const nextState = coPilotOptions.map((eachCoPilot: any) => {
            if (eachCoPilot?.text == event.target.value) {
                return {
                    ...eachCoPilot,
                    checked: !eachCoPilot?.checked,
                };
            } else {
                return eachCoPilot;
            }
        });
        setCoPilotOptions(nextState);
        setCheckChanged(true);
    };

    const checkDisabled = () => {
        let count = 0;
        for (let i = 0; i < coPilotOptions.length; i++) {
            if (coPilotOptions[i]["checked"]) {
                count = count + 1;
                break;
            }
        }
        return count;
    };

    const handleHeadDeleteAction = () => {
        setOpenDeleteCoPilot(true);
    };

    const refetchCoPilotList = () => {
        navigate("/v2/admin/copilots");
    };

    const handleTitleChange = (event: any) => {
        setTitle(event.target.value);
        if (childRef.current) {
            if (typeof childRef.current.handleTitle === "function") {
                childRef.current.handleTitle(event.target.value);
            }
        }
    };

    const onSpecificCoPilotSuccessFetch = (data: any) => {
        setTitle(data?.title);
    };

    const onSpecificCoPilotErrorFetch = (error: any) => {
        undefined;
    };

    const {
        isFetching: isSpecificCoPilotDataFetching,
        isLoading: isSpecificCoPilotDataLoading,
        isError: isSpecificCoPilotDataError,
        data: specificCoPilotData,
        error: specificCoPilotError,
        refetch: refetchSpecificCoPilotData,
    } = useGetSpecificCoPilot({
        coPilotID: id,
        isEnabled: true,
        onSuccess: onSpecificCoPilotSuccessFetch,
        onError: onSpecificCoPilotErrorFetch,
    });

    return (
        <Box sx={{ display: "flex", flexDirection: "column", margin: "0rem 3rem" }}>
            <CssBaseline />
            <Header title="Admin Dashboard" redirectTo="/v2/admin/copilots" />
            <AdminHeader
                title="Create a CoPilot"
                onIconClick={() => {
                    undefined;
                }}
            />

            <Box sx={styles.container}>
                <IconButton sx={{ width: "42px", height: "42px" }} onClick={previewCoPilot}>
                    <img src={EyeIcon} alt="view" />
                </IconButton>

                <input
                    className="titleInput"
                    value={title ?? ""}
                    onChange={handleTitleChange}
                    disabled={window.location.pathname.indexOf("assign") >= 0 ? true : false}
                />
                <Button type="primaryOutlined" text="Options" onClick={() => handleOptionsClick()} />

                <Button type="primary" text="Save" onClick={() => handleSaveClick()} />

                <IconButton sx={{ width: "42px", height: "42px" }} onClick={() => handleHeadDeleteAction()}>
                    <img src={TrashIcon} alt="Delete" />
                </IconButton>
            </Box>

            <div style={{ marginTop: "10px" }}>
                <Tabs
                    tabs={tabs}
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                    onTabSelect={onTabSelect}
                    enableDataCheck={true}
                />{" "}
            </div>
            {/* <Box sx={{ ...styles.container, alignItems: "center" }}>
                <FormControlLabel control={<Checkbox />} label="Allow users to complete form more than 1 time" />
                <Box sx={{ ...styles.selectStyles }}>
                    <ReactSelect
                        data={noOfUsers}
                        label={"Select 0-6 (Default is 1)"}
                        defaultValue={selectedNoOfUsers}
                        setValue={setSelectedNoOfUsers}
                        isDisabled={false}
                    />
                </Box>
            </Box> */}

            {optionsOpen && (
                <AddOptions
                    open={optionsOpen}
                    setOpen={setOptionsOpen}
                    title={"CoPilot options"}
                    subTitle={"Add more control over a CoPilot with the options listed below"}
                >
                    <Box sx={{ mt: 3 }}>
                        {coPilotOptions && (
                            <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
                                <FormGroup>
                                    {(coPilotOptions || []).map((eachOption: any, index: number) => {
                                        return (
                                            <FormControlLabel
                                                key={index}
                                                value={eachOption?.text}
                                                control={
                                                    <Checkbox
                                                        checked={eachOption?.checked}
                                                        onChange={handleOptionSelection}
                                                        name={eachOption?.text}
                                                    />
                                                }
                                                label={
                                                    <Typography
                                                        fontSize={18}
                                                        fontWeight={400}
                                                        sx={{ margin: "20px 0px 0px 10px" }}
                                                    >
                                                        {eachOption?.text}
                                                    </Typography>
                                                }
                                            />
                                        );
                                    })}
                                </FormGroup>
                            </FormControl>
                        )}
                    </Box>

                    <Box style={{ display: "flex", justifyContent: "center", marginTop: "30px" }}>
                        <Button
                            // disabled={checkDisabled() == 0 ? true : false}
                            disabled={_.isEqual(coPilotOptions, originalcoPilotOptions.current)}
                            type="primary"
                            text="Save"
                            onClick={() => handleSaveOptions()}
                        />
                    </Box>
                </AddOptions>
            )}
            <Box component="main" sx={{ flexGrow: 1, p: 3, mt: 0 }}>
                <Outlet context={{ childRef }} />
            </Box>
            {openDeleteCoPilot && (
                <DeleteSurveyModel
                    open={openDeleteCoPilot}
                    setOpen={setOpenDeleteCoPilot}
                    selectedRows={[parseInt(id)]}
                    typeOfRequest="CoPilot"
                    refetchFunction={refetchCoPilotList}
                />
            )}
        </Box>
    );
}

export default CreateCoPilotLayout;
