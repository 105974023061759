const styles: any = {
    modal: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    container: {
        background: '#fff',
        borderRadius: 10,
        padding: '55px 48px',
        width: 600,
        position: 'relative',
    },
    closeIcon: {
        position: 'absolute',
        right: 36,
        top: 31,
        width: 16,
        height: 16,
        color: '#929292',
        cursor: 'pointer',
    },
    title: {
        fontWeight: 500,
        fontSize: 16,
        marginBottom: 42,
    },
    flexContainer: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    halfContainer: {
        width: '50%',
    },
    item: {
        marginBottom: 18,
    },
    text: {
        fontSize: 16,
        color: '#000',
    },
    textGray: {
        fontSize: 16,
        color: '#929292',
        marginBottom: 8,
    },
    select: {
        width: '95%',
    },
}

export default styles