import Header from "components/Dashboard/Header";

import { Box } from "@mui/material";
import { Outlet } from "react-router-dom";
import styles from "./styles";

export default function DashboardLayout() {
    return (
        <Box sx={styles.dashboardLayoutContainer}>
            <Header />
            <Outlet />

            {/* The footer is being removed from layout and will only be added to the landing page and the user dashboard. */}
            {/* <Footer /> */}
        </Box>
    );
}
