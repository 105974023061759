// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-alpine.css'; // Optional theme CSS
import 'ag-grid-enterprise';
import { AgGridReact } from 'ag-grid-react'; // the AG Grid React Component
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { LicenseManager } from 'ag-grid-enterprise';
LicenseManager.setLicenseKey(process.env.REACT_APP_AG_GRID_LICENSE_KEY)

const ViewResponses = ({rows, cols}) => {

 const gridRef = useRef(); // Optional - for accessing Grid's API
 const [rowData, setRowData] = useState(); // Set rowData to Array of Objects, one Object per Row
 const [columnDefs, setColumnDefs] = useState([]);

 useEffect(() => {
  
  
    setColumnDefs(cols)
    setRowData(rows)
 },[])
 // Each Column Definition results in one Column.
 

 // DefaultColDef sets props common to all Columns
 const defaultColDef = useMemo( ()=> ({
     sortable: true,
     filter: true,
     enableRowGroup: true,
     floatingFilter: true,
     filter: 'agTextColumnFilter',
     resizable: true,
   }));

 const getContextMenuItems = useCallback((params) => {
    const result = [
      'autoSizeAll',
      'expandAll',
      'contractAll',
      'seperator',
      'copy',
      'copyWithHeaders',
      'separator',
      'resetColumns',
      'seperator',
      'csvExport',
      'excelExport',
      'seperator',
      'chartRange',
    ];
    return result;
  }, []);


  const statusBar = useMemo(() => {
    return {
      statusPanels: [
        { statusPanel: 'agTotalAndFilteredRowCountComponent', align: 'left' },
        { statusPanel: 'agTotalRowCountComponent', align: 'center' },
        { statusPanel: 'agFilteredRowCountComponent' },
        { statusPanel: 'agSelectedRowCountComponent' },
        { statusPanel: 'agAggregationComponent' },
      ],
    };
  }, []);


  const columnTypes = useMemo(() => {
    return {
      numberColumn: { width: 130, filter: 'agNumberColumnFilter' },
      medalColumn: { width: 100, columnGroupShow: 'open', filter: false },
      nonEditableColumn: { editable: false },
    };
  }, []);
 

 return (
   <div>

     {/* Example using Grid's API */}

     {/* On div wrapping Grid a) specify theme CSS Class Class and b) sets Grid size */}
     <div className="ag-theme-alpine" style={{width: '90vw', height: '70vh'}}>

       <AgGridReact
           ref={gridRef} // Ref for accessing Grid's API

           sideBar={true}

           rowGroupPanelShow='always'
           rowData={rowData} // Row Data for Rows

           columnDefs={columnDefs} // Column Defs for Columns
           defaultColDef={defaultColDef} // Default Column Properties
           columnTypes={columnTypes}

           enableRangeSelection={true}

           allowContextMenuWithControlKey={true}
           getContextMenuItems={getContextMenuItems}

           wrapText={true}

           statusBar={statusBar}
           
           animateRows={true} // Optional - set to 'true' to have rows animate when sorted

           onCellClicked={cellClickedListener} // Optional - registering for Grid Event
           />
     </div>
   </div>
 );
};

export default ViewResponses;