import TableCheckIcon from "assets/icons/tablecheck.svg";
import TableCrossIcon from "assets/icons/tablecross.svg";
import VSortLight from "assets/images/vsortlight.svg";
import "./CompTable.css";

const CompTable: React.FC = () => {
    const comparisions = [
        {
            feature: `An all-in-one SaaS platform for comprehensive qualitative data management`,
            vsort: TableCheckIcon,
            other1: TableCrossIcon,
            other2: TableCrossIcon,
            other3: TableCrossIcon,
        },
        {
            feature: `Cloud-based cluster deployment system for efficient data collection and analysis`,
            vsort: TableCheckIcon,
            other1: TableCrossIcon,
            other2: TableCrossIcon,
            other3: TableCrossIcon,
        },
        {
            feature: `AI CoPilots skilled in inductive qualitative data analysis`,
            vsort: TableCheckIcon,
            other1: TableCheckIcon,
            other2: TableCrossIcon,
            other3: TableCheckIcon,
        },
        {
            feature: `AI CoPilots skilled in deductive qualitative data analysis, utilizing the Cultural Proficiency© Framework`,
            vsort: TableCheckIcon,
            other1: TableCrossIcon,
            other2: TableCrossIcon,
            other3: TableCrossIcon,
        },
    ];

    return (
        <div className="compTableContainer">
            <table className="compTable">
                <thead>
                    {/* <tr>
                        <th style={{ visibility: "hidden" }}></th>
                        <th className="compVsortHeader" style={{ borderBottom: "none" }}></th>
                        <th style={{ visibility: "hidden" }} colSpan={3}></th>
                    </tr> */}
                    <tr>
                        <th className="compFeatureHeader">Features</th>
                        <th className="compVsortHeader">
                            <img src={VSortLight} />
                        </th>
                        <th className="compOtherHeader" colSpan={3}>
                            vs Other Companies
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {comparisions.map((comparision) => {
                        return (
                            <tr>
                                <td className="compFeatureData">{comparision.feature}</td>
                                <td className="compVsortData">
                                    <img src={comparision.vsort} />
                                </td>
                                <td className="compOtherData">
                                    <img src={comparision.other1} />
                                </td>
                                <td className="compOtherData compOtherData2">
                                    <img src={comparision.other2} />
                                </td>
                                <td className="compOtherData compOtherData3">
                                    <img src={comparision.other3} />
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
};

export default CompTable;
