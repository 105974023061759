const styles = {
    container: {
        display: "flex",
        flexDirection: "row",
        gap: "3rem",
        justifyContent: "left",
        alighItems: "center",
        backgroundColor: "#fff",
        padding: "15px",
        borderRadius: "10px",
        marginBottom: "1.2rem",
    },

    active: "#29ABE2",
    nonActive: "#000000",
};

export default styles;
