const styles = {
    container: {
        width: '100%',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: 'green',
        position: 'relative',
    },
    logoLink: {
        position: 'absolute',
        top: 10,
        left: '10%',
    },
    logo: {
        height: 60,
    },
    title: {
        fontWeight: 700,
        fontSize: '4rem',
        color: '#fff',
    },
}

export default styles