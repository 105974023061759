import { Box } from "@mui/material";
import { setTabNavigation, TabNavigationSelector } from "app/slices/TabNavigationSlice";
import { Dispatch, SetStateAction } from "react";
import { useDispatch, useSelector } from "react-redux";
import { uid } from "react-uid";
import styles from "./styles";

interface tab {
    name: string;
    url: string;
}

type PropsType = {
    activeTab: tab;
    tabs: tab[];
    onTabSelect: (tab: tab) => void;
    setActiveTab: Dispatch<SetStateAction<tab>>;
    enableDataCheck?: boolean;
};

const Tabs = ({ tabs, activeTab, setActiveTab, onTabSelect, enableDataCheck = false }: PropsType) => {
    const dispatch = useDispatch();
    const isTabNavigationEnabled = useSelector(TabNavigationSelector);

    const handleSelectTab = (tab: tab) => {
        console.log(!enableDataCheck, isTabNavigationEnabled);
        if (!enableDataCheck || isTabNavigationEnabled) {
            setActiveTab(tab);
            onTabSelect(tab);
            return;
        }

        const res = confirm("Exit Page? Changes you made may not be saved.");
        if (res) {
            dispatch(setTabNavigation(true));
            setActiveTab(tab);
            onTabSelect(tab);
        }
    };

    return (
        <Box sx={styles.tabs}>
            {tabs.map((tab, idx) => (
                <Box
                    key={uid(tab)}
                    sx={[
                        styles.tab,
                        idx < tabs.length - 1 && styles.tabWithMargin,
                        tab.name === activeTab.name && styles.activeTab,
                    ]}
                    onClick={() => handleSelectTab(tab)}
                >
                    {tab.name}
                </Box>
            ))}
        </Box>
    );
};

export default Tabs;
