const styles = {
    flexFull: {
        flex: 1,
    },
    header: {
        position: 'absolute',
        top: 10,
        width: '100%',
        zIndex: 1000,
    },
    info: {
        background: 'rgba(255, 255, 255, 0.2)',
        height: 60,
        display: {
            xs: 'none',
            xl: 'flex',
        },
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '0 2%',
        marginBottom: 10,
    },
    socialIcons: {
        marginLeft: 30,
        display: 'flex',
        alignItems: 'center',
    },
    menuSocial: {
        marginLeft: 20,
    },
    socialIcon: {
        height: 16,
    },
    mainHeader: {
        marginLeft: '10%',
        width: '90%',
        paddingRight: '5% !important',
        display: {
            xs: 'none',
            xl: 'flex',
        },
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    logo: {
        height: 60,
    },
    logoMobile: {
        height: 30,
    },
    menuItems: {
        display: 'flex',
        alignItems: 'center',
    },
    menuIcon: {
        width: 30,
        height: 30,
        marginRight: 8,
    },
    menuItem: {
        display: 'flex',
        alignItems: 'center',
        fontWeight: 700,
        fontSize: '1rem',
        color: {
            xs: '#000',
            xl: '#fff',
        },
        textDecoration: 'none',
        textTransform: 'none',
        margin: '0 10px',
    },
    btnSignIn: {
        padding: '13px 25px',
        border: '1px solid #fff',
        borderRadius: 5,
        fontWeight: 700,
        fontSize: '1rem',
        color: '#fff',
        textDecoration: 'none',
        marginRight: 20,
    },
    btnSignUp: {
        padding: '13px 37px',
        background: 'linear-gradient(270deg, #25A2D5 0%, #009043 100%)',
        border: 'none',
        borderRadius: 5,
        fontWeight: 700,
        fontSize: '1rem',
        color: '#fff',
        textDecoration: 'none',
    },
    mobileMenu: {
        display: {
            xs: 'flex',
            xl: 'none',
        },
        width: '100%',
        padding: '0 20px',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    mobileMain: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginBottom: 20,
    },
    menuContent: {
        width: 300,
        height: '100%',
        padding: 20,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    mobileMenuAction: {
        display: 'flex',
        flexDirection: 'column',
    },
    btnActionMobile: {
        width: '100%',
        padding: '13px 0',
        background: 'linear-gradient(270deg, #25A2D5 0%, #009043 100%)',
        border: 'none',
        borderRadius: 5,
        fontWeight: 700,
        fontSize: '1rem',
        color: '#fff',
        textAlign: 'center',
        textDecoration: 'none',
        marginBottom: 10,
    },
    mobileMneuActionContact: {
        marginTop: 20,
    },
    menuInfo: {
        color: {
            xs: '#000',
            xl: '#fff',
        },
    },
}

export default styles