// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { ListItem } from "@mui/material";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import { getDateTime } from "utils/standard-date-time";

export default function UserVSortsActivities({ clusterDetail, clusterStep, clusterActivities, headerDetails }) {
    const activityData = [
        {
            title: `Activities on "${clusterDetail["cluster_name"]}"`,
            titleStyle: {
                fontStyle: "italic",
                fontSize: "1.5rem",
            },
            body: (
                <>
                    {clusterActivities &&
                        clusterActivities.length > 0 &&
                        clusterActivities.map(
                            ({ form_type, container_name, section_title, domain_name, completed_at }, index) => {
                                return (
                                    <ListItem sx={{ display: "list-item" }} key={index + 1}>
                                        {`Completed {FormTypeLabels[form_type]} on "{container_name}" on{" "}`}
                                        {getDateTime(completed_at)}
                                    </ListItem>
                                );
                            }
                        )}
                    {clusterActivities && clusterActivities.length === 0 && (
                        <Typography sx={{ mt: "10px" }}>
                            {`You are about to begin the activities on "{clusterDetail["cluster_name"]}".`}
                            <br />
                            {clusterStep["container_name"] && (
                                <span>{`You are currently on "{clusterStep["container_name"]}" step.`}</span>
                            )}
                        </Typography>
                    )}
                </>
            ),
        },
    ];
    return (
        <Grid item xs={12} md={12}>
            {headerDetails?.footer_text ? (
                <>
                    <Grid display="flex" direction="column" justifyContent="center" alignItems="center" container>
                        <Typography variant="h6" gutterBottom>
                            <b>
                                {headerDetails.title} | {headerDetails.domain_name} | {headerDetails.header_text}
                            </b>
                        </Typography>
                    </Grid>
                    <Grid display="flex" direction="column" justifyContent="center" alignItems="center" container>
                        <Typography mr={15} ml={15} textAlign="justify" variant="subtitle2" gutterBottom>
                            <b>Directions: </b>
                            {headerDetails.footer_text}
                        </Typography>
                    </Grid>
                </>
            ) : (
                <>
                    <Grid display="flex" direction="column" justifyContent="center" alignItems="center" container>
                        <Typography variant="h6" gutterBottom>
                            You are about to begin the activities on {clusterDetail["cluster_name"]}.
                        </Typography>
                    </Grid>
                    <Grid display="flex" direction="column" justifyContent="center" alignItems="center" container>
                        <Typography mr={15} ml={15} textAlign="center" variant="subtitle2" gutterBottom>
                            {clusterStep["container_name"]
                                ? `You are currently on ${clusterStep["container_name"]} step.`
                                : null}
                        </Typography>
                    </Grid>
                </>
            )}

            {/* <VSAccordion data={activityData} /> */}
        </Grid>
    );
}
