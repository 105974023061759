// Material UI Components
import TextField from "@material-ui/core/TextField";

// Material Drag Icon

// Form Element Type Constant

// Form Element Footer

import SurveyElementWrapper from "./SurveyElementWrapper";

// `StateInput` is the function to show the user States (USA) Field Input while creating/editing a Survey Form
// 1. item -> Represents the Form Item Object (Consisting of all properties)
// 2. handleValue -> Represents the function to handle the Text Input value
// 3. deleteEl -> Represents the function to handle deleting the Form Element
// 4. handleRequired -> Represents the function to handle making a Form Element required/not required
// 5. handleElType -> Represents the function to handle the type of the Form Element
// 6. duplicateElement -> Represents the function to handle duplicating the Form Element
const StateInput = (props: any) => {
    const { item, handleValue, index } = props;
    return (
        <SurveyElementWrapper {...props}>
            <TextField
                value={item.question_text}
                variant="outlined"
                onChange={(e) => handleValue({id: item.id, e, index})}
                fullWidth
                required={!!item.is_required}
                placeholder="Question"
                sx={{ mb: 2 }}
                multiline
            />
        </SurveyElementWrapper>
    );
};

export default StateInput;
