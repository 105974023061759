import React from "react";

// Loading Indicator
import CircularLoadingProgress from "components/CircularProgress";
import RequireAuth from "./RequireAuth";

type PropsType = {
    Component: React.FC;
};

// `LazyAuthProtectedComponentWrappper` is the function to Lazy Load any component which is Authentication Protected
// 1. Component -> Represents the component that needs to be lazy loaded and Authentication protected
export default function LazyAuthProtectedComponentWrappper({ Component }: PropsType) {
    console.log("inside lazy auth protect");
    return (
        <RequireAuth>
            <React.Suspense fallback={<CircularLoadingProgress />}>
                <Component />
            </React.Suspense>
        </RequireAuth>
    );
}
