import SwitchAccountIcon from "@mui/icons-material/SwitchAccount";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import { Box, Button, Typography } from "@mui/material";
import { CurrentUserSelector } from "app/slices/UserSlice";
import { useGetAllCoPilotsAssigned } from "hooks/CoPilots";
import { getAllDemographicsOptions, getDemographicsData, updateUserDemographics } from "hooks/userData";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
    CountryOption,
    DemographicRequestBody,
    DemographicsData,
    DemographicsOptions,
    EducationOption,
    GenderOption,
    IncomeOption,
    PoliticalOption,
    RaceOption,
    RelationshipOption,
    SexualOrientationOption,
    StateOption,
    UserClusterStatus,
    UserStatus,
    ViewType,
} from "types/dashboard";
import { User } from "types/user";
import { currentUserHasAdminRole } from "utils/auth.utils";
import { getUserName } from "utils/userHelper";
import CoPilotsCarousel from "../CoPilots";
import DemographicsModal from "../Demographics/Modal";
import styles from "./styles";

type PropsType = {
    user: User | null;
    status: UserStatus;
    organization: string | undefined;
    group: string;
    viewType: ViewType;
    clusterStatus: UserClusterStatus;
};

const UserProfile: React.FC<PropsType> = ({ user, status, organization, group, viewType, clusterStatus }) => {
    const currentUser = useSelector(CurrentUserSelector);

    const [coPilotsList, setCoPilotsList] = useState<any>({});

    // const roles = currentUser[`${window.location.origin}/roles`];
    const roles = currentUser?.roles;

    const navigate = useNavigate();

    const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
    const [demographicsOptions, setDemographicsOptions] = useState<DemographicsOptions>({
        countries: [],
        states: [],
        educations: [],
        genders: [],
        incomes: [],
        politicalOrientations: [],
        races: [],
        relationshipStatuses: [],
        sexualOrientations: [],
    });
    const [userDemographics, setUserDemographics] = useState<DemographicsData | null>(null);

    const onSuccessFetch = (data: any) => {
        setCoPilotsList(data[0]);
    };

    const onErrorFetch = (error: any) => {
        undefined;
    };
    const {
        isFetched,
        isFetching: isDataFetching,
        isLoading: isDataLoading,
        isError: isDataError,
        data: allData,
        error,
        refetch: refetchData,
    } = useGetAllCoPilotsAssigned({
        isEnabled: true,
        onSuccess: onSuccessFetch,
        onError: onErrorFetch,
    });

    const onDemographicsOptionsFetchSuccess = (data: any) => {
        const countryOptions: CountryOption[] = data.countries.map(
            (country: any) =>
                ({
                    id: country.country_id,
                    code: country.country_code,
                    name: country.country_name,
                } as CountryOption)
        );

        const stateOptions: StateOption[] = data.states["230"].map(
            (state: any) =>
                ({
                    id: state.state_id,
                    name: state.state_name,
                    code: state.state_code,
                    country_id: state.state_country_id,
                } as StateOption)
        );

        const educationOptions: EducationOption[] = data.educations.map(
            (education: any) =>
                ({
                    id: education.education_id,
                    name: education.education_name,
                    description: education.education_description || "",
                } as EducationOption)
        );

        const genderOptions: GenderOption[] = data.genders.map(
            (gender: any) =>
                ({
                    id: gender.gender_id,
                    name: gender.gender_name,
                } as GenderOption)
        );

        const incomeOptions: IncomeOption[] = data.incomes.map(
            (income: any) =>
                ({
                    id: income.income_id,
                    currency: income.currency,
                    currency_symbol: income.currency_symbol,
                    min_income: income.min_income,
                    max_income: income.max_income,
                } as IncomeOption)
        );

        const politicalOrientationOptions: PoliticalOption[] = data.politicalOrientations.map(
            (political: any) =>
                ({
                    id: political.political_orientation_id,
                    name: political.political_orientation_name,
                    description: political.political_orientation_description || "",
                } as PoliticalOption)
        );

        const raceOptions: RaceOption[] = data.races.map(
            (race: any) =>
                ({
                    id: race.race_id,
                    name: race.race_name,
                    description: race.race_description || "",
                } as RaceOption)
        );

        const relationshipOptions: RelationshipOption[] = data.relationshipStatuses.map(
            (relationship: any) =>
                ({
                    id: relationship.relationship_status_id,
                    name: relationship.relationship_status_name,
                    description: relationship.relationship_status_description || "",
                } as RelationshipOption)
        );

        const sexualOrientationOptions: SexualOrientationOption[] = data.sexualOrientations.map(
            (sexualOrientation: any) =>
                ({
                    id: sexualOrientation.sexual_orientation_id,
                    name: sexualOrientation.sexual_orientation_name,
                    description: sexualOrientation.sexual_orientation_description || "",
                } as SexualOrientationOption)
        );

        setDemographicsOptions({
            countries: countryOptions,
            states: stateOptions,
            educations: educationOptions,
            genders: genderOptions,
            incomes: incomeOptions,
            politicalOrientations: politicalOrientationOptions,
            races: raceOptions,
            relationshipStatuses: relationshipOptions,
            sexualOrientations: sexualOrientationOptions,
        });
    };

    const onDataFetchError = () => {
        setDemographicsOptions({
            countries: [],
            states: [],
            educations: [],
            genders: [],
            incomes: [],
            politicalOrientations: [],
            races: [],
            relationshipStatuses: [],
            sexualOrientations: [],
        });
        setUserDemographics(null);
    };
    const { data: demographicsOptionsData } = getAllDemographicsOptions({
        onSuccess: onDemographicsOptionsFetchSuccess,
        onError: onDataFetchError,
    });

    const onUserDemographicsFetchSuccess = (demograhpicsData: any) => {
        setUserDemographics({
            date_of_birth: demograhpicsData.date_of_birth || "",
            country_id: demograhpicsData.country_id,
            country_name: demograhpicsData.country_name || "",
            country_code: demograhpicsData.country_code || "",
            state_id: demograhpicsData.state_id,
            state_name: demograhpicsData.state_name || "",
            state_code: demograhpicsData.state_code || "",
            state_country_id: demograhpicsData.state_country_id,
            race_id: demograhpicsData.race_id,
            race_name: demograhpicsData.race_name || "",
            race_description: demograhpicsData.race_description || "",
            gender_id: demograhpicsData.gender_id,
            gender_name: demograhpicsData.gender_name || "",
            relationship_status_id: demograhpicsData.relationship_status_id,
            relationship_status_name: demograhpicsData.relationship_status_name || "",
            relationship_status_description: demograhpicsData.relationship_status_description || "",
            sexual_orientation_id: demograhpicsData.sexual_orientation_id,
            sexual_orientation_name: demograhpicsData.sexual_orientation_name || "",
            sexual_orientation_description: demograhpicsData.sexual_orientation_description || "",
            education_id: demograhpicsData.education_id,
            education_name: demograhpicsData.education_name || "",
            education_description: demograhpicsData.education_description || "",
            income_id: demograhpicsData.income_id,
            min_income: demograhpicsData.min_income,
            max_income: demograhpicsData.max_income,
            currency: demograhpicsData.currency || "USD",
            currency_symbol: demograhpicsData.currency_symbol || "$",
            political_orientation_id: demograhpicsData.political_orientation_id,
            political_orientation_name: demograhpicsData.political_orientation_name || "",
            political_orientation_description: demograhpicsData.political_orientation_description || "",
        });
    };
    const { data: userDemographicsData } = getDemographicsData({
        onSuccess: onUserDemographicsFetchSuccess,
        onError: onDataFetchError,
    });
    const onOpenModal = () => {
        setIsOpenModal(true);
    };

    const onCloseModal = () => {
        setIsOpenModal(false);
    };

    const onSaveDemographics = async (data: DemographicRequestBody) => {
        try {
            await updateUserDemographics({ data });
            onCloseModal();
        } catch (error) {
            undefined;
        }
    };

    return (
        <Box sx={styles.container}>
            <Box sx={styles.containerChild}>
                <Box sx={styles.statusHeader}>
                    <Typography sx={styles.title}>User Profile</Typography>
                    {currentUserHasAdminRole(roles) && (
                        <Button
                            variant="outlined"
                            startIcon={<SwitchAccountIcon />}
                            style={styles.adminBtn}
                            onClick={() => navigate("/v2/admin/clusters")}
                        >
                            Admin
                        </Button>
                    )}
                </Box>

                <Box sx={styles.userInfo} id="user-details-card">
                    <img src={user?.img} alt="Avatar" style={styles.avatar} />
                    <Box>
                        <Typography sx={{ ...styles.text, mb: 5, ...styles.textGray }}>{user?.role || ""}</Typography>
                        <Typography sx={{ ...styles.title, mb: 7 }}>
                            {user ? getUserName(user) : ""}&nbsp;{" "}
                            <VerifiedUserIcon fontSize="inherit" sx={{ color: "#29ABE2", verticalAlign: "middle" }} />
                        </Typography>
                        <Typography sx={styles.textGray}>{user?.email || ""}</Typography>
                    </Box>
                </Box>
                {/* {viewType === ViewType.VIEW_MODE_LIST && (
                <Box sx={styles.flexContainer}>
                    <Box sx={styles.status}>
                        <Box sx={styles.statusHeader}>
                            <Typography sx={styles.textGray}>
                                Number of <br /> Inquiries&nbsp;
                            </Typography>
                            <img src={InfoIcon} alt="i" style={styles.infoIcon} />
                        </Box>
                        <Typography sx={styles.statusValue}>{status.flaggedVignettes}</Typography>
                    </Box>
                    <Box sx={styles.status}>
                        <Box sx={styles.statusHeader}>
                            <Typography sx={styles.textGray}>
                                Unpacked
                                <br /> Vignettes&nbsp;
                            </Typography>
                            <img src={InfoIcon} alt="i" style={styles.infoIcon} />
                        </Box>
                        <Typography sx={styles.statusValue}>{status.unpackedVignettes}</Typography>
                    </Box>
                    <Box sx={{ ...styles.status, ...styles.statusGreen }}>
                        <Box sx={styles.statusHeader}>
                            <Typography sx={{ ...styles.textGray, ...styles.colorGreen }}>
                                Cultural
                                <br /> Profiency&nbsp;
                            </Typography>
                            <img src={InfoIcon} alt="i" style={styles.infoIcon} />
                        </Box>
                        <Typography sx={{ ...styles.statusValue, ...styles.colorGreen }}>
                            {status.culturalProfiency}
                        </Typography>
                    </Box>
                </Box>
            )} */}

                {viewType === ViewType.VIEW_MODE_LIST && (
                    <Box sx={styles.flexContainer} id="user-status-cards">
                        <Box sx={styles.status}>
                            <Box sx={styles.statusHeader}>
                                <Typography sx={styles.textGray}>
                                    Active <br /> Clusters&nbsp;
                                </Typography>
                                {/* <img src={InfoIcon} alt="i" style={styles.infoIcon} /> */}
                            </Box>
                            <Typography sx={styles.statusValue}>{clusterStatus?.activeClusters}</Typography>
                        </Box>
                        <Box sx={styles.status}>
                            <Box sx={styles.statusHeader}>
                                <Typography sx={styles.textGray}>
                                    Completed
                                    <br /> Clusters&nbsp;
                                </Typography>
                                {/* <img src={InfoIcon} alt="i" style={styles.infoIcon} /> */}
                            </Box>
                            <Typography sx={styles.statusValue}>{clusterStatus?.completedClusters}</Typography>
                        </Box>
                        <Box sx={{ ...styles.status, ...styles.statusGreen }}>
                            <Box sx={styles.statusHeader}>
                                <Typography sx={{ ...styles.textGray, ...styles.colorGreen }}>
                                    AI CoPilots
                                    <br /> Queries&nbsp;
                                </Typography>
                                {/* <img src={InfoIcon} alt="i" style={styles.infoIcon} /> */}
                            </Box>
                            <Typography sx={{ ...styles.statusValue, ...styles.colorGreen }}>
                                {clusterStatus?.coPilotsQueries}
                            </Typography>
                        </Box>
                    </Box>
                )}
                <Box sx={styles.flexContainer} id="org-and-group">
                    <Box sx={styles.organizationContainer}>
                        <Typography sx={styles.textGray}>Organization</Typography>
                        <Typography sx={styles.text}>{organization}</Typography>
                    </Box>
                    <Box sx={styles.groupContainer}>
                        <Typography sx={styles.textGray}>Group</Typography>
                        <Typography sx={styles.text}>{group}</Typography>
                    </Box>
                </Box>
                <Button
                    sx={styles.btnViewDemographics}
                    onClick={() => setIsOpenModal((isOpenModal) => !isOpenModal)}
                    id="demographics-button"
                >
                    Manage Demographics
                </Button>
            </Box>
            <Box sx={styles.containerChild}>
                <Box id="copilot-card">
                    <Box sx={styles.copilotsHeader}>
                        <Typography sx={styles.pilotHeaderText}>AI CoPilots</Typography>

                        <Button
                            variant="outlined"
                            style={styles.newBtn}
                            onClick={() => navigate(`/aicopilot/${coPilotsList?.id}`)}
                        >
                            New
                        </Button>
                    </Box>
                    <CoPilotsCarousel coPilotsList={coPilotsList} />
                </Box>
                <DemographicsModal
                    open={isOpenModal}
                    options={demographicsOptions}
                    data={userDemographics}
                    onClose={onCloseModal}
                    onSave={onSaveDemographics}
                />
            </Box>
        </Box>
    );
};

export default UserProfile;
