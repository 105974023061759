import { Box, Typography } from "@mui/material";
import Footer from "components/Footer";
import React from "react";
import styles from "./styles";
import Logo from "assets/images/vsorts-logo.png";
import PermissioFaceFrownIcon from "assets/icons/face-frown-red.svg";
import Header from "components/Dashboard/Header";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "pages/adminV2/Components";

function InvitationErrorPage() {

    const navigate = useNavigate();

    return (
        <Box sx={{ ...styles.layout, height: "100vh" }}>
            {/* Header */}
            <Box sx={styles.headerContainer}>
                <Link to="/">
                    <img src={Logo} alt="Logo" style={styles.logo} />
                </Link>
            </Box>

            <Box sx={styles.container}>
                <Box sx={styles.messageContainer}>
                    <img src={PermissioFaceFrownIcon} alt="view" height={60}/>
                    <Typography sx={styles.title}>Oops! Your invitation has expired.</Typography>
                    <Typography sx={styles.body}>Please request a new invitation from your admin</Typography>
                    <Button style={styles.button} type="primary" text="Go to VSorts homepage" onClick={() => navigate("/")} />
                </Box>
                <Footer />
            </Box>
        </Box>
    );
}

export default InvitationErrorPage;
