import { Button, Typography } from '@mui/material'
import confirm from 'assets/icons/Confirm.svg'
import failed from 'assets/icons/Failed.png'
import "./styles.css"

const PaymentStatusModal = ({setOpen, paymentDetails}: any) => {
  console.log(paymentDetails)
  return (
    <div className='paymentModal'>
      <img src={paymentDetails.status ==="SUCCESS" ? confirm : failed} alt='confirm' />
      <div className='paymentModal-content'>
        <div className='paymentModal-content-header'>
          {
            paymentDetails.status === "SUCCESS" ? (
              <>
                <p>Your account has been upgraded to </p>
                <Typography sx={{color: "#29ABE2", fontWeight: 700, fontSize: "1.2rem"}}> {paymentDetails?.subscriptionInfo?.stripeDetails.name}</Typography>
              </>
            ) : (
              <p>We’re sorry, payment has failed and your account was not upgraded</p>
            )
          }
        </div>
        <p className='paymentModal-content-description'>
          {
            paymentDetails.status === "SUCCESS" ? 
              "To make changes to your subscription status, go to your profiles billing settings or contact VSorts Support to request changes."
              : "Please try again or contract support for help if this issue continues"
          }
        </p>
      </div>
      <Button onClick={() => setOpen(false)}>Done</Button>
    </div>
  )
}

export default PaymentStatusModal