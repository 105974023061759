import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import { Box, Button, Grid, Typography } from "@mui/material";
import VSCard from "components/VSCard/VSCard";
import { useEffect, useMemo, useState } from "react";
import styles from "./styles";
import VignetteTextDisplay from "./vignetteTextDisplay";
export default function SelectedCategoryVignettes({ flaggedVignettes, selectedDomain, openUnpackingDialog }: any) {
    const [correctVignetteCount, setCorrectVignetteCount] = useState(0);
    const [totalVignetteCount, setTotalVignetteCount] = useState(0);
    console.log("parkinglot")

    useEffect(() => {
        setCorrectVignetteCount(flaggedVignettes?.selectedDomain?.flaggedVignettesCount);
        setTotalVignetteCount(flaggedVignettes?.selectedDomain?.totalVignettesCount);
    }, [flaggedVignettes]);

    const isGreaterThanFiftyPercent = useMemo(() => {
        return correctVignetteCount > (totalVignetteCount / 2);
      }, [correctVignetteCount, totalVignetteCount]);

      console.log(isGreaterThanFiftyPercent)
    return (
        <>
            <Grid item xs={5}>
                <VSCard sx={styles.congratsCard}>
                    <Typography sx={styles.congratsText}>
                        {
                            isGreaterThanFiftyPercent ? "Congrats!" : ""
                        }
                         You got{" "}
                        <span style={{ color: "#009245", fontWeight: "bold" }}>
                            {flaggedVignettes[0]?.["totalVignettesCount"] - flaggedVignettes[0]?.flaggedVignettesCount}{" "}
                            /{flaggedVignettes[0]?.["totalVignettesCount"]} vignettes correct.
                        </span>
                    </Typography>
                </VSCard>
                <VSCard sx={styles.explanationCard}>
                    <Typography sx={styles.explanationHeadingText}>Explanation</Typography>
                    <Typography sx={styles.explanationText}>
                        Click on a blue marked vignette to see an explanation as to why it was marked as wrong
                    </Typography>
                    <Box sx={styles.explanationOuterBox}>
                        <Box sx={styles.explanationInnerBox}>
                            <Button aria-label="delete" variant="outlined" sx={styles.iconButton}>
                                <ThumbUpIcon sx={{ color: "#C0C0C0", fontSize: "22px" }} />
                            </Button>
                            <Button aria-label="delete" variant="outlined" sx={styles.iconButton}>
                                <ThumbDownIcon sx={{ color: "#C0C0C0", fontSize: "22px" }} />
                            </Button>
                        </Box>
                        <Button variant="outlined" sx={styles.unpackButton} disabled>
                            Unpack
                        </Button>
                    </Box>
                </VSCard>
            </Grid>
            <Grid item xs={5}>
                <Box style={{ marginLeft: "2vw" }}>
                    {flaggedVignettes?.map((eachDomain: { [x: string]: any[] }) => {
                        if (eachDomain["domain_name"] == selectedDomain) {
                            return (
                                <>
                                    {eachDomain["vignettes"].map((eachVignette, index) => (
                                        <Grid item xs={10} onClick={() => openUnpackingDialog(eachVignette)}>
                                            <Typography sx={styles.eachCategory}>
                                                {eachVignette["user_rank_category"]}
                                            </Typography>
                                            <VignetteTextDisplay
                                                vignette_text={eachVignette["vignette_text"]}
                                                unpacked={eachVignette["unpacked"]}
                                            />
                                        </Grid>
                                    ))}
                                </>
                            );
                        }
                    })}
                </Box>
            </Grid>
        </>
    );
}
