import { Box, Button, Typography } from "@mui/material";
import DiscussIcon from "assets/icons/discuss-gradient.svg";
import EmailIcon from "assets/icons/email-gradient.svg";
import FacebookIcon from "assets/icons/facebook-gradient.svg";
import PhoneIcon from "assets/icons/phone-gradient.svg";
import TwitterIcon from "assets/icons/twitter-gradient.svg";
import YoutubeIcon from "assets/icons/youtube-gradient.svg";
import Footer from "components/Footer";
import { CALENDLY_LINK, EMAIL, PHONE_NUMBER } from "constants/contact.constants";
import { SECTIONS } from "constants/landing-page.constants";
import { DISCUSS_URL, FACEBOOK_URL, TWITTER_URL, YOUTUBE_URL } from "constants/social.constants";
import styles from "./styles";

const OldFooter = ({ onScroll, footerRef }: any) => {
    return (
        <Box sx={styles.container} ref={footerRef}>
            <Box sx={styles.mainFooter}>
                <Box sx={styles.mainFooterLeft}>
                    <Footer />
                    <a href={CALENDLY_LINK} target="_blank" rel="noreferrer" style={styles.demoLink}>
                        <Typography sx={styles.requestMeetingText}>Request a Meeting...</Typography>
                    </a>
                </Box>
                <Box sx={styles.mainFooterRight}>
                    <Box sx={styles.info}>
                        <Typography sx={styles.infoTextBold}>VSorts™</Typography>
                        <Typography sx={styles.infoText}>Changing the world one vignette at a time.</Typography>
                    </Box>
                    <Box sx={styles.followUs}>
                        <Typography sx={styles.infoTextBold}>Follow us</Typography>
                        <Box sx={styles.socialIcons}>
                            <a href={FACEBOOK_URL} style={styles.menuSocial} target="_blank" rel="noreferrer">
                                <img src={FacebookIcon} alt="Facebook" style={styles.socialIcon} />
                            </a>
                            <a href={TWITTER_URL} style={styles.menuSocial} target="_blank" rel="noreferrer">
                                <img src={TwitterIcon} alt="Twitter" style={styles.socialIcon} />
                            </a>
                            <a href={DISCUSS_URL} style={styles.menuSocial} target="_blank" rel="noreferrer">
                                <img src={DiscussIcon} alt="Discuss" style={styles.socialIcon} />
                            </a>
                            <a href={YOUTUBE_URL} style={styles.menuSocial} target="_blank" rel="noreferrer">
                                <img src={YoutubeIcon} alt="Youtube" style={styles.socialIcon} />
                            </a>
                        </Box>
                    </Box>
                    <a href={DISCUSS_URL} target="_blank" rel="noreferrer" style={styles.btnSignUp}>
                        Contact Us
                    </a>
                    <Box sx={styles.flexContainer}>
                        <a href={`tel:${PHONE_NUMBER.number}`} style={styles.menuLink}>
                            <img src={PhoneIcon} alt="" style={styles.menuIcon} />
                            <Typography sx={styles.menuInfo}>{PHONE_NUMBER.format}</Typography>
                        </a>
                        <a href={`mailto: ${EMAIL}`} style={styles.menuLink}>
                            <img src={EmailIcon} alt="@" style={styles.menuIcon} />
                            <Typography sx={styles.menuInfo}>{EMAIL}</Typography>
                        </a>
                    </Box>
                </Box>
            </Box>
            <Box sx={styles.footerMenu}>
                <Box sx={styles.footerMenuInfoContainer}>
                    <Typography sx={styles.desc}>Terms & Conditions | Privacy Policy ©&nbsp;</Typography>
                    <Typography sx={styles.infoTextBold}>{new Date().getFullYear()}</Typography>
                </Box>
                <Box sx={styles.flexContainer}>
                    <Button sx={styles.menuItem} onClick={() => onScroll(SECTIONS.VSORTS)}>
                        VSorts™
                    </Button>
                    <Button sx={styles.menuItem} onClick={() => onScroll(SECTIONS.FEATURES)}>
                        Features
                    </Button>
                    <Button sx={styles.menuItem} onClick={() => onScroll(SECTIONS.ENTERPRISE)}>
                        Enterprise
                    </Button>
                    <Button sx={styles.menuItem} onClick={() => onScroll(SECTIONS.TESTIMONIALS)}>
                        Testimonials
                    </Button>
                    <Button sx={styles.menuItem} onClick={() => onScroll(SECTIONS.CONTACT)}>
                        Contact
                    </Button>
                </Box>
            </Box>
        </Box>
    );
};

export default OldFooter;
