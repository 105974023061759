import RequireAdminAuth from "components/RequireAdminAuth";
import React, { ForwardRefExoticComponent } from "react";

// Loading Indicator
import CircularLoadingProgress from "components/CircularProgress";

type PropsType = {
    Component: React.FC | ForwardRefExoticComponent<React.RefAttributes<unknown>>
}

// `LazyProtectedAdminComponentWrapper` is the function to Lazy Load any component which is Authentication Protected
// 1. Component -> Represents the component that needs to be lazy loaded and Authentication protected
export default function LazyProtectedAdminComponentWrapper({ Component }: PropsType) {
    return (
        <RequireAdminAuth>
            <React.Suspense fallback={<CircularLoadingProgress />}>
                <Component />
            </React.Suspense>
        </RequireAdminAuth>
    );
}
