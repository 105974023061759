import CollapsiblePanel from "../../../components/CollapsiblePanel/CollapsiblePanel";
import "./FAQs.css";

const FAQContent = [
    {
        no: 1,
        header: "What is VSorts™ AI?",
        content:
            "VSorts™ AI is a purpose-built SaaS platform for researchers, educational leaders, and consultants designed to streamline qualitative data management. It supports multimodal data collection—including text, video, and audio—alongside data structuring, secure storage, and AI-powered qualitative analysis and reporting. By addressing common challenges such as fragmented workflows, underutilized data, and delays in delivering actionable insights, VSorts™ AI ensures a seamless and efficient approach to qualitative data handling.",
    },
    {
        no: 2,
        header: "Who is the ideal VSorts™ AI User?",
        content:
            "The ideal VSorts™ AI user includes researchers, organizational leaders, consultants, and educators who handle qualitative data in their work. Whether you're analyzing multimodal data, managing stakeholders, or delivering actionable insights, VSorts™ AI provides tools to streamline workflows, enhance efficiency, and support informed decision-making. It's especially valuable for those seeking AI-powered solutions for data collection, analysis, and reporting while ensuring compliance with IRB, FERPA, and HIPAA standards.",
    },
    {
        no: 3,
        header: "What are AI CoPilots?",
        content: (
            <div>
                <p>
                    VSorts™ AI CoPilots are advanced AI-powered tools within the VSorts™ SaaS platform, designed to act
                    as data analysts or coaches and enhance qualitative workflows. Leveraging expert training and
                    keystone datasets, they interpret complex multimodal data—such as open-ended surveys, audio, and
                    video—through diverse social science and organizational frameworks, including Cultural Proficiency©.
                    By automating tasks like data structuring and coding (e.g., provisional and sub-coding), CoPilots
                    deliver real-time feedback and actionable insights with high accuracy and efficiency, reducing
                    cognitive load and empowering users in research, organizational development, and consulting to make
                    faster, data-driven decisions.
                </p>
                <p style={{ fontWeight: "bold" }}>Cultural Proficiency© CoPilot powered by VSorts™ AI </p>
                <p>
                    The Cultural Proficiency© CoPilot, powered by VSorts™ AI, applies the Cultural Proficiency©
                    Framework to help organizations and individuals navigate cultural dynamics effectively. By
                    identifying cultural barriers and biases, the CoPilot offers tools for self-assessment, managing
                    differences, institutionalizing cultural knowledge, and adapting to diversity. Widely used in
                    educational and organizational settings, it fosters understanding, respect, and inclusion, creating
                    more culturally competent and inclusive environments.
                </p>
            </div>
        ),
    },
    {
        no: 4,
        header: "How are VSorts™ AI CoPilots trained? Are humans still involved?",
        content:
            "VSorts™ AI CoPilots combines advanced AI algorithms with human expertise to deliver precise, socially and culturally relevant qualitative analysis. Trained on extensive datasets and guided by subject matter experts, they interpret multimodal data—text, audio, and video—through frameworks like Cultural Proficiency© and proprietary social science and organizational models. Human oversight ensures continuous accuracy, adaptability, and alignment with best practices, making CoPilots an essential tool for educational, organizational, and research applications. Integrating AI efficiency and expert input sets a new benchmark for actionable, socioculturally informed insights.",
    },
];

const FAQs: React.FC = () => {
    return (
        <div className="faqs">
            <div className="faqsContainer">
                <h1>Frequently Asked Questions</h1>
                {FAQContent.map((faq) => (
                    <CollapsiblePanel header={faq.header} content={faq.content} key={faq.no} />
                ))}
            </div>
        </div>
    );
};

export default FAQs;
