import { useQuery } from "react-query";

// Axios Interceptor
import { request } from "utils/axios-utils";

// Constants
import { APIMethodConstants } from "constants/api.method.constants";
import { NetworkConstants } from "constants/network.constants";
import { QueryKeyConstants } from "constants/query.key.constants";

export const fetchActivityTypes = () =>
    request({ url: NetworkConstants.getVSetsActivityTypes, method: APIMethodConstants.get });

export const useGetVSetsActivityTypeQuery = ({ activeTab, onSuccess, onError }: any) => {
    return useQuery([QueryKeyConstants.fetchVsetActivityTypes, activeTab], fetchActivityTypes, {
        staleTime: Infinity,
        onSuccess,
        onError,
        select: (data) => {
            return data.data.data;
        },
    });
};
