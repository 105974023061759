import { FormTypes } from "constants/enum.constants";
import { StringConstants } from "constants/string.constants";
import { SwalConstants } from "constants/swal.constants";
import { bulkDeleteFormQuestions } from "hooks/surveyFormData";
import { DynamicModel } from "pages/adminV2/Components";
import { useNavigate } from 'react-router-dom';
import { SwalAlert } from "utils/swal-utils";
import "./DeleteSurvey.css";

interface props {
    open: boolean;
    setOpen: (value: boolean) => void;
    selectedRows?: readonly number[];
    typeOfRequest: "Form" | "CoPilot";
}

function DeleteSurveyModel({ open, setOpen, selectedRows, typeOfRequest }: props) {
    // Function to handle Deleting of a Form Question

    const numberOfRows = selectedRows?.length ?? 0;
    const navigate = useNavigate()

const bulkDeleteQuestion = async (ids: any) => {
        const response = await bulkDeleteFormQuestions(ids, { form_type: FormTypes.presurvey }).catch((error) => {
            SwalAlert({
                icon: SwalConstants.warning,
                title: StringConstants.operationErrorTitle,
                description: StringConstants.operationErrorDescription,
            });
        });

        if(response?.data?.status === "SUCCESS") {
            setOpen(false);
            navigate("/v2/admin/forms")
        }
    };

    const handleBulkDelete = async () => {
        bulkDeleteQuestion({ ids: selectedRows });
    };


    return (
        <DynamicModel
            open={open}
            setOpen={setOpen}
            title={
                  `Are you sure you want to delete ${numberOfRows} Form${numberOfRows > 1 ? "s" : ""}??`
            }
            type={typeOfRequest}
        >
            <div className="deleteModel">
                <p className="deleteModelContainer__desc">You cannot undo this action once you confirm</p>
                <div className="deleteModelContainer">
                    <button onClick={() => handleBulkDelete()}>
                        {`Delete Form${numberOfRows > 1 ? "s" : ""}`}
                    </button>
                </div>
            </div>
        </DynamicModel>
    );
}

export default DeleteSurveyModel;
