import {
    Box,
    Checkbox,
    CssBaseline,
    FormControl,
    FormControlLabel,
    FormGroup,
    IconButton,
    Typography,
} from "@mui/material";
import EyeIcon from "assets/icons/eye.svg";
import TrashIcon from "assets/icons/trash-red.svg";
import Header from "components/Dashboard/Header";
import {
    useGetClusterDetails,
    useGetClusterIsPublicStatus,
    useRevertClusterIsPublic,
} from "hooks/VsortsClusterFormData";
import _ from "lodash";
import { Button, Tabs } from "pages/adminV2/Components";
import AdminHeader from "pages/adminV2/Components/AdminHeader";
import AddOptions from "pages/adminV2/CoPilots/AddOptions";
import { useEffect, useRef, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import styles from "./styles";

interface tab {
    name: string;
    url: string;
}

const tabs: tab[] = [
    {
        name: "Cluster",
        url: "/v2/admin/clusters/create",
    },
    {
        name: "Assign",
        url: "/v2/admin/clusters/create/assign",
    },
];

function CreateClusters() {
    const childRef = useRef<any>();
    const searchParams = new URLSearchParams(window.location.search);
    const [title, setTitle] = useState("");
    const id = parseInt(searchParams.get("id") ?? "");
    const isEdit = searchParams.get("isEdit");
    const isView = !!searchParams.get("isView") && searchParams.get("isView") === "true";
    const [optionsOpen, setOptionsOpen] = useState(false);
    const [clusterOptions, setClusterOptions] = useState<any>([
        {
            text: "",
            checked: false,
        },
    ]);

    const originalClusterOptions = useRef<any>([
        {
            text: "",
            checked: false,
        },
    ]);

    const [activeTab, setActiveTab] = useState<tab>({
        name: "",
        url: "",
    });

    useEffect(() => {
        refetchClusterDetailsData();
    }, [activeTab]);

    const location = useLocation();
    const navigate = useNavigate();

    const onTabSelect = (tab: tab) => {
        setActiveTab(tab);
        navigate(`${tab.url}?title=${title}&id=${id}&isEdit=${isEdit}`);
    };

    useEffect(() => {
        const tab: tab = tabs.find((tab) => location.pathname == tab.url) || {
            name: "",
            url: "",
        };
        setActiveTab(tab);
        refetchClusterDetailsData();
    }, []);

    useEffect(() => {
        if (optionsOpen) {
            refetchData();
        }
    }, [optionsOpen]);

    // handle save click from child Outlet components
    const handleSaveClick = () => {
        if (childRef.current) {
            if (typeof childRef.current.saveCluster === "function") {
                childRef.current.saveCluster();
            }
        }
    };

    // handle delete click from child Outlet components
    const handleDeleteClick = () => {
        if (childRef.current) {
            if (typeof childRef.current.deleteCluster === "function") {
                childRef.current.deleteCluster();
            }
        }
    };

    const handleOptionsClick = () => {
        setOptionsOpen(true);
    };

    const onSuccessFetch = (data: any) => {
        setClusterOptions([
            {
                text: "Publish this Cluster to the VSorts AI platform and allow the public to view and use it",
                checked: !!data?.is_public,
            },
        ]);
        originalClusterOptions.current = [
            {
                text: "Publish this Cluster to the VSorts AI platform and allow the public to view and use it",
                checked: !!data?.is_public,
            },
        ];
    };

    const onErrorFetch = (error: any) => {
        console.log(error);
    };

    const {
        isFetching: isDataFetching,
        isLoading: isDataLoading,
        isError: isDataError,
        data: allData,
        error,
        refetch: refetchData,
    } = useGetClusterIsPublicStatus({
        clusterID: id,
        isEnabled: optionsOpen,
        onSuccess: onSuccessFetch,
        onError: onErrorFetch,
    });

    const onClusterDetailsFetchSuccess = (data: any) => {
        setTitle(data?.cluster_name);
        if (childRef.current) {
            if (typeof childRef.current.handleOriginalTitle === "function") {
                childRef.current.handleOriginalTitle(data?.cluster_name);
            }
        }
    };

    const onClusterDetailsFetchError = (err: any) => {
        console.log(err);
    };

    const {
        isFetching: isClusterDetailsDataFetching,
        isLoading: isClusterDetailsDataLoading,
        isError: isClusterDetailsDataError,
        data: clusterDetailsData,
        error: clusterDetailsError,
        refetch: refetchClusterDetailsData,
    } = useGetClusterDetails({
        clusterID: id,
        isEnabled: optionsOpen,
        onSuccess: onClusterDetailsFetchSuccess,
        onError: onClusterDetailsFetchError,
    });

    const onSaveSuccess = (data: any) => {
        console.log("success", data.data.data);
        setOptionsOpen(false);
    };
    const onSaveError = (error: any) => {
        console.log("error", error);
        setOptionsOpen(false);
    };

    const {
        mutate: callingCluster,
        isError: isPostError,
        reset: resetCallAICoPilot,
        isLoading: isPostLoading,
        error: postError,
    } = useRevertClusterIsPublic({
        clusterID: id,
        onSuccess: onSaveSuccess,
        onError: onSaveError,
    });

    const handleSaveOptions = async () => {
        console.log(id);
        await callingCluster();
    };

    const handleOptionSelection = (event: any) => {
        const nextState = clusterOptions.map((eachCluster: any) => {
            if (eachCluster?.text == event.target.value) {
                return {
                    ...eachCluster,
                    checked: !eachCluster?.checked,
                };
            } else {
                return eachCluster;
            }
        });
        setClusterOptions(nextState);
    };

    const handleTitleChange = (event: any) => {
        setTitle(event.target.value);
        if (childRef.current) {
            if (typeof childRef.current.handleTitle === "function") {
                childRef.current.handleTitle(event.target.value);
            }
        }
    };
    return (
        <Box sx={{ display: "flex", flexDirection: "column", margin: "0rem 3rem" }}>
            <CssBaseline />
            <Header title="Admin Dashboard" redirectTo="/v2/admin/clusters" />
            <AdminHeader
                title="Create a cluster"
                onIconClick={() => {
                    undefined;
                }}
            />

            {/* Title Section */}
            <Box sx={styles.container}>
                <IconButton sx={{ width: "42px", height: "42px" }}>
                    <img src={EyeIcon} alt="view" />
                </IconButton>

                <input
                    className="titleInput"
                    value={title ?? ""}
                    onChange={handleTitleChange}
                    disabled={window.location.pathname.indexOf("assign") >= 0 ? true : isView}
                />

                {!isView && (
                    <>
                        <Button
                            style={{ minWidth: "195px" }}
                            type="primaryOutlined"
                            text="Options"
                            onClick={() => handleOptionsClick()}
                        />

                        <Button type="primary" text="Save" onClick={() => handleSaveClick()} />

                        <IconButton sx={{ width: "42px", height: "42px" }} onClick={() => handleDeleteClick()}>
                            <img src={TrashIcon} alt="Delete" />
                        </IconButton>
                    </>
                )}
            </Box>

            <div style={{ marginTop: "10px" }}>
                <Tabs
                    tabs={tabs}
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                    onTabSelect={onTabSelect}
                    enableDataCheck={true}
                />{" "}
            </div>

            <Box component="main" sx={{ flexGrow: 1, p: 3, mt: 10 }}>
                <Outlet context={{ childRef }} />
            </Box>

            {optionsOpen && (
                <AddOptions
                    open={optionsOpen}
                    setOpen={setOptionsOpen}
                    title={"Cluster options"}
                    subTitle={"Add more control over a cluster with the options listed below"}
                >
                    <Box sx={{ mt: 3 }}>
                        {clusterOptions && (
                            <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
                                <FormGroup>
                                    {(clusterOptions || []).map((eachOption: any, index: number) => {
                                        return (
                                            <FormControlLabel
                                                key={index}
                                                value={eachOption?.text}
                                                control={
                                                    <Checkbox
                                                        checked={eachOption?.checked}
                                                        onChange={handleOptionSelection}
                                                        name={eachOption?.text}
                                                    />
                                                }
                                                label={
                                                    <Typography
                                                        fontSize={18}
                                                        fontWeight={400}
                                                        sx={{ margin: "20px 0px 0px 10px" }}
                                                    >
                                                        {eachOption?.text}
                                                    </Typography>
                                                }
                                            />
                                        );
                                    })}
                                </FormGroup>
                            </FormControl>
                        )}
                    </Box>

                    <Box style={{ display: "flex", justifyContent: "center", marginTop: "30px" }}>
                        <Button
                            // disabled={checkDisabled() == 0 ? true : false}
                            disabled={_.isEqual(clusterOptions, originalClusterOptions.current)}
                            type="primary"
                            text="Save"
                            onClick={() => handleSaveOptions()}
                        />
                    </Box>
                </AddOptions>
            )}

            {/* <ClusterSummary clusterStepItems={clusterStepItems} handleAddClusterActivity={handleAddClusterActivity} />

            <Box component="main" sx={{ flexGrow: 1, p: 3, mt: 0 }}>
                {ClusterStepComponent}
            </Box>  */}
        </Box>
    );
}

export default CreateClusters;
