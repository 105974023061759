import { useMutation, useQueryClient } from "react-query";

// Axios Interceptor
import { request } from "utils/axios-utils";

// Constants
import { APIMethodConstants } from "constants/api.method.constants";
import { NetworkConstants } from "constants/network.constants";
import { QueryKeyConstants } from "constants/query.key.constants";

const postVSetsReaction = ({ url, data }: any) => {
  return request({ url, method: APIMethodConstants.post, data });
};

// usePostVSetsReactionMutation -> To create or update a VSets category
export const usePostVSetsReactionMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
      ({ userId, clusterStepId, domainId }: any) => {
        const url = NetworkConstants.vSetsReactionNextStep;
        return postVSetsReaction({ url, data: { user_id: userId, cluster_step_id: clusterStepId, domain_id: domainId } });
      },
      {
          onSuccess: () => {
              queryClient.invalidateQueries(QueryKeyConstants.fetchVSetReactionNextStep);
          },
      }
  );
};