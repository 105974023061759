import { useParams, useSearchParams } from "react-router-dom";

// Form Builder - To Create a drag-and-drop form
import FormBuilder from "pages/admin/dashboard/components/FormBuilder";

import "./style.css";

// `SurveyForm` is the Function to show the user the Drag and Drop Form Builder
// 1. surveyQuestionsType -> Represents the type of Survey Questions (Pre-Survey = pre_survey_questions, Post-Survey = post_survey_questions and Final Feedback = final_feedback_questions)
// 2. isCreate -> Represents if the Form needs to be opened in Creator Mode
// 3. isEdit -> Represents if the Form needs to be opened in Editor Mode
// 4. isView -> Represents if the Form needs to be opened in Viewer Mode
export default function SurveyForm({ surveyQuestionsType, isCreate = false, isEdit = false, isView = false, isUnpacking = false, isScoring = false }: any) {
    /// Survey Name
    const [searchParams] = useSearchParams();
    const surveyName = searchParams.get("name");

    /// Survey ID
    const { surveyID } = useParams();
    //containerID is passed when Unpacking questions need to be created as they work with containedID from VSet
    const { containerID } = useParams();

    return (
        <FormBuilder
            formType={surveyQuestionsType}
            isCreate={isCreate}
            isEdit={isEdit}
            isView={isView}
            surveyName={surveyName}
            surveyID={(isUnpacking || isScoring) ? containerID : surveyID}
        />
    );
}
