import { Box, IconButton, Typography } from "@mui/material";
import SquareBorderIcon from "assets/icons/square-with-border.svg";
import { APIMethodConstants } from "constants/api.method.constants";
import { VSetParameterTypes } from "constants/enum.constants";
import {
    useCreateEditVsetParameters,
    useVSortsAllParameters,
    useVSortsContainerParameterData,
} from "hooks/VSortsContainerTableData";
import { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from "react";
import { useOutletContext } from "react-router-dom";
import AdminSelect, { OptionType } from "../../../Components/AdminSelect";
import { IVSetParameter } from "../../types";
import styles from "./styles";
import { setTabNavigation } from "app/slices/TabNavigationSlice";
import { useDispatch } from "react-redux";

const CreateVsetParameters = forwardRef(function CreateVsetParameters() {
    const { childRef }: any = useOutletContext();

    const searchParams = new URLSearchParams(window.location.search);
    const title = searchParams.get("title");
    const containerId = searchParams.get("id");
    const isView = searchParams.get("isView") === "true";
    const dispatch = useDispatch();

    const [offsetParameter, setOffsetParameter] = useState<string>();
    const orgOffsetParameter = useRef<string>();

    const [minUnpackingParameter, setMinUnpackingParameter] = useState<string>();
    const orgMinUnpackingParameter = useRef<string>();

    const [maxUnpackingParameter, setMaxUnpackingParameter] = useState<string>();
    const orgMaxUnpackingParameter = useRef<string>();

    const [tallyParameter, setTallyParameter] = useState<boolean>();
    const orgTallyParameter = useRef<string>();

    const [offsetParameterId, setOffsetParameterId] = useState<number | null>();
    const [minUnpackingParameterId, setMinUnpackingParameterId] = useState<number | null>();
    const [maxUnpackingParameterId, setMaxUnpackingParameterId] = useState<number | null>();
    const [tallyParameterId, setTallyParameterId] = useState<number | null>();

    // `useVSortsAllParameters` is the query to fetch VSorts All parameter kinds
    const {
        isLoading: isvSetsParameterTypesLoading,
        isError: isvSetsParameterTypesError,
        data: vSetsParameterTypes,
        error: vSetsParameterTypesError,
        refetch: refetchVSetsAllParametersData,
    } = useVSortsAllParameters({
        onSuccess: (response: any) => {
            undefined;
        },
        // onError: onVSetsParameterDataFetchError,
    });

    // `useVSortsContainerParameterData` is the query to fetch All parameters in a VSorts container
    const {
        isFetched,
        isFetching: isVSetsContainerParametersFetching,
        isLoading: isVSetsContainerParametersLoading,
        isError: isVSetsContainerParametersError,
        data: vSetsContainerParameters,
        error: vSetsContainerParametersError,
        refetch: refetchVSetParameters,
    } = useVSortsContainerParameterData({
        containerID: containerId,
        onSuccess: (response: any) => {
            undefined;
        },
        // onError: onVSetsContainerParameterDataFetchError,
    });

    // `createVSetParameters` is the mutation to create VSorts Parameters
    // 1. mutate -> Represents the mutation function to make the network request
    // 2. isError -> Represents the error caused while creating VSorts Parameter
    // 3. reset -> Represents the function to clear the useCreateEditParamValues mutation state
    const {
        isLoading: isCreateVSetParametersLoading,
        mutate: createVSetParameters,
        isError: isCreateVSetParameterError,
        reset: resetCreateVSetParamterMutation,
    }: any = useCreateEditVsetParameters(containerId);

    const offSetOptions: OptionType[] = [
        {
            value: "1",
            text: "1",
        },
        {
            value: "2",
            text: "2",
        },
        {
            value: "3",
            text: "3",
        },
        {
            value: "4",
            text: "4",
        },
        {
            value: "5",
            text: "5",
        },
        {
            value: "6",
            text: "6",
        },
    ];

    const tallyOptions: OptionType[] = [
        {
            value: true,
            text: "Yes",
        },
        {
            value: false,
            text: "No",
        },
    ];

    // set existing parameter values and ids to be used during edit
    useEffect(() => {
        if (!vSetsContainerParameters || vSetsContainerParameters.length == 0) {
            return;
        }

        vSetsContainerParameters.forEach((parameter: IVSetParameter) => {
            switch (parameter.parameter_text) {
                case VSetParameterTypes.offset:
                    setOffsetParameter(parameter.value);
                    orgOffsetParameter.current = parameter.value;
                    setOffsetParameterId(parameter.id);
                    break;
                case VSetParameterTypes.maxUnpacking:
                    setMaxUnpackingParameter(parameter.value);
                    orgMaxUnpackingParameter.current = parameter.value;
                    setMaxUnpackingParameterId(parameter.id);
                    break;
                case VSetParameterTypes.minUnpacking:
                    setMinUnpackingParameter(parameter.value);
                    orgMinUnpackingParameter.current = parameter.value;
                    setMinUnpackingParameterId(parameter.id);
                    break;
                case VSetParameterTypes.tally:
                    setTallyParameter(parameter.value);
                    orgTallyParameter.current = parameter.value;
                    setTallyParameterId(parameter.id);
                    break;
            }
        });
    }, [vSetsContainerParameters]);

    useEffect(() => {
        console.log("offsetParameter change", offsetParameter)
        if (isView) {
            dispatch(setTabNavigation(true));
            return;
        }

        if (offsetParameter !== orgOffsetParameter.current || minUnpackingParameter !== orgMinUnpackingParameter.current || maxUnpackingParameter !== orgMaxUnpackingParameter.current) {
            dispatch(setTabNavigation(false));
        } else {
            console.log("change", true)
            dispatch(setTabNavigation(true));
        }
    }, [offsetParameter, minUnpackingParameter, maxUnpackingParameter])

    const handleOffsetParameterChange = (value: string) => {
        setOffsetParameter(value);
    };

    const handleMinUnpackingParameterChange = (value: string) => {
        setMinUnpackingParameter(value);
    };

    const handleMaxUnpackingParameterChange = (value: string) => {
        setMaxUnpackingParameter(value);
    };

    const handleTallyParameterChange = (value: boolean) => {
        setTallyParameter(value);
    };

    // called when save button is clicked on the parent component
    useImperativeHandle(childRef, () => {
        return {
            // save/update vset parameters
            save() {
                const parameterRequest: any[] = [];
                vSetsParameterTypes.forEach((parameterType: any) => {
                    switch (parameterType.parameter_text) {
                        case VSetParameterTypes.offset:
                            parameterRequest.push({
                                container_id: containerId,
                                id: offsetParameterId,
                                parameter_id: parameterType.id,
                                parameter_text: parameterType.parameter_text,
                                value: offsetParameter,
                            });
                            break;
                        case VSetParameterTypes.maxUnpacking:
                            parameterRequest.push({
                                container_id: containerId,
                                id: maxUnpackingParameterId,
                                parameter_id: parameterType.id,
                                parameter_text: parameterType.parameter_text,
                                value: maxUnpackingParameter,
                            });
                            break;
                        case VSetParameterTypes.minUnpacking:
                            parameterRequest.push({
                                container_id: containerId,
                                id: minUnpackingParameterId,
                                parameter_id: parameterType.id,
                                parameter_text: parameterType.parameter_text,
                                value: minUnpackingParameter,
                            });
                            break;
                        case VSetParameterTypes.tally:
                            parameterRequest.push({
                                container_id: containerId,
                                id: tallyParameterId,
                                parameter_id: parameterType.id,
                                parameter_text: parameterType.parameter_text,
                                value: tallyParameter,
                            });
                            break;
                    }
                });
                createVSetParameters({
                    apiMethodType: APIMethodConstants.post,
                    data: {
                        data: parameterRequest,
                    },
                });
            },
        };
    });

    if (isVSetsContainerParametersLoading || isvSetsParameterTypesLoading || isCreateVSetParametersLoading) {
        return <h1>Loading</h1>;
    }

    if (isvSetsParameterTypesError || isCreateVSetParameterError || isVSetsContainerParametersError) {
        return <h1>Error</h1>;
    }

    return (
        <div>
            {/* Offset */}
            <Box sx={styles.container}>
                <IconButton sx={styles.icon} disableRipple>
                    <img src={SquareBorderIcon} alt="view" />
                </IconButton>
                <Typography sx={styles.centerText}>Offset</Typography>
                <AdminSelect
                    options={offSetOptions}
                    selectedValue={offsetParameter ?? ""}
                    onChange={handleOffsetParameterChange}
                    readOnly={isView}
                />
            </Box>

            {/* Minimum unpacking */}
            <Box sx={styles.container}>
                <IconButton sx={styles.icon} disableRipple>
                    <img src={SquareBorderIcon} alt="view" />
                </IconButton>
                <Typography sx={styles.centerText}>Minimum&nbsp;Unpacking</Typography>
                <AdminSelect
                    options={offSetOptions}
                    selectedValue={minUnpackingParameter ?? ""}
                    onChange={handleMinUnpackingParameterChange}
                    readOnly={isView}
                />
            </Box>

            {/* Maximum unpacking */}
            <Box sx={styles.container}>
                <IconButton sx={styles.icon} disableRipple>
                    <img src={SquareBorderIcon} alt="view" />
                </IconButton>
                <Typography sx={styles.centerText}>Maximun&nbsp;Unpacking</Typography>
                <AdminSelect
                    options={offSetOptions}
                    selectedValue={maxUnpackingParameter ?? ""}
                    onChange={handleMaxUnpackingParameterChange}
                    readOnly={isView}
                />
            </Box>

            {/* Tally */}
            <Box sx={styles.container}>
                <IconButton sx={styles.icon} disableRipple>
                    <img src={SquareBorderIcon} alt="view" />
                </IconButton>
                <Typography sx={styles.centerText}>Tally</Typography>
                <AdminSelect
                    options={tallyOptions}
                    selectedValue={tallyParameter ?? true}
                    onChange={handleTallyParameterChange}
                    readOnly={isView}
                />
            </Box>
        </div>
    );
});

export default CreateVsetParameters;
