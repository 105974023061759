import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";

// Global CSS Styles
import GlobalCSS from "global.css";
import "./index.css";

import App from "App";

// Redux Imports
import { Auth0Provider } from "@auth0/auth0-react";
import { TourProvider, components } from '@reactour/tour';
import { persistor, store } from "app/store";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import * as serviceWorker from "serviceWorker";
const steps = [
    {
        selector: '#user-details-card',
        content: 'Find your details like email and role here.',
    },
    {
        selector: '#user-status-cards',
        content: '#Provides details on the status of each Cluster and CoPilot.',
    },
    {
        selector: '#org-and-group',
        content: 'Know the organization and group you have been assigned to.',
    },
    {
        selector: '#demographics-button',
        content: '#Manage your demographic information here.',
    },
    {
        selector: '#copilot-card',
        content: 'Find latest CoPilot here that is used to provide real-time support by analyzing inquiries, offering actionable insights, and suggesting strategies.'
    },
    {
        selector: '#cluster-0',
        content: 'Individual clusters that is a set of activities that you can participate in. It is intuitive by providing status of completion.',
    },

    {
        selector: '#cluster-status-0',
        content: 'Provides the status of the cluster.',
    },
    {
        selector: '#cluster-action-0',
        content: 'Gives information on the action that needs to be taken or if waiting for results.',
    }
]

function Badge({ children }) {
    return (
        <components.Badge
            styles={{ badge: (base) => ({ ...base, backgroundColor: '#29ABE2' }) }}
        >
            {children}
        </components.Badge>
    )
}
ReactDOM.render(
    <Auth0Provider
        domain={process.env.REACT_APP_AUTH0_DOMAIN}
        clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
        redirectUri={window.location.origin}
    >
        <React.StrictMode>
            <Provider store={store}>
                <PersistGate loading={null} persistor={persistor}>
                    <BrowserRouter>
                        <GlobalCSS />
                        <TourProvider steps={steps} components={{ Badge }}>
                            <App />
                        </TourProvider>
                    </BrowserRouter>
                </PersistGate>
            </Provider>
        </React.StrictMode>
    </Auth0Provider>,

    document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
