// Material UI Components
import TextField from "@material-ui/core/TextField";

// Material Drag Icon

// Form Element Type Constant

// Form Element Footer
import { Grid, Typography } from '@mui/material';
import SurveyElementWrapper from "../SurveyElementWrapper";

// `NumberInput` is the function to show the user Number Input while creating/editing a Survey Form
// 1. item -> Represents the Form Item Object (Consisting of all properties)
// 2. handleValue -> Represents the function to handle the Text Input value
// 3. deleteEl -> Represents the function to handle deleting the Form Element
// 4. handleRequired -> Represents the function to handle making a Form Element required/not required
// 5. handleElType -> Represents the function to handle the type of the Form Element
// 6. duplicateElement -> Represents the function to handle duplicating the Form Element
const NumberInput = (props: any) => {
    const { handleValue, item, index } = props;
    return (
        <SurveyElementWrapper {...props}>
            <Grid container display={"flex"} flexDirection={"column"} gap={"10px"}>
                <Typography>Question</Typography>
                <TextField
                    defaultValue={item.question_text}
                    variant="outlined"
                    onBlur={(e) => handleValue({ id: item.id, e, index })}
                    fullWidth
                    required={!!item.is_required}
                    placeholder="Question"
                    sx={{ mb: 2 }}
                    multiline
                    InputProps={{
                        sx: {
                            borderRadius: "10px",
                            padding: "6px 1rem",
                            fontSize: "14px",
                            color: "#111111",
                            fontFamily: "'Montserrat', sans-serif !important",
                        }
                    }}
                />
            </Grid>
            <TextField 
                variant="outlined" 
                fullWidth 
                placeholder="Number Input Field" 
                disabled 
                type="number"
                InputProps={{
                    sx: {
                        borderRadius: "10px",
                        padding: "6px 1rem",
                        fontSize: "14px",
                        color: "#111111",
                        fontFamily: "'Montserrat', sans-serif !important",
                    }
                }}
            />
        </SurveyElementWrapper>
    );
};

export default NumberInput;
