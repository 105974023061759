import { NSF_LINK } from "constants/landing-page.constants";
import { useState } from "react";
import seedFundingImage from "../../../assets/images/seed-funding.png";
import "./NSF.css";

const NSF: React.FC = () => {
    const [phaseTab, setPhaseTab] = useState(0);

    const handlePhaseChange = (tabCount: any) => {
        setPhaseTab(tabCount);
    };
    return (
        <div className="NSF">
            {/* <Tabs
                value={phaseTab}
                centered
                sx={{
                    marginBottom: "29px",
                }}
                TabIndicatorProps={{
                    style: {
                        backgroundColor: "#29ABE2",
                        height: "2px",
                    },
                }}
            >
                <Tab
                    label="Phase 2 Funding"
                    onClick={() => handlePhaseChange(0)}
                    sx={{
                        minWidth: 0,
                        padding: 0,
                        textTransform: "none",
                        fontSize: "inherit",
                        marginRight: "30px",
                        "&.Mui-selected": {
                            color: "#29ABE2",
                            fontWeight: "bold",
                        },
                    }}
                />
                <Tab
                    label="Phase 1 Funding"
                    onClick={() => handlePhaseChange(1)}
                    sx={{
                        minWidth: 0,
                        padding: 0,
                        textTransform: "none",
                        fontSize: "inherit",
                        marginRight: "30px",
                        "&.Mui-selected": {
                            color: "#29ABE2",
                            fontWeight: "bold",
                        },
                    }}
                />
            </Tabs> */}
            {phaseTab == 0 && (
                <div className="NSFContainer">
                    <div className="NSFContainer__left">
                        <h1 style={{ fontSize: "30px" }}>VSorts™ AI Awarded $874,195 NSF SBIR Phase II Award!</h1>
                        <p style={{ fontSize: "18px" }}>
                            VSorts™ AI is thrilled to announce that we&apos;ve secured the National Science
                            Foundation&apos;s Small Business Innovation Research (SBIR) Phase II award of $874,195.00—a
                            testament to our team&apos;s collective efforts and dedication! This prestigious recognition
                            validates our innovations and showcases our groundbreaking advancements in the VSorts™ AI
                            platform—a unified solution for collecting, structuring, and analyzing qualitative data
                            using AI-enabled tools. With this substantial funding, we&apos;re not just entering an
                            exciting new phase of research and development, but we&apos;re also poised to make a
                            transformative impact on how qualitative data is leveraged for human development and
                            research in education and subsequent vertical markets. This underscores our potential for
                            growth and innovation.
                        </p>
                        <a
                            href="https://www.nsf.gov/awardsearch/showAward?AWD_ID=2417969&HistoricalAwards=false"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <button className="navbarActionButton navbarActionButtons-getStarted">Learn more</button>
                        </a>
                    </div>
                    <img src={seedFundingImage} alt="seed Funding" />
                </div>
            )}

            {phaseTab == 1 && (
                <div className="NSFContainer">
                    <div className="NSFContainer__left">
                        <h1 style={{ fontSize: "30px" }}>
                            VSorts™ AI Awarded Prestigious National Science Foundation SBIR Startup Funding!
                        </h1>
                        <p style={{ fontSize: "18px" }}>
                            VSorts™ AI is proud to receive the National Science Foundation&apos;s Small Business
                            Innovation Research (SBIR) Phase 1 grant. This recognition underscores our patent pending
                            innovation and commitment to enhancing domain-specific social and cultural awareness across
                            various sectors. Our team is dedicated to reducing biases and social risks and promoting
                            inclusive language, practices, and organizational alignment.
                        </p>
                        <a href={NSF_LINK} target="_blank" rel="noreferrer">
                            <button className="navbarActionButton navbarActionButtons-getStarted">Learn more</button>
                        </a>
                    </div>
                    <img src={seedFundingImage} alt="seed Funding" />
                </div>
            )}
        </div>
    );
};

export default NSF;
