import { useMemo } from "react";

// Material UI Components
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import VSAlert from "components/VSAlert";

import SectionFooter from "./SectionFooter";

// Material Drag Icon

// `SectionInput` is the function to show the user Text Field Input while creating/editing a Survey Form
// 1. item -> Represents the Form Item Object (Consisting of all properties)
// 2. handleValue -> Represents the function to handle the Text Input value
// 3. deleteEl -> Represents the function to handle deleting the Form Element
// 4. handleRequired -> Represents the function to handle making a Form Element required/not required
// 5. handleElType -> Represents the function to handle the type of the Form Element
// 6. duplicateElement -> Represents the function to handle duplicating the Form Element
const SectionInput = (props: any) => {
    const { index, totalSection, item, handleSectionValue, arrayMoved } = props;
    const { section_title, description } = item;
    const hasMultipleSection = totalSection > 1;
    return useMemo(
        () => (
            <Paper elevation={3} sx={{ marginTop: "2rem" }} key={index + 1}>
                <VSAlert
                    icon={false}
                    severity="info"
                    sx={(theme) => ({
                        color: "#fff",
                        background: theme.palette.secondary.main,
                        height: hasMultipleSection ? "auto" : "0.5rem",
                    })}
                >
                    {hasMultipleSection && `Section ${index + 1} of ${totalSection}`}
                </VSAlert>
                <Box sx={{ p: 3 }}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <TextField
                                label="Title"
                                value={item.section_title}
                                variant="outlined"
                                onChange={(e) => handleSectionValue({ e, index })}
                                fullWidth
                                required
                                sx={{ mb: 2 }}
                                multiline
                            />
                            <TextField
                                label="Description"
                                value={item.description || ""}
                                variant="outlined"
                                onChange={(e) => handleSectionValue({ e, index, isDescription: true })}
                                fullWidth
                                sx={{ mb: 2 }}
                                multiline
                            />
                        </Grid>
                    </Grid>
                </Box>
                <SectionFooter {...props} />
            </Paper>
        ),
        [section_title, description, totalSection, arrayMoved]
    );
};

export default SectionInput;
