import { Card, CardActionArea, Typography } from "@mui/material";

const VSParkingTask = (props: any) => {
    return (
        <CardActionArea
            sx={{
                width: props.width || "349px",
                height: "84px",
                marginBottom: "1rem",
            }}
        >
            <Card
                sx={{
                    display: "flex",
                    alignItems: "center",
                    padding: "0px 10px 0px 10px",

                    width: props.width || "349px",
                    height: "84px",

                    background: " #FFFFFF",
                    boxShadow: "0px 10px 10px rgba(41, 171, 226, 0.11)",
                    borderRadius: "8px",
                    borderLeft: "10px solid #29ABE2",
                    marginBottom: "1rem",
                }}
            >
                <Typography
                    sx={{ fontWeight: 400, fontSize: "12px", lineHeight: "16px" }}
                >
                    Black students have the same opportunities and access to attend
                    college as their white peers.
                </Typography>
            </Card>
        </CardActionArea>
    );
};

export default VSParkingTask;