/**
Available Options are: 
{
  year: 'numeric',
  month: 'short' | 'long',
  day: 'numeric',
  weekday: 'long' | 'short',
  hour: '2-digit',
  minute: '2-digit',
  hour12: boolean
}
* You can pass extra option to any function and set key to undefined if it is not required.
*/

const locale = "en-US";

const OPTION_ONLY_DATE = {
    year: "numeric",
    month: "long",
    day: "numeric",
};

export const getOnlyDate = (reqDate: any, extraOption: any) =>
    new Date(reqDate).toLocaleDateString(locale, { ...OPTION_ONLY_DATE, ...extraOption });

const OPTION_DATE_WITH_TIME = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
};
export const getDateTime = (reqDate: any, extraOption: any) =>
    new Date(reqDate).toLocaleDateString(locale, { ...OPTION_DATE_WITH_TIME, ...extraOption });

const OPTION_ONLY_TIME = {
    hour: "2-digit",
    minute: "2-digit",
};

export const getOnlyTime = (reqDate: any, extraOption: any) =>
    new Date(reqDate).toLocaleTimeString(locale, { ...OPTION_ONLY_TIME, ...extraOption });
