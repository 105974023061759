import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box, Typography } from "@mui/material";
import { useState } from "react";
import styles from "../../styles";
import VSetCard from "./VSetCard";
import "./VSetUserCard.css";

interface VignetteResponse {
    vignette_id: number;
    vignette_text: string;
    assigned_category_id: number;
    assigned_category_name: string;
    user_category_id: number;
    user_category_name: string;
    container_id: number;
    container_name: string;
    domain_id: number;
    domain_name: string;
    domain_order: number;
    user_rank: number;
    assigned_rank: number;
    user_id: string;
    answers: ClusterAnswers[];
    unpacked: number;
    flagged: number;
    is_scoring_complete: number;
}

interface ClusterAnswers {
    id: number;
    answer_id: number;
    answer_type: string;
    user_id: string;
    answer: string;
    question_text: string;
    question_id: number;
    question_number: number;
    vignette_id: number;
}

interface UserResponse {
    user_id: string;
    responses: VignetteResponse[];
}

type ClusterInformationResponses = UserResponse[] | null;

interface Item {
    label: string;
    count: number;
}

type vSetUserCardProps = {
    userId: string;
    type: string | undefined;
    clusterInformationResponses: ClusterInformationResponses | null;
    items: Item[];
};

const VSetUserCard = ({ userId, type, clusterInformationResponses, items }: vSetUserCardProps) => {
    const [isExpanded, setIsExpanded] = useState(false);

    const handleExpand = () => {
        setIsExpanded(!isExpanded);
    };

    return (
        <Box sx={{ ...styles.container, display: "flex", flexDirection: "column" }}>
            <Typography sx={{ display: "flex", flexDirection: "row" }}>
                {isExpanded ? (
                    <ExpandLessIcon sx={{ color: "#29abe2" }} onClick={() => handleExpand()} />
                ) : (
                    <ExpandMoreIcon sx={{ color: "#29abe2" }} onClick={() => handleExpand()} />
                )}

                <p className="card-container-header-line">{userId}</p>
            </Typography>

            {isExpanded ? (
                <Box>
                    {items?.map(
                        (response) =>
                            response.label !== "All" && (
                                <VSetCard
                                    title={response.label}
                                    type={type}
                                    clusterInformationResponses={clusterInformationResponses}
                                    userId={userId}
                                />
                            )
                    )}
                </Box>
            ) : null}
        </Box>
    );
};

export default VSetUserCard;
