import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { persistReducer } from 'redux-persist';
import persistStore from "redux-persist/es/persistStore";
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import storage from "redux-persist/lib/storage";
import counterReducer from "../features/counter/counterSlice";
import CreateVsetReducer from "./slices/CreateVsetSlice";
import UnpackingReducer from "./slices/UnpackingSlice";
import UserReducer from "./slices/UserSlice";
import TabNavigationReducer from "./slices/TabNavigationSlice";


const reducer : any = combineReducers({
    counter: counterReducer,
    user: UserReducer,
    unpacking: UnpackingReducer,
    createVset: CreateVsetReducer,
    tabNavigation: TabNavigationReducer
})

const persistConfig = {
    key: 'root',
    whitelist: ['user', 'tabNavigation'],
    blacklist: ['unpacking', 'createVset', 'counter'],
    storage,
    stateReconciler: autoMergeLevel2,
};

const persistedReducer = persistReducer(persistConfig, reducer);


export const store = configureStore({
    reducer: persistedReducer,
    middleware: [],
});

export const persistor = persistStore(store);