import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

// Custom Constants
import { StringConstants } from "constants/string.constants";
import { SwalConstants } from "constants/swal.constants";

const SweetAlert = withReactContent(Swal);

// `SwalAlert` is the function to display the user a Alert Dialog to convey any message (Error, Info, Warning and more)
// 1. icon -> Represents the icon for the Alert Dialog (warning, error, success, info, question)
// 2. title -> Reperesnts the title of the Alert Dialog
// 3. description -> Represents the description of the Alert Dialog
// 4. isAllowOutsideClickEnabled -> Represents if the user can click otuside the Alert Dialog to close it (true/false)
export const SwalAlert = ({
    icon = SwalConstants.info,
    title = StringConstants.networkErrorTitle,
    description = StringConstants.networkErrorDescription,
    isAllowOutsideClickEnabled = false,
}) => {
    return SweetAlert.fire({
        icon: icon,
        title: title,
        text: description,
        allowOutsideClick: isAllowOutsideClickEnabled,
    }).then((result) => {
        if (result.isConfirmed) {
            window.location.reload();
        }
    });
};
