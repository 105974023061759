import { createSlice } from "@reduxjs/toolkit";

const tabNavigationSlice = createSlice({
    name: "tabNavigation",
    initialState: {
        enableTabNavigation: true,
    },
    reducers: {
        setTabNavigation: (state, action) => {
            state.enableTabNavigation = action.payload;
        },
    },
});

export const { setTabNavigation } = tabNavigationSlice.actions;
export const TabNavigationSelector = (state: any) => state.tabNavigation.enableTabNavigation;

export default tabNavigationSlice.reducer;
