import { Paper, Typography } from "@mui/material";
import coPilotStyle from "./coPilotPageStyles";
export default function ViewPreviousEntries({ previousEntriesData, viewPrevious, viewOldQuery }: any) {
    const viewOldQueryFunction = (selectedQuery: any) => {
        viewOldQuery(selectedQuery);
    };
    return (
        <Paper
            sx={{
                position: "absolute",
                left: viewPrevious ? "4vw" : "",
                p: "20px",
                boxShadow: "none",
                borderRadius: "10px !important",
                minWidth: "14vw",
                minHeight: "98.48vh",
                background: "#F3F8FB",
                borderStyle: "solid",
                borderWidth: "1px",
                borderColor: "#29ABE2",
                transition: "left 10s",
                "@media (max-height: 1080px)": {
                    minHeight: "98.48vh",
                },
                "@media (max-height: 720px)": {
                    minHeight: "109.5vh",
                },
            }}
        >
            {Object.keys(previousEntriesData)?.map((eachPreviousEntry, index) => {
                return (
                    <div key={index}>
                        <Typography
                            sx={coPilotStyle.previousEntryTitle}
                            style={{ paddingTop: index == 0 ? "1px" : "30px" }}
                        >
                            {eachPreviousEntry}
                        </Typography>
                        {previousEntriesData[eachPreviousEntry]?.map((eachQuery: any) => {
                            return (
                                <div style={coPilotStyle.eachQueryDiv} onClick={() => viewOldQueryFunction(eachQuery)}>
                                    <Typography sx={coPilotStyle.eachQueryTitle}>
                                        {eachQuery?.input_vignette?.substring(0, 10)}
                                        {eachQuery?.input_vignette?.length <= 10 ? "" : "..."}
                                    </Typography>
                                    {/* <MoreVertIcon /> */}
                                </div>
                            );
                        })}
                    </div>
                );
            })}
        </Paper>
    );
}
