

import CircularLoadingProgress from "components/CircularProgress";
import NetworkError from "pages/error/networkError";

import { HeatMapViewSelector, MinimumUnpackingCountSelector, setMaximumUnpackingCount, setMinimumUnpackingCount, setUnpackedVignetteCount, UnpackedVignetteCountSelector } from "app/slices/UnpackingSlice";
import VSButton from "components/VSButton";
import { VSetParameterTypes } from "constants/enum.constants";
import { StringConstants } from "constants/string.constants";
import { SwalConstants } from "constants/swal.constants";
import { useCompleteUnpackingStep, useGetVSetFlaggedResponses, useGetVSetMinMaxUnpackingCount } from "hooks/VSortsContainerData";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SwalAlert } from "utils/swal-utils";
import VSetResponses from "../ViewVSetResponses";
import ViewVSetResponses from "./ResponsesGrid";



export default function FlaggedVignettes({ isCreate = false, isView = false, isEdit = false, container_id, cluster_id, user_id, cluster_step_id }: any) {
    const heatMapView = useSelector(HeatMapViewSelector);
    const unpackedVignetteCount = useSelector(UnpackedVignetteCountSelector);
    const minimumUnpackingCount = useSelector(MinimumUnpackingCountSelector);
    const [availableVignettes, setAvailableVignettes] = useState(0);
    const dispatch = useDispatch();

    const onVSetResponsesDataFetchSuccess = (data: any) => {
        
        let count = 0;
        let total = 0;
        (data || []).map((eachDomain: any) => {
            total = total + eachDomain['vignettes'].length;
            eachDomain['vignettes'].map((eachVignette: any) => {
                if(eachVignette?.unpacked == 1){
                    count = count + 1
                }
            })
        })
        dispatch(setUnpackedVignetteCount(count));
        setAvailableVignettes(total);
    };

    const onVSetResponsesDataFetchError = (error: any) => {
        dispatch(setUnpackedVignetteCount(0));
    };

    const {
        isFetching: isVSetsResponsesDataFetching,
        isLoading: isVsetsResponsesDataLoading,
        isError: isVSetsResponsesDataError,
        data: allVSetsResponsesData,
        error: vsetResponesError,
        refetch: refetchVSetsResponsesData,
    } = useGetVSetFlaggedResponses({
        container_id: container_id,
        cluster_id: cluster_id,
        user_id: user_id,
        isEnabled: !isCreate,
        onSuccess: onVSetResponsesDataFetchSuccess,
        onError: onVSetResponsesDataFetchError,
    });


    const onVSetParameterDataFetchSuccess = (data: any) => {
        (data || []).map((eachParam: any) => {
            if(eachParam.parameter_text == VSetParameterTypes.minUnpacking){
                dispatch(setMinimumUnpackingCount(eachParam.value))
            }
            else if(eachParam.parameter_text == VSetParameterTypes.maxUnpacking){
                dispatch(setMaximumUnpackingCount(eachParam.value))
            }
        })
    };

    const onVSetParameterDataFetchError = (error: any) => {
        dispatch(setUnpackedVignetteCount(0));
    };

    const {
        isFetching: isVSetsParameterDataFetching,
        isLoading: isVSetsParameterDataLoading,
        isError: isVSetsParameterDataError,
        data: vsetParameterData,
        error: vsetParameterError,
        refetch: refetchVSetsParameterData,
    } = useGetVSetMinMaxUnpackingCount({
        container_id: container_id,
        isEnabled: !isCreate,
        onSuccess: onVSetParameterDataFetchSuccess,
        onError: onVSetParameterDataFetchError,
    });


    const {
        mutate: addVSortsCluster,
        isError: isAddVSortsClusterError,
        reset: resetAddVSortsClusterMutation,
    } = useCompleteUnpackingStep({
        data: {
            cluster_step_id: cluster_step_id
        },
    });

    const createNewVSortsCluster = async () => addVSortsCluster();


    if (isAddVSortsClusterError) {
        resetAddVSortsClusterMutation();
        // SwalAlert being called with {} to take the default value to given to the parameters
        SwalAlert({icon: SwalConstants.success,
            title: StringConstants.unpackingSuccessfulTitle,
            description: StringConstants.unpackingSuccessful,
            isAllowOutsideClickEnabled: false
        });
    }



    if (isVsetsResponsesDataLoading || isVSetsResponsesDataFetching) {
        return <CircularLoadingProgress />;
    }

    if (isVSetsParameterDataLoading || isVSetsParameterDataFetching) {
        return <CircularLoadingProgress />;
    }

    // We check if there is an error present while fetching all Questions of a Survey and display `NetworkError`
    if (isVSetsResponsesDataError) {
        
        return <NetworkError handleOnRefresh={refetchVSetsResponsesData} errorText={vsetResponesError} />;
    }

    if (isVSetsParameterDataError) {
        
        return <NetworkError handleOnRefresh={refetchVSetsParameterData} errorText={vsetParameterError} />;
    }

    const finishUnpacking = async () => {
        await createNewVSortsCluster();
    }

    const checkFinishedUnpackingStatus = () => {
        if(availableVignettes == 0){
            return false;
        }
        if(availableVignettes == unpackedVignetteCount){
            return false;
        }
        if(unpackedVignetteCount >= minimumUnpackingCount){
            return false;
        }
        else{
            return true
        }
    }
    
    return (
        <>
            {
                heatMapView ?
                    <VSetResponses container_id={container_id} cluster_id={cluster_id} user_id={user_id} cluster_step_id={cluster_step_id}/> :
                    <ViewVSetResponses data={allVSetsResponsesData} container_id={container_id} cluster_id={cluster_id} user_id={user_id} cluster_step_id={cluster_step_id} refetchVSetsResponsesData={refetchVSetsResponsesData} />

            }
            <VSButton disabled={checkFinishedUnpackingStatus() ? true : false} onClick={finishUnpacking}>
                Finish Unpacking
            </VSButton>
        </>
    );
}