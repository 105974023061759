// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import DeleteIcon from "@mui/icons-material/Delete";
import { IconButton } from "@mui/material";
import { DynamicModel } from "pages/adminV2/Components";
import { Fragment, useState } from "react";
import VSTextField from "../../../../../components/VSTextField";
import DeleteVSet from "../../modals/DeleteVSets";
import "./VSet.css";

// `TableRows` is the function to display the user each row for the VSorts Container Table along with letting them edit/delete
// 1. rowsData -> Represents the all the rows in the Table (ALl Information about the table)
// 2. deleteTableRow -> Represents the function to delete a Table Row
// 3. handleChange -> Represents the function to handle input text change for each cell in the Table
// 4. headers -> Represents the VSorts Categories (Excluding the "Domain" item)
function TableRows({ rowsData, deleteTableRows, handleChange, headers, isView = false }) {
    const headerColSpan = Math.floor(headers.length / 2);
    const footerColSpan = headers.length - headerColSpan;
    const footerColSpan2 = headers.length;
    const [index, setIndex] = useState(0);
    const [isOpen, setIsOpen] = useState(false);

    const handleDelete = () => {
        deleteTableRows(index);
        setIsOpen(false);
    };

    const handleClick = (parentIndex) => {
        setIsOpen(true);
        setIndex(parentIndex);
    };

    return rowsData.map((data, parentIndex) => {
        return (
            <Fragment key={parentIndex + "main_fragment"}>
                <tr key={parentIndex + "domain"}>
                    <td rowSpan="8">
                        <div className="form-group has-error createVSetTableColumn">
                            <input
                                id="validationError"
                                className="form-control"
                                type="text"
                                placeholder="Domain"
                                value={data.domain_name}
                                name="domain"
                                disabled={isView}
                                onChange={(event) =>
                                    handleChange({
                                        index: 0,
                                        event: event,
                                        parentIndex: parentIndex,
                                        inputType: "domain",
                                    })
                                }
                                required
                            />
                            {!isView && (
                                <IconButton
                                    onClick={() => handleClick(parentIndex)}
                                    className="createVSetTableIconButton"
                                >
                                    <DeleteIcon color="warning" />
                                </IconButton>
                            )}

                            <div className="invalid-tooltip">This is an error state form validation message!</div>
                        </div>
                    </td>
                </tr>
                <tr>
                    {data.sparks.map((_sparkItem, index) => {
                        return (
                            <td key={index + "spark"} className="table-title createVSetTableTitle">
                                Spark
                            </td>
                        );
                    })}
                </tr>
                <tr>
                    {data.sparks.map((sparkItem, index) => {
                        return (
                            <>
                                <td key={index + "spark_text"} className="createVSetTableCell">
                                    {/* <p className='createVSetTableCategoryTitle'>spark</p> */}
                                    <textarea
                                        rows={2}
                                        className="form-control createVSetTableTextArea"
                                        type="text"
                                        placeholder="Type spark here"
                                        name="spark"
                                        value={sparkItem}
                                        disabled={isView}
                                        onChange={(event) =>
                                            handleChange({
                                                index: index,
                                                event: event,
                                                parentIndex: parentIndex,
                                                inputType: "sparks",
                                            })
                                        }
                                        required
                                    />
                                </td>
                            </>
                        );
                    })}
                </tr>
                <tr>
                    {data.vignettes.map((_vignetteItem, index) => {
                        return <td key={index + "vignette"} className="table-title"></td>;
                    })}
                </tr>
                <tr>
                    {data.vignettes.map((vignetteItem, index) => {
                        return (
                            <td key={index + "vignette_text"} className="createVSetTableCell">
                                <p className="createVSetTableCategoryTitle">Vignette</p>
                                <textarea
                                    rows={2}
                                    className="form-control createVSetTableTextArea"
                                    type="text"
                                    name="vignette"
                                    value={vignetteItem}
                                    disabled={isView}
                                    onChange={(event) =>
                                        handleChange({
                                            index: index,
                                            event: event,
                                            parentIndex: parentIndex,
                                            inputType: "vignettes",
                                        })
                                    }
                                    required
                                />
                            </td>
                        );
                    })}
                </tr>
                {/* <tr>
                    {data.sources.map((_sourceItem, index) => {
                        return (
                            <td key={index + "source"} className="table-title">
                                Sources
                            </td>
                        );
                    })}
                </tr> */}
                <tr>
                    {data.sources.map((sourceItem, index) => {
                        return (
                            <td key={index + "source_text"} className="createVSetTableCell">
                                <p className="createVSetTableCategoryTitle">Sources</p>
                                <textarea
                                    rows={2}
                                    className="form-control createVSetTableTextArea"
                                    type="text"
                                    name="sources"
                                    value={sourceItem}
                                    disabled={isView}
                                    onChange={(event) =>
                                        handleChange({
                                            index: index,
                                            event: event,
                                            parentIndex: parentIndex,
                                            inputType: "sources",
                                        })
                                    }
                                />
                            </td>
                        );
                    })}
                </tr>
                {/* <tr>
                    {data.explanation.map((_explanationItem, index) => {
                        return (
                            <td key={index + "explanation"} className="table-title">
                                Explanation
                            </td>
                        );
                    })}
                </tr> */}
                <tr>
                    {data.explanation.map((explanationItem, index) => {
                        return (
                            <td key={index + "explanation"} className="createVSetTableCell">
                                <p className="createVSetTableCategoryTitle">Explanation</p>
                                <textarea
                                    rows={2}
                                    className="form-control createVSetTableTextArea"
                                    type="text"
                                    name="explanation"
                                    value={explanationItem}
                                    disabled={isView}
                                    onChange={(event) =>
                                        handleChange({
                                            index: index,
                                            event: event,
                                            parentIndex: parentIndex,
                                            inputType: "explanation",
                                        })
                                    }
                                />
                            </td>
                        );
                    })}
                </tr>
                <tr>
                    <td colSpan={footerColSpan2}>
                        <VSTextField
                            fullWidth
                            id={`${parentIndex}-domain-footer`}
                            label="Directions"
                            value={data.footer_text}
                            disabled={isView}
                            onChange={(event) =>
                                handleChange({
                                    index: 0,
                                    event: event,
                                    parentIndex: parentIndex,
                                    inputType: "footer_text",
                                })
                            }
                        />
                    </td>
                </tr>

                {isOpen && (
                    <DynamicModel open={isOpen} setOpen={setIsOpen}>
                        <DeleteVSet type={"createVset"} handleClickAction={handleDelete} />
                    </DynamicModel>
                )}
            </Fragment>
        );
    });
}
export default TableRows;
