const styles = {
    searchBox: {
        flex: 1,
        borderRadius: 10,
        width: '100%',
        color: '#94A8B0',
        '& .MuiInputBase-root': {
            overflow: 'hidden',
            borderRadius: 25,
            backgroundColor: 'transparent',
            border: '1px solid',
            borderColor: '#94A8B0',
            fontSize: 14,
            '&:hover': {
              backgroundColor: 'transparent',
              borderColor: '#94A8B0',
            },
            '&.Mui-focused': {
              backgroundColor: 'transparent',
              borderColor: '#94A8B0',
              '& .MuiOutlinedInput-notchedOutline' : {
                borderWidth: 1,
                borderColor: '#94A8B0',
              },
            },
            '& .MuiInputBase-input': {
                padding: '5px 0',
            },
          },
    },
    searchIcon: {
        width: 15,
        height: 15,
    },
    closeIcon: {
        cursor: 'pointer',
        width: 10,
        height: 10,
    }
}

export default styles