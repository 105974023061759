import KeyboardVoiceIcon from "@mui/icons-material/KeyboardVoice";
import { IconButton } from "@mui/material";
import { useEffect } from "react";
import SpeechRecognition, { useSpeechRecognition } from "react-speech-recognition";
import styles from "../styles";

export default function SpeechToTextButton(props: any) {
    const { handleSpeechToText, item, setIsListening } = props;

    const { transcript, listening, resetTranscript, browserSupportsSpeechRecognition } = useSpeechRecognition();

    useEffect(() => {
        handleSpeechToText(item, transcript);
    }, [transcript]);

    if (!browserSupportsSpeechRecognition) {
        return <span>Browser doesn&apos;t support speech recognition.</span>;
    }

    const listenFunction = () => {
        if (listening) {
            SpeechRecognition.stopListening();
            setIsListening(false);
        } else {
            SpeechRecognition.startListening({ continuous: true });
            setIsListening(true);
        }
    };

    return (
        <div>
            {listening ? (
                <IconButton
                    onClick={listenFunction}
                    onDoubleClick={resetTranscript}
                    size="small"
                    color="primary"
                    sx={styles.iconButtonListening}
                >
                    {<KeyboardVoiceIcon sx={{ color: "white" }} />}
                </IconButton>
            ) : (
                <IconButton
                    onClick={listenFunction}
                    onDoubleClick={resetTranscript}
                    size="small"
                    color="primary"
                    sx={styles.iconButton}
                >
                    {<KeyboardVoiceIcon sx={{ color: "white" }} />}
                </IconButton>
            )}
        </div>
    );
}
