import styled from "styled-components";

export const FloatingButtonWrapper = styled.div`
    height: 40px;
    position: fixed;
    bottom: 4%;
    right: 0.5%;
    opacity: 1;
    .floating-item {
        border: 1px solid indigo;
        padding: 1rem;
        display: inline-block;
    }
    .action-button {
        color: white;
        border-radius: 16px;
        padding: 1rem;
        display: inline-block;
        &.preview {
            background-color: #3f50b5;
        }
        &.save {
            background-color: #357a38;
        }
    }
`;
