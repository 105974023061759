const styles: any = {
    container: {
        background: "#fff",
        borderRadius: 10,
        padding: "30px 30px 30px",
        height: "100%",
        flex: 1,
        //marginBottom: "20px",
    },
    flexContainer: {
        paddingTop: "20px",
        gap: "20px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },
    title: {
        fontWeight: 500,
        fontSize: 16,
        color: "#000",
    },
    btnTab: {
        border: "none",
        //marginLeft: 20,
        textTransform: "none",
    },
    btnSearch: {
        color: "#94A8B0",
        border: "1px solid #94A8B0",
        borderRadius: "100%",
        minWidth: "auto",
        marginLeft: 17,
        textTransform: "none",
        width: "35px",
        height: "35px",
    },
    btnFilter: {
        textTransform: "none",
        background: "none",
        color: "#111",
        border: "none",
        boxShadow: "none",
        paddingLeft: "1px",
        paddingRight: "1px",
        fontWeight: 400,
        "&:hover": {
            background: "none",
            border: "none",
            boxShadow: "none",
        },
        marginLeft: "4px",
    },
    btnMore: {
        marginRight: "-18px",
    },
    selected: {
        fontWeight: 800,
        fontSize: 16,
        color: "#29ABE2",
    },
    unselected: {
        fontSize: 16,
        color: "#929292",
    },
    clusters: {
        marginTop: 15,
        overflowY: "auto",
        paddingRight: 30,
        paddingLeft: 10,
        height: 340,
    },
    cluster: {
        border: "1px solid #F3F8FB",
        width: "100%",
        //height: 85,
        padding: "20px 34px",
        background: "#fff",
        borderRadius: 10,
        boxShadow: "0px 10px 5px 0.8px rgba(41, 171, 226, 0.08)",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginBottom: 12,
        cursor: "pointer",
    },
    clusterInfo: {
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
    },
    clusterTitle: {
        fontSize: 16,
        color: "#000",
        marginBottom: 10,
    },
    clusterIcon: {
        width: 14,
        height: 14,
        marginRight: 4,
    },
    resultsText: {
        fontWeight: 500,
        fontSize: 14,
        //textDecoration: 'underline',
        color: "#929292",
    },
    progressText: {
        fontSize: 14,
        fontStyle: "italic",
        color: "#CA7D0B",
    },
    pendingText: {
        fontSize: 14,
        fontStyle: "italic",
        color: "#929292",
    },
    btnStart: {
        width: 135,
        height: 42,
        borderRadius: 10,
        border: "2px solid #29ABE2",
        //background: '#29ABE2',
        color: "#29ABE2",
        fontSize: 16,
        textTransform: "none",
        marginRight: 4,
    },
    btnContinue: {
        width: 135,
        height: 42,
        borderRadius: 10,
        border: "1px solid #29ABE2",
        background: "#29ABE2",
        color: "#fff",
        fontSize: 16,
        textTransform: "none",
        marginRight: 4,
        "&:hover": {
            background: "#fff",
            color: "#29ABE2",
        },
    },
    btnSubmitted: {
        width: 135,
        height: 42,
        borderRadius: 10,
        border: "1px solid #C0C0C0",
        background: "#C0C0C0",
        color: "#fff !important",
        fontSize: 16,
        textTransform: "none",
        marginRight: 4,
    },
    btnCompleted: {
        width: 135,
        height: 42,
        border: "none",
        background: "none",
        color: "#009245 !important",
        fontSize: 16,
        textTransform: "none",
        marginRight: 4,
    },
    greenCircle: {
        width: 10,
        height: 10,
        background: "#29ABE2",
        borderRadius: "50%",
    },
    grayCircle: {
        width: 10,
        height: 10,
        background: "#929292",
        borderRadius: "50%",
    },
    searchBox: {
        flex: 1,
        borderRadius: 10,
        width: "100%",
        color: "#94A8B0",
        "& .MuiInputBase-root": {
            overflow: "hidden",
            borderRadius: 25,
            backgroundColor: "transparent",
            border: "1px solid",
            borderColor: "#94A8B0",
            fontSize: 14,
            "&:hover": {
                backgroundColor: "transparent",
                borderColor: "#94A8B0",
            },
            "&.Mui-focused": {
                backgroundColor: "transparent",
                borderColor: "#94A8B0",
                "& .MuiOutlinedInput-notchedOutline": {
                    borderWidth: 1,
                    borderColor: "#94A8B0",
                },
            },
            "& .MuiInputBase-input": {
                padding: "5px 0",
            },
        },
    },
    searchIcon: {
        width: 15,
        height: 15,
    },
    closeIcon: {
        cursor: "pointer",
        width: 10,
        height: 10,
    },
};

export default styles;
