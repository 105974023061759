import { Box, Grid, Typography } from "@mui/material";
import VSCard from "components/VSCard/VSCard";
import styles from "./styles";
import VignetteTextDisplay from "./vignetteTextDisplay";
export default function AllCategoryVignettes({ flaggedVignettes, chooseSelectedDomain, openUnpackingDialog }: any) {
    return (
        <Grid item xs={10}>
            <VSCard sx={styles.allCategoryVignetteCard}>
                {flaggedVignettes?.map((eachDomain: { [x: string]: any[] }) => {
                    return (
                        <>
                            <Typography
                                sx={styles.domainHeading}
                                onClick={() => chooseSelectedDomain(eachDomain["domain_name"])}
                            >
                                {eachDomain["domain_name"]} ({eachDomain["flaggedVignettesCount"]}/
                                {eachDomain["totalVignettesCount"]})
                            </Typography>
                            <Box sx={styles.allVignetteFlexBox}>
                                {eachDomain["vignettes"].map((eachVignette, index) => (
                                    <Grid item xs={5.5} onClick={() => openUnpackingDialog(eachVignette)}>
                                        <VignetteTextDisplay
                                            vignette_text={eachVignette["vignette_text"]}
                                            unpacked={eachVignette["unpacked"]}
                                        />
                                    </Grid>
                                ))}
                            </Box>
                        </>
                    );
                })}
            </VSCard>
        </Grid>
    );
}
