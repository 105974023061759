import { IconButton } from "@mui/material";
import ArrowLeft from "assets/icons/arrowleft.svg";
import ArrowRight from "assets/icons/arrowright.svg";
import FeatureImg3 from "assets/images/Dashboard.svg";
import FeatureImg1 from "assets/images/VSets - CoPilot.svg";
import FeatureImg4 from "assets/images/VSets - Sorting-1.svg";
import FeatureImg2 from "assets/images/VSets - Survey Select-1.svg";
import React, { useRef } from "react";
import Slider from "react-slick";
import "./FeatureSlides.css";

const FeaturesSlides: React.FC = () => {
    const sliderRef = useRef<Slider>(null);

    const features = [
        {
            image: FeatureImg1,
            title: "AI CoPilots: On-Demand Analysis, Insights, and Coaching",
            description: `VSorts™ AI CoPilots, positioned as data analysts or coaches, streamline qualitative data analysis by delivering contextually nuanced, on-demand interpretations with human oversight. Serving as analytical partners or interpretive guides, they enhance the value of qualitative data by ensuring comprehensive coverage, transparency, accessibility, and deeper understanding for all stakeholders.`,
        },
        {
            image: FeatureImg2,
            title: "Forms, Surveys, and Polls",
            description: `Boost the efficiency of qualitative analysis for forms, surveys, and polls with VSorts™ AI. Our advanced platform, driven by AI CoPilots trained by subject matter experts, delivers rapid, on-demand qualitative data insights. By eliminating unused data and reducing cognitive fatigue, VSorts™ can save you up to 75% of your time. Access pre-analyzed or real-time insights instantly, making decision-making effortless and effective, especially for open-ended responses.`,
        },
        {
            image: FeatureImg3,
            title: "Dashboard",
            description: `The VSorts™ AI Dashboard, with our proprietary Cluster Deployment System (CDS), transforms qualitative data management. It streamlines data ingestion through a centralized system with curated activities and now includes AI CoPilots to facilitate inquiry and provide user coaching. This tool optimizes data collection, analysis, storage, and visualization, making it essential for researchers and consultants seeking efficient qualitative data solutions.`,
        },
        {
            image: FeatureImg4,
            title: "VSets",
            description: `VSets, a core feature of VSorts™ AI, swiftly assesses competencies, social risks, and biases for research and practical applications. Providing deep insights into individual and group perspectives, VSets is an invaluable tool for organizational development, team coaching, and self-improvement in educational and corporate settings.`,
        },
    ];

    const handleClickNext = () => {
        if (sliderRef.current) {
            sliderRef.current.slickNext();
        }
    };

    const handleClickPrevious = () => {
        if (sliderRef.current) {
            sliderRef.current.slickPrev();
        }
    };

    const settings = {
        infinite: features.length > 0,
        useTransform: false,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        fade: true,
    };

    return (
        <div className="featureSlideWrapper">
            <Slider ref={sliderRef} {...settings}>
                {features.map((feature) => {
                    return (
                        <div>
                            <div className="featureSlideContainer">
                                <div className="featureSlideImageContainer">
                                    <img src={feature.image} alt="Features" className="featureSlideImage" />
                                </div>
                                <div className="featureSlideContentContainer">
                                    <div>
                                        <div>
                                            <h2 className="featureSlideContentTitle">{feature.title}</h2>
                                        </div>

                                        <div className="featureSlideContainerContent">{feature.description}</div>
                                    </div>

                                    <div>
                                        <IconButton disableRipple onClick={() => handleClickPrevious()}>
                                            <img src={ArrowLeft} alt="Left" />
                                        </IconButton>
                                        <IconButton disableRipple onClick={() => handleClickNext()}>
                                            <img src={ArrowRight} alt="Right" />
                                        </IconButton>
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </Slider>
        </div>
    );
};

export default FeaturesSlides;
