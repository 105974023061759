import { Box, IconButton, Typography } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import DragIcon from "assets/icons/drag.svg";
import TrashIcon from "assets/icons/trash-red.svg";
import dayjs from "dayjs";
import AdminSelect from "pages/adminV2/Components/AdminSelect";
import Button from "pages/adminV2/Components/Button";
import { ClusterStep } from "types/clusters";
import styles from "./styles";
type props = {
    clusterStep: ClusterStep;
    formTypeContainers: (formType: string) => any[];
    handleActivityTypeChange: (step: ClusterStep) => void; //pass updated step,
    handleActivityChange: (step: ClusterStep) => void; //pass updated step,
    handleActivityDelete: (step: ClusterStep) => void;
    isView: boolean;
    handleStartDateStatusChange: (step: ClusterStep, status: boolean) => void;
};

function ClusterStepItem({
    clusterStep,
    formTypeContainers,
    handleActivityTypeChange,
    handleActivityChange,
    handleActivityDelete,
    isView,
    handleStartDateStatusChange,
}: props) {
    console.log("clusterStep", clusterStep);
    const formTypes = [
        {
            text: "VSets",
            value: "vsorts",
        },
        {
            text: "Unpacking",
            value: "unpacking",
        },
        {
            text: "Form",
            value: "presurvey",
        },
    ];

    // get activities based on activity type
    function formTypesWithOption(formType: string) {
        let formContainers = formTypeContainers(formType);
        return (formContainers = formContainers.map((container: any) => {
            return {
                ...container,
                text: container?.container_name,
                value: container?.id,
            };
        }));
    }

    const handleActivityTypeOnChange = (value: any) => {
        clusterStep.formType = value;
        handleActivityTypeChange(clusterStep);
    };

    const handleActivityOnChange = (value: any) => {
        clusterStep.containerId = value;
        handleActivityChange(clusterStep);
    };

    const handleStartDateChange = (startDateStatus: boolean) => {
        if (startDateStatus == false) {
            clusterStep.start_date = "";
        }
        handleStartDateStatusChange(clusterStep, startDateStatus);
    };

    const handleRemove = () => {
        handleActivityDelete(clusterStep);
    };

    const handleStartDate = (date: any) => {
        clusterStep.start_date = dayjs(date.$d).format("YYYY-MM-DD");
        handleActivityChange(clusterStep);
    };
    return (
        <>
            <Box sx={styles.paperContainer}>
                <Box sx={styles.container}>
                    <IconButton disableRipple>
                        <img src={DragIcon} alt="frag" />
                    </IconButton>

                    {/* select activity type */}
                    <Box sx={{ ...styles.rowContainer, flex: 1 }}>
                        <Typography sx={styles.small}>Select an activity type</Typography>
                        <AdminSelect
                            style={{ padding: "10px" }}
                            placeholder="Select type"
                            options={formTypes}
                            selectedValue={clusterStep.formType}
                            onChange={handleActivityTypeOnChange}
                            readOnly={isView}
                        />
                    </Box>

                    {/* Select activity */}
                    <Box sx={{ ...styles.rowContainer, flex: 3 }}>
                        <Typography sx={styles.small}>Select an activity</Typography>
                        <AdminSelect
                            style={{ padding: "10px" }}
                            placeholder="Select activity"
                            options={formTypesWithOption(clusterStep.formType ?? "")}
                            selectedValue={clusterStep.containerId ?? ""}
                            onChange={handleActivityOnChange}
                            readOnly={isView}
                        />
                    </Box>

                    {!isView && (
                        <Box sx={{ alignContent: "center" }}>
                            <IconButton sx={styles.icon} onClick={handleRemove}>
                                <img src={TrashIcon} alt="frag" />
                            </IconButton>
                        </Box>
                    )}
                </Box>
                <Box sx={styles.container}>
                    <IconButton disableRipple></IconButton>
                    {clusterStep?.startDatePresent ? (
                        <Box sx={{ ...styles.rowContainer, paddingLeft: "10px" }}>
                            <Typography sx={styles.small}>Start date</Typography>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DemoContainer components={["DatePicker"]}>
                                    <DatePicker
                                        value={dayjs(clusterStep.start_date?.split("T")[0])}
                                        onChange={(date: any) => handleStartDate(date)}
                                        readOnly={isView}
                                        sx={{
                                            "& .MuiOutlinedInput-root": {
                                                padding: "",
                                                fontSize: "14px",
                                                color: "#111111",
                                            },
                                            "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                                                borderRadius: "9px",
                                                padding: "6px 1rem",
                                                fontSize: "14px",
                                                color: "#111111",
                                            },
                                        }}
                                    />
                                </DemoContainer>
                            </LocalizationProvider>
                            {!isView && (
                                <Button
                                    style={{ color: "red" }}
                                    type="normal"
                                    text="Remove start date"
                                    onClick={() => handleStartDateChange(false)}
                                />
                            )}
                        </Box>
                    ) : (
                        <>
                            {!isView && (
                                <Button
                                    style={{ color: "#29ABE2" }}
                                    type="normal"
                                    text="Add start date"
                                    onClick={() => handleStartDateChange(true)}
                                />
                            )}
                        </>
                    )}
                </Box>
            </Box>
        </>
    );
}

export default ClusterStepItem;
