import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Box, Typography } from "@mui/material";
import { useGetAllCoPilotsAssigned } from "hooks/CoPilots";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "../Clusters/styles";
import IndividualCoPilot from "./individualCoPilot";
import individualStyles from "./individualStyles";
export default function CoPilotList() {
    const navigate = useNavigate();
    const [coPilotsList, setCoPilotsList] = useState<any>([]);
    const [coPilotsListId, setCoPilotsListId] = useState<any>([]);
    const onSuccessFetch = (data: any) => {
        setCoPilotsList(data);
        setCoPilotsListId(data[0].id);
    };

    const onErrorFetch = (error: any) => {
        undefined;
    };
    const {
        isFetched,
        isFetching: isDataFetching,
        isLoading: isDataLoading,
        isError: isDataError,
        data: allData,
        error,
        refetch: refetchData,
    } = useGetAllCoPilotsAssigned({
        isEnabled: true,
        onSuccess: onSuccessFetch,
        onError: onErrorFetch,
    });

    return (
        <Box sx={styles.container}>
            <Box sx={{ ...styles.clusters }}>
                {coPilotsList?.map((eachCoPilot: any, index: any) => {
                    return (
                        <Box sx={styles.cluster} key={index} id={`copilot-${index}`}>
                            <IndividualCoPilot coPilot={eachCoPilot} index={index} coPilotsListId={coPilotsListId} />
                        </Box>
                    );
                })}
                {coPilotsList.length && (
                    <>
                        <Box
                            sx={styles.cluster}
                            style={{
                                borderColor: "#29ABE2",
                                background: "#F3F8FB",
                            }}
                        >
                            <div style={{ width: "100%" }}>
                                <InfoOutlinedIcon sx={{ color: "#29ABE2", marginBottom: "20px" }} />

                                <Typography sx={individualStyles.title}>
                                    What are CoPilots and what do they do?
                                </Typography>
                                <Typography sx={individualStyles.subTitle}>
                                    CoPilots are advanced AI tools designed to enhance decision-making in educational
                                    roles. They provide real-time support by analyzing inquiries, offering actionable
                                    insights, and suggesting strategies. CoPilots support personal growth and help
                                    create more effective, inclusive, and responsive educational environments.{" "}
                                </Typography>
                            </div>
                        </Box>

                        {/* <Box
                            sx={styles.cluster}
                            style={{
                                borderColor: "#29ABE2",
                                background: "#F3F8FB",
                            }}
                        >
                            <div style={{ width: "100%" }}>
                                <InfoOutlinedIcon sx={{ color: "#29ABE2", marginBottom: "10px" }} />

                                <Typography sx={individualStyles.title}>How do I get more tokens?</Typography>
                                <Typography sx={individualStyles.subTitle}>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec mattis fringilla
                                    ullamcorper. Phasellus massa turpis, semper id maximus sed, viverra a metus.
                                    Praesent velit leo, faucibus ultricies lacus eu, suscipit eleifend metus. Integer
                                    vel vehicula enim, a pulvinar lorem. Etiam sit amet tempor turpis, id tempor sapien.
                                </Typography>
                            </div>
                        </Box> */}
                    </>
                )}
            </Box>
        </Box>
    );
}
