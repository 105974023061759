import { useMutation, useQuery, useQueryClient } from "react-query";

// Axios Interceptor
import { request } from "utils/axios-utils";

// Constants
import { APIMethodConstants } from "constants/api.method.constants";
import { NetworkConstants } from "constants/network.constants";
import { QueryKeyConstants } from "constants/query.key.constants";

const fetchAllCoPilots = () => {
    return request({
        url: `${NetworkConstants.fetchAllCoPilots}`,
    });
};

const fetchSpecificCoPilots = (coPilotID: any) => {
    return request({
        url: `${NetworkConstants.fetchAllCoPilots}/${coPilotID}`,
    });
};

const fetchAdminCoPilotResponses = (coPilotID: any) => {
    return request({
        url: `${NetworkConstants.fetchAdminCoPilotResponses}/${coPilotID}`,
    });
};

const fetchAdminCoPilotList = () => {
    return request({
        url: `${NetworkConstants.fetchAdminCoPilotList}`,
    });
};

const fetchPreviousEntries = (coPilotID: any) => {
    return request({
        url: `${NetworkConstants.fetchPreviousEntries}/${coPilotID}`,
    });
};

const fetchCoPilotPublicStatus = (coPilotID: any) => {
    return request({
        url: `${NetworkConstants.revertIsPublic}/${coPilotID}`,
    });
};

const fetchCoPIlotQueryCount = () => {
    return request({
        url: `${NetworkConstants.fetchCoPilotQueryCount}`,
    });
};

const fetchCoPilotEngines = () => {
    return request({
        url: `${NetworkConstants.fetchCoPilotEngines}`,
    });
};

const callAICoPilot = ({ data, coPilotID }: any) => {
    return request({
        url: `${NetworkConstants.fetchAllCoPilots}/${coPilotID}`,
        method: APIMethodConstants.post,
        data: data,
    });
};

const saveAiCoPilot = ({ data, coPilotID }: any) => {
    return request({
        url: `${NetworkConstants.saveCoPilot}/${coPilotID}`,
        method: APIMethodConstants.put,
        data: data,
    });
};

const duplicateCoPilot = ({ coPilotID, data }: any) => {
    return request({
        url: `${NetworkConstants.duplicateCoPilot}/${coPilotID}`,
        method: APIMethodConstants.post,
        data: data,
    });
};

const revertIsPublic = ({ coPilotID }: any) => {
    return request({
        url: `${NetworkConstants.revertIsPublic}/${coPilotID}`,
        method: APIMethodConstants.put,
    });
};

const deleteCoPilot = ({ data }: any) => {
    return request({
        url: `${NetworkConstants.deleteCoPilot}`,
        method: APIMethodConstants.delete,
        data: data,
    });
};

const createCoPilotTitle = ({ data }: any) => {
    return request({
        url: `${NetworkConstants.createCoPilotTitle}`,
        method: APIMethodConstants.post,
        data: data,
    });
};

export const deployCoPilot = ({ apiMethodType, coPilotID }: any) => {
    return request({
        url: NetworkConstants.deployCoPilot,
        method: apiMethodType,
        data: {
            coPilotID: coPilotID,
        },
    });
};

export const useGetAllCoPilotsAssigned = ({ onSuccess, onError }: any) => {
    return useQuery([QueryKeyConstants.fetchAllCoPilots], () => fetchAllCoPilots(), {
        // staleTime: Infinity,
        onSuccess,
        onError,
        select: (data) => {
            return data.data.data;
        },
    });
};

export const useGetAllCoPilotsAdmin = ({ coPilotID, onSuccess, onError }: any) => {
    return useQuery([QueryKeyConstants.fetchAdminCoPilotList], () => fetchAdminCoPilotList(), {
        staleTime: Infinity,
        onSuccess,
        onError,
        select: (data) => {
            return data.data.data;
        },
    });
};

export const useGetSpecificCoPilot = ({ coPilotID, onSuccess, onError }: any) => {
    return useQuery([QueryKeyConstants.fetchSpecificCoPilots], () => fetchSpecificCoPilots(coPilotID), {
        staleTime: Infinity,
        onSuccess,
        onError,
        select: (data) => {
            return data.data.data;
        },
    });
};

export const useGetAdminCoPilotResponses = ({ coPilotID, onSuccess, onError }: any) => {
    return useQuery(
        [QueryKeyConstants.fetchAdminCoPilotResponses, coPilotID],
        () => fetchAdminCoPilotResponses(coPilotID),
        {
            staleTime: Infinity,
            onSuccess,
            onError,
            select: (data) => {
                return data.data.data;
            },
        }
    );
};

export const useGetPreviousEntries = ({ coPilotID, onSuccess, onError, isEnabled }: any) => {
    return useQuery([QueryKeyConstants.fetchPreviousEntries], () => fetchPreviousEntries(coPilotID), {
        // staleTime: Infinity,
        enabled: isEnabled,
        onSuccess,
        onError,
        select: (data) => {
            return data.data.data;
        },
    });
};

export const useGetIsPublicStatus = ({ coPilotID, onSuccess, onError, isEnabled }: any) => {
    return useQuery([QueryKeyConstants.fetchCoPilotIsPublicStatus], () => fetchCoPilotPublicStatus(coPilotID), {
        // staleTime: Infinity,
        enabled: isEnabled,
        onSuccess,
        onError,
        select: (data) => {
            return data.data.data;
        },
    });
};

export const useCoPilotQueryCount = ({ onSuccess, onError }: any) => {
    return useQuery([QueryKeyConstants.fetchCoPIlotQueryCount], () => fetchCoPIlotQueryCount(), {
        // staleTime: Infinity,
        onSuccess,
        onError,
        select: (data) => {
            return data.data.data;
        },
    });
};

export const useGetAllCoPilotEngines = ({ onSuccess, onError }: any) => {
    return useQuery([QueryKeyConstants.fetchCoPilotEngines], () => fetchCoPilotEngines(), {
        staleTime: Infinity,
        onSuccess,
        onError,
        select: (data) => {
            return data.data.data;
        },
    });
};

export const useCallAICopilot = ({ coPilotID, data, onSuccess, onError }: any) => {
    return useMutation(() => callAICoPilot({ data: data, coPilotID: coPilotID }), {
        onSuccess: onSuccess,
        onError: onError,
    });
};

export const useSavingCoPilot = ({ coPilotID, data, onSuccess, onError }: any) => {
    return useMutation(() => saveAiCoPilot({ data: data, coPilotID: coPilotID }), {
        onSuccess: onSuccess,
        onError: onError,
    });
};

export const useDuplicatingCoPilot = ({ coPilotID, data, onSuccess, onError }: any) => {
    return useMutation(() => duplicateCoPilot({ data: data, coPilotID: coPilotID }), {
        onSuccess: onSuccess,
        onError: onError,
    });
};

export const useRevertIsPublic = ({ coPilotID, onSuccess, onError }: any) => {
    return useMutation(() => revertIsPublic({ coPilotID: coPilotID }), {
        onSuccess: onSuccess,
        onError: onError,
    });
};

export const useDeleteCoPilot = ({ data, onSuccess, onError }: any) => {
    return useMutation(() => deleteCoPilot({ data: data }), {
        onSuccess: onSuccess,
        onError: onError,
    });
};

export const useCreateCoPilotTitle = () => {
    const queryClient = useQueryClient();

    return useMutation(
        (data: any) => {
            return createCoPilotTitle({ data: data });
        },
        {
            onSuccess: (data: any) => {
                queryClient.invalidateQueries(QueryKeyConstants.createCoPilotTitle);
            },
        }
    );
};

export const useDeployCoPilot = () => {
    const queryClient = useQueryClient();
    return useMutation(({ coPilotID, apiMethodType }: any) => deployCoPilot({ coPilotID, apiMethodType }), {
        onSuccess: () => {
            queryClient.invalidateQueries(QueryKeyConstants.createCoPilotTitle);
        },
    });
};
